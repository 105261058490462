import { Modal, Button, Form } from "react-bootstrap";
import React, { useState } from "react";
import { FormControl } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import ShowError from "../../FormValidation/ShowError";
import PropTypes from "prop-types";
import Select from "react-select";
import { spamConfidenceLevelList } from "../../../DummyData/Aggregators/Aggregators";
// import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import CreateButton from "../../../components/Buttons/CreateEntity";
import CreateSpamFilterDrawer from "../MUIModals/MUICreateSpamFilterModal";

const CreateTemplateModal = (props) => {
  // const { rowData, setRowData } = props;
  const data = props.data;
  // For validation
  const templateNameError = "";
  const messageError = "";
  // const data = props?.data
  const [filterName, setFilterName] = useState(
    props.approvalRequest === true ? data?.name : "",
  );
  // const [spamLevel, setSpamLevel] = useState(props.approvalRequest===true?data.requestedState.confidence:"")
  const [spamLevel, setSpamLevel] = useState(
    props.approvalRequest === true
      ? spamConfidenceLevelList.find(
          (item) => item.value === data?.confidence.toString(),
        )
      : "",
  );
  const [filterRule, setFilterRule] = useState(
    props.approvalRequest === true ? data?.keyword : "",
  );
  const axiosPrivate = CreateAxiosInstance();
  // const [filterSensitivity, setFilterSensitivity] = useState(1);

  const dispatch = useDispatch();

  const handleClick = async (e, hideModal) => {
    e.preventDefault();
    hideModal();
    props.setShowAgGrid(false);
    const payload = {
      name: filterName,
      keyword: filterRule,
      confidence: spamLevel.value,
      spamFilterType: props.notificationType,
    };
    await axiosPrivate
      .post(`${process.env.REACT_APP_SPAM_FILTER_CREATE}`, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message }),
          );
          props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.error(err);
        {
          props.memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Failed To Create Spam Filter.",
                }),
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                }),
              );
        }
        props.setShowAgGrid(true);
      });
  };
  const handleAccept = async (e, hideModal) => {
    e.preventDefault();
    hideModal();
    props.setShowAgGrid(false);
    const payload = {
      status: "APPROVED",
      entity: "SPAM_FILTER",
      entityId:
        data.approvalEntityId === null ? data.id : data.approvalEntityId,
    };
    await axiosPrivate
      .post(process.env.REACT_APP_ACCEPT_REJECT_REQUEST, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message }),
          );
          props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          toggleFailAlert({
            title: "Failed!",
            message: "Unable to process request.",
          }),
        );
        props.setShowAgGrid(true);
      });
  };
  const handleReject = async (e, hideModal) => {
    e.preventDefault();
    hideModal();
    props.setShowAgGrid(false);
    const payload = {
      status: "REJECTED",
      entity: "SPAM_FILTER",
      entityId:
        data.approvalEntityId === null ? data.id : data.approvalEntityId,
    };
    await axiosPrivate
      .post(process.env.REACT_APP_ACCEPT_REJECT_REQUEST, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message }),
          );
          props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          toggleFailAlert({
            title: "Failed!",
            message: "Unable to process request",
          }),
        );
        props.setShowAgGrid(true);
      });
  };

  // const getFilterSensitivityDescription = () => {
  //   switch (Number(filterSensitivity)) {
  //     case 1:
  //       return (
  //         <div>
  //           <p style={{ fontWeight: 800, margin: 0 }} className="text-danger">
  //             Low
  //           </p>
  //           <ul style={{ margin: "0" }}>
  //             <li>Fewer checks, fastest performance</li>
  //             <li> Recommended for promotional messages</li>
  //           </ul>
  //         </div>
  //       );
  //     case 2:
  //       return (
  //         <div>
  //           <p style={{ fontWeight: 800, margin: 0 }}>Medium</p>
  //           <ul style={{ margin: "0" }}>
  //             <li>More number of checks, decent performance</li>
  //             <li> Recommended for OTP and Transactional messages</li>
  //           </ul>
  //         </div>
  //       );
  //     case 3:
  //       return (
  //         <div>
  //           <p style={{ fontWeight: 800, margin: 0 }} className="text-success">
  //             High
  //           </p>
  //           <ul style={{ margin: "0" }}>
  //             <li>Most number of checks, slowest performance</li>
  //             <li> Recommended for OTP and Transactional messages</li>
  //           </ul>
  //         </div>
  //       );
  //   }
  // };

  return (
    <Modal
      {...props}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.approvalRequest
            ? data.approvalOperation === "CREATE"
              ? "Add Filter"
              : "Delete Filter"
            : props.memberRole === "TS_ADMIN"
            ? "Add Filter"
            : "Add Filter Request"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group id="filterName">
            <div className="mb-3">
              <Form.Label>Filter Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={filterName}
                disabled={props.approvalRequest === true ? true : false}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder="Enter Filter Name"
              />
              {templateNameError && <ShowError msg={templateNameError} />}
            </div>
          </Form.Group>
          <Form.Group id="filterRule">
            <div className="mb-3">
              <Form.Label>Keyword</Form.Label>
              <FormControl
                as="textarea"
                placeholder="Add Keyword Here"
                rows={5}
                aria-label="With textarea"
                disabled={props.approvalRequest === true ? true : false}
                value={filterRule}
                onChange={(e) => setFilterRule(e.target.value)}
              />
              {messageError && <ShowError msg={messageError} />}
            </div>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Choose Spam Confidence level</Form.Label>
            <Select
              placeholder="Choose Spam Confidence Level"
              onChange={(e) => setSpamLevel(e)}
              isDisabled={props.approvalRequest === true}
              value={spamLevel}
              options={spamConfidenceLevelList.map((option) => {
                return {
                  label: option.label,
                  value: option.value,
                };
              })}
            />
            {/* <option value="otp">OTP</option> */}
          </Form.Group>

          {/* <Form.Group id="filterSensitivity">
            <div className="mb-3">
              <Form.Label>Spam Confidence Level</Form.Label>
              <Form.Range
                min={1}
                max={3}
                value={filterSensitivity}
                onChange={(e) => {
                  setFilterSensitivity(e.target.value);
                }}
              />
              {getFilterSensitivityDescription()}
              {messageError && <ShowError msg={messageError} />}
            </div>
          </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {props.approvalRequest && props.handleRequest === "APPROVED" && (
          <Button
            type="submit"
            onClick={(e) => handleAccept(e, props.onHide)}
            disabled={props.memberRole === "TS_USER"}
          >
            Accept
          </Button>
        )}
        {props.approvalRequest && props.handleRequest === "REJECTION" && (
          <Button
            type="submit"
            onClick={(e) => handleReject(e, props.onHide)}
            disabled={props.memberRole === "TS_USER"}
          >
            Reject
          </Button>
        )}
        {props.approvalRequest && !props.handleRequest && (
          <>
            <Button
              type="submit"
              onClick={(e) => handleAccept(e, props.onHide)}
              disabled={props.memberRole === "TS_USER"}
            >
              Accept
            </Button>
            <Button
              type="submit"
              onClick={(e) => handleReject(e, props.onHide)}
              disabled={props.memberRole === "TS_USER"}
            >
              Reject
            </Button>
          </>
        )}
        {(!props.approvalRequest || data.approvalStatus === "APPROVED") && (
          <Button
            type="submit"
            onClick={(e) => handleClick(e, props.onHide)}
            disabled={!(filterName && filterRule && spamLevel)}
          >
            Add
          </Button>
        )}
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
CreateTemplateModal.propTypes = {
  rowData: PropTypes,
  setRowData: PropTypes,
  onHide: PropTypes,
  notificationType: PropTypes,
  token: PropTypes,
  data: PropTypes,
  approvalRequest: PropTypes,
  memberRole: PropTypes,
  setShowAgGrid: PropTypes,
  handleRequest: PropTypes,
};

export default function App({
  token,
  editModalShow,
  rowData,
  setEditModalShow,
  setRowData,
  notificationType,
  data,
  approvalRequest,
  memberRole,
  setShowAgGrid,
  handleRequest,
  refreshCache
}) {
  const [modalShow, setModalShow] = React.useState(
    editModalShow ? editModalShow : false,
  );
  const [showCreateRecipientDrawer, setShowCreateRecipientDrawer] =
    useState(false);
  const handleCloseModal = () => {
    if (approvalRequest) {
      setEditModalShow(false);
    }
    setModalShow(false); // Close modal
  };
  App.propTypes = {
    token: PropTypes,
    rowData: PropTypes,
    setRowData: PropTypes,
    notificationType: PropTypes,
    approvalRequest: PropTypes,
    editModalShow: PropTypes,
    setEditModalShow: PropTypes,
    data: PropTypes,
    memberRole: PropTypes,
    setShowAgGrid: PropTypes,
    handleRequest: PropTypes,
    refreshCache: PropTypes
  };

  return (
    <>
      {approvalRequest === true ? (
        <></>
      ) : (
        <CreateButton
        onClick={() => setShowCreateRecipientDrawer(true)}
          btnText={
            memberRole === "TS_ADMIN" ? "Add a filter" : "Add a filter Request"
          }
        />
      )}
       {
          showCreateRecipientDrawer &&
          <CreateSpamFilterDrawer
          openDrawer={showCreateRecipientDrawer}
          setOpenDrawer={setShowCreateRecipientDrawer}
          token={token}
          memberRole={memberRole}
          notificationType={notificationType}
          setShowAgGrid={setShowAgGrid}
          refreshCache={refreshCache}
        />}
      {modalShow && (
        <CreateTemplateModal
          show={modalShow}
          token={token}
          onHide={handleCloseModal}
          data={data}
          rowData={rowData}
          setRowData={setRowData}
          notificationType={notificationType}
          approvalRequest={approvalRequest}
          handleRequest={handleRequest}
          memberRole={memberRole}
          setShowAgGrid={setShowAgGrid}
        />
      )}
    </>
  );
}
