import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  width: 40,
  borderRadius: "500px",
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    width: 40,
    borderRadius: "500px",
    height: 4.5,
    backgroundColor: "currentColor",
  },
  "& .QontoStepIcon-circle": {
    width: 40,
    height: 4.5,
    borderRadius: "500px",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon({ active, completed, className }) {
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const CustomizedSteppers = ({ steps, activeStep }) => {
  return (
    <Stack
      sx={{
        width: "50%",
        margin: "0 auto",
        ".MuiStepper-root": {
          margin: "0 auto !important",
          marginBottom: "30px !important",
        },
        ".MuiStep-root": {
            maxWidth: "45px"
        }
      }}
      spacing={4}
    >
      <Stepper alternativeLabel activeStep={activeStep} connector={<div />}>
        {Array.from({ length: steps }, (_, i) => (
          <Step key={i}>
            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

CustomizedSteppers.propTypes = {
  steps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default CustomizedSteppers;
