import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
// import { axiosWithBaseUrl } from "../../axios/baseUrl";
import { FormControl } from "@themesberg/react-bootstrap";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import Loader from "react-spinners/ClipLoader";
import { toggleAlert, toggleFailAlert } from "../../Redux/Reducers/alertsReducers";
import CreateButton from "../../components/Buttons/CreateEntity";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import ShowError from "../FormValidation/ShowError";
import validate from "../FormValidation/validation";
import MUICreateContactList from "../Modals/MUIModals/MUICreateContactList";

function CreateGroupModal(props) {
    const axiosPrivate = CreateAxiosInstance();
    const [name, setName] = useState('');
    const isLoading = false;
    const [nameError, setNameError] = useState("");
    const dispatch = useDispatch();

    const createGroup = async (payload, hideModal) => {
        hideModal()
        props.setShowAgGrid(false)
        await axiosPrivate.post(`${process.env.REACT_APP_NEW_GROUP}`, payload,
            {
                headers: {
                    "Authorization": `Bearer ${props.token}`
                }
            })
            .then(res => {
                if (res?.data?.status === 200) {
                    dispatch(toggleAlert({ title: "Success!", message: res.data.message }));
                    props.setShowAgGrid(true)

                }
            })
            .catch(err => {
                console.error(err)
                {props.memberRole === "TS_ADMIN" ? dispatch(toggleFailAlert({ title: "Failed!", message: "Group could not be created." })) : dispatch(toggleFailAlert({ title: "Failed!", message: "Request not raised." }))}
                props.setShowAgGrid(true)

            })
    }

    const handleClick = (e, hideModal) => {
        e.preventDefault();


        //Validate form
        if (validate(name, "isEmpty")) {
            setNameError("Group Name cannot be empty");
            return;
        }
        else {
            setNameError("");
        }

        const newGroup = { name};

        createGroup(newGroup, hideModal);
    };

    return (
        <Modal
            {...props}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.memberRole === "TS_ADMIN" ? "Create Group" : "Create Group Request"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ? <Loader /> :
                        <Form>
                            <Form.Group id="name">
                                <div className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <FormControl
                                        aria-label="With text"
                                        value={name}
                                        placeholder="Name for this group"
                                        onChange={e => setName(e.target.value)}
                                    />
                                    {nameError && <ShowError msg={nameError} />}

                                </div>
                            </Form.Group>
                        </Form>

                }
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" onClick={(e) => handleClick(e, props.onHide)}>
                    Create
                </Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
CreateGroupModal.propTypes = {
    token: PropTypes,
    url: PropTypes,
    onHide: PropTypes,
    setDataRendered: PropTypes,
    setShowAgGrid: PropTypes,
    memberRole: PropTypes
}

export default function App({ token, memberRole, setShowAgGrid, refreshCache }) {
    App.propTypes = {
        token: PropTypes,
        setShowAgGrid: PropTypes,
        memberRole: PropTypes,
        refreshCache: PropTypes
    };
    const [modalShow, setModalShow] = React.useState(false);
    const [showCreateContactList, setShowCreateContactList] =
    useState(false);

    return (
        <>
        <CreateButton onClick={() => setShowCreateContactList(true)} btnText={memberRole === "TS_ADMIN" ? "Create Group" : "Create Group Request"} />
                   {
                modalShow && <CreateGroupModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    token={token}
                    memberRole={memberRole}
                    refreshCache={refreshCache}
                    setShowAgGrid={setShowAgGrid}
                />
            }
        {
          showCreateContactList &&
          <MUICreateContactList
          openDrawer={showCreateContactList}
          setOpenDrawer={setShowCreateContactList}
          setShowAgGrid={setShowAgGrid}
          memberRole={memberRole}
          token={token}
          refreshCache={refreshCache}
        //   setRefreshGrid={setRefreshGrid}
        />}

        </>
    );
}
