export const Routes = {
  // pages
  Homepage: { path: "/" },
  MIS: { path: "/dashboard" },
  Dashboard: { path: "/mis-dashboard/real-time-stats" },
  RTS: { path: "/mis-dashboard/real-time-stats" },
  MHS: { path: "/mis-dashboard/message-health-status" },
  AGS: { path: "/mis-dashboard/aggregator-stats" },
  EMAIL_RTS: { path: "/mis-dashboard/email-real-time-stats" },
  EMAIL_MHS: { path: "/email-dashboard/email-message-health-status" },
  EMAIL_AGS: { path: "/email-dashboard/email-aggregator-stats" },
  // PRTS: { path: "/pn-dashboard/push-notification-real-time-stats" },
  // WRTS: { path: "/wa-dashboard/whatsapp-real-time-stats" },
  Flash_RTS: { path: "/flash-dashboard/flash-real-time-stats" },

  SendMessages: { path: "/send-messages" },
  MessageTemplates: { path: "/templates/message-templates" },
  EmailTemplates: { path: "/templates/email-templates" },
  // WhatsappTemplates: { path: "/templates/whatsapp-templates" },
  // PNTemplates: { path: "/templates/push-notification-templates" },
  FlashTemplates: { path: "/templates/flash-templates" },

  SMSAggregators: { path: "/aggregators/sms" },
  SMSAggregator: { path: "/aggregators/sms/:aggregator" },

  EmailAggregators: { path: "/aggregators/email" },
  EmailAggregator: { path: "/aggregators/email/:aggregator" },

  // PushNotificationAggregators: { path: "/aggregators/push-notification" },
  // PushNotificationAggregator: {
  //   path: "/aggregators/push-notification/:aggregator",
  // },
  // WhatsappAggregators: { path: "/aggregators/whatsapp" },
  // WhatsappAggregator: { path: "/aggregators/whatsapp/:aggregator" },
  FlashAggregators: { path: "/aggregators/flash" },
  FlashAggregator: { path: "/aggregators/flash/:aggregator" },

  SMSSpamFilter: { path: "/spam-filters/sms-spam-filters" },
  EmailSpamFilter: { path: "/spam-filters/email-spam-filters" },
  FlashSpamFilter: { path: "/spam-filters/flash-spam-filters" },

  AuditLogs: { path: "/audit-logs" },

  APIKeys: { path: "/api-keys" },

  ApprovalRequest: {path:"/approval-request"},

  Entry: { path: "/entry" },
  Users: { path: "/users" },
  SMSContactList: { path: "/contact-list/sms-contact-list" },
  EmailContactList: { path: "/contact-list/email-contact-list" },
  FlashContactList: { path: "/contact-list/flash-contact-list" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },
};
