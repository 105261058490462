import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenu from "./UserMenu";
import SendMenu from "./SendMenu";
import PropTypes from "prop-types";
import {
  Nav,
  Badge,
  Image,
  Accordion,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { useSelector } from "react-redux";
import dashboard from "../assets/dashboard.svg";
import chat from "../assets/chat.svg";
import person from "../assets/person.svg";
import draft from "../assets/drafts.svg";
import key from "../assets/key.svg";
import gpp from "../assets/gpp_maybe.svg";
import thingsphere from "../assets/image 48.svg";
import demography from "../assets/demography.svg";
import done_all from "../assets/done_all.svg";
import groups from "../assets/groups.svg";
import expnadedIcon from "../assets/expandedIcon.svg";
import collapsedIcon from "../assets/collapsedIcon.svg";
import collapsedThing from "../assets/collapsedThing.svg";
// import Profile from "../assets/Profile.svg"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
// import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import { useHistory } from "react-router-dom";
import SubMenu from "./Modals/Common/subMenu";

const drawerWidth = 290;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowY:"visible",
  overflowX:"visible",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;
  // const showClass = show ? "show" : "";  
  const history = useHistory();
  
  
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );

  // const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const {
      eventKey,
      title,
      icon,
      custom,
      customIcon,
      children = null,
    } = props;
    CollapsableNavItem.propTypes = {
      eventKey: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.any,
      custom: PropTypes.string,
      customIcon: PropTypes.any,
      children: PropTypes.any,
    };
    const [subMenu, setSubMenu] = useState()
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    return (
      <Accordion
        as={Nav.Item}
        defaultActiveKey={defaultKey}
        className=" text-textPrimary accordion-button-hover "
        style={{ border: "none", }}
      >
        <Accordion.Item
          eventKey={eventKey}
          className={"text-textPrimary"}
          style={{ border: "none", backgroundColor: "transparent" , width: !open && "50px"}}
        >
          <Accordion.Button
            as={Nav.Link}
            // className="d-flex justify-content-between align-items-center bg-whiteBackground text-textPrimary"
            className={`d-flex justify-content-between align-items-center bg-whiteBackground text-textPrimary ${
              !open ? "accordion-button-collapsed" : "" 
            } ${( pathname.includes(eventKey) && !open)? "active-submenu":""}`}
            onClick={() => { !open && setSubMenu((prevSubMenu) => (prevSubMenu === eventKey ? null : eventKey))
               }}  
          >
            <span >
              {custom ? (
                customIcon
              ) : (
                <span className="sidebar-icon" >
                  <FontAwesomeIcon icon={icon} />{" "}
                </span>
              )}
              <span
                className="sidebar-text sidebar-nav-item-text"
                style={{
                  overflow: "hidden",
                  transition: "all",
                  // width: open ? "125px" : "0px",
                  display: !open && "none"
                }}
              >
                {title}
              </span>
              {!open && !subMenu && <div className="hover-title" style={{display: "none"}} >{title}</div>}
              {!open && <SubMenu isOpen={subMenu} onClose={()=>{setSubMenu(false)}} eventKey={eventKey} />}
            </span>
          </Accordion.Button>
          {open===true && <Accordion.Body
            className="multi-level text-textPrimary"
            id="mis-sidebar-accordion"
            style={{ border: "none" }}
          >
            <Nav
              className="flex-column"
              style={{ paddingLeft: "0px", marginInline: "12px" }}
            >
              {children}
            </Nav>
          </Accordion.Body>}
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    // const {expanded} = useContext(SidebarContext)
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      custom,
      customIcon,
      submenu,
    } = props;

    NavItem.propTypes = {
      title: PropTypes.string,
      link: PropTypes.any,
      external: PropTypes.any,
      target: PropTypes.any,
      icon: PropTypes.any,
      image: PropTypes.any,
      badgeText: PropTypes.string,
      badgeBg: PropTypes.any,
      badgeColor: PropTypes.any,
      custom: PropTypes.any,
      customIcon: PropTypes.any,
      submenu: PropTypes.object,
    };
    const classNames = badgeText
      ? `d-flex justify-content-start align-items-center justify-content-between`
      : "";
    const navItemClassName = link === pathname ? "active w-100" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };
    return (
      <Nav.Item
        className={`${navItemClassName} navbar-sideicon ${
          !open ? "nav-item-collapsed" : ""
        }`}
        style={
          submenu && {
            marginInlineStart: "0px",
            position: "relative",
            display: "flex",
            alignContent: "center",
            marginTop: "5px",
          }
        }
        // group
      >
        <Nav.Link
          {...linkProps}
          target={target}
          className={`${classNames}  text-textPrimary navbar-sideicon`}
          style={{ border: "none",width:open && "270px" }}
        >
          <span>
            {custom && customIcon}
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={50}
                height={50}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span
              className="sidebar-text sidebar-nav-item-text"
              style={{
                overflow: "hidden",
                transition: "all",
                // width: open ? "140px" : "0px",
                display: !open && "none" 
              }}
            >
              {title}
            </span>
      {!open && <div className="hover-title" style={{display:"none"}}>{title}</div>}
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2 navbar-sideicon"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };
  return(
  <div className="main-sidebar-container">
  <Box sx={{ display: 'flex', flexDirection: "column", height: "100%",}} className="show-text show-label-text">
      <CssBaseline />
      <Drawer variant="permanent" open={open} className="show-text-label show-text">
        <DrawerHeader  className="show-text show-label-text main-sidebar-container-color " style={{display:"flex",flexDirection:"column",justifyContent:"center",minHeight: open ? "64px" : "90px"}}>
          {
            open === false && <div style={{display:"flex", flexDirection:"column",gap:"24px",marginTop:"24px"}}>
              <div>
                <img src={collapsedThing} alt="Your Image" style={{cursor:"pointer"}} onClick={() => {
                  history.push(Routes.MIS.path);}}  />
              </div>
              <div>
                <img src={collapsedIcon} onClick={handleDrawerOpen} style={{cursor:"pointer"}}/>
              </div>
            </div>
          }
          {open === true && <div  style={{marginLeft: "auto", marginRight: "auto",}} className="icon-button">
          <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", gap:"65px"}}>
            <div>
              <img src={thingsphere} style={{cursor:"pointer"}} onClick={() => {
            history.push(Routes.MIS.path);
          }}  />
            </div>
            <div>
              <img src={expnadedIcon} onClick={handleDrawerClose} width="28px" height="28px" style={{cursor:"pointer"}} />
            </div>
          </div>
          </div>}
        </DrawerHeader>
        <div className="show-label-text main-sidebar-container-color" style={{display: "flex", flexDirection:"column",justifyContent:"space-between",height:"100vh",}} >
          <div>
        <List style={{display:"flex",flexDirection:"column",marginLeft:"10px",marginRight:"10px"}} className="show-label-text">
          <div className="sidebar show-text-label">
            <div className="show-text-label">
        
            <CollapsableNavItem
            eventKey="mis-dashboard/"
            title="Dashboard"
            // icon={faChartPie}
            custom={true}
            customIcon={
              <img
                src={dashboard}
                alt="rts-sidebar-icon"
                style={{
                  width: "26px",
                  height: "26px",
                  // filter: "invert(100%)",
                  marginInlineEnd: "12px",
                  // marginBottom: "4px"
                }}
              />
            }
          >
            <NavItem
              submenu={true}
              title="SMS MIS"
              link={Routes.RTS.path}
              custom={true}
              customIcon={
                <img
                  src={chat}
                  alt="rts-sidebar-icon"
                  style={{
                    width: "26px",
                    height: "26px",
                    // filter: "invert(100%)",
                    marginInlineEnd: "12px",
                    // marginBottom: "4px"
                  }}
                />
              }
            />
            <NavItem
              submenu={true}
              title="Email MIS"
              link={Routes.EMAIL_RTS.path}
              custom={true}
              customIcon={
                <img
                  src={draft}
                  alt="rts-sidebar-icon"
                  style={{
                    width: "26px",
                    height: "26px",
                    // filter: "invert(100%)",
                    marginInlineEnd: "12px",
                    // marginBottom: "2px"
                  }}
                />
              }
            />
            
          </CollapsableNavItem>
        {memberRole === "TS_ADMIN" && <SendMenu expanded={open} />}

              <CollapsableNavItem
                eventKey="templates/"
                title="Templates"
                custom={true}
                customIcon={
                  <img
                    src={draft}
                    alt="push-notification-sidebar-icon"
                    style={{
                      width: "26px",
                      height: "26px",
                      // filter: "invert(100%)",
                      marginInlineEnd: "10px",
                      // marginBottom: "2px"
                    }}
                  />
                }
                
              >
                <NavItem
                  title="SMS "
                  link={Routes.MessageTemplates.path}
                  submenu={true}
                  custom={true}
                  customIcon={
                    <img
                      src={chat}
                      alt="text-sms-sidebar-icon"
                      style={{
                        width: "26px",
                        height: "26px",
                        // filter: "invert(100%)",
                        marginInlineEnd: "12px",
                        // marginBottom: "4px"
                      }}
                    />
                  }
                />
                <NavItem
                  title="Email "
                  submenu={true}
                  link={Routes.EmailTemplates.path}
                  custom={true}
                  customIcon={
                    <img
                      src={draft}
                      alt="text-sms-sidebar-icon"
                      style={{
                        width: "26px",
                        height: "26px",
                        // filter: "invert(100%)",
                        marginInlineEnd: "12px",
                        // marginBottom: "2px"
                      }}
                    />
                  }
                />
                {/* <NavItem
                  title="Flash"
                  link={Routes.FlashTemplates.path}
                  icon={faBolt}
                /> */}
                    </CollapsableNavItem>
                    <CollapsableNavItem
                      eventKey="contact-list/"
                      title="Contact List"
                      // icon={faUsers}
                      custom={true}
                      customIcon={
                        <img
                          src={groups}
                          alt="sms-contact-list-sidebar-icon"
                          style={{
                            width: "26px",
                            height: "26px",
                            // filter: "invert(100%)",
                            marginInlineEnd: "12px",
                            // marginBottom: "4px"
                          }}
                        />
                      }
                    >
                      <NavItem
                        title="SMS"
                        submenu={true}
                        link={Routes.SMSContactList.path}
                        custom={true}
                        customIcon={
                          <img
                            src={chat}
                            alt="sms-contact-list-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "12px",
                              // marginBottom: "4px"
                            }}
                          />
                        }
                      />
                      <NavItem
                        title="Email"
                        submenu={true}
                        link={Routes.EmailContactList.path}
                        custom={true}
                        customIcon={
                          <img
                            src={draft}
                            alt="sms-contact-list-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "12px",
                              // marginBottom: "2px"
                            }}
                          />
                        }
                      />
                      {/* <NavItem
                  title="Flash"
                  submenu={true}
                  link={Routes.FlashContactList.path}
                  icon={faBolt}
                /> */}
                    </CollapsableNavItem>
                    <NavItem
                      title="Recipients"
                      link={Routes.Users.path}
                      custom={true}
                      customIcon={
                        <img
                          src={person}
                          alt="sms-contact-list-sidebar-icon"
                          style={{
                            width: "26px",
                            height: "26px",
                            marginInlineEnd: "12px",
                          }}
                        />
                      }
                    />
                    <CollapsableNavItem
                      eventKey="spam-filters/"
                      title="Spam Filters"
                      custom={true}
                      customIcon={
                        <img
                          src={gpp}
                          alt="push-notification-sidebar-icon"
                          style={{
                            width: "26px",
                            height: "26px",
                            // filter: "invert(100%)",
                            marginInlineEnd: "12px",
                            // marginBottom: "4px"
                          }}
                        />
                      }
                    >
                      <NavItem
                        title="SMS"
                        submenu={true}
                        link={Routes.SMSSpamFilter.path}
                        custom={true}
                        customIcon={
                          <img
                            src={chat}
                            alt="push-notification-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "12px",
                              // marginBottom: "4px"
                            }}
                          />
                        }
                      />
                      <NavItem
                        title="Email"
                        submenu={true}
                        link={Routes.EmailSpamFilter.path}
                        custom={true}
                        customIcon={
                          <img
                            src={draft}
                            alt="push-notification-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "12px",
                              // marginBottom: "2px"
                            }}
                          />
                        }
                      />
                      {/* <NavItem
                  title="Flash"
                  submenu={true}
                  link={Routes.FlashSpamFilter.path}
                  icon={faBolt}
                /> */}
                    </CollapsableNavItem>
                    {memberRole === "TS_ADMIN" && (
                      <NavItem
                        title="Audit Logs"
                        // icon={faFilter}
                        custom={true}
                        customIcon={
                          <img
                            src={demography}
                            alt="whatsapp-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "10px",
                              // marginBottom: "4px"
                            }}
                          />
                        }
                        link={Routes.AuditLogs.path}
                      />
                    )}
                    {memberRole === "TS_ADMIN" && (
                      <NavItem
                        title="API Documentation"
                        link={Routes.APIKeys.path}
                        custom={true}
                        customIcon={
                          <img
                            src={key}
                            alt="whatsapp-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "10px",
                              // marginBottom: "4px"
                            }}
                          />
                        }
                      />
                    )}
                    {memberRole === "TS_ADMIN" && (
                      <NavItem
                        title="Approval Request"
                        link={Routes.ApprovalRequest.path}
                        custom={true}
                        customIcon={
                          <img
                            src={done_all}
                            alt="whatsapp-sidebar-icon"
                            style={{
                              width: "26px",
                              height: "26px",
                              // filter: "invert(100%)",
                              marginInlineEnd: "12px",
                              // marginBottom: "4px"
                            }}
                          />
                        }
                      />
                    )}
                  </div>
                </div>
              </List>
            </div>
            <div>
              <div
                style={{
                  marginBottom: "12px",
                  marginLeft: "16px",
                  marginRight: "18px",
                }}
              >
                <UserMenu expanded={open} />
              </div>
            </div>
          </div>
        </Drawer>
      </Box>
    </div>
  );
};
export default Sidebar;
