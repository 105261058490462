import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
// import edit_icon from "../../assets/edit_icon.png";
// import UpdateTemplate from "../Modals/SpamFilterModals/UpdateSpamFilterModal";
import DeleteModal from "../Modals/Common/deleteModal";
// import UploadCSVModal from "../Modals/UploadCSV/UploadCSVModal";
import PropTypes from "prop-types";
import filterDemoData from "../../DummyData/filtersData/EmailSpamFilterData";
import CreateSpamFilterModal from "../Modals/SpamFilterModals/CreateSpamFilterModal";
// import { axiosWithBaseUrl } from "../../axios/baseUrl";
import {
  faCheck,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { getLastMonthDate } from "../../util/dateUtil";
import DownloadCSV from "../Buttons/DownloadCSV";
import EntityModal from "../Modals/entityDetailModal";
import DeleteEntity from "../Buttons/DeleteEntity";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import MUISMSSpamDetailModal from "../Modals/MUIModals/MUISMSSpamDetailModal";

const EmailSpamFiltersGrid = ({ token, memberRole }) => {
  const axiosPrivate = CreateAxiosInstance();
  const fromDate = getLastMonthDate();
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [showSpamDetailsDrawer, setShowSpamDetailsDrawer] =
    useState(false);
  const [rowData, setRowData] = useState(filterDemoData.data.spamFilterData);
  const [singleData, setSingleData] = useState([]);
  const [showAgGrid, setShowAgGrid] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [agGridData, setAgGridData] = useState();
  const gridRef = useRef();

  const [refreshGrid, setRefreshGrid] = useState(false);
  const masterDetail = true;

  const colDefs = [
    {
      headerName: "Group",
      field: "approvalRequestsCount",
      flex: 2,
      maxWidth: 50,
      valueFormatter: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        }
      },
      cellRendererSelector: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        } else {
          return {
            component: "agGroupCellRenderer",
          };
        }
      },
      // rowGroup: true,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Filter name",
      field: "name",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Keyword",
      field: "keyword",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Confidence",
      field: "confidence",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Operation",
      field: "approvalOperation",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Status",
      cellRenderer: "renderStatus",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Created by",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];
  const onCellClicked = (event) => {
    // If action buttons are clicked then don't show the drawer.
    if (event.colDef.headerName !== "Actions" && !showSpamDetailsDrawer) {
      setSelectedRowData(event.data);
      setShowSpamDetailsDrawer(true);
    }
  };

  const [columnDefs] = useState(colDefs);
  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      columnDefs: [
        { field: "name", headerName: "Filter name", flex: 1 },
        { field: "keyword", headerName: "Keyword", flex: 1 },
        { field: "confidence", headerName: "Confidence", flex: 1 },
        { field: "approvalOperation", headerName: "Operation", flex: 1 },
        {
          headerName: "Status",
          cellRendererFramework: (params) => (
            <StatusTypes {...params} />
          ),
          flex: 2,
          filter: "agTextColumnFilter",
          filterParams: { suppressAndOrCondition: true },
        },
        { field: "createdByName", headerName: "Created by", flex: 1 },
        {
          field: "action",
          headerName: "Action",
          headerClass: "cell-border-left",
          cellRendererFramework: (params) => (
            <ActionButtonsSubGrid {...params} />
          ),
        },
      ],
      domLayout: "autoWidth",
    },
    getDetailRowData: async (params) => {
      await axiosPrivate
        .post(
          process.env.REACT_APP_SPAM_FILTER,
          {
            startRow: 0,
            endRow: 100,
            sortModel: [],
            filterModel: {},
            notificationType: "EMAIL",
            approvalDocumentType: "REQUEST",
            approvalEntityId: params.data.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          if (!resp?.spamFilterDetails?.length) {
            params.successCallback([], 0);
          } else {
            params.successCallback(
              resp.spamFilterDetails,
              resp.spamFilterDetails.length,
            );
          }
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },

    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      display: "flex",
      //   flex:2
    },
  };
  const StatusTypes = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p
          style={{ fontSize: ".9rem", fontWeight: "600" }}
          className={`${getStatusType(params.data.approvalStatus)} capitalize`}
        >
          {params.data.approvalStatus?.toLowerCase()}
        </p>
      </div>
    );
  };
  const getStatusType = (type) => {
    switch (type) {
      case "APPROVED":
        return "recipient_status_approved";
      case "PENDING":
        return "recipient_status_pending";
      case "REJECTED":
        return "recipient_status_rejected";
    }
  };
  const ActionButtonsSubGrid = (params) => {
    // const handleApproveRequest = () => {
    //   setSingleData(params.data);
    //   setApprovalRequest(true);
    // };
    if (
      (params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") ||
      params.data.approvalStatus === "APPROVED" ||
      params.data.approvalStatus === "REJECTED"
    ) {
      return (
        <div className="">
          <div className="action-btn"></div>
        </div>
      );
    }
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_ADMIN") {
      return (
        <div className="d-flex gap-3">
          <div
            className="action-btn"
            onClick={() => {
              setShowSpamDetailsDrawer(true);
              setSelectedRowData(params.data)
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div
            className="action-btn"
            onClick={() => {
              setShowSpamDetailsDrawer(true);
              setSelectedRowData(params.data)
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      );
    }
    return null;
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    [],
  );
  const dimensions = { width: "100%" };

  // custom action components for the action field in ag grid
  const actionButtons = (params) => {
    const handleClick = () => {
      // setSelectedRowData(params.data)
      setSingleData(params.data);
      // setApprovalRequest(false);
    };
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") {
      return <div></div>
    }
    if(params.data.approvalStatus === "PENDING") {
      return (
        <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
         
        }}
      >
       <div className="action-btn">
       <FontAwesomeIcon icon={faCheck} style={{height: "14px", width: "14px"}}
          onClick={()=>{
            setShowSpamDetailsDrawer(true);
            setSelectedRowData(params.data)
          }}
          
        />
       </div>
        
       <div className="action-btn">
       <FontAwesomeIcon icon={faTimes} style={{height: "14px", width: "14px"}}
          onClick={()=>{
            setShowSpamDetailsDrawer(true);
            setSelectedRowData(params.data)
          }}
        />
       </div>
        { params.data.approvalOperation !== "CREATE" && <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />}
      </div>
      )
    }
    return (
      <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
        }}
      >
        <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />
      </div>
    );
  };

  const dataSource = {
    async getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
      await axiosPrivate
        .post(
          process.env.REACT_APP_SPAM_FILTER,
          {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            notificationType: "EMAIL",
            approvalDocumentType: "ENTITY",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          if (!resp?.spamFilterDetails?.length) {
            params.successCallback([], 0);
            params.api.showNoRowsOverlay();
          } else {
            params.successCallback(
              resp.spamFilterDetails,
              resp.spamFilterDetails.length,
            ); //filling ag grid with content
            params.api.hideOverlay();
          }
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },
  };
  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const ViewAuditLogs = (params) => {
    const handleAgGridData = async () => {
      let payload = {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {
          entityId: {
            filterType: "text",
            type: "contains",
            filter: params.data.id,
          },
        },
      };
      await axiosPrivate
        .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            from: fromDate,
            to: new Date(),
            Timezone: moment().utcOffset(),
          },
        })
        .then((response) => {
          const resp = response?.data?.data;
          setShowDetails(true);
          setAgGridData(resp.auditLogs);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <div className="d-flex gap-3">
        <div
          className="action-btn"
          onClick={() => {
            handleAgGridData();
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };
  colDefs.push({
    headerName: "Actions",
    cellRenderer: "renderActionButtons",
    headerClass: "cell-border-left",
  });

  const confidenceFn = (params) => {
    const confidence = Number(params.data.confidence);
    if (confidence === 1) {
      return <div className="text-danger">Low</div>;
    }
    if (confidence === 2) {
      return <div>Medium</div>;
    }
    if (confidence === 3) {
      return <div className="text-success">High</div>;
    }
  };

  const frameworkComponents = {
    renderActionButtons: actionButtons,
    renderConfidenceLevel: confidenceFn,
    renderViewAuditLogs: ViewAuditLogs,
    renderStatus: StatusTypes,
  };

  //Download CSV
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const refreshCache = useCallback(
    (searchData) => {
      const newDataSource = {
        async getRows(params) {
          const { startRow, endRow, filterModel, sortModel } = params.request;
          await axiosPrivate
            .post(
              process.env.REACT_APP_SPAM_FILTER,
              {
                startRow: startRow,
                endRow: endRow,
                sortModel: sortModel,
                filterModel: filterModel,
                notificationType: "EMAIL",
                approvalDocumentType: "ENTITY",
                globalSearch: searchData,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then((response) => {
              const resp = response?.data?.data;
              if (!resp?.spamFilterDetails?.length) {
                params.successCallback([], 0);
                params.api.showNoRowsOverlay();
              } else {
                params.successCallback(
                  resp.spamFilterDetails,
                  resp.spamFilterDetails.length,
                ); //filling ag grid with content
                params.api.hideOverlay();
              }
            })
            .catch((error) => {
              console.error(error);
              params.failCallback();
            });
        },
      };
      gridRef.current.api.setServerSideDatasource(newDataSource);
    },
    [axiosPrivate, token],
  );
  useEffect(() => {
    if (gridRef?.current?.api) {
      refreshCache("");
    }
  }, [refreshGrid, refreshCache]);

  const refreshGridAfterSearch = (searchData) => {
    if (!gridRef.current.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  return (
    <>
    {showSpamDetailsDrawer && (
          <MUISMSSpamDetailModal
            openDrawer={showSpamDetailsDrawer}
            setOpenDrawer={setShowSpamDetailsDrawer}
            entityDetails={selectedRowData}
            token={token}
            setRefreshGrid={setRefreshGrid}
          />
        )}
      <div className="d-flex justify-content-end mb-2 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <GlobalSearch refreshGrid={refreshGridAfterSearch} />
          <DownloadCSV onClick={onBtnExport} />
          {/* <UploadCSVModal token={token} title={"Message Templates"} /> */}
          <CreateSpamFilterModal
            token={token}
            rowData={rowData}
            setRowData={setRowData}
            memberRole={memberRole}
            notificationType={"EMAIL"}
            setShowAgGrid={setShowAgGrid}
            refreshCache={refreshCache}
          />
        </div>
      </div>
      {showAgGrid ? (
        <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={15}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            masterDetail={masterDetail}
            detailCellRendererParams={detailCellRendererParams}
            detailRowAutoHeight={true}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="No data to show"
            components={frameworkComponents}
            onCellClicked={onCellClicked}
          />
        </div>
      ) : (
        <></>
      )}
      {/* {editModalShow && (
        <UpdateTemplate
          modalShow={editModalShow}
          setModalShow={setEditModalShow}
          url={`${process.env.REACT_APP_MESSAGE_TEMPLATES}/${singleData.id}`}
          data={singleData}
          token={token}
        />
      )} */}
      {editModalShow && (
        <CreateSpamFilterModal
          token={token}
          rowData={rowData}
          setRowData={setRowData}
          editModalShow={editModalShow}
          setEditModalShow={setEditModalShow}
          notificationType={"EMAIL"}
          memberRole={memberRole}
          data={singleData}
          // approvalRequest={approvalRequest}
          // handleRequest={handleRequest}
          setShowAgGrid={setShowAgGrid}
        />
      )}

      {deleteModalShow && (
        <DeleteModal
          modalShow={deleteModalShow}
          setModalShow={setDeleteModalShow}
          memberRole={memberRole}
          type="Spam Filter"
          url={`${process.env.REACT_APP_SPAM_FILTER}/${singleData.id}`}
          entity={singleData.name}
          itemToDelete={singleData.id}
          // demo={true}
          rowData={rowData}
          setRowData={setRowData}
          gridRef={gridRef}
          setShowAgGrid={setShowAgGrid}
        />
      )}
      {showDetails && (
        <EntityModal
          modalShow={showDetails}
          setModalShow={setShowDetails}
          data={agGridData}
        />
      )}
    </>
  );
};
EmailSpamFiltersGrid.propTypes = {
  token: PropTypes,
  memberRole: PropTypes,
};
export default EmailSpamFiltersGrid;
