let deliveryFailedData = [];
const excludeKeys = [
  "totalPendingMessagesCount",
  "totalDeliveryFailedMessagesCount",
  "totalValidationFailedMessagesCount",
  "totalDeliveredMessagesCount",
  "totalFailedMessagesCount",
];
export const getPieChartSliceData = (
  sliceValue,
  data,
  notificationType,
  downloadingPdf,
  entity = "message",
  pendingMessageStatusUpdateCount = 0,
) => {
  if (downloadingPdf === undefined || null) {
    if (sliceValue === "Delivery Failed") {
      deliveryFailedData = data;
    } else {
      let found = 0;
      deliveryFailedData.forEach((item) => {
        if (item.k === sliceValue) {
          found = 1;
        }
      });
      if (found === 1) {
        // deliveryFailedData=deliveryFailedData
      } else {
        deliveryFailedData = [];
      }
    }
  }
  if (entity === "email") {
    const dummyData = [];
    dummyData.push({ k: "Emails Delivered", v: 29971 });
    dummyData.push({ k: "Email Deliveries Failed", v: 749 });
    dummyData.push({ k: "Email Validations Failed", v: 414 });
    dummyData.push({ k: "Emails Pending", v: 1854 });
    return dummyData;
  }
  if (entity === "flash") {
    const dummyData = [];
    dummyData.push({ k: "Messages Delivered", v: 43678 });
    dummyData.push({ k: "Message Deliveries Failed", v: 694 });
    dummyData.push({ k: "Message Validations Failed", v: 488 });
    dummyData.push({ k: "Messages Pending", v: 2457 });
    return dummyData;
  }
  if (entity === "Push Notifications") {
    const dummyData = [];
    dummyData.push({ k: "Push Notifications Delivered", v: 27971 });
    dummyData.push({
      k: "Delivery Failed",
      v: 412 + pendingMessageStatusUpdateCount,
    });
    dummyData.push({ k: "Validation Failed", v: 749 });
    dummyData.push({
      k: "Push Notifications Pending",
      v: 3856 - pendingMessageStatusUpdateCount,
    });

    return dummyData;
  }

  if (entity === "WhatsApp Messages") {
    const dummyData = [];
    dummyData.push({ k: "Messages Delivered", v: 2655 });
    dummyData.push({
      k: "Delivery Failed",
      v: 140 + pendingMessageStatusUpdateCount,
    });
    dummyData.push({ k: "Validation Failed", v: 121 });
    dummyData.push({
      k: "Dessages Pending",
      v: 778 - pendingMessageStatusUpdateCount,
    });

    return dummyData;
  }
  const sliceData = [];
  if (data) {
    switch (sliceValue) {
      case "Messages Delivered":
        data.forEach((item) => {
          if (
            item.k === "totalPlivoCount" &&
            notificationType === "SMS" &&
            item.v > 0
          )
            sliceData.push({ k: "Plivo", v: item.v });
          if (
            item.k === "totalMockCount" &&
            notificationType === "SMS" &&
            item.v > 0
          )
            sliceData.push({ k: "Mock", v: item.v });
          if (
            item.k === "totalDeliveredMessagesCount" &&
            notificationType === "EMAIL" &&
            item.v > 0
          )
            sliceData.push({ k: "MAILJET", v: item.v });
        });
        break;
      case "Plivo":
        data.forEach((item) => {
          if (item.k === "totalPlivoCount" && item.v > 0)
            sliceData.push({ k: "Plivo", v: item.v });
        });
        break;
      case "EMAIL_SENT":
        data.forEach((item) => {
          if (item.k === "totalDeliveredMessagesCount" && item.v > 0)
            sliceData.push({ k: "MAILJET", v: item.v });
        });
        break;
      case "Mock":
        data.forEach((item) => {
          if (item.k === "totalMockCount" && item.v > 0)
            sliceData.push({ k: "Mock", v: item.v });
        });
        break;
      case "MAILJET":
        data.forEach((item) => {
          if (item.k === "totalDeliveredMessagesCount" && item.v > 0)
            sliceData.push({ k: "EMAIL_SENT", v: item.v });
        });
        break;
      case "Validation Failed":
        data.forEach((item) => {
          if (item.k === "totalFailReason1Count" && item.v > 0)
            sliceData.push({ k: "Invalid Phone Number Length", v: item.v });
          if (item.k === "totalFailReason2Count" && item.v > 0)
            sliceData.push({ k: "Message Content Too Long", v: item.v });
          if (item.k === "totalFailReason3Count" && item.v > 0)
            sliceData.push({ k: "Invalid Message Content", v: item.v });
          // if (item.k === "totalValidationFailedMessagesCount" && item.v >0 ) sliceData.push({ "k": "Validation Failed", "v": item.v})
        });
        break;
      case "Invalid Phone Number Length":
        data.forEach((item) => {
          if (item.k === "totalInvalidPhoneNumberLengthCount" && item.v > 0)
            sliceData.push({ k: "Invalid Phone Number Length", v: item.v });
        });
        break;
      case "Message Content Too Long":
        data.forEach((item) => {
          if (item.k === "totalMessageContentTooLongCount" && item.v > 0)
            sliceData.push({ k: "Message Content Too Long", v: item.v });
        });
        break;
      case "Invalid Message Content":
        data.forEach((item) => {
          if (item.k === "totalInvalidMessageContentCount" && item.v > 0)
            sliceData.push({ k: "Invalid Message Content", v: item.v });
        });
        break;
      case "Delivery Failed":
        data.forEach((item) => {
          if (!excludeKeys.includes(item.k)) {
            if (item.v > 0) {
              sliceData.push({ k: item.k, v: item.v });
            }
          }
        });
        break;
      case "Messages Pending":
        data.forEach((item) => {
          if (item.k === "totalValidatedMessagesCount" && item.v > 0)
            sliceData.push({ k: "Validated Messages", v: item.v });
          if (item.k === "totalBuiltMessagesCount" && item.v > 0)
            sliceData.push({ k: "Built Messages", v: item.v });
          if (item.k === "totalDispatchedMessagesCount" && item.v > 0)
            sliceData.push({ k: "Dispatched Messages", v: item.v });
          if (item.k === "totalPlivoSentCount" && item.v > 0)
            sliceData.push({ k: "Plivo Sent", v: item.v });
          if (item.k === "totalPlivoQueuedCount" && item.v > 0)
            sliceData.push({ k: "Plivo Queued", v: item.v });
          if (item.k === "totalPlivoRequestedCount" && item.v > 0)
            sliceData.push({ k: "Plivo Requested", v: item.v });
        });
        break;
      case "Validated Messages":
        data.forEach((item) => {
          if (item.k === "totalValidatedMessagesCount" && item.v > 0)
            sliceData.push({ k: "Validated Messages", v: item.v });
        });
        break;
      case "VALIDATED":
        data.forEach((item) => {
          if (item.k === "VALIDATED" && item.v > 0)
            sliceData.push({ k: "Validated Messages", v: item.v });
        });
        break;
      case "Built Messages":
        data.forEach((item) => {
          if (item.k === "totalBuiltMessagesCount" && item.v > 0)
            sliceData.push({ k: "Built Messages", v: item.v });
        });
        break;
      case "BUILT":
        data.forEach((item) => {
          if (item.k === "BUILT" && item.v > 0)
            sliceData.push({ k: "Built Messages", v: item.v });
        });
        break;
      case "Dispatched Messages":
        data.forEach((item) => {
          if (item.k === "totalDispatchedMessagesCount" && item.v > 0)
            sliceData.push({ k: "Dispatched Messages", v: item.v });
        });
        break;
      case "DISPATCHED":
        data.forEach((item) => {
          if (item.k === "DISPATCHED" && item.v > 0)
            sliceData.push({ k: "Dispatched Messages", v: item.v });
        });
        break;
      case "Plivo Sent":
        data.forEach((item) => {
          if (item.k === "totalPlivoSentCount" && item.v > 0)
            sliceData.push({ k: "Plivo Sent", v: item.v });
        });
        break;
      case "PLIVO_SENT":
        data.forEach((item) => {
          if (item.k === "PLIVO_SENT" && item.v > 0)
            sliceData.push({ k: "Plivo Sent", v: item.v });
        });
        break;
      case "Plivo Queued":
        data.forEach((item) => {
          if (item.k === "totalPlivoQueuedCount" && item.v > 0)
            sliceData.push({ k: "Plivo Queued", v: item.v });
        });
        break;
      case "PLIVO_QUEUED":
        data.forEach((item) => {
          if (item.k === "PLIVO_QUEUED" && item.v > 0)
            sliceData.push({ k: "Plivo Queued", v: item.v });
        });
        break;
      case "Plivo Requested":
        data.forEach((item) => {
          if (item.k === "totalPlivoRequestedCount" && item.v > 0)
            sliceData.push({ k: "Plivo Requested", v: item.v });
        });
        break;
      case "PLIVO_REQUESTED":
        data.forEach((item) => {
          if (item.k === "PLIVO_REQUESTED" && item.v > 0)
            sliceData.push({ k: "Plivo Requested", v: item.v });
        });
        break;
      default:
        if (deliveryFailedData.length > 0) {
          deliveryFailedData.forEach((item) => {
            if (item.k === sliceValue) {
              sliceData.push({ k: item.k, v: item.v });
            }
          });
        } else {
          data.forEach((item) => {
            if (item.k === "totalDeliveredMessagesCount" && item.v > 0)
              sliceData.push({ k: "Messages Delivered", v: item.v });
            if (item.k === "totalPendingMessagesCount" && item.v > 0)
              sliceData.push({ k: "Messages Pending", v: item.v });
            // if (item.k === "totalValidationFailedMessagesCount" && item.v > 0) sliceData.push({ "k": "Validation Failed", "v": item.v })
            if (item.k === "totalDeliveryFailedMessagesCount" && item.v > 0)
              sliceData.push({ k: "Delivery Failed", v: item.v });
            deliveryFailedData = [];
          });
        }
    }
  }
  return sliceData;
};
