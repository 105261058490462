import React from "react";
import PropTypes from "prop-types";
import right_arrow from "../../../assets/Dashboard/chevron_right.svg";
import CustomDivider from "../../../components/CustomDivider/CustomDivider";
import back_icon from "../../../assets/Dashboard/keyboard_backspace.svg";

const RealTimeStatsBadges = ({
  isLoading,
  getPieSliceValue,
  pieChartData,
  breadcrumbData,
  setBreadcrumbData,
  pieColors,
  hideDrillDown = false,
}) => {
  const arrowStyles = {
    background: "rgba(249, 249, 249, 1)",
    padding: "0px 16px",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const error = "";
  const totalCountReducer = (accumulator, currentValue) =>
    accumulator + parseInt(currentValue.v, 10);
  const totalCount = pieChartData?.data?.reduce(totalCountReducer, 0);
  const handleClick = (key) => {
    if (hideDrillDown) {
      return;
    }
    if (breadcrumbData.length === 0) {
      breadcrumbData.push("");
    }
    if (!breadcrumbData.includes(key)) {
      const newData = [...breadcrumbData, key];
      setBreadcrumbData(newData);
    }

    getPieSliceValue(key);
    // if (isLeaf(key)) MHSRef.current && MHSRef.current.scrollIntoView();
  };

  const goBack = () => {
    const newData = [...breadcrumbData];
    newData.pop();
    setBreadcrumbData(newData);
    getPieSliceValue(newData[newData.length - 1]);
  };

  if(!pieChartData?.data){
    return <></>
  }

  return (
    <div className="" style={{ width: "100%" }}>
      <>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="py-1">
            <div className="rts-badge-container p-4">
              <div className="d-flex flex-column gap-5">
                {breadcrumbData?.length > 1 && (
                  <div>
                    <img
                      className="cursor-pointer"
                      src={back_icon}
                      alt="back_button"
                      onClick={goBack}
                    />
                  </div>
                )}
                <div
                  className="d-flex  gap-2 justify-content-start align-items-center"
                  style={{ marginBottom: "36px" }}
                >
                  <div className="total-requested-key">
                    Total messages requested
                  </div>
                  <div className="total-requested-value">
                    {error ? (
                      <p className="total-requested-value">
                        Failed to get data
                      </p>
                    ) : (
                      `${totalCount}`
                    )}
                  </div>
                </div>
              </div>
              {pieChartData?.data?.map((item, index) => {
                const key = item.k;
                const value = item.v;

                const percentage = (value / totalCount) * 100 || 0;

                return (
                  <div key={`${item?.k}_${index}`}>
                    <div
                      className={`d-flex justify-content-between ${
                        !hideDrillDown && "cursor-pointer"
                      }`}
                      onClick={() => handleClick(key)}
                    >
                      <div className="d-flex gap-3">
                        <div
                          className="box"
                          style={{ backgroundColor: pieColors[index] }}
                        ></div>
                        <div className="d-flex flex-column gap-2">
                          <div>
                            {error ? (
                              <p className="text-gray">Failed to get data</p>
                            ) : (
                              <div className="d-flex gap-2 align-items-center total-requested-value">
                                <span className="fs-5">{value}</span>
                                <span className="rts-percentage">
                                  {!isNaN(percentage) &&
                                    `(${percentage.toFixed(2)}%)`}{" "}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="rts-slice-name">{key}</div>
                        </div>
                      </div>
                      {pieChartData?.data?.length > 1 && !hideDrillDown && (
                        <div style={arrowStyles}>
                          <img src={right_arrow} alt="right_arrow" />
                        </div>
                      )}
                    </div>
                    <div className="pt-2"> </div>
                    <CustomDivider />
                    <div className="pb-2"> </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </>
    </div>
  );
};
RealTimeStatsBadges.propTypes = {
  isLoading: PropTypes,
  getPieSliceValue: PropTypes.func,
  pendingMessageStatusUpdateCount: PropTypes.number,
  pieChartData: PropTypes.array,
  breadcrumbData: PropTypes.array,
  setBreadcrumbData: PropTypes.func,
  pieColors: PropTypes.object,
  hideDrillDown: PropTypes.bool,
};
export default RealTimeStatsBadges;
