import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import TimeField from "./TimeField";
import {
  AddIconDiv,
  DayContainer,
  GridContainer,
  RemoveIconDiv,
  TimeSlotDiv,
} from "./MultipleTimeField.styles";
import AddIcon from "../../../../assets/reicpient-details-modal/AddIcon.svg";
import RemoveIcon from "../../../../assets/reicpient-details-modal/RemoveIcon.svg";

const MultipleTimeField = ({
  activeDay,
  overlap,
  payload,
  setPayload,
  dayIndex,
  operationType,
}) => {
  const newSlot =  [
    { startTime: "00:00", endTime: "01:00" },
  ];
  const [timeFields, setTimeFields] = useState(payload[dayIndex]?.timeSlots);
  const READ = "READ";

  useEffect(() => {
    setTimeFields(payload[dayIndex].timeSlots);
  }, [dayIndex, payload, setTimeFields]);

  // Add new timefield for the active day
  const addTimeField = () => {
    if (operationType === READ) {
      return;
    } 
    const updatedTimeFields = [...timeFields, ...newSlot];
    setTimeFields(updatedTimeFields);
    
    let updatedPayload = [...payload];
    updatedPayload[dayIndex].timeSlots = updatedTimeFields;
    setPayload(updatedPayload);
  };

  // Remove time field for active day
  const handleRemoveTimeField = (index) => {
    if (operationType === READ) {
      return;
    }
    const updatedTimeFields = [...timeFields];
    updatedTimeFields.splice(index, 1);
    // if (selectedTimeSlots.length > 1) {
    //   handleRemoveSlot(activeDay, timeFields[index]);
    // }
    const updatedPayload = payload;
    // updatedPayload.forEach((item) => {
    //   if (item.day === activeDay) {
    //     item.timeSlots = [...updatedTimeFields];
    //   }
    // });
    updatedPayload[dayIndex].timeSlots = [...updatedTimeFields];

    setTimeFields(updatedTimeFields);
    setPayload(updatedPayload);
  };

  return (
    <GridContainer>
      {timeFields.map((timeFieldData, index) => {
        return (
          <TimeSlotDiv key={`${dayIndex}_${index}`}>
            {index === 0 ? (
              <DayContainer>{activeDay}</DayContainer>
            ) : (
              <DayContainer></DayContainer>
            )}
            <TimeField
              index={index}
              day={activeDay}
              startTime={dayjs(timeFieldData.startTime, "HH:mm")}
              endTime={dayjs(timeFieldData.endTime, "HH:mm")}
              overlap={overlap?.index === index}
              payload={payload}
              setPayload={setPayload}
              dayIndex={dayIndex}
              timefieldIndex={index}
              operationType={operationType}
            />
            {index >= 0 && index === timeFields.length - 1 && (
              <AddIconDiv onClick={addTimeField}>
                <img src={AddIcon} alt="Add" />
              </AddIconDiv>
            )}
            {index > 0 && operationType !== READ && (
              <RemoveIconDiv
                onClick={() => handleRemoveTimeField(index)}
                overlap={overlap?.index === index}
              >
                <img src={RemoveIcon} alt="Remove" />
              </RemoveIconDiv>
            )}
          </TimeSlotDiv>
        );
      })}
    </GridContainer>
  );
};
MultipleTimeField.propTypes = {
  activeDay: PropTypes.string,
  overlap: PropTypes.bool,
  payload: PropTypes.array,
  setPayload: PropTypes.func,
  dayIndex: PropTypes.number,
  operationType: PropTypes.string,
};
export default MultipleTimeField;
