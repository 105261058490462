export const flashRTSData = {
  data: {
    totalMessagesCount: 47317,
    data: [
      {
        v: 43678,
        k: "totalDeliveredMessagesCount",
      },
      {
        v: 1182,
        k: "totalFailedMessagesCount",
      },
      {
        v: 488,
        k: "totalValidationFailedMessagesCount",
      },
      {
        v: 694,
        k: "totalDeliveryFailedMessagesCount",
      },
      {
        v: 2457,
        k: "totalPendingMessagesCount",
      },
    ],
  },
};
