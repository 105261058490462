export default {
  data: {
    spamFilterData: [
      {
        id: "62eb97af484f252e1cd16701",
        dateAdded: new Date().toLocaleDateString(),
        filterName: "Filter 1",
        filterRule: "100% guarantee",
        createdByName: "Mohit",
        confidence: 1,
      },
      {
        id: "62eb97af484f252e1cd16702",
        dateAdded: new Date().toLocaleDateString(),
        filterName: "Filter 2",
        filterRule: "act now!",
        createdByName: "Mohit",
        confidence: 2,
      },
      {
        id: "62eb97af484f252e1cd16703",
        dateAdded: new Date().toLocaleDateString(),
        filterName: "Filter links",
        filterRule: `<a\\s+(?:[^>]*?\\s+)?href=(["'])(.*?)\\1`,
        createdByName: "Mohit",
        confidence: 3,
      },
    ],
    totalMessageTemplatesCount: 3,
  },
};
