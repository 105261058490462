import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import PropTypes from "prop-types";

const AggregatorPieChart = ({ data, pieChartColorData }) => {
  let count = 0;
  if (data) {
    data.forEach((item) => {
      count += item.totalMessagesCount;
    });
  }
  const [options] = useState({
    autoSize: true,
    title: {
      text: "Aggregator Stats",
      fontSize: 18,
    },
    subtitle: {
      text: "Number of messages sent by providers: " + count,
    },
    tooltip: { enabled: false },

    series: [
      {
        data: data?.map((item) => {
          return { k: item?.provider, v: item?.totalMessagesCount };
        }),
        type: "pie",
        calloutLabelKey: "k",
        angleKey: "v",
        label: {
          minAngle: 0,
        },
        callout: {
          strokeWidth: 2,
        },
        fills: pieChartColorData,
        strokes: pieChartColorData,
      },
    ],
    padding: {
      top: 40,
      bottom: 60,
    },
    legend: {
      position: "left",
    },
    theme: {
      overrides: {
        pie: {
          series: {
            highlightStyle: {
              item: {
                fill: undefined,
                stroke: "black",
                strokeWidth: 2,
              },
              series: {
                dimOpacity: 1,
                strokeWidth: 2,
              },
            },
          },
        },
      },
    },
  });
  return (
    <div className="mb-4" style={{ height: "500px" }}>
      <AgChartsReact options={options} />
    </div>
  );
};
AggregatorPieChart.propTypes = {
  data: PropTypes.array,
  pieChartColorData: PropTypes.array,
};

export default AggregatorPieChart;
