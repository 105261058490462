import { Form, FormGroup, Button, FormControl } from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import Select from "react-select";

export default ()=> {
    const [authID, setAuthID] = useState("MANGI3ZWFMYMQWMWM5YW");
    const [authToken, setAuthToken] = useState("Y2VmMWNhMDk2YzJiNzEyZDRlZGY1YzhlMmQ2MzRl");
    const [PHLOID, setPHLOID] = useState("");
    const [src, setSrc] = useState("+917780292711");
    const [powerpack_uuid, setPowerpack_uuid] = useState("");
    const [url, setUrl] = useState("");
    const [method, setMethod] = useState("");
    const [messageDirection, setMessageDirection] = useState("");

    return  <Form >
    <FormGroup>
       <div className="mb-3">
             <Form.Label>Aggregator image:</Form.Label>
             <div className="">
            <input type="file"></input>
             </div>
        </div>
    </FormGroup> 
    <FormGroup>
    <div className="mb-3">
                <Form.Label>Authentication ID:</Form.Label>
                <FormControl
                    aria-label="With text"
                    placeholder="Type or paste your Auth ID here"
                    required
                    value={authID}
                    onChange={e => setAuthID(e.target.value)}
                />

    </div>
        </FormGroup> 
        <FormGroup>
       <div className="mb-3">
                    <Form.Label>Authentication Token:</Form.Label>
                    <FormControl
                        aria-label="With text"
                        placeholder="Type or paste your Auth Token here"
                        required
                        value={authToken}
                        onChange={e => setAuthToken(e.target.value)}
                    />

        </div>
        </FormGroup>
        <FormGroup>
       <div className="mb-3">
                    <Form.Label>Plivo Source:</Form.Label>
                    <FormControl
                        aria-label="With text"
                        placeholder="Type or paste Plivo source here"
                        required
                        value={src}
                        onChange={e => setSrc(e.target.value)}
                    />

        </div>
        </FormGroup>
        <FormGroup>
       <div className="mb-3">
                    <Form.Label>URL:</Form.Label>
                    <FormControl
                        aria-label="With text"
                        placeholder="Type or paste your url here"
                        required
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                    />

        </div>
        </FormGroup>
        <FormGroup>
                    <div className="mb-3">
                        <Form.Label>HTTP Method</Form.Label>
                        <Select
                            options={[{label:"POST",value:"post"},{label:"GET",value:"get"}]}
                            placeholder="Select HTTP Method from list"
                            value={method}
                            onChange={e => setMethod(e)}
                        />
                    </div>
        </FormGroup> 
        <FormGroup>
                    <div className="mb-3">
                        <Form.Label>Message Direction</Form.Label>
                        <Select
                            options={[{label:"Inbound",value:"inbound"},{label:"Outbound",value:"outbound"}]}
                            placeholder="Select message direction from list"
                            value={messageDirection}
                            onChange={e => setMessageDirection(e)}
                        />
                    </div>
        </FormGroup>
        
        <div className="d-flex justify-content-end">

        <Button style={{marginInlineStart:"auto"}} type="submit">Save Changes</Button>
        </div>
    </Form>
}