import {
  Form,
  FormGroup,
  Button,
  FormControl,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";

export default () => {
  const [aggregatorImage, setAggregatorImage] = useState("");
  const [aggregatorName, setAggregatorName] = useState("");
  const [aggregatorAPIKey, setAggregatorAPIKey] = useState("");
  const history = useHistory();

  const [description, setDescription] = useState("");

  // const showDescription = (e)=>{
  //     console.log(e.target.id)
  //     switch (e.target.id){
  //         case "aggregatorImage":
  //             return setDescription("Upload an image of your aggregator service")
  //     }
  // }

  // const clearDescription = e =>{
  //     setDescription("");
  // }

  return (
    <>
      <div className="d-flex gap-3 mb-3">
        <img
          src="https://e7.pngegg.com/pngimages/727/475/png-clipart-computer-icons-back-icon-cdr-angle.png"
          className="cursor-pointer"
          style={{ width: "35px", aspectRatio: "1" }}
          onClick={() => history.push("/aggregators/flash")}
        />
        <h5>Add your aggregator settings</h5>
      </div>
      <Form>
        <FormGroup className="my-3">
          <Form.Label>Upload an image for you aggregator</Form.Label>
          <FormControl
            type="file"
            id="aggregatorImage"
            style={{ width: "fit-content" }}
            onChange={(e) => setAggregatorImage(e.target.value)}
          />
        </FormGroup>
        <Row>
          <Col xl={6}>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Aggregator name:</Form.Label>
                <FormControl
                  aria-label="With text"
                  placeholder="Type or paste your Auth ID here"
                  required
                  value={aggregatorName}
                  onChange={(e) => setAggregatorName(e.target.value)}
                />
              </div>
            </FormGroup>
          </Col>
          <Col xl={6}>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Your API key:</Form.Label>
                <FormControl
                  aria-label="With text"
                  placeholder="Type or paste your API key here"
                  required
                  value={aggregatorAPIKey}
                  onChange={(e) => setAggregatorAPIKey(e.target.value)}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};
