import React from "react";
const ErrorMessage = ({ code }) => {
    switch (code) {
        case 404:
            return <div>
                <h2>Error {code}</h2>
                <h2>Resource not found. Please verify that your request was correct.</h2>
            </div>


        case 401:
            return <div>
                <h2>Error {code}</h2>
                <h2>Please login to view this page.</h2>
            </div>


        case 403:
            return <div>
                <h2>Error {code}</h2>
                <h2>You may not be allowed to view this page.</h2>
            </div>


        case 500:
            return <div>
                <h2>Error {code}</h2>
                <h2>Something went wrong with the server.</h2>
            </div>


        case 504:
            return <div>
                <h2>Error {code}</h2>
                <h2>We are having  issues with the server.</h2>
                <h6>Please come back in some time.</h6>
            </div>

        default:
            return <h2>Something went wrong.</h2>
    }
}

export default ErrorMessage