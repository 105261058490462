import axios from "axios";
import { logout, setMemberData } from "../Redux/Reducers/memberReducer";
import { useDispatch, useSelector } from "react-redux";
import { logoutAPI } from "../util/logoutApiUtil";
import { useMemo } from "react";
import { axiosWithBaseUrl } from "../axios/baseUrl";

export const CreateAxiosInstance = () => {
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const refreshToken = useSelector(
    (state) => state.persistedReducer?.member?.refreshToken
  );
  const username = useSelector(state => state.persistedReducer?.member?.data?.name);
  const memberRole = useSelector(state => state.persistedReducer?.member?.data?.roles);
  const email = useSelector(state => state.persistedReducer?.member?.data?.email);
  const dispatch = useDispatch();
  const id = useSelector(
    (state) => state.persistedReducer?.member?.data?.id
  );

  const axiosPrivate = useMemo(() => {
    const dispatchLogout = () => {
      dispatch(logout());
    };

    const instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    instance.interceptors.request.use(
      function (config) {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          try {
            const response = await axiosWithBaseUrl.post(
              `${process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN_API}?refresh_token=${refreshToken}`
            );
            if (response) {
              const client = {
                tokenParsed: { name: username, email: email },
                id: id,
                roles: [memberRole],
                token: response.data.access_token,
                refreshToken: response.data.refresh_token,
              };
              dispatch(
                setMemberData(client)
              );
            }
            prevRequest.headers[
              "Authorization"
            ] = `Bearer ${response.data.access_token}`;
            return axiosPrivate(prevRequest);
          } catch (error) {
            console.log(error);

            logoutAPI(token, refreshToken, dispatchLogout);
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [token, refreshToken, dispatch, email, id, memberRole, username]);

  return axiosPrivate;
};
