import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import React,{useState, useMemo} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
function EntitiyDetail(props) {

    const rowData = props.data;
    const colDefs = [
     
        {
          headerName: "CREATED ON",
          field: "timestamp",
        //   flex: 1,
          width: 210,
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: ["lessThan", "greaterThan", "inRange"],
            suppressAndOrCondition: true,
          },
        },{
            headerName: "ENTITY",
            field: "entity",
            // flex: 1,
            width: 200,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {
            headerName: "ENTITY NAME",
            field: "entityName",
            // flex: 1,
            width: 200,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {
            headerName: "ACTION BY ROLES",
            field: "actionByRole",
            // flex: 1,
            width: 200,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {
            headerName: "SERVICE NAME",
            field: "serviceName",
            // flex: 1,
            width: 200,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {
            headerName: "MESSAGE",
            field: "message",
            // flex: 2,
            width: 800,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {headerName:"OPERATION", cellRenderer: 'renderActionTypes',
          field: "operation",
        //   flex: 1,
          width: 200,
          filter: "agTextColumnFilter",
          filterParams: { suppressAndOrCondition: true }},
         
        
          {
            headerName: "ACTION BY ID",
            field: "actionById",
            // flex: 1,
            width: 350,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {
            headerName: "ACTION BY NAME",
            field: "actionByName",
            // flex: 1,
            width: 200,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },
          {
            headerName: "STATE CHANGE MESSAGE",
            field: "stateChangeMessage",
            // flex: 1,
            width: 2000,
            filter: "agTextColumnFilter",
            filterParams: { suppressAndOrCondition: true },
          },]
        const [columnDefs] = useState(colDefs);
        const defaultColDef = useMemo(
            () => ({
              sortable: true,
              resizable: true,
              filter: true,
        
              filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
              },
            }),
            []
          );
    return (
        <Modal
            {...props}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Audit Log Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{ overflowX: "auto" }}>
          <div id="msgHealthTable" className="ag-theme-alpine"  style={{ width: "100%" }}>
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              cacheBlockSize={5}
              overlayNoRowsTemplate="No data available"
              domLayout="autoHeight" // if too many rows should not use this, give fixed height
              animateRows={true}
            />
          </div>
        </div>
            </Modal.Body>
        </Modal>
    )
}
EntitiyDetail.propTypes={
    data: PropTypes
}
export default function App({modalShow, setModalShow, data}) {
    App.propTypes={
        modalShow: PropTypes,
        setModalShow: PropTypes,
        data: PropTypes
    }
    return (
        <>

            <EntitiyDetail
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={data}
            />
        </>
    );
}
