import React, { useState, useEffect } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPageTitle } from "../Redux/Reducers/pageTitleReducer";
import MISFilterModal from "../components/Modals/MISFilterModal";
// import DataLoading from "../../components/Spinners/DataLoading";
import Error from "../components/Error/Error";
import { Card } from "react-bootstrap";
import {
  getCurrentDate,
  getLastMonthDate,
  getHoursDate,
  getDaysDate,
} from "../util/dateUtil";
import Select from "react-select";
import ApprovalRequestGrid from "../components/Grids/ApprovalRequestGrid";
import APISuccess from "../components/Toasts/APISuccess";
import APIFail from "../components/Toasts/APIFail";
import { reactSelectStyles } from "../util/ReactSelectStyle";
import reset_icon from "../assets/Dashboard/reset.svg"

const MessageHealthStatus = () => {
  const alertSuccess = useSelector(
    (state) => state.persistedReducer.alerts.showSuccess,
  );
  const alertFail = useSelector(
    (state) => state.persistedReducer.alerts.showFail,
  );
  const [fromDate, setFromDate] = useState(getLastMonthDate());
  const [toDate, setToDate] = useState(getCurrentDate());
  const dispatch = useDispatch();
  const [time, setTime] = useState(null);
  // const [MHSDataLoading, setMHSDataLoading] = useState(false);
  //states for filter by date range
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [dataFetchError] = useState(false);

  // useEffect(() => {
  //     setFromDate("");
  // }, [])

  const getTimeData = (time) => {
    switch (time) {
      case "hour":
        setFromDate(getHoursDate(1));
        setToDate(getCurrentDate());
        break;
      case "6hours":
        setFromDate(getHoursDate(6));
        setToDate(getCurrentDate());
        break;
      case "day":
        setFromDate(getDaysDate(1));
        setToDate(getCurrentDate());
        break;
      case "week":
        setFromDate(getDaysDate(7));
        setToDate(getCurrentDate());
        break;
      case "month":
        setFromDate(getDaysDate(30));
        setToDate(getCurrentDate());
        break;
      default:
        break;
    }
  };

  const resetData = () => {
    // setFromDate(getYesterdayDate());
    setFromDate(getLastMonthDate());
    setToDate(getCurrentDate());
    setTime("");
  };

  //For api request with filter
  const handleSubmit = async (e, onHide) => {
    e.preventDefault();
    onHide();
    setTime(null);

    // getMHSData();
  };
  useEffect(() => {
    time;
  }, [time]);

  //Dispatch for page title
  useEffect(() => {
    dispatch(getPageTitle("Approval Request"));
  }, [dispatch]);

  // if (MHSDataLoading) return <DataLoading />

  return (
    <div className="my-2 shadow-sm">
      {dataFetchError && <Error code={500} />}

      <div className="my-3 d-flex justify-content-end dashboard-tabs-container" style={{marginRight: "10px"}}>
        {/* FILTER BY TIME */}
        <div className="d-flex gap-3 align-items-center">
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={reactSelectStyles}
            placeholder="Filter By Time"
            value={time}
            onChange={(e) => {
              setTime({ label: e.label, value: e.value });
              getTimeData(e.value);
            }}
            options={[
              { label: "Last Hour", value: "hour" },
              { label: "Last 6 hours", value: "6hours" },
              { label: "Last Day", value: "day" },
              { label: "Last Week", value: "week" },
              { label: "Last Month", value: "month" },
            ]}
          />

          {/* FILTER BY DATE RANGE */}
          <MISFilterModal
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleSubmit={handleSubmit}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
          />
         <div
            onClick={() => resetData()}
            className="cursor-pointer"
            style={{ width: "50px", display: "flex", justifyContent: "center" }}
          >
            <img
              style={{ flexGrow: 1 }}
              src={reset_icon}
              alt="reset-button"
              // width={70}
              className="reset-btn"
            />
          </div>
        </div>
      </div>
      {alertSuccess && <APISuccess toggle={true} />}
      {alertFail && <APIFail toggle={true} />}
      <Row className="mb-3">
        <Col xs={12} xl={12}>
          <Card>
            <Card.Body>
              <ApprovalRequestGrid
                heading="Audit Logs"
                fromDate={fromDate}
                toDate={toDate}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MessageHealthStatus;
