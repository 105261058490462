import { Modal, Button, Form, FormGroup } from "react-bootstrap";
import React from "react";
import PropTypes from 'prop-types';
import calendar_icon from "../../assets/Dashboard/calendar_today.svg"
import expand_more from "../../assets/Dashboard/expand_more.svg"

function DateRangeModal(props) {
    const { from, to, setFrom, setTo, setFromDate, setToDate, handleSubmit } = props;
    

    // local states
    
    const handleClick = (e, props) => {
        
        e.preventDefault()
        setFromDate(new Date(from));
        setToDate(new Date(to));
        handleSubmit(e, props.onHide);
    }

    return (
        <Modal
            {...props}
            // size="lg"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Filter between time and day
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <FormGroup>
                        <div className="mb-3">
                            <Form.Label>From </Form.Label>
                            <input className="form-control" type="datetime-local"
                                value={from}
                                onChange={e => { setFrom(e.target.value) }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="mb-3">
                            <Form.Label>To </Form.Label>
                            <input className="form-control" type="datetime-local"
                                value={to}
                                onChange={e => { setTo(e.target.value) }}
                            />
                        </div>
                    </FormGroup>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" onClick={(e) => handleClick(e, props)} disabled={from.length === 0 || to.length === 0 ? true : false}>
                    Update
                </Button>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
DateRangeModal.propTypes = {
    from: PropTypes,
    to: PropTypes,
    setFrom: PropTypes,
    setTo: PropTypes,
    fromDate: PropTypes,
    toDate: PropTypes,
    setFromDate: PropTypes,
    setToDate: PropTypes,
    handleSubmit: PropTypes,
    onHide: PropTypes
}

export default function App ({ from, to, setFrom, setTo, fromDate, toDate, setFromDate, setToDate, handleSubmit }) {
    App.propTypes = {
        from: PropTypes,
        to: PropTypes,
        setFrom: PropTypes,
        setTo: PropTypes,
        fromDate: PropTypes,
        toDate: PropTypes,
        setFromDate: PropTypes,
        setToDate: PropTypes,
        handleSubmit: PropTypes
    } 
    
    const [modalShow, setModalShow] = React.useState(false);
    /* date to show on filters*/
    const extractedFromDate = `${new Date(fromDate).getDate()} ${new Date(fromDate).toLocaleString('default', { month: 'short' })} ${new Date(fromDate).getFullYear().toString().slice(-2)}`;
    const extractedToDate = `${new Date(toDate).getDate()} ${new Date(toDate).toLocaleString('default', { month: 'short' })} ${new Date(toDate).getFullYear().toString().slice(-2)}`;

    return (
        <>
            <div className=" dashboard-custom-filter gap-2 ">
                <img src={calendar_icon} alt="calendar icon"/>
                <div
                    className="py-1 me-2 "
                    onClick={() => setModalShow(true)}>
                    {`${extractedFromDate} to ${extractedToDate}` }

                </div>
                <img src={expand_more} style={{height:"8px"}} alt="expand more"/>
            </div>

            <DateRangeModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setFromDate={setFromDate}
                setToDate={setToDate}
                handleSubmit={handleSubmit}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                fromDate={fromDate}
                toDate={toDate}
            />
        </>
    );
}
