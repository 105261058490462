const buttonStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 12px",
    gap: "6px",
    maxWidth: "138px",
    background: "#F7F7FA",
    borderRadius: "8px",
    justifyContent: "center",
    cursor:"pointer"
  };

  const labelStyles = {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#7E7E7E",
    margin: 0,
  };

  export {buttonStyles, labelStyles};