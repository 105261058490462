// import styled from "styled-components";
// import { lightTheme } from "./Themes";

// export const FlexColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 12px;
// `;

// export const FlexRow = styled.div`
//   display: flex;
//   gap: 12px;
// `;

// export const DayContainer = styled.div`
//   width: 30px;
//   font-family:Inter;
//   color:rgba(126, 126, 126, 1);
//   font-weight:600;
//   line-height:20px;
//   font-size:14px;
// `;

// export const Day = styled.div`
//   width: 48px;
//   height: 48px;
//   border-radius: 8px;
//   background-color: ${({ active, theme }) =>
//     active ? theme.surface.primary : theme.surface.secondary};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 1px solid ${({ active }) => (active ? 'black' : 'transparent')};
//   font-weight: ${({ active, theme }) =>
//     !active ? theme.fontWeight.regular : theme.fontWeight.bold};
//   // font-family: ${({ theme }) => theme.font};
//   font-size: ${({ theme }) => theme.fontSize.s};
 
// `;

// export const Copy = styled.div`
//   display: flex;
//   gap: 10px;
//   white-space: nowrap;
//   padding-top: 12px;
//   padding-bottom: 12px;
//   color: ${lightTheme.content.copy};
//   font-family: ${lightTheme.font};
//   font-weight: ${lightTheme.fontWeight.semibold};
//   font-size: ${lightTheme.fontSize.s};
//   cursor: pointer;
// `;

// export const TimeZone = styled.div`
//   padding-top: 36px;
//   padding-right: 20px;
//   padding-bottom: 12px;
//   font-family: ${({ theme }) => theme.font};
//   color: ${({ theme }) => theme.content.timeZone};
// `;

// export const TimeSlotDiv = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 20px;
// `;

// export const GridContainer = styled.div`
//   display: grid;
//   grid-gap: 8px;
//   position: relative;
// `;

// export const RemoveIconDiv = styled.div`
//   cursor: pointer;
//   display: flex;
//   align-items: ${({ overlap }) => (overlap ? "baseline" : "center")};
//   margin-left: 10px;
//   height: 70px;
// `;

// export const AddIconDiv = styled.div`
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   margin-left: 10px;
// `;


import styled from "styled-components";
import { lightTheme } from "./Themes";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 12px;
`;

const DayContainer = styled.div`
  font-family: ${(props) => props.theme.font};
  width: 30px;
`;

const Day = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.active ? props.theme.surface.primary : props.theme.surface.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${(props) => (props.active ? props.theme.surface.black : "transparent")};
  font-weight: ${(props) =>
    !props.active
      ? props.theme.fontWeight.regular
      : props.theme.fontWeight.bold};
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSize.s};
  cursor: pointer;
`;

const Copy = styled.div`
  display: flex;
  gap: 10px;
  text-wrap: nowrap;
  padding-top: 12px;
  padding-bottom: 12px;
  color: ${lightTheme.content.copy};
  font-family: ${lightTheme.font};
  font-weight: ${lightTheme.fontWeight.semibold};
  font-size: ${lightTheme.fontSize.s};
  cursor: pointer;
`;

const TimeZone = styled.div`
  padding-top: 36px;
  padding-right: 20px;
  padding-bottom: 12px;
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.content.timeZone};
`;

const TimeSlotDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-gap: 0px;
  position: relative;
`;

const RemoveIconDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: ${(props) => (props.overlap ? "baseline" : "center")};
  margin-left: 10px;
  height: 70px;
`;

const AddIconDiv = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 70px;
`;

export {
  FlexColumn,
  FlexRow,
  DayContainer,
  Day,
  Copy,
  TimeZone,
  TimeSlotDiv,
  GridContainer,
  RemoveIconDiv,
  AddIconDiv,
};
