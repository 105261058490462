import React from "react";
import { Nav, Image } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import userMenu from "../assets/right_icon.svg";
import PropTypes from "prop-types";
import Send from "../assets/send.svg";
import { CreateAxiosInstance } from "../hooks/useAxiosPrivate";
import FileSendModal from "../components/SendMessageModals/FileSendModal";
import ScheduleSendModal from "../components/SendMessageModals/ScheduleSendModal";
import APITriggeredSend from "../components/SendMessageModals/APITriggeredSend";
import DemoToDoList from "../components/SendMessageModals/DemoToDoList";

function UserMenu(props) {
  const axiosPrivate = CreateAxiosInstance();
  const username = useSelector(
    (state) => state.persistedReducer?.member?.data?.name
  );
  const [groups, setGroups] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [showFileSendModal, setShowFileSendModal] = useState(false);
  const [showSingleSendModal, setShowSingleSendModal] = useState(false);
  const [showApiTriggerSendModal, setShowApiTriggerSendModal] = useState(false);
  const [showScheduleSendModal, setShowScheduleSendModal] = useState(false);
  const getAllMessageTemplates = useCallback(async () => {
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_MESSAGE_TEMPLATES,
        {
          startRow: 0,
          endRow: 100,
          sortModel: [],
          filterModel: {},
        }
      );
      if (response?.status === 200) {
        const resp = response?.data?.data;
        setMessageTemplates(resp.messageTemplateDetails);
      }
    } catch (error) {
      if (error?.response?.status) {
        console.error(error);
      }
    }
  }, [axiosPrivate]);
  const getAllGroups = useCallback(async () => {
    try {
      const response = await axiosPrivate.post(process.env.REACT_APP_GROUPS, {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {},
      });
      if (response?.status === 200) {
        const resp = response?.data?.data;
        setGroups(resp?.groupDetails);
      }
    } catch (error) {
      if (error?.response?.status) {
        console.error(error);
      }
    }
  }, [axiosPrivate]);

  useEffect(() => {
    getAllMessageTemplates();
    getAllGroups();
  }, []);

  return (
    <Nav className="align-items-center sendMenu">
      {username ? (
        <Dropdown as={Nav.Item}>
          <Dropdown.Toggle as={Nav.Link} className="px-0">
            <div className="media d-flex align-items-center">
              <div className="media-body text-dark align-items-center d-none d-lg-block">
                <div
                  className="media d-flex align-items-center"
                  style={{ gap: "12px", height: "30px" }}
                >
                  <Image
                    src={Send}
                    className="md-avatar rounded-circle user-icon sendicon"
                  />
                  <div
                    style={{
                      width: props.expanded ? "170px" : "0px",
                      overflow: "hidden",
                      transition: "all",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {" "}
                    Send{" "}
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <img src={userMenu} width="8px" height="8px" />
                  </div>
                </div>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="send-dropdown dropdown-menu-right mt-2"
            style={{ paddingLeft: "8px", paddingRight: "8px" }}
          >
            <Dropdown.Item
              className="fw-bold text-textPrimary dropdown-menu-icon"
              onClick={() => {
                setShowSingleSendModal(true);
              }}
            >
              Single send
            </Dropdown.Item>
            {showSingleSendModal && (
              <DemoToDoList
                onHide={() => {
                  setShowSingleSendModal(false);
                }}
              />
            )}
            <Dropdown.Item
              className="fw-bold text-textPrimary dropdown-menu-icon"
              onClick={() => {
                setShowFileSendModal(true);
              }}
            >
              File send
            </Dropdown.Item>
            {showFileSendModal && (
              <FileSendModal
                show={true}
                onHide={() => {
                  setShowFileSendModal(false);
                }}
                templateData={messageTemplates}
              />
            )}
            <Dropdown.Item
              onClick={() => {
                setShowScheduleSendModal(true);
              }}
              className="fw-bold text-textPrimary dropdown-menu-icon"
            >
              Scheduled send
            </Dropdown.Item>
            {showScheduleSendModal && (
              <ScheduleSendModal
                templateData={messageTemplates}
                groupData={groups}
                onHide={() => {
                  setShowScheduleSendModal(false);
                }}
              />
            )}
            <Dropdown.Item
              onClick={() => {
                setShowApiTriggerSendModal(true);
              }}
              className="fw-bold text-textPrimary dropdown-menu-icon"
            >
              API Trigger send
            </Dropdown.Item>
            {showApiTriggerSendModal && (
              <APITriggeredSend
                onHide={() => {
                  setShowApiTriggerSendModal(false);
                }}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Link to="/entry">
          <Button variant="primary" className="px-4 ms-auto">
            Login
          </Button>
        </Link>
      )}
    </Nav>
  );
}

UserMenu.propTypes = {
  expanded: PropTypes.string,
};

export default UserMenu;
