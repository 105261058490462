const validate = (value, type) => {
    // const isEmpty = /^$/;
    const textRE = /^[a-zA-Z]{3,}[a-zA-Z]$/;
    // const phoneRE = /^[0-9]{10}$/;
    // const prioRE = /^[1-3]$/;
    // const otherRE = /^[a-zA-Z]{3,}[a-zA-Z]$/
    switch (type) {
        case "isEmpty":
            // return isEmpty.test(value) ? true : false;
            // console.log(value===null)
            return value === "" || !value ? true : false;
        case "text":
            return textRE.test(value) ? true : false;
        case "phone":
            break;
        // return phoneRE.test(value) ? true : false;
        default:
            return false;
    }
};

export default validate;