import React from 'react';
import ErrorMessage from '../components/Error/ErrorMessage';

function NotFound() {
  return (
    <div id="error">
      <div className="error">
        <div className="error-bg">
            <div><h1>oops!</h1></div>
            <div><ErrorMessage code={404}/></div>
            <div> <a href="#">go back</a></div>
        </div>
      </div>
    </div>
  )
}

export default NotFound