import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import close_modal from "../../../assets/close.svg";
import { TextField } from "@mui/material";
import ContactListAuditLogGrid from "../../Grids/ContactListAuditLogGrid"
import { useDispatch, useSelector } from "react-redux";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import { toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import PropTypes from 'prop-types';

function MUIContactListDetailsModal({
  openDrawer,
  setOpenDrawer,
  entityDetails,
  token,
  setRefreshGrid
}) {
  const axiosPrivate = CreateAxiosInstance();
  const dispatch = useDispatch()
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  const [activeTab, setActiveTab] = useState("entityDetails");
  const [name, setName] = useState("");
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );
  const handleApprovalRequest = async (requestType) => {
    // e.preventDefault();
    // hideModal();
    // props.setShowAgGrid(false);
    const payload = {
      status: requestType,
      entity: "CONTACT_LIST",
      entityId:
        entityDetails.approvalEntityId === null
          ? entityDetails.id
          : entityDetails.approvalEntityId,
    };
    await axiosPrivate
      .post(process.env.REACT_APP_ACCEPT_REJECT_REQUEST, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message })
          );
          toggleDrawer(false);
          setOpenDrawer(false);
            setRefreshGrid(true);
          // props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          toggleFailAlert({
            title: "Failed!",
            message: "Unable to process request.",
          })
        );
        toggleDrawer(false);
        setOpenDrawer(false);
        // setRefreshGrid(true);
        // props.setShowAgGrid(true);
      });
  };
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };
  useEffect(() => {
    if (!openDrawer) {
      return;
    }
    setRefreshGrid(false);
    setName(entityDetails.name);
  }, [entityDetails, openDrawer]);
  
  const TabSwitch = () => {
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    return (
      <div className="tab-switch-container">
        <div
          className={`tab ${
            activeTab === "entityDetails" ? "active" : "inactive"
          }`}
          onClick={() => handleTabClick("entityDetails")}
        >
          Info
        </div>
        <div
          className={`tab ${activeTab === "auditLogs" ? "active" : "inactive"}`}
          onClick={() => handleTabClick("auditLogs")}
        >
          Audit logs
        </div>
      </div>
    );
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        {openDrawer && (
          <Box
            sx={{ width: 650 }}
            role="presentation"
            className="recipient-details-container"
          >
            <div className="recipient-details-header">
              <div className="recipient-modal-title">
                <h5> Contact List details</h5>
                <img
                  style={{ cursor: "pointer" }}
                  src={close_modal}
                  alt="Close Modal"
                  onClick={toggleDrawer(false)}
                />
              </div>
              <TabSwitch />
              {addDivider()}
            </div>
            <div
              className="recipient-details-body-content"
              style={{
                marginBlock: "14px",
                paddingBlock: 0,
                height: "20px",
                minHeight: "20px",
              }}
            >
              {activeTab === "entityDetails" && (
                <section className="">
                  <div className="recipient-item-pair-container">
                    <div className="mb-3">
                      <p className="recipient-details-label">Name</p>
                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        <TextField
                          id="input-with-icon-textfield"
                          label=""
                          notched={false}
                          placeholder="Add Group Name Here"
                          required={true}
                          disabled
                          value={name}
                          //   onChange={(e) => setName(e.target.value)}
                          InputProps={{
                            sx: {
                              padding: "1px 3px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              borderRadius: "8px",
                              height: "40px",
                              "& .MuiSvgIcon-root": {
                                height: "20px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& .MuiInputBase-input": {
                                fontWeight: 700,
                                fontFamily: "Inter",
                                lineHeight: "14px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              background: "rgba(247, 247, 250, 1)",
                            },
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === "auditLogs" && (
                <div>
                  <ContactListAuditLogGrid
                    token={token}
                    entityId={entityDetails.approvalEntityId === null ? entityDetails.id : entityDetails.approvalEntityId}
                  />
                </div>
              )}
            </div>
            {memberRole === "TS_ADMIN" &&
              entityDetails.approvalStatus === "PENDING" && (
                <div className="recipient-details-footer">
                  <div className="recipient-modal-response-buttons">
                    <div className="recipient-details-footer">
                      <div className="recipient-modal-response-buttons">
                        <div
                          className="reject"
                          onClick={() => handleApprovalRequest("REJECTED")}
                        >
                          <p className="reject-text">Reject</p>
                        </div>
                        <div
                          className="approve"
                          onClick={() => {
                            handleApprovalRequest("ACCEPTED");
                          }}
                        >
                          <p className="approve-text">Approve</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </Box>
        )}
      </Drawer>
    </div>
  );
}
MUIContactListDetailsModal.propTypes ={
    entityDetails: PropTypes.string,
    openDrawer: PropTypes.string,
    setOpenDrawer: PropTypes.func,
    token: PropTypes.string,
    setRefreshGrid: PropTypes.func
}

export default MUIContactListDetailsModal;
