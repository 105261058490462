import { randomColorData } from "../util/pieChartSliceRandomColors";
let deliveryFailedData = [];
export const getPieChartSliceColors = (
  sliceValue,
  data,
  notificationType,
  downloadingPdf,
) => {
  if (downloadingPdf === undefined || downloadingPdf === null) {
    if (sliceValue === "Delivery Failed") {
      deliveryFailedData = data;
    } else {
      let found = 0;
      deliveryFailedData.forEach((item) => {
        if (item.k === sliceValue) {
          found = 1;
        }
      });
      if (found === 1) {
        // deliveryFailedData=deliveryFailedData
      } else {
        deliveryFailedData = [];
      }
    }
  }
  let colorData = [];
  if (data) {
    switch (sliceValue) {
      case "AggregatorStats": {
        if (notificationType === "SMS")
          return ["#189AB4", "#05445E", "#D4F1F4"];
        else {
          return ["#88C1FF"];
        }
      }
      case "Messages Delivered":
        data.forEach((item) => {
          if (
            item.k === "totalPlivoCount" &&
            notificationType === "SMS" &&
            item.v > 0
          )
            colorData.push("#88C1FF");
          if (
            item.k === "totalMockCount" &&
            notificationType === "SMS" &&
            item.v > 0
          )
            colorData.push("#017AFF");
          if (
            item.k === "totalDeliveredMessagesCount" &&
            notificationType === "EMAIL" &&
            item.v > 0
          )
            colorData.push("#88C1FF");
        });
        break;
      case "Plivo":
        data.forEach((item) => {
          if (item.k === "totalPlivoCount" && item.v > 0)
            colorData.push("#88C1FF");
        });
        break;
      case "MAILJET":
        data.forEach((item) => {
          if (item.k === "totalDeliveredMessagesCount" && item.v > 0)
            colorData.push("#88C1FF");
        });
        break;
      case "Mock":
        data.forEach((item) => {
          if (item.k === "totalMockCount" && item.v > 0)
            colorData.push("#017AFF");
        });
        break;
      case "Validation Failed":
        data.forEach((item) => {
          if (item.k === "totalFailReason1Count" && item.v > 0)
            colorData.push("#FF3131");
          if (item.k === "totalFailReason2Count" && item.v > 0)
            colorData.push("#FF9382");
          if (item.k === "totalFailReason3Count" && item.v > 0)
            colorData.push("#FF6443");
          if (item.k === "totalValidationFailedMessagesCount" && item.v > 0)
            colorData.push("#FF8471");
        });
        break;
      case "Invalid Phone Number Length":
        data.forEach((item) => {
          if (item.k === "totalInvalidPhoneNumberLengthCount" && item.v > 0)
            colorData.push("#FF3131");
        });
        break;
      case "Message Content Too Long":
        data.forEach((item) => {
          if (item.k === "totalMessageContentTooLongCount" && item.v > 0)
            colorData.push("#FF9382");
        });
        break;
      case "Invalid Message Content":
        data.forEach((item) => {
          if (item.k === "totalInvalidMessageContentCount" && item.v > 0)
            colorData.push("#FF6443");
        });
        break;
      case "Delivery Failed":
        colorData = randomColorData;
        // data.forEach((item) => {
        //   if (!excludeKeys.includes(item.k)) {
        //     const generatedColor = chroma.random().hex();
        //     if (item.v > 0) {
        //       colorData.push(generatedColor);
        //       i = i + 1;
        //     }
        //   }
        // });
        break;
      case "Messages Pending":
        data.forEach((item) => {
          if (item.k === "totalValidatedMessagesCount" && item.v > 0)
            colorData.push("#FFFACD");
          if (item.k === "totalBuiltMessagesCount" && item.v > 0)
            colorData.push("#EEDC82");
          if (item.k === "totalDispatchedMessagesCount" && item.v > 0)
            colorData.push("#EEE8AA");
          if (item.k === "totalPlivoSentCount" && item.v > 0)
            colorData.push("#F0E68C");
          if (item.k === "totalPlivoQueuedCount" && item.v > 0)
            colorData.push("#FFDAB9");
          if (item.k === "totalPlivoRequestedCount" && item.v > 0)
            colorData.push("#D2B48C");
        });
        break;
      case "Validated Messages":
        data.forEach((item) => {
          if (item.k === "totalValidatedMessagesCount" && item.v > 0)
            colorData.push("#FFFACD");
        });
        break;
      case "VALIDATED":
        data.forEach((item) => {
          if (item.k === "VALIDATED" && item.v > 0) colorData.push("#FFFACD");
        });
        break;
      case "Built Messages":
        data.forEach((item) => {
          if (item.k === "totalBuiltMessagesCount" && item.v > 0)
            colorData.push("#EEDC82");
        });
        break;
      case "BUILT":
        data.forEach((item) => {
          if (item.k === "BUILT" && item.v > 0) colorData.push("#EEDC82");
        });
        break;
      case "Dispatched Messages":
        data.forEach((item) => {
          if (item.k === "totalDispatchedMessagesCount" && item.v > 0)
            colorData.push("#EEE8AA");
        });
        break;
      case "DISPATCHED":
        data.forEach((item) => {
          if (item.k === "DISPATCHED" && item.v > 0) colorData.push("#EEE8AA");
        });
        break;
      case "Plivo Sent":
        data.forEach((item) => {
          if (item.k === "totalPlivoSentCount" && item.v > 0)
            colorData.push("#F0E68C");
        });
        break;
      case "PLIVO_SENT":
        data.forEach((item) => {
          if (item.k === "PLIVO_SENT" && item.v > 0) colorData.push("#F0E68C");
        });
        break;
      case "Plivo Queued":
        data.forEach((item) => {
          if (item.k === "totalPlivoQueuedCount" && item.v > 0)
            colorData.push("#FFDAB9");
        });
        break;
      case "PLIVO_QUEUED":
        data.forEach((item) => {
          if (item.k === "PLIVO_QUEUED" && item.v > 0)
            colorData.push("#FFDAB9");
        });
        break;
      case "Plivo Requested":
        data.forEach((item) => {
          if (item.k === "totalPlivoRequestedCount" && item.v > 0)
            colorData.push("#D2B48C");
        });
        break;
      case "PLIVO_REQUESTED":
        data.forEach((item) => {
          if (item.k === "PLIVO_REQUESTED" && item.v > 0)
            colorData.push("#D2B48C");
        });
        break;
      default:
        if (deliveryFailedData.length > 0) {
          let i = 0;
          deliveryFailedData.forEach((item) => {
            if (item.k === sliceValue) {
              colorData.push("#FF6D56");
              i = i + 1;
            }
          });
        } else {
          data.forEach((item) => {
            if (item.k === "totalDeliveredMessagesCount" && item.v > 0)
              colorData.push("#017AFF");
            if (item.k === "totalPendingMessagesCount" && item.v > 0)
              colorData.push("#FFE986");
            if (item.k === "totalValidationFailedMessagesCount" && item.v > 0)
              colorData.push("#FF8471");
            if (item.k === "totalDeliveryFailedMessagesCount" && item.v > 0)
              colorData.push("#FF3131");
          });
        }
    }
    return colorData;
  }
};
