import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import close_modal from "../../../assets/close.svg";
import Select from "react-select";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import ShowError from "../../FormValidation/ShowError";
import { recipientConstants } from "./Constants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RecipientAuditLogGrid from "../../Grids/RecipientAuditLogGrid";

const EmailTemplateDetailsDrawer = ({
  openDrawer,
  setOpenDrawer,
  setRefreshGrid,
  data,
}) => {
  const Quill_Modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "size"], // toggled buttons
      ["blockquote", "code-block", "link"],
      ["image", "video"],

      [{ header: 1 }, { header: 2 }, { header: 3 }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"], // remove formatting button
    ],
  };
  const Quill_Formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "code-block",
    "color",
    "background",
    "script",
    "align",
    "indent",
    "direction",
  ];

  //Constants
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  // States
  const [error, setError] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [messageType, setMessageType] = useState([]);
  const [messagePriority, setMessagePriority] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [subject, setSubject] = useState("");
  const options = [
    { value: "OTP", label: "OTP" },
    { value: "Transactional", label: "Transactional" },
    { value: "Promotional", label: "Promotional" },
  ];
  const [activeTab, setActiveTab] = useState("entityDetails");

  const token = useSelector((state) => state.persistedReducer?.member?.token);
  setRefreshGrid(false);

  const handleCreateRequest = async () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    setRefreshGrid(false);
    populateForm(data);
  }, [data]);

  //Hydrate the form fields
  const populateForm = (data) => {
    if (!data) {
        setError("Invalid data");
    }
    setTemplateName(data.templateName);
    // setMessage(data.message);
    setMessagePriority(data.messagePriority);
    options.forEach((option) => {
      if (option.value === data.messageType) {
        setMessageType(option);
      }
    });
    setSubject(data.subject);
    setEmailContent(data.emailContent);
  };

  const onTemplateNameChange = (e) => {
    console.log("e,: ", e);
    let value = e.target.value;
    setTemplateName(value);
  };
  const onMessageTypeChange = (e) => {
    console.log("e,: ", e);
    setMessageType(e);
  };
  const onMessagePriorityChange = (e) => {
    const min = 1;
    const max = 3;
    let value = parseInt(e.target.value, 10);
    if (value > max) value = max;
    if (value < min) value = min;
    setMessagePriority(value);
  };
  const onSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  // Componet: Divider
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };

  // Component: Divider margin
  const addDividerMargin = () => {
    return <div className="divider-margin"></div>;
  };

  // Component: Tab Switch
  const TabSwitch = () => {
    const handleTabClick = (tabName) => {
      console.log("entitydetails id: ", data);
      setActiveTab(tabName);
    };

    return (
      <div className="tab-switch-container">
        <div
          className={`tab ${
            activeTab === "entityDetails" ? "active" : "inactive"
          }`}
          onClick={() => handleTabClick("entityDetails")}
        >
          Info
        </div>
        <div
          className={`tab ${activeTab === "auditLogs" ? "active" : "inactive"}`}
          onClick={() => handleTabClick("auditLogs")}
        >
          Audit logs
        </div>
      </div>
    );
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  return (
    <div>
      {/* Main container */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{ width: recipientConstants.RECIPIENT_DRAWER_WIDTH }}
          role="presentation"
          className="recipient-details-container"
        >
          {/* tenmp content */}

          <div className="recipient-details-header">
            <div className="recipient-modal-title">
              <h5> Email template details</h5>
              <img
                style={{ cursor: "pointer" }}
                src={close_modal}
                alt="Close Modal"
                onClick={toggleDrawer(false)}
              />
            </div>
            <TabSwitch />
            {addDivider()}
          </div>
          <div
            className="recipient-details-body-content"
            style={{
              marginBlock: "14px",
              paddingBlock: 0,
              height: "20px",
              minHeight: "20px",
            }}
          >
            {activeTab === "entityDetails" && (
              <section className="">
                <div className="recipient-item-pair-container">
                  <div className="mb-3">
                    <p className="recipient-details-label">Template Name</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Email Template Name"
                        required={true}
                        value={templateName}
                        onChange={onTemplateNameChange}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiSvgIcon-root": {
                              height: "20px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>

                  <Form.Group id="name">
                    <div className="mb-3">
                      <p className="recipient-details-label">Email Type</p>
                      {
                        <div>
                          <Select
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                flexGrow: 1,
                                marginTop: "16px",
                                padding: "3px 0px",
                                height: "40px",
                                borderRadius: "8px",
                                fontSize: "14px",
                                fontFamily: "Inter",
                                lineHeight: "20px",
                                color: "rgba(17, 21, 24, 1)",
                                // border:"1px solid red",
                                fontWeight: "500",
                                borderColor: state.isFocused
                                  ? "rgba(249, 249, 249, 1)"
                                  : "rgba(249, 249, 249, 1)",
                                backgroundColor: "rgba(249, 249, 249, 1)",
                              }),
                            }}
                            options={options}
                            placeholder="Select email type"
                            value={messageType}
                            onChange={onMessageTypeChange}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                      }
                    </div>
                  </Form.Group>
                </div>
                <div className="recipient-item-pair-container">
                  <Form.Group id="email">
                    <div className="mb-3">
                      <p className="recipient-details-label">Email Priority</p>
                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        <TextField
                          type="number"
                          id="input-with-icon-textfield"
                          label=""
                          notched={false}
                          required
                          placeholder="Email Priority"
                          value={messagePriority}
                          onChange={onMessagePriorityChange}
                          InputProps={{
                            sx: {
                              padding: "1px 3px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              borderRadius: "8px",
                              height: "40px",
                              "& .MuiInputBase-input": {
                                fontWeight: 700,
                                fontFamily: "Inter",
                                lineHeight: "14px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              background: "rgba(247, 247, 250, 1)",
                            },
                          }}
                        />
                      </Box>
                    </div>
                  </Form.Group>

                  <Form.Group id="email">
                    <div className="mb-3">
                      <p className="recipient-details-label">Email Subject</p>
                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        <TextField
                          id="input-with-icon-textfield"
                          label=""
                          notched={false}
                          required
                          placeholder="Email Subject"
                          value={subject}
                          onChange={onSubjectChange}
                          InputProps={{
                            sx: {
                              padding: "1px 3px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              borderRadius: "8px",
                              height: "40px",
                              "& .MuiInputBase-input": {
                                fontWeight: 700,
                                fontFamily: "Inter",
                                lineHeight: "14px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              background: "rgba(247, 247, 250, 1)",
                            },
                          }}
                        />
                      </Box>
                    </div>
                  </Form.Group>
                </div>
                <div className="recipient-item-pair-container email-content">
                  <Form.Group id="email-content">
                    <div className="mb-3">
                      <Form.Label>Email Content</Form.Label>
                      <ReactQuill
                        readOnly={true}
                        modules={Quill_Modules}
                        formats={Quill_Formats}
                        theme="snow"
                        value={emailContent}
                        onChange={setEmailContent}
                        placeholder="Type your email content here"
                      />
                    </div>
                  </Form.Group>
                </div>
                {error && <ShowError msg={error} />}

                {/* horizontal line break */}
                {addDividerMargin()}
                {addDivider()}
                <div className="pb-2"></div>
              </section>
            )}
            {activeTab === "auditLogs" && (
              <div>
                <RecipientAuditLogGrid token={token} entityId={data.id} />
              </div>
            )}
          </div>
          <div className="recipient-details-footer">
            <div className="recipient-modal-response-buttons">
              <div
                className="approve"
                onClick={() => {
                  handleCreateRequest();
                }}
              >
                <p className="approve-text">Close</p>
              </div>
            </div>
          </div>

          {/* end of temp content */}
        </Box>
      </Drawer>
    </div>
  );
};

EmailTemplateDetailsDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setRefreshGrid: PropTypes.func,
  setOpenDrawer: PropTypes.func,
  data: PropTypes.shape({
    templateName: PropTypes,
    emailContent: PropTypes,
    messageType: PropTypes,
    messagePriority: PropTypes,
    subject: PropTypes,
    id: PropTypes,
  }),
};

export default EmailTemplateDetailsDrawer;
