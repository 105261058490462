import { Modal, Button, Form, FormGroup } from "react-bootstrap";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import validate from "../FormValidation/validation";
import ShowError from "../FormValidation/ShowError";
import { useDispatch } from "react-redux";
import { toggleAlert } from "../../Redux/Reducers/alertsReducers";
import {
  transactionalSenderIDList,
  promotionalSenderIDList,
} from "../../DummyData/OneTimeSendData/SenderIDs/SenderIDs";
import {
  aggregatorList,
  messageTypeList,
} from "../../DummyData/Aggregators/Aggregators";
import PropTypes from "prop-types";
import { FormControl } from "@themesberg/react-bootstrap";
import CustomizedSteppers from "../Stepper/CustomStepper";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const OneTimeMessageModal = (props) => {
  const { templatedata: templateData, groupdata: groupData } = props;

  const [page, setPage] = useState(1);
  const [senderID, setSenderID] = useState("");
  const [messageType, setMessageType] = useState("SMS");
  const [messageTemplateID, setMessageTemplateID] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [destination, setDestination] = useState("");
  const [groupID, setGroupID] = useState("");
  const [scheduleFrequency, setScheduleFrequency] = useState(0);
  const [triggerTime, setTriggerTime] = useState("");
  const dispatch = useDispatch();
  // const token = useSelector((state) => state.persistedReducer?.member?.token);
  const steps = 4;
  const [activeStep, setActiveStep] = React.useState(0);

  // validate states
  const [messageTemplateIDError, setMessageTemplateIDError] = useState("");
  const [groupIDError, setGroupIDError] = useState("");

  const handleClose = () => {
    setNotificationType("");
    setMessageTemplateID("");
    setMessageType("SMS");
    setSenderID("");
    setGroupID("");
    setDestination("");
    setPage(1);
    setActiveStep(0);
    props.onHide();
  };

  const handleSubmit = (e, hideModal) => {
    e.preventDefault();
    setPage(1);

    if (validate(messageTemplateID, "isEmpty")) {
      setMessageTemplateIDError("Please select a message template.");
      return;
    } else setMessageTemplateIDError("");

    if (validate(groupID, "isEmpty")) {
      setGroupIDError("Please select a group.");
      return;
    } else setGroupIDError("");
    sendMesage();
    hideModal();
  };

  useEffect(() => {}, [messageTemplateID, groupID, destination]);

  const sendMesage = () => {
    dispatch(
      toggleAlert({
        title: "Message Scheduled",
        message: "Message has been scheduled.",
      })
    );
    // axiosWithBaseUrl.post(
    //   process.env.REACT_APP_BULK_SEND,
    //   { messageTemplate: messageTemplateID, groupId: groupID, notificationType: [messageType === 'otp'? 'SMS' : messageType] },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
  };
  const getForm = () => {
    switch (page) {
      case 1:
        return (
          <>
            <FormGroup id="messageType">
              <Form.Label>Notification Type</Form.Label>
              <Form.Select
                value={messageType}
                onChange={(e) => setMessageType(e.target.value)}
              >
                {messageTypeList.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </FormGroup>
            <FormGroup id="notificationType" className="mt-3">
              <Form.Label>Message Type</Form.Label>
              <Form.Select
                value={notificationType}
                onChange={(e) => setNotificationType(e.target.value)}
              >
                {/* <option value="otp">OTP</option> */}
                <option value="transactional">Transactional</option>
                <option value="promotional">Promotional</option>
              </Form.Select>
            </FormGroup>
          </>
        );

      case 2:
        return (
          <FormGroup id="senderID">
            <Form.Label>Choose Sender ID</Form.Label>
            <Form.Select
              value={senderID}
              onChange={(e) => setSenderID(e.target.value)}
            >
              <option value="" disabled hidden>
                Choose Sender ID
              </option>
              {messageType === "otp" || messageType === "transactional"
                ? transactionalSenderIDList.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))
                : promotionalSenderIDList.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
            </Form.Select>
          </FormGroup>
        );
      case 3:
        return (
          <>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>
                  Choose Message Template -{" "}
                  <b> Message Template Name: [Message Template Content]</b>
                </Form.Label>
                <Select
                  placeholder="Choose Message Template"
                  onChange={(e) => setMessageTemplateID(e.value)}
                  options={
                    templateData &&
                    templateData.map((item) => {
                      return {
                        label: `${item?.templateName} : [${item?.message}]`,
                        value: item?.id,
                      };
                    })
                  }
                />
                {messageTemplateIDError && (
                  <ShowError msg={messageTemplateIDError} />
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Choose Contact List</Form.Label>
                <Select
                  placeholder="Choose Contact List"
                  onChange={(e) => setGroupID(e.value)}
                  options={
                    groupData &&
                    groupData.map((item) => {
                      return {
                        label: `${item?.name}   [${
                          item.userCount[0]?.totalCount || "No"
                        } user(s) in group]`,
                        value: item?.id,
                      };
                    })
                  }
                />
                {groupIDError && <ShowError msg={groupIDError} />}
              </div>
            </FormGroup>
          </>
        );

      case 4:
        return (
          <div>
            <FormGroup>
              <Form.Label>Choose Aggregator</Form.Label>
              <Select
                placeholder="Choose Aggregator"
                onChange={(e) => setDestination(e.value)}
                id="aggregator"
                options={(aggregatorList[messageType] || []).map((option) => {
                  return {
                    label: option.label,
                    value: option.value,
                  };
                })}
              />
              <div className="mb-3"></div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Schedule Frequency</Form.Label>
                <FormControl
                  type="number"
                  aria-label="number"
                  value={scheduleFrequency}
                  onChange={(e) =>
                    setScheduleFrequency(parseInt(e.target.value))
                  }
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Time to Send at</Form.Label>
                <input
                  className="form-control"
                  type="datetime-local"
                  value={triggerTime}
                  onChange={(e) => setTriggerTime(e.target.value)}
                />
              </div>
            </FormGroup>
          </div>
        );
    }
  };

  const goToNextForm = () => {
    setPage((page) => page + 1);
    // setLoading(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPreviousForm = () => {
    setPage((page) => page - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateForm = () => {
    let disabled = false;
    if (page === 1 && messageType === "") disabled = true;
    if (page === 2 && senderID === "") disabled = true;
    if (page === 3 && (messageTemplateID === "" || groupID === ""))
      disabled = true;
    if (page === 4 && destination === "") disabled = true;
    return disabled;
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="send-modal"
    >
      <Modal.Header closeButton onClose={handleClose}>
        {page > 1 && (
          <IconButton
            aria-label="delete"
            onClick={goToPreviousForm}
            className="back-icon-btn"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Modal.Title id="contained-modal-title-vcenter">
          {`Scheduled Send`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomizedSteppers steps={steps} activeStep={activeStep} />
        <Form>{getForm()}</Form>
      </Modal.Body>
      <div className="d-flex justify-content-between p-3">
        <Button
          className="text-btn"
          variant="text"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <div className="d-flex gap-3 ">
          {page > 1 && (
            <Button
              className="text-btn"
              variant="text"
              onClick={goToPreviousForm}
            >
              Back
            </Button>
          )}
          {page < 4 && (
            <Button
              className="next-btn"
              onClick={goToNextForm}
              disabled={validateForm()}
            >
              Next
            </Button>
          )}
          {page === 4 && (
            <Button
              type="submit"
              className="next-btn"
              onClick={(e) => handleSubmit(e, props.onHide)}
              disabled={!(destination && scheduleFrequency && triggerTime)}
            >
              Send
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
OneTimeMessageModal.propTypes = {
  templatedata: PropTypes.any.isRequired,
  groupdata: PropTypes.any.isRequired,
  onHide: PropTypes,
};

export default function App({ templateData, groupData, onHide }) {
  App.propTypes = {
    templateData: PropTypes,
    groupData: PropTypes,
    onHide: PropTypes,
  };

  return (
    <>
      <OneTimeMessageModal
        show={true}
        onHide={onHide}
        templatedata={templateData}
        groupdata={groupData}
      />
    </>
  );
}
