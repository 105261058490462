import React from "react";
import uploadCSV from "../../assets/upload_csv.svg";
import PropTypes from 'prop-types'; // Import PropTypes with correct casing
import { buttonStyles, labelStyles } from "./CommonStyles";

const UploadCSV = ({onClick}) => {

  return (
    <div style={buttonStyles} onClick={onClick}>
      <div className="d-flex align-items-center justify-content-center gap-1">
        <img src={uploadCSV} alt="Upload Icon"/>
        <p style={labelStyles}>Upload CSV</p>
      </div>
    </div>
  );
}


UploadCSV.propTypes = { // Ensure correct casing for propTypes
  onClick: PropTypes // Validate onClick prop as a function
}

export default UploadCSV;