export const reactSelectStyles={
    control: (baseStyles, state) => ({
      ...baseStyles,
      flexGrow: 1,
      // marginTop: "16px",
      // padding: "3px 0px",
      height: "40px",
      borderRadius: "8px",
      fontSize: "13px",
      fontFamily: "Inter",
      fontWeight:600,
      lineHeight: "20px",
      color: "rgba(126, 126, 126, 1)",
      // border:"1px solid red",
      borderColor: state.isFocused
        ? "rgba(249, 249, 249, 1)"
        : "rgba(249, 249, 249, 1)",
      backgroundColor: "transparent",
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: 'rgba(126, 126, 126, 1)',
          fontWeight:600
      }
  },
  dropdownIndicator: (base) => {
    let changes = {
      // all your override styles
      color: "rgba(126, 126, 126, 1)"
    };
    return Object.assign(base, changes);
  }
  }