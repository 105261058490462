import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faKey, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Buffer } from "buffer";
import BgImage from "../../assets/img/illustrations/signin.svg";
import { useDispatch, useSelector } from "react-redux";
import { getMemberData } from "../../Redux/Reducers/memberReducer"
import APISuccess from "../../components/Toasts/APISuccess";
import { toggleAlert } from "../../Redux/Reducers/alertsReducers";
import { axiosWithBaseUrl } from "../../axios/baseUrl";
import TSFull from "../../assets/TS_full_black.png"

const UserEntry = () => {
    // User details
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [signinErrorMsg, setSigninErrorMsg] = useState("");
    const [signupErrorMsg, setSignupErrorMsg] = useState("");
    const alertSuccess = useSelector(state => state.persistedReducer.alerts.showSuccess);

    // Current tab
    const [currentTab, setCurrentTab] = useState("signin");

    const dispatch = useDispatch();
    const data = useSelector(state => state.persistedReducer?.member?.data?.data)

    if (data) window.location.assign("/");

    const handleLogin = async (e) => {
        e.preventDefault();
        setSigninErrorMsg("");
        //Encode the user details send in header
        const encodedString = Buffer.from(username + ':' + password).toString('base64');
        await axiosWithBaseUrl(process.env.REACT_APP_MEMBER_LOGIN,
            {
                headers: {
                    "Authorization": `Bearer ${encodedString}`
                }
            })
            .then(resp => {
                if (resp?.data?.status === 200) {
                    dispatch(getMemberData(resp.data.data));
                }
            })
            .catch(err => {
                console.error(err);
                setSigninErrorMsg("Login Failed. Please check your credentials.");
            })
    }

    const handleSignup = async e => {
        e.preventDefault();
        setSignupErrorMsg("");

        if (newPassword !== confirmPassword) {
            setSignupErrorMsg("Passwords do not match");
            return;
        }

        await axiosWithBaseUrl.post(process.env.REACT_APP_MEMBER_SIGNUP, { username: newUsername, password: newPassword, roles: ["admin"] })
            .then(resp => {
                if (resp.data.status === 200) {
                    dispatch(toggleAlert({ title: "Success!", message: "Registration Successful. You may login now." }));
                    setCurrentTab("signin");
                }
            })
            .catch(err => {
                if (err.response?.data?.status === 409) {
                    setSignupErrorMsg(err.response.data.message);
                }
            })

    }

    return (
        <main>
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5" >
                {alertSuccess && <APISuccess toggle={true} />}
                <Container>
                    <p className="text-center mb-5">
                        <div>
                            <img src={TSFull} width="400" style={{ opacity: .8 }} />
                        </div>
                    </p>
                    <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }} >
                        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
                            <div className="bg-white shadow-soft border rounded border-light p-4 px-lg-5 py-lg-3 w-100 fmxw-500">
                                <div className="mx-auto w-75 d-flex justify-content-between align-items-center gap-3 px-3 entry-type-container">
                                    <h4
                                        onClick={() => setCurrentTab("signin")}
                                        className={`entry-tab ${currentTab === "signin" && 'user-entry__active-tab'}`}
                                    >Sign in</h4>
                                    <h2 className="entry-type-seperator">|</h2>
                                    <h4
                                        className={`entry-tab ${currentTab === "signup" && 'user-entry__active-tab'}`}
                                        onClick={() => setCurrentTab("signup")}

                                    >Sign up</h4>
                                </div>
                            </div>
                            {
                                currentTab === "signin" ?
                                    <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                        <div className="text-center text-md-center mb-4 mt-md-0">
                                            <h3 className="mb-0">Sign in to our platform</h3>
                                        </div>
                                        <Form className="mt-4" onSubmit={handleLogin}>
                                            <Form.Group id="username" className="mb-4">
                                                <Form.Label>Your Username</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        autoFocus
                                                        required
                                                        type="text"
                                                        placeholder="Your name"
                                                        value={username}
                                                        onChange={e => setUsername(e.target.value)}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Group id="password" className="mb-4">
                                                    <Form.Label>Your Password</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FontAwesomeIcon icon={faUnlockAlt} />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            required
                                                            type="password"
                                                            placeholder="Password"
                                                            value={password}
                                                            onChange={e => setPassword(e.target.value)}
                                                        />
                                                    </InputGroup>
                                                </Form.Group>
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <Form.Check type="checkbox">
                                                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                                                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                                                    </Form.Check>
                                                    <Card.Link className="small text-end">Lost password?</Card.Link>
                                                </div>
                                            </Form.Group>
                                            {
                                                signinErrorMsg && <p className="text-danger">{signinErrorMsg}</p>
                                            }
                                            <Button variant="primary" type="submit" className="w-100">
                                                Sign in
                                            </Button>
                                        </Form>

                                        <div className="mt-3 mb-4 text-center">
                                            <span className="fw-normal">or with SSO </span>
                                        </div>
                                        <div className="d-flex justify-content-center my-4">
                                            <Button variant="outline-light" className="w-100 d-flex justify-content-center gap-2 align-items-center text-facebook">
                                                <FontAwesomeIcon icon={faKey} />
                                                Employees Login
                                            </Button>
                                        </div>
                                    </div> :
                                    <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                                        <div className="text-center text-md-center mb-4 mt-md-0">
                                            <h3 className="mb-0">Create an account</h3>
                                        </div>
                                        <Form className="mt-4" onSubmit={handleSignup}>
                                            <Form.Group id="username" className="mb-4">
                                                <Form.Label>Your Username</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        autoFocus
                                                        required
                                                        type="text"
                                                        placeholder="Your user name"
                                                        value={newUsername}
                                                        onChange={e => setNewUsername(e.target.value)}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group id="password" className="mb-4">
                                                <Form.Label>Your Password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        placeholder="Password"

                                                        value={newPassword}
                                                        onChange={e => setNewPassword(e.target.value)}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group id="confirmPassword" className="mb-4">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faUnlockAlt} />
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        value={confirmPassword}
                                                        onChange={e => setConfirmPassword(e.target.value)}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <FormCheck type="checkbox" className="d-flex mb-4">
                                                <FormCheck.Input required id="terms" className="me-2" />
                                                <FormCheck.Label htmlFor="terms">
                                                    I agree to the <Card.Link>terms and conditions</Card.Link>
                                                </FormCheck.Label>
                                            </FormCheck>
                                            {
                                                signupErrorMsg && <p className="text-danger">{signupErrorMsg}</p>
                                            }

                                            <Button variant="primary" type="submit" className="w-100">
                                                Sign up
                                            </Button>
                                        </Form>

                                        <div className="mt-3 mb-4 text-center">
                                            <span className="fw-normal">or</span>
                                        </div>
                                        <div className="d-flex justify-content-center my-4">
                                            <Button variant="outline-light" className="w-100 d-flex justify-content-center gap-2 align-items-center text-facebook">
                                                <FontAwesomeIcon icon={faKey} />
                                                Employees Login
                                            </Button>
                                        </div>
                                    </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
export default UserEntry;