import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import "ag-grid-enterprise";
import moment from "moment";
import { convertAgGridTimeToLocalTime } from "../../util/dateUtil";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import PropTypes from "prop-types";
import UpdateGroupModal from "../Modals/MUIModals/MUIContactListDetailsModal";
import UpdateUserModal from "../Modals/MUIModals/MUIRecipientDetailsModal";
import UpdateTemplate from "../Modals/MUIModals/MUIMessageTemplateDetailsModal";
import UpdateSpamFilterModal from "../Modals/MUIModals/MUISMSSpamDetailModal";
import EditEntity from "../../components/Buttons/EditEntity";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
const ApprovalRequestGrid = ({ heading, fromDate, toDate }) => {
  // User token to pass for api requests
  console.log(heading, "heading");
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const [entity, setEntity] = useState("");
  const [singleData, setSingleData] = useState([]);
  const [editModalShow, setEditModalShow] = useState(false);
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState(null);
  let totalRecords = useRef(0);
  const paginationPageSize = 15;
  const cacheBlockSize = 15;
  const axiosPrivate = CreateAxiosInstance();
  const [showAgGrid, setShowAgGrid] = useState(true);
  const [refreshGridCache, setRefreshGridCache] = useState(false);

  //START OF AG GRID CONFIGURATIONS
  /*
      Three types of column filter: agTextColumnFilter, agNumberColumnFilter & agDateColumnFilter
       */
  const colDefs = [
    {
      headerName: "Created on",
      field: "createdOn",
      flex: 1,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["lessThan", "greaterThan", "inRange"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Created by name",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Entity",
      field: "entity",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Operation",
      field: "operation",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];
  const getSingleData = async (id, entity) => {
    await axiosPrivate
      .get(
        `${process.env.REACT_APP_APPROVAL_REQUEST}?id=${id}&entity=${entity}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Timezone: moment().utcOffset(),
          },
        },
      )
      .then((res) => {
        setSingleData(res.data.data);

        setEditModalShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ActionButtons = (params) => {
    const handleClick = () => {
      setEntity(params.data.entity);
      getSingleData(params.data.id, params.data.entity);
    };
    return (
      <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
        }}
      >
        <EditEntity
          onClick={() => {
            handleClick();
          }}
        />
      </div>
    );
  };
  const frameworkComponents = {
    renderActionButtons: ActionButtons,
  };
  colDefs.push({
    headerName: "Approval",
    cellRenderer: "renderActionButtons",
    headerClass: "cell-border-left",
  });

  const [columnDefs] = useState(colDefs);
  const dataSource = useMemo(() => {
    return {
      async getRows(params) {
        const { startRow, endRow, filterModel, sortModel } = params.request;
        if (Object.keys(filterModel).length > 0) {
          convertAgGridTimeToLocalTime(filterModel, fromDate, toDate);
        }
        let payload = {
          startRow: startRow,
          endRow: endRow,
          sortModel: sortModel,
          filterModel: filterModel,
          from: fromDate,
          to: toDate,
        };

        await axiosPrivate
          .post(`${process.env.REACT_APP_APPROVAL_REQUEST}`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
              Timezone: moment().utcOffset(),
            },
          })
          .then((response) => {
            const resp = response?.data?.data;
            if (!resp?.approvalRequests?.length) {
              params.successCallback([], 0);
              params.api.showNoRowsOverlay();
            } else {
              params.successCallback(
                resp.approvalRequests,
                parseInt(resp?.approvalRequests?.length),
              );
            }

            //For CSV download button setting the totalRows:
            totalRecords.current = resp.totalLogs;
          })
          .catch((error) => {
            console.error(error);
            params.failCallback();
          });
      },
    };
  }, [fromDate, toDate, token, axiosPrivate]);

  //change grid data according to filters used
  useEffect(() => {
    rowData && rowData.setServerSideDatasource(dataSource);
    // eslint-disable-next-line
  }, [fromDate, toDate, dataSource]);

  useEffect(() => {
    if (refreshGridCache) {
        refreshGrid("");
    }
  }, [refreshGridCache]);

  //change grid data according to pie chart slice click
  // useEffect(() => {
  //   rowData && rowData.setServerSideDatasource(dataSource);
  // }, [rowData, dataSource]);

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,

      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    [],
  );

  const refreshCache = useCallback(
    (searchData) => {
      const newDataSource = {
        async getRows(params) {
          const { startRow, endRow, filterModel, sortModel } = params.request;
          if (Object.keys(filterModel).length > 0) {
            convertAgGridTimeToLocalTime(filterModel, fromDate, toDate);
          }
          let payload = {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            from: fromDate,
            to: toDate,
            globalSearch: searchData,
          };

          await axiosPrivate
            .post(`${process.env.REACT_APP_APPROVAL_REQUEST}`, payload, {
              headers: {
                Authorization: `Bearer ${token}`,
                Timezone: moment().utcOffset(),
              },
            })
            .then((response) => {
              const resp = response?.data?.data;
              if (!resp?.approvalRequests?.length) {
                params.successCallback([], 0);
                params.api.showNoRowsOverlay();
              } else {
                params.successCallback(
                  resp.approvalRequests,
                  parseInt(resp?.approvalRequests?.length),
                );
              }

              //For CSV download button setting the totalRows:
              totalRecords.current = resp.totalLogs;
            })
            .catch((error) => {
              console.error(error);
              params.failCallback();
            });
        },
      };
      gridRef.current.api.setServerSideDatasource(newDataSource);
    },
    [axiosPrivate, token, fromDate, toDate],
  );

  const refreshGrid = (searchData) => {
    if (!gridRef.current.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  const dimensions = { width: "100%" };

  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
          <GlobalSearch refreshGrid={refreshGrid} />
        </div>
        {showAgGrid ? (
          <div
            id="msgHealthTable"
            className="ag-theme-alpine"
            style={dimensions}
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={paginationPageSize}
              cacheBlockSize={cacheBlockSize}
              domLayout="autoHeight" // if too many rows should not use this, give fixed height
              animateRows={true}
              rowModelType="serverSide"
              serverSideInfiniteScroll={true}
              onGridReady={onGridReady}
              overlayNoRowsTemplate="No data to show"
              components={frameworkComponents}
            />
          </div>
        ) : (
          <></>
        )}
        {editModalShow && entity === "CONTACT_LIST" && (
          <UpdateGroupModal
            token={token}
            openDrawer={editModalShow}
            setOpenDrawer={setEditModalShow}
            entityDetails={singleData}
            setRefreshGrid={setRefreshGridCache}
          />
        )}
        {editModalShow && entity === "RECIPIENT" && (
          <UpdateUserModal
            openDrawer={editModalShow}
            setOpenDrawer={setEditModalShow}
            entityDetails={singleData}
            setRefreshGrid={setShowAgGrid}
          />
        )}
        {editModalShow && entity === "MESSAGE_TEMPLATE" && (
          <UpdateTemplate
            openDrawer={editModalShow}
            setOpenDrawer={setEditModalShow}
            entityDetails={singleData}
            setRefreshGrid={setRefreshGridCache}
          />
        )}
        {editModalShow && entity === "SPAM_FILTER" && (
          <UpdateSpamFilterModal
            token={token}
            openDrawer={editModalShow}
            setOpenDrawer={setEditModalShow}
            entityDetails={singleData}
            setRefreshGrid={setRefreshGridCache}
          />
        )}
      </div>
    </>
  );
};
ApprovalRequestGrid.propTypes = {
  heading: PropTypes,
  fromDate: PropTypes,
  toDate: PropTypes,
};
export default ApprovalRequestGrid;
