import React from "react";
import addImage from "../../assets/add.svg";
import PropTypes from "prop-types"; // Import PropTypes with correct casing

const CreateEntity = ({ btnText, onClick }) => {
  const buttonStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 12px",
    background: "#7373DB",
    borderRadius: "8px",
    cursor: "pointer",
  };

  const labelStyles = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14px",
    /* identical to box height, or 117% */
    color: "#FFFFFF",
    margin:0
  };

  return (
    <div style={buttonStyles} onClick={onClick}>
      <div className="d-flex align-items-center justify-content-center gap-2">
        <img src={addImage} />
        <p style={labelStyles}>{btnText}</p>
      </div>
    </div>
  );
};

CreateEntity.propTypes = {
  // Ensure correct casing for propTypes
  btnText: PropTypes, // Validate onClick prop as a function
  onClick: PropTypes,
};

export default CreateEntity;
