export const isLeaf = (sliceValue) => {
    switch (sliceValue) {
        case "Plivo": return true;
        case "Mock": return true;
        case "Built Messages": return true;
        case "Validated Messages": return true;
        case "Dispatched Messages": return true;
        case "Prohibited By Carrier": return true;
        case "Message Content Too Long": return true;
        case "Invalid Message Content": return true;
        case "Invalid Phone Number Length": return true;
        case "Blocked By Recipient": return true;
        case "Recipient Unsubscribed": return true;
    }
    return false;
}