export const flashAgGridData = {
  data: {
    messageDetails: [
      {
        createdOn: "22-09-2022 18:00:32:508",
        updatedOn: "22-09-2022 18:20:00:508",
        email: "akash.21@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "BUILT",
        createdByName: "Mohit2",
      },
      {
        createdOn: "22-09-2022 18:00:32:508",
        updatedOn: "22-09-2022 18:20:00:508",
        email: "jon.1990@gmail.com",
        smsProvider: "MOCK",
        messageStatus: "MOCK_SENT",
        smsFailureReason: "-",
        createdByName: "Mohit2",
      },
      {
        createdOn: "22-09-2022 18:00:32:508",
        updatedOn: "22-09-2022 18:20:00:508",
        email: "jon.1990@gmail.com",
        smsProvider: "-",
        messageStatus: "VALIDATION_FAILED",
        smsFailureReason: "Duplicated Message",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "jon.1990@gmail.com",
        smsProvider: "-",
        messageStatus: "VALIDATION_FAILED",
        smsFailureReason: "Duplicated Message",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "thurston@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "VALIDATED",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "alfred@gmail.com",
        smsProvider: "PEPIPOST",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "mglee@gmail.com",
        smsProvider: "PEPIPOST",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Prohibited By Carrier",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "ribet@gmail.com",
        smsProvider: "BRAINPULSE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "zeller@gmail.com",
        smsProvider: "BRAINPULSE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Prohibited By Carrier",
        createdByName: "Mohit2",
      },
      {
        createdOn: "18-08-2022 14:23:32:508",
        updatedOn: "18-08-2022 14:23:32:508",
        email: "gmcgath@gmail.com",
        smsProvider: "MOCK",
        messageStatus: "MOCK_SENT",
        smsFailureReason: "-",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "jginspace@gmail.com",
        smsProvider: "BRAINPULSE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "drhyde@gmail.com",
        smsProvider: "BRAINPULSE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Prohibited By Carrier",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "pajas@gmail.com",
        smsProvider: "PEPIPOST",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "demmel@gmail.com",
        smsProvider: "PEPIPOST",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Prohibited By Carrier",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "ilikered@gmail.com",
        smsProvider: "PEPIPOST",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "overbom@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Prohibited By Carrier",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "eidac@gmail.com",
        smsProvider: "MOCK",
        messageStatus: "MOCK_SENT",
        smsFailureReason: null,
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "ilikered@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "ilikered@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "ilikered@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
      {
        createdOn: "17-08-2022 14:23:32:560",
        updatedOn: "17-08-2022 14:23:32:560",
        email: "ilikered@gmail.com",
        smsProvider: "SENDINBLUE",
        messageStatus: "DELIVERY_FAILED",
        smsFailureReason: "Blocked By Recipient",
        createdByName: "Mohit2",
      },
    ],
    totalMessagesCount: 128,
  },
};
