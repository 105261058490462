export default {
  data: {
    messageTemplateDetails: [
      {
        id: "6332c82d99a35d48dd7ffe13",
        templateName: "ओटीपी",
        message: "नमस्ते {{name}}, लेन-देन के लिए आपका ओटीपी 12345 है।",
        messageType: "Transactional",
        messagePriority: 1,
        notificationType: "Flash SMS",
        createdByName: "Mohit",
      },
      {
        id: "62c810633b9c707caa5cbe4b",
        templateName: "OTP",
        message: "Hello {{name}}, Your OTP for Transaction is 12345.",
        messageType: "Transactional",
        messagePriority: 1,
        notificationType: "Flash SMS",
        createdByName: "Mohit",
      },
      // {
      //     "id": "62c810633b9c707caa5cbe4b",
      //     "templateName": "dynamic",
      //     "messageType": "OTP",
      //     "messagePriority": 1,
      //     "createdByName": "Mohit"
      // }
    ],
    totalMessageTemplatesCount: 3,
  },
};
