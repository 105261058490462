import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Image,
//   Navbar,
//   Container,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../Redux/Reducers/memberReducer";
import { logoutAPI } from "../util/logoutApiUtil";
import profile from "../assets/Profile.svg"
import userMenu from "../assets/userMenu.svg"
import PropTypes from 'prop-types';

function UserMenu(props) {
  const username = useSelector(state => state.persistedReducer?.member?.data?.name);
  const memberRole = useSelector(state => state.persistedReducer?.member?.data?.roles);
  const email = useSelector(state => state.persistedReducer?.member?.data?.email);
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const refreshToken = useSelector((state) => state.persistedReducer?.member?.refreshToken);
  const dispatch = useDispatch();

  const dispatchLogout= ()=>{
    dispatch(logout())
  }
  
  const handleLogout = () => {
    logoutAPI(token, refreshToken, dispatchLogout);
  }
  return (
    <Nav className="align-items-center" >
            {
              username ?
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0 nav-profile">
                    <div className="media d-flex align-items-center">
                      <div className="media-body text-dark align-items-center d-none d-lg-block">
                        <div className="media d-flex align-items-center" style={{gap:"12px"}}>
                          <Image
                            src={profile}

                            className="user-avatar md-avatar rounded-circle user-icon"
                          />
                          <div style={{ width:props.expanded?"170px":"0px",overflow:"hidden",transition:"all",fontFamily:"Inter",fontSize:"13px",display:"flex",flexDirection:"column",  }}>
                            <span className="text-textPrimary">{username} {memberRole === "TS_ADMIN" && `(ADMIN)`}</span>
                            {email && (
                            <span style={{ color: "#7E7E7E", fontFamily:"Inter", fontSize:"12px", marginTop:"-8px"}}>{email}</span>)}
                          </div>
                          <div>
                            <img
                              src={userMenu}
                              width="8.5px"
                              height="12px"
                            />
                            
                          </div>
                        </div>
                      </div>
                    </div>

                  </Dropdown.Toggle>
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2" style={{paddingLeft:"8px", paddingRight: "8px"}}>
                    <Dropdown.Item
                      className="fw-bold text-textPrimary dropdown-menu-icon"
                    >
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="fw-bold text-textPrimary dropdown-menu-icon"
                    >
                      Preferences
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="fw-bold text-textPrimary dropdown-menu-icon"
                      onClick={handleLogout}
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="text-danger me-2"
                      />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                :

                <Link to="/entry">
                  <Button
                    variant="primary"
                    className="px-4 ms-auto"
                  >Login</Button>
                </Link>
            }


          </Nav>
  )
}
UserMenu.propTypes = {
  expanded: PropTypes.string
}

export default UserMenu