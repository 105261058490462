import { Modal, Button, Form } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleAlert, toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Quill_Modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'size'],        // toggled buttons
        ['blockquote', 'code-block', 'link'],
        ['image', 'video'],

        [{ 'header': 1 }, { 'header': 2 }],
        [{ "header": [] }],                                      // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ]
};
const Quill_Formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "code-block",
    "color",
    "background",
    'script',
    'align',
    'indent',
    'direction',
]

const CreateTemplateModal = (props) => {
  // const [emailContent, setEmailContent]=useState("<p>Thanks for subscribing to the This Week in Cats newsletter!</p><p><br></p><p>But before we can send you a newsletter, we need to confirm that you're a real person who wants email from us!</p><p><br></p><p>If you are, click here:</p><p><br></p><p>Not you? You may unsubscribe to us at anytime by clicking on the link at the bottom of this email.</p><p><br></p><p>BuzzFeed</p><p>11E, 5th Street</p><p>Bangalore</p><p><br></p><p>Unsubsribe</p><button>Clickme</button>");
  const [emailContent, setEmailContent]=useState(props.props.data);

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.props.templateName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactQuill
            readOnly
            modules={Quill_Modules}
            formats={Quill_Formats}
            theme="snow"
            value={emailContent}
            onChange={setEmailContent}
            placeholder="Type your email content here"
            // style={{height:"1000px"}}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function App(props) {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <Button variant="primary" className=" py-1 fs-6 transparent-btn" onClick={() => setModalShow(true)}>
      View
      </Button>
      {
        modalShow && <CreateTemplateModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          props={props}
        />
      }

    </>
  );
}
