import {
  faCheck,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState, useEffect } from "react";
import DownloadCSV from "../../components/Buttons/DownloadCSV";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { getLastMonthDate } from "../../util/dateUtil";
import DeleteEntity from "../Buttons/DeleteEntity";
import EditEntity from "../Buttons/EditEntity";
import CreateGroupModal from "../GroupsModal/CreateGroupModal";
// import UpdateGroupModal from "../GroupsModal/UpdateGroupModal";
import DeleteModal from "../Modals/Common/deleteModal";
import UploadCSVModal from "../Modals/UploadCSV/UploadCSVModal";
import EntityModal from "../Modals/entityDetailModal";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import { removePlusPrefix } from "../../util/RemovedPlusPrefixFromString";
import MUIContactListDetailsModal from "../Modals/MUIModals/MUIContactListDetailsModal";
import MUIUpdateContactListDrawer from "../Modals/MUIModals/MUIUpdateContactListDrawer";

const GroupGrid = ({ token, memberRole }) => {
  const axiosPrivate = CreateAxiosInstance();
  const fromDate = getLastMonthDate();
  // const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [singleData, setSingleData] = useState([]);
  const gridRef = useRef();
  const [rowData, setRowData] = useState(null);
  const [showAgGrid, setShowAgGrid] = useState(true);
  // const [handleRequest, setHandleRequest] = useState("");
  // const [approvalRequest, setApprovalRequest] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [agGridData, setAgGridData] = useState();
  const [showContactListDetailsDrawer, setShowContactListDrawer] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showUpdateContactListDrawer, setShowUpdateConatctListDrawer] = useState(false)
  const masterDetail = true;

  const ViewAuditLogs = (params) => {
    const handleAgGridData = async () => {
      let payload = {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {
          entityId: {
            filterType: "text",
            type: "contains",
            filter: params.data.id,
          },
        },
      };
      await axiosPrivate
        .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            from: fromDate,
            to: new Date(),
            Timezone: moment().utcOffset(),
          },
        })
        .then((response) => {
          const resp = response?.data?.data;
          setShowDetails(true);
          setAgGridData(resp.auditLogs);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <div className="d-flex gap-3">
        <div
          className="action-btn"
          onClick={() => {
            handleAgGridData();
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };

  const colDefs = [
    {
      headerName: "Group",
      field: "name",
      flex: 1,
      maxWidth: 50,
      valueFormatter: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        }
      },
      cellRendererSelector: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        } else {
          return {
            component: "agGroupCellRenderer",
          };
        }
      },
      // rowGroup: true,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Number of recipients",
      field: "totalCount",
      valueGetter: (params) => {
        return params.data.userCount.length
          ? params.data.userCount[0].totalCount
          : 0;
      },
      flex: 1,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Operation",
      field: "approvalOperation",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Status",
      cellRenderer: "renderStatus",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Created by",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
    },
  ];
  

  const dataSource = {
    async getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;

      await axiosPrivate
        .post(
          process.env.REACT_APP_GROUPS,
          {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            approvalDocumentType: "ENTITY",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          if (!resp?.groupDetails?.length) {
            params.successCallback([], 0);
            params.api.showNoRowsOverlay();
          } else {
            params.successCallback(resp.groupDetails, resp.totalGroupsCount);
            params.api.hideOverlay();
          }
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },
  };

  const [columnDefs] = useState(colDefs);

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const dimensions = { width: "100%" };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
    }),
    [],
  );
  const StatusTypes = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p
          style={{ fontSize: ".9rem", fontWeight: "600" }}
          className={`${getStatusType(params.data.approvalStatus)} capitalize`}
        >
          {params.data.approvalStatus?.toLowerCase()}
        </p>
      </div>
    );
  };
  const getStatusType = (type) => {
    switch (type) {
      case "APPROVED":
        return "recipient_status_approved";
      case "PENDING":
        return "recipient_status_pending";
      case "REJECTED":
        return "recipient_status_rejected";
    }
  };
  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
        columnDefs: [
            { field: 'name', headerName: "Name" , flex: 1},
            {
              headerName: "Number of recipients",
              field: "totalCount",
              valueGetter: (params) => {
                return params.data.userCount.length
                  ? params.data.userCount[0].totalCount
                  : 0;
              },
              flex: 1,
              filter: "agTextColumnFilter",
            },
            { field: "approvalOperation", headerName: "Operation",flex : 1},
            {
              headerName: "Status",
              cellRendererFramework: (params) => (
                <StatusTypes {...params} />
              ),
              flex: 2,
              filter: "agTextColumnFilter",
              filterParams: { suppressAndOrCondition: true },
            },
            { field: 'createdByName', headerName: "CREATED BY", flex: 1},
            {
              field: "action",
              headerName: "ACTION",
              flex: 1,
              cellRendererFramework: (params) => (
                <ActionButtonsSubGrid {...params} />
              ),
          }
            
        ],
        domLayout: 'autoWidth',
    },
    
    getDetailRowData: async params => {
            await axiosPrivate
              .post(
                process.env.REACT_APP_GROUPS,
                {
                  startRow: 0,
                  endRow: 100,
                  sortModel: [],
                  filterModel: {},
                  "approvalDocumentType": "REQUEST",
                  "approvalEntityId": params.data.id
      
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                // const resp = response?.data?.data;
                  params.successCallback(response.data.data.groupDetails, parseInt(1)); //filling ag grid with content
              })
              .catch((error) => {
                console.error(error);
                // params.failCallback();
              });
            // params.successCallback(gridData,gridDataLength);
        },
        
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      display: "flex",
    //   flex:2
    },
}
const ActionButtonsSubGrid = (params) => {
  if((params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") || params.data.approvalStatus === "APPROVED" || params.data.approvalStatus === "REJECTED"){
    return (
      <div className="">
        <div className="action-btn" >
  
        </div>

      </div>
    )
  }
  if(params.data.approvalStatus === "PENDING" && memberRole === "TS_ADMIN"){
    return (
      <div className="d-flex gap-3">
        <div className="action-btn" onClick={()=>{
          getUpdateData(params.data)
        }}>
          <FontAwesomeIcon icon={faCheck}/>
        </div>
        <div className="action-btn" onClick={()=>{
          getUpdateData(params.data)
        }}>
          <FontAwesomeIcon icon={faTimes}/>
        </div>
      </div>
    )
  }
  return null
};

  // custom action components for the action field in ag grid
  const ActionButtons = (params) => {
    const handleClick = () => {
      setSingleData(params.data);
      setSelectedRowData(params.data);
    };
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") {
      return <div></div>
    }
    if(params.data.approvalStatus === "PENDING") {
      return (
        <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
         
        }}
      >
       <div className="action-btn">
       <FontAwesomeIcon icon={faCheck} style={{height: "14px", width: "14px"}}
          onClick={()=>{getUpdateData(params.data)}}
        />
       </div>
        
       <div className="action-btn">
       <FontAwesomeIcon icon={faTimes} style={{height: "14px", width: "14px"}}
          onClick={()=>{getUpdateData(params.data)}}
        />
       </div>
        { params.data.approvalOperation !== "CREATE" && <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />}
      </div>
      )
    }
    return (
      <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
        }}
      >
        <EditEntity
          onClick={() => {
            handleClick();
            setShowUpdateConatctListDrawer(true)
            // setEditModalShow(true);
          }}
        />
        <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />
      </div>
    );
  };

  const frameworkComponents = {
    renderActionButtons: ActionButtons,
    renderViewAuditLogs: ViewAuditLogs,
    renderStatus: StatusTypes,
  };
  // colDefs.push({
  //   headerName: "View audit logs",
  //   cellRenderer: "renderViewAuditLogs",
  // });
  colDefs.push({
    headerName: "Actions",
    cellRenderer: "renderActionButtons",
    headerClass: "cell-border-left",
  });

  //Download CSV
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const refreshCache = useCallback(
    (searchData) => {
      const newDataSource = {
        async getRows(params) {
          const { startRow, endRow, filterModel, sortModel } = params.request;

          await axiosPrivate
            .post(
              process.env.REACT_APP_GROUPS,
              {
                startRow: startRow,
                endRow: endRow,
                sortModel: sortModel,
                filterModel: filterModel,
                approvalDocumentType: "ENTITY",
                globalSearch: removePlusPrefix(searchData),
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then((response) => {
              const resp = response?.data?.data;
              if (!resp?.groupDetails?.length) {
                params.successCallback([], 0);
                params.api.showNoRowsOverlay();
              } else {
                params.successCallback(
                  resp.groupDetails,
                  resp.totalGroupsCount,
                );
                params.api.hideOverlay();
              }
            })
            .catch((error) => {
              console.error(error);
              params.failCallback();
            });
        },
      };
      gridRef.current.api.setServerSideDatasource(newDataSource);
    },
    [axiosPrivate, token],
  );
  useEffect(() => {
    if (gridRef?.current?.api) {
      refreshCache("");
    }
  }, [refreshGrid, refreshCache]);
  const getUpdateData = async (data) => {
      await axiosPrivate
        .post(
          process.env.REACT_APP_GROUPS,
          {
            startRow: 0,
            endRow: 100,
            sortModel: [],
            filterModel: {},
            "approvalDocumentType": "REQUEST",
            "approvalEntityId": data.approvalEntityId !== null ? data.approvalEntityId : data.id

          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setSelectedRowData(response.data.data.groupDetails[0]);
          setShowContactListDrawer(true);

        })
        .catch((error) => {
          console.error(error);
          // params.failCallback();
        });
      // params.successCallback(gridData,gridDataLength);
  }
  const onCellClicked = (event) => {
    if(event.data.approvalStatus === "PENDING" && event.data.approvalOperation === "UPDATE" && !(event.colDef.headerName === "Actions") ){
      getUpdateData(event.data)
      return;
    }
    // If action buttons are clicked then don't show the drawer.
    if (event.colDef.headerName !== "Actions" && !showContactListDetailsDrawer) {
      setSelectedRowData(event.data);
      setShowContactListDrawer(true);
    }
  };

  const refreshGridAfterSearch = (searchData) => {
    if (!gridRef.current.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  return (
    <>
    <div>
        {showContactListDetailsDrawer && (
          <MUIContactListDetailsModal
            openDrawer={showContactListDetailsDrawer}
            setOpenDrawer={setShowContactListDrawer}
            entityDetails={selectedRowData}
            token={token}
            setRefreshGrid={setRefreshGrid}
          />
        )}
      </div>
      <div className="d-flex justify-content-end mb-2 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <GlobalSearch refreshGrid={refreshGridAfterSearch} />
          <DownloadCSV onClick={onBtnExport} />
          {memberRole === "TS_ADMIN" && (
            <UploadCSVModal
              token={token}
              title={"Groups"}
              setShowAgGrid={setShowAgGrid}
            />
          )}
          <CreateGroupModal
            token={token}
            memberRole={memberRole}
            setShowAgGrid={setShowAgGrid}
            refreshCache={refreshCache}
          />
        </div>
      </div>
      {showAgGrid ? (
        <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={15}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            masterDetail={masterDetail}
            detailCellRendererParams={detailCellRendererParams}
            detailRowAutoHeight={true}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="No data to show"
            components={frameworkComponents}
            onCellClicked={onCellClicked}
          />
        </div>
      ) : (
        <></>
      )}
      {showUpdateContactListDrawer && (
        <MUIUpdateContactListDrawer
          openDrawer={showUpdateContactListDrawer}
          setOpenDrawer={setShowUpdateConatctListDrawer}
          setShowAgGrid={setShowAgGrid}
          entityDetails={selectedRowData}
          refreshCache={refreshCache}
        />
      )}
      {/* {editModalShow && (
        <UpdateGroupModal
          token={token}
          modalShow={editModalShow}
          setModalShow={setEditModalShow}
          url={
            approvalRequest
              ? `${process.env.REACT_APP_ACCEPT_REJECT_REQUEST}`
              : `${process.env.REACT_APP_GROUPS}/${singleData.id}`
          }
          data={singleData}
          memberRole={memberRole}
          approvalRequest={approvalRequest}
          handleRequest={handleRequest}
          setShowAgGrid={setShowAgGrid}
        />
      )} */}
      {deleteModalShow && (
        <DeleteModal
          modalShow={deleteModalShow}
          setModalShow={setDeleteModalShow}
          type="Group"
          url={`${process.env.REACT_APP_GROUPS}/${singleData.id}`}
          entity={singleData.name}
          memberRole={memberRole}
          setShowAgGrid={setShowAgGrid}
        />
      )}
      {showDetails && (
        <EntityModal
          modalShow={showDetails}
          setModalShow={setShowDetails}
          data={agGridData}
        />
      )}
    </>
  );
};
GroupGrid.propTypes = {
  token: PropTypes,
  memberRole: PropTypes,
};
export default GroupGrid;
