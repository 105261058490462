import React from "react";
import PropTypes from 'prop-types'; // Import PropTypes with correct casing
import downloadCSV from "../../assets/download_csv.svg";
import { buttonStyles, labelStyles } from "./CommonStyles";

const DownloadCSV = ({ onClick, btnText="Download CSV" }) => { // Ensure onClick prop name follows camelCase convention
  
  return (
    <div style={buttonStyles} onClick={onClick}>  {/* Ensure onClick handler is correctly applied */}
      <div className="d-flex align-items-center justify-content-center gap-1">
        <img src={downloadCSV} alt="Download icon" /> {/* Provide alt text for accessibility */}
        <p style={labelStyles}>{btnText}</p>
      </div>
    </div>
  );
}

DownloadCSV.propTypes = { // Ensure correct casing for propTypes
    onClick: PropTypes.func, // Validate onClick prop as a function
    btnText:PropTypes.string
}

export default DownloadCSV;
