import { Modal, Button, Form, FormGroup, FormControl } from "react-bootstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { data } from "../../DummyData/APITriggeredSendData";

function MyVerticallyCenteredModal(props) {
  const [showPopover, setShowPopover] = useState(false);

  const {
    endpoint: end_point,
    json_content,
    http_method,
    curl_command,
    expected_response,
  } = data;

  const showPopoverMsg = () => {
    setTimeout(() => setShowPopover(false), 3000);
    return <p className="text-gray">Command copied to clipboard.</p>;
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="send-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          API Triggered Send
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scroll-wrapper">
          <Form>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Endpoint</Form.Label>
                <FormControl aria-label="text" disabled value={end_point} />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>HTTP Method</Form.Label>
                <FormControl aria-label="text" disabled value={http_method} />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Request Body</Form.Label>
                <FormControl
                  as="textarea"
                  rows={10}
                  aria-label="With textarea"
                  disabled
                  value={json_content}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>clientURL</Form.Label>
                <div className="d-flex gap-3 align-items-center">
                  <FormControl
                    aria-label="text"
                    as="textarea"
                    rows={12}
                    disabled
                    value={curl_command}
                  />
                </div>
                <div className="mt-2">{showPopover && showPopoverMsg()}</div>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Expected Response</Form.Label>
                <FormControl
                  as="textarea"
                  rows={7}
                  aria-label="With textarea"
                  disabled
                  value={expected_response}
                />
              </div>
            </FormGroup>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="text-btn" variant="text" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
MyVerticallyCenteredModal.propTypes = {
  onHide: PropTypes.func,
};

const Fn = ({ onHide }) => {
  Fn.propTypes = {
    onHide: PropTypes,
  };

  return (
    <>
      <MyVerticallyCenteredModal show={true} onHide={onHide} />
    </>
  );
};

export default Fn;
