import { Toast, ToastContainer } from 'react-bootstrap'
import React, { useEffect } from 'react'
import success from "../../assets/img/success_check_mark.png"
import { useSelector, useDispatch } from 'react-redux'
import { toggleAlert } from '../../Redux/Reducers/alertsReducers'

export default () => {
    const alertState = useSelector(state => state.persistedReducer.alerts.showSuccess);
    const title = useSelector(state => state.persistedReducer.alerts.title);
    const messageBody = useSelector(state => state.persistedReducer.alerts.message);
    const dispatch = useDispatch()

    useEffect(() => {
        const toast = setTimeout(() => {
            dispatch(toggleAlert(false))
        }, 5000)

        return () => clearTimeout(toast)
    }, [dispatch]
    )

    return (
        <div className="d-flex justify-content-end" style={{ zIndex: 3000 }}>
            <ToastContainer>
                {/* <Toast show={alertState} onClose={() => setShow(!show)}> */}
                <Toast show={alertState} onClose={() => dispatch(toggleAlert(false))}>
                    <Toast.Header className="text-success">
                        <img src={success} className="rounded me-2" alt="" />
                        <strong className="me-auto">{title}</strong>
                        {/* <small className="text-muted">just now</small> */}
                    </Toast.Header >
                    <Toast.Body className='text-success bg-white'>{messageBody}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
