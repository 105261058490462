import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import ScheduleSendModal from "../components/SendMessageModals/ScheduleSendModal";
import APITriggeredSend from "../components/SendMessageModals/APITriggeredSend";
import DemoToDoList from "../components/SendMessageModals/DemoToDoList";
import APISuccess from "../components/Toasts/APISuccess";
import { useDispatch, useSelector } from "react-redux";
import Error from "../components/Error/Error";
import { getPageTitle } from "../Redux/Reducers/pageTitleReducer";
import { CreateAxiosInstance } from "../hooks/useAxiosPrivate";
import FileSendModal from "../components/SendMessageModals/FileSendModal";

const SendMessages = () => {
  const axiosPrivate = CreateAxiosInstance();
  const alertState = useSelector(
    (state) => state.persistedReducer.alerts.showSuccess
  );
  const [groups, setGroups] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [dataFetchError, setDataFetchError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState("");

  const getAllGroups = useCallback(async () => {
    try {
      const response = await axiosPrivate.post(process.env.REACT_APP_GROUPS, {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {},
      });
      if (response?.status === 200) {
        const resp = response?.data?.data;
        setGroups(resp?.groupDetails);
        setDataFetchError(false);
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status) {
        setErrorCode(error.response.status);
        setLoading(false);
      }
    }
  }, [axiosPrivate]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageTitle("Send Notifications"));
  }, [dispatch]);

  const getAllMessageTemplates = useCallback(async () => {
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_MESSAGE_TEMPLATES,
        {
          startRow: 0,
          endRow: 100,
          sortModel: [],
          filterModel: {},
        }
      );
      if (response?.status === 200) {
        const resp = response?.data?.data;
        setMessageTemplates(resp.messageTemplateDetails);
        setDataFetchError(false);
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status) {
        setErrorCode(error.response.status);
        setLoading(false);
      }
    }
  }, [axiosPrivate]);

  useEffect(() => {
    getAllGroups();
    getAllMessageTemplates();
  }, [getAllGroups, getAllMessageTemplates]);
  return loading ? (
    <></>
  ) : dataFetchError ? (
    <div>{dataFetchError && <Error code={errorCode} />}</div>
  ) : (
    <>
      <Card border="light" className="bg-white shadow-sm my-2 ">
        <Card.Body>
          <Row>
            <Col xs={12} xl={12} className="mb-4 ">
              <Row>
                <Col xs={12} xl={12}>
                  <Row className=" ">
                    <Col xs={12} className="px-0">
                      {alertState && <APISuccess toggle={true} />}
                      <p className="mb-0">
                        Select the type of notification you would like to send.
                      </p>
                      <div className="d-flex gap-4 flex-wrap mt-3 ">
                        <DemoToDoList />
                        {/* <OneTimeSendModal
                          templateData={messageTemplates}
                          groupData={groups}
                        /> */}
                        <FileSendModal templatedata={messageTemplates} />
                        {/* <ScheduleSend /> */}
                        <ScheduleSendModal
                          templateData={messageTemplates}
                          groupData={groups}
                        />
                        <APITriggeredSend />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default SendMessages;
