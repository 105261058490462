import React,{useState} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import close_modal from "../../../assets/close.svg";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import Select from "react-select";
import {spamConfidenceLevelList} from "../../../DummyData/Aggregators/Aggregators"
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import { toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import ShowError from "../../FormValidation/ShowError";

const CreateSpamFilterDrawer = ({ openDrawer, setOpenDrawer, token, memberRole, notificationType , refreshCache}) => {
    const axiosPrivate = CreateAxiosInstance()
    const [filterName, setFilterName] = useState("");
    const [error, setError] = useState("")
    const dispatch = useDispatch();
    const [filterRule, setFilterRule] = useState("");
    const [spamLevel, setSpamLevel] = useState("");
    const toggleDrawer = (open) => (event) => {
        if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
        ) {
        return;
    }

    setOpenDrawer(open);
  };
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };
  const handleFilterName = (e) => {
    const value = e.target.value
    setFilterName(value)
    if(!(value.length > 0)) {
        setError("Please Enter Filter Name")
    } else {
        setError("")
    }
  }
  const handleFilterRule = (e) => {
    const value = e.target.value 
    setFilterRule(value)
    if(!(value.length > 0)) {
        setError("Please Enter Keyword")
    } else {
        setError("")
    }
    
  }
  const handleSpamLevel = (e) => {
    const value = e;
    setSpamLevel(value)
    if (!value) {
        setError("Please Enter Spam Level")
    } else {
        setError("")
    }
  }

  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  const handleAddFilter = async () =>{
    if (filterName.length < 1 && filterRule.length < 1 && !spamLevel.value){
        setError("Please Enter Filter Name , Keyword and Spam Level ")
        return;
    }
    if (filterName.length < 1) {
        setError("Please Enter Filter Name")
        return;
    }
    if(filterRule.length < 1) {
        setError("Please Enter Keyword")
        return;
    }
    if(!spamLevel.value) {
        setError("Please Enter Spam Level")
        return;
    }
    const payload={
        name: filterName,
        keyword: filterRule,
        confidence: spamLevel.value,
        spamFilterType: notificationType,
    }
    await axiosPrivate
    .post(`${process.env.REACT_APP_SPAM_FILTER_CREATE}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res?.status === 200) {
        setOpenDrawer(false)
        dispatch(
          toggleAlert({ title: "Success!", message: res.data.message }),
        );
        toggleDrawer(false);
        refreshCache("")
          // setRefreshGrid(true);
        // props.setShowAgGrid(true);
      }
    })
    .catch((err) => {
      console.error(err);
      setOpenDrawer(false)
      {
        memberRole === "TS_ADMIN"
          ? dispatch(
              toggleFailAlert({
                title: "Failed!",
                message: "Failed To Create Spam Filter.",
              }),
            )
          : dispatch(
              toggleFailAlert({
                title: "Failed!",
                message: "Request not raised.",
              }),
            );
      }
      toggleDrawer(false);
          // setRefreshGrid(true);
    //   props.setShowAgGrid(true);
    });
  }
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        <Box
          //   sx={{ width: recipientConstants.RECIPIENT_DRAWER_WIDTH }}
          sx={{ width: 650 }}
          role="presentation"
          className="recipient-details-container"
        >
          <div
            className="recipient-details-header"
            style={{ minHeight: "64px" }}
          >
            <div
              className="recipient-modal-title"
              style={{ marginBlock: "14px", paddingBlock: 0 }}
            >
              <h5>Add Filter</h5>
              <img
                style={{ cursor: "pointer" }}
                src={close_modal}
                alt="Close Modal"
                onClick={toggleDrawer(false)}
              />
            </div>
            {addDivider()}
          </div>
          <div
            className="recipient-details-body-content"
            style={{
              marginBlock: "14px",
              paddingBlock: 0,
              height: "20px",
              minHeight: "20px",
            }}
          >
            <section className="">
              <div className="recipient-item-pair-container">
                <div className="mb-3">
                <p className="recipient-details-label">Filter Name</p>
                <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <TextField
                      id="input-with-icon-textfield"
                      label=""
                      notched={false}
                      placeholder="Add Filter Name"
                      required={true}
                      value={filterName}
                      onChange={(e) => handleFilterName(e)}
                      InputProps={{
                        sx: {
                          padding: "1px 3px",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          borderRadius: "8px",
                          height: "40px",
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& .MuiInputBase-input": {
                            fontWeight: 700,
                            fontFamily: "Inter",
                            lineHeight: "14px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                          background: "rgba(247, 247, 250, 1)",
                        },
                      }}
                    />
                  </Box>
                </div>
                <Form.Group id="name">
                  <div className="mb-3">
                    <p className="recipient-details-label">Keyword</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Add Keyword Here"
                        required
                        value={filterRule}
                        onChange={(e) => handleFilterRule(e)}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>
              </div>
              <div className="recipient-item-pair-container">
              <div className="mb-3">
              <p className="recipient-details-label">Choose Spam Confidence Level</p>
              <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            flexGrow: 1,
                            marginTop: "16px",
                            padding: "3px 0px",
                            height: "40px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            lineHeight: "20px",
                            color: "rgba(17, 21, 24, 1)",
                            // border:"1px solid red",
                            fontWeight: "500",
                            borderColor: state.isFocused
                              ? "rgba(249, 249, 249, 1)"
                              : "rgba(249, 249, 249, 1)",
                            backgroundColor: "rgba(249, 249, 249, 1)",
                          }),
                        }}
                        options={spamConfidenceLevelList.map((option) => {
                            return {
                              label: option.label,
                              value: option.value,
                            };
                          })}
                        placeholder="Choose Spam Confidence Level"
                        value={spamLevel}
                        onChange={(e) => handleSpamLevel(e)}
                      />
                    </div>
                </div>
               
              </div>
            </section>
            <div>
            {error && <ShowError msg={error} />}
            </div>
          </div>
          <div className="recipient-details-footer">
            <div className="recipient-modal-response-buttons">
              <div
                className="approve"
                onClick={() => {
                  handleAddFilter();
                }}
              >
                <p className="approve-text" >Add</p>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};
CreateSpamFilterDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  token: PropTypes.string,
  memberRole: PropTypes.string,
  notificationType: PropTypes.string,
  setRefreshGrid: PropTypes.func,
  refreshCache: PropTypes.func
};

export default CreateSpamFilterDrawer;
