import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import CreateEntity from "../../Buttons/CreateEntity";
import ShowError from "../../FormValidation/ShowError";
import PropTypes from "prop-types";
import CreateTemplate from "../MUIModals/MUICreateEmailTemplateModal";

const Quill_Modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "size"], // toggled buttons
    ["blockquote", "code-block", "link"],
    ["image", "video"],

    [{ header: 1 }, { header: 2 }, { header: 3 }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ],
};
const Quill_Formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
  "color",
  "background",
  "script",
  "align",
  "indent",
  "direction",
];

const CreateTemplateModal = (props) => {
  const [templateName, setTemplateName] = useState("");
  // const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState("");
  const [messagePriority, setMessagePriority] = useState(1);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  // const [notificationTypeList] = useState([]);
  // For validation
  const [templateNameError] = useState("");
  // const [messageError, setMessageError] = useState('');
  const [messageTypeError] = useState("");
  // const [notificationTypeListError, setNotificationTypeListError] = useState('');
  const dispatch = useDispatch();
  const createdByName = useSelector(
    (state) => state.persistedReducer?.member?.data?.name,
  );

  const { rowData, setRowData } = props;

  // const createTemplate = async (payload, hideModal) => {
  //   // await axiosWithBaseUrl.post(`${process.env.REACT_APP_NEW_MESSAGE_TEMPLATE}`, payload, {
  //   //   headers: {
  //   //     "Authorization": `Basic ${props.token}`
  //   //   }
  //   // })
  //   //   .then(res => {
  //   //     if (res.data.status === 200) {
  //   //       hideModal()
  //   //       dispatch(toggleAlert({ title: "Success!", message: res.data.message }));
  //   //       window.location.reload();
  //   //       // dispatch(getMessageTemplateData());
  //   //     }
  //   //   })
  //   //   .catch(err => {
  //   //     console.log(err);
  //   //     dispatch(toggleFailAlert({ title: "Failed!", message: "Your message template was not created." }));
  //   //     hideModal();

  //   //   })
  // }

  const handleClick = (e, hideModal) => {
    e.preventDefault();
    // if (validate(templateName, "isEmpty")) {
    //   setTemplateNameError("Template name content cannot be empty");
    //   return;
    // }
    // else {
    //   setTemplateNameError("");
    // }
    // if (validate(message, "isEmpty")) {
    //   setMessageError("Message content cannot be empty");
    //   return;
    // }
    // else {
    //   setMessageError("");
    // }
    // if (validate(messageType, "isEmpty")) {
    //   setMessageTypeError("Message Type cannot be empty");
    //   return;
    // }
    // else {
    //   setMessageTypeError("");

    // }
    // if (notificationTypeList.length === 0) {
    //   setNotificationTypeListError("Please select notification type");
    //   return;
    // }
    // else {
    //   setNotificationTypeListError("");
    // }

    // const notificationType = notificationTypeList.map(item => item.value);
    const emailTemplateData = {
      id: "62eb97af484f252e1cd16799",
      templateName,
      subject: emailSubject,
      messageType,
      emailContent,
      messagePriority,
      createdByName,
    };

    const newData = [...rowData];
    newData.unshift(emailTemplateData);
    setRowData(newData);
    dispatch(
      toggleAlert({
        title: "Success!",
        message: "Email template created successfully.",
      }),
    );
    hideModal();

    // createTemplate(emailTemplateData, hideModal);
  };

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group id="templateName">
            <div className="mb-3">
              <Form.Label>Template Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Enter email template name."
              />
              {templateNameError && <ShowError msg={templateNameError} />}
            </div>
          </Form.Group>
          <Form.Group id="templateSubject">
            <div className="mb-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                required
                type="text"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                placeholder="Enter email subject."
              />
              {templateNameError && <ShowError msg={templateNameError} />}
            </div>
          </Form.Group>
          <Form.Group>
            <div className="mb-3">
              <Form.Label>Email Type</Form.Label>
              <Form.Select
                required
                // defaultValue="OTP"
                placeholder="Select Type"
                onChange={(e) => setMessageType(e.target.value)}
              >
                <option readOnly>Select Message Types</option>
                <option value="OTP">OTP</option>
                <option value="Transactional">Transactional</option>
                <option value="Promotional">Promotional</option>
              </Form.Select>
              {messageTypeError && <ShowError msg={messageTypeError} />}
            </div>
          </Form.Group>
          <Form.Group id="emailPriority">
            <div className="mb-3">
              <Form.Label>Email Priority</Form.Label>
              <Form.Control
                required
                min={1}
                max={3}
                type="number"
                value={messagePriority}
                onChange={(e) => setMessagePriority(e.target.value)}
                placeholder="Enter email priority"
              />
            </div>
          </Form.Group>
          {/* <Form.Group>
            <div className="mb-3">
              <Form.Label>Notification Type</Form.Label>
              <Select
                placeholder="Select Notification Type"
                isMulti
                options={[{ label: "SMS", value: "SMS" },
                { label: "EMAIL", value: "EMAIL" },
                { label: "INAPP", value: "INAPP" }
                ]}
                // value={nof}
                onChange={e => setNotificationTypeList(e)}
              />
              {notificationTypeListError && <ShowError msg={notificationTypeListError} />}
            </div>
          </Form.Group> */}
          <Form.Group id="email-content">
            <div className="mb-3">
              <Form.Label>Email Content</Form.Label>
              <ReactQuill
                modules={Quill_Modules}
                formats={Quill_Formats}
                theme="snow"
                value={emailContent}
                onChange={setEmailContent}
                placeholder="Type your email content here"
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={(e) => handleClick(e, props.onHide)}>
          Create
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

CreateTemplateModal.propTypes = {
  onHide: PropTypes.func,
  rowData: PropTypes.array,
  token: PropTypes.string,
  setRowData: PropTypes.func,
};

const CreateEmailTemplate = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return ( 
    <>
      <CreateEntity
        onClick={() => setModalShow(true)}
        btnText="Create Template"
      />
      {modalShow && (
        <CreateTemplate
        openDrawer={modalShow}
        setRefreshGrid={()=>{}}
        setOpenDrawer={setModalShow}
        />
      )}
    </>
  );
};

export default CreateEmailTemplate;
