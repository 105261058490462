import { Col, Row } from "@themesberg/react-bootstrap";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import DownloadCSV from "../../components/Buttons/DownloadCSV";
import AggregatorPieChart from "../../components/Charts/AggregatorPieChart";
import Error from "../../components/Error/Error";
import AggregatorStatsGrid from "../../components/Grids/AggregatorStatsGrid";
import { AggregatorStatsHeader } from "../../data/MISDashboardData";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import RealTimeStats from "../components/MISComponents/RealTimeStatsBadges";
import { getPieChartSliceColors } from "../../util/pieChartSliceColors";
const AggregatorStats = (props) => {
  const axiosPrivate = CreateAxiosInstance();
  const [showDownloadingText, setShowDownloadingText] = useState(false);
  const MHSRef = useRef();
  const [ASData, setASData] = useState([]);
  const [ASDataLoading, setASDataLoading] = useState(true);
  const [RTSData, setRTSData] = useState({});
  const [dataFetchError, setDataFetchError] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const generatePdf = () => {
    setShowDownloadingText(true);
    const pdfName =
      `dashboard-data-${moment().format("MMM-DD-YYYY-HH:mm")}` + ".pdf";
    const captureElement = document.querySelector(".downloadStats");
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });
    html2canvas(captureElement).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const pdfHeading =
        "Downloaded At: " + moment().format("MMM-DD-YYYY-HH:mm");
      doc.text(pdfHeading, 0, 10);
      doc.setFontSize(8);
      const imgWidth = 300;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, "PNG", 0, 30, imgWidth, imgHeight, "", "FAST", 0.5);
      doc.save(pdfName);
      setShowDownloadingText(false);
    });
  };

  const getRTSData = useCallback(async () => {
    try {
      const response = await axiosPrivate.post(
        `${
          process.env.REACT_APP_PROVIDER_LEVEL_DATA
        }?notification-type=${"SMS"}&&page=aggregator`,
        {
          filterBy: "",
          leaf: "true",
        },
        {
          headers: {
            From: props.fromDate.toISOString(),
            To: props.toDate.toISOString(),
          },
        },
      );
      if (response?.data?.status) {
        setRTSData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
      setErrorCode(error?.response?.status);
    }
  }, [props.fromDate, props.toDate, axiosPrivate]);

  //api for getting data for Aggregation Stats
  const getASData = useCallback(async () => {
    setASDataLoading(true);
    try {
      const response = await axiosPrivate.get(
        `${
          process.env.REACT_APP_GET_AGGREGATOR_STATS
        }?notification-type=${"SMS"}`,
        {
          headers: {
            From: new Date(props.fromDate).toUTCString(),
            To: new Date(props.toDate).toUTCString(),
          },
        },
      );
      if (response?.data?.status) {
        setASData(response.data.data);
        setASDataLoading(false);
      }
    } catch (error) {
      if (error?.response?.status) {
        setErrorCode(error.response.status);
      }
      console.error(error);
      setASDataLoading(false);
      setDataFetchError(true);
    }
  }, [props.fromDate, props.toDate, axiosPrivate]);

  useEffect(() => {
    getASData();
    getRTSData();
  }, [getASData, getRTSData]);

  const pieData = ASData?.map((item) => {
    return { k: item?.provider, v: item?.totalMessagesCount };
  });

  const pieChartColorData = getPieChartSliceColors(
    "AggregatorStats",
    [],
    "SMS",
  );
  console.log({ RTSData });

  return (
    <div className="my-2 shadow-sm">
      {dataFetchError && <Error code={errorCode} />}

      {showDownloadingText ? (
        <div style={{ marginLeft: "24px" }}>PDF is downloading...</div>
      ) : (
        <></>
      )}

      {/* AGGREGATOR STATS CHART */}
      <Card className="mb-3 downloadStats">
        {ASData !== null ? (
          <div
            data-html2canvas-ignore="true"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: 25,
              marginTop: 10,
            }}
          >
            <DownloadCSV
              btnText="Download PDF"
              onClick={() => {
                generatePdf();
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-start" style={{marginInline:"1.4rem"}}>
          <div style={{ width: "45%" }}>
            {ASDataLoading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                Loading...
              </div>
            ) : (
              <AggregatorPieChart
                data={ASData}
                pieChartColorData={pieChartColorData}
              />
            )}
          </div>
          <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
            <RealTimeStats
              data={pieData}
              MHSRef={MHSRef}
              fromDate={props.fromDate}
              toDate={props.toDate}
              ASDataLoading={ASDataLoading}
              pieChartData={{ data: pieData }}
              pieColors={pieChartColorData}
              hideDrillDown={true}
            />
          </div>
        </div>
      </Card>

      <Row className="mb-3">
        <Col xs={12} xl={12}>
          {ASDataLoading ? (
            <div
              style={{
                display: "grid",
                placeItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              Loading...
            </div>
          ) : (
            <Card>
              <Card.Body>
                <AggregatorStatsGrid
                  heading="Aggregator Stats Table"
                  tableHeading={AggregatorStatsHeader}
                  tableData={ASData}
                  isLoading={ASDataLoading}
                  fromDate={new Date(props.fromDate).toUTCString()}
                  toDate={new Date(props.toDate).toUTCString()}
                  notificationType="SMS"
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};
AggregatorStats.propTypes = {
  fromDate: PropTypes,
  toDate: PropTypes,
  pieColors: PropTypes.array,
};

export default AggregatorStats;
