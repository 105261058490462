import React from "react";
import PropTypes from "prop-types";
import delete_icon from "../../assets/delete.svg";

const DeleteEntity = ({ onClick }) => {
  return (
    <div className="action-btn" onClick={onClick}>
      <img
        src={delete_icon}
        alt="Delete Icon"
        style={{ height: "16px", width: "16px" }}
      />
    </div>
  );
};

DeleteEntity.propTypes = {
  onClick: PropTypes.func,
};
export default DeleteEntity;