import React from "react";
import PropTypes from "prop-types";
import edit_icon from "../../assets/edit.svg";

const EditEntity = ({ onClick }) => {
  return (
    <div className="action-btn" onClick={onClick}>
      <img
        src={edit_icon}
        alt="Delete Icon"
        style={{ height: "16px", width: "16px" }}
      />
    </div>
  );
};

EditEntity.propTypes = {
  onClick: PropTypes.func,
};
export default EditEntity;