import { Toast, ToastContainer } from 'react-bootstrap'
import React, { useEffect } from 'react'
import fail from "../../assets/img/fail.png"
import { useSelector, useDispatch } from 'react-redux'
import { toggleFailAlert } from '../../Redux/Reducers/alertsReducers'

export default () => {
    const alertState = useSelector(state => state.persistedReducer.alerts.showFail);
    const title = useSelector(state => state.persistedReducer.alerts.title);
    const messageBody = useSelector(state => state.persistedReducer.alerts.message);
    const dispatch = useDispatch()
    useEffect(() => {
        const toast = setTimeout(() => {
            dispatch(toggleFailAlert(false))
        }, 5000)

        return () => clearTimeout(toast)
    }, [dispatch]
    )

    return (
        <div className="d-flex justify-content-end" style={{ zIndex: 3000 }}>
            <ToastContainer>
                {/* <Toast show={alertState} onClose={() => setShow(!show)}> */}
                <Toast show={alertState} onClose={() => dispatch(toggleFailAlert(false))}>
                    <Toast.Header className="text-danger">
                        <img src={fail} style={{ width: "35px" }} className="rounded me-2" alt="" />
                        <strong className="me-auto">{title}</strong>
                        {/* <small className="text-muted">just now</small> */}
                    </Toast.Header >
                    <Toast.Body className='text-danger bg-white'>{messageBody}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}
