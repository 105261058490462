// import { styled as MuiStyled } from "@mui/material";
// import { TimePicker } from "@mui/x-date-pickers";
// import styled from "styled-components";
// import { lightTheme } from "./Themes";
// import PropTypes from "prop-types";

// const StyledTimePicker = MuiStyled(TimePicker)`
//   .MuiIconButton-root {
//     display: none;
//   }
//   .MuiOutlinedInput-notchedOutline {
//     border: none;
//   }
//   width: 110px;
//   border: none;
//   .Mui-focused {
//     border: 1px solid black;
//     border-radius: 10px;
//   }
//   .MuiInputBase-input {
//     height: 100%;
//   }
// `;

// const StyledDiv = styled.div`
//   border: ${(props) => props.overlap ? "2px solid red" : ""};
//   display: flex;
//   justify-content: space-between;
//   border-radius: 10px;
//   width: 270px;
//   background-color: ${lightTheme.surface.secondary};
// `;

// StyledDiv.propTypes = {
//   overlap: PropTypes.bool,
// };

// const Day = styled.div`
//   font-weight: ${lightTheme.fontWeight.bold};
//   font-size: ${lightTheme.fontSize.s};
//   font-family: ${lightTheme.font};
//   display: flex;
//   align-items: center;
// `;

// const ErrorContainers = styled.div`
//   color: ${lightTheme.content.error};
//   margin-top: 5px;
//   display: flex;
//   align-items: center;
// `;

// const ErrorText = styled.div`
//   margin-left: 5px;
//   margin-top: 2px;
// `;

// export {
//   StyledTimePicker, 
//   StyledDiv,
//   Day,
//   ErrorContainers,
//   ErrorText
// }

import { styled as MuiStyled } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import styled from "styled-components";
import { lightTheme } from "./Themes";

const StyledTimePicker = MuiStyled(TimePicker)`
  .MuiIconButton-root {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  width: 110px;
  border: none;
  .Mui-focused {
    border: 1px solid black;
    border-radius: 10px;
  }
  .MuiInputBase-input {
    height: 100%;
  }
`;

const StyledDiv = styled.div`
  border: ${(props) =>
    props.overlap ? "2px solid red" : `2px solid ${lightTheme.surface.secondary}`};
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 270px;
  background-color: ${lightTheme.surface.secondary};
`;

const Day = styled.div`
  font-weight: ${(props) => props.theme.fontWeight.bold};
  font-size: ${(props) => props.theme.fontSize.s};
  font-family: ${(props) => props.theme.font};
  display: flex;
  align-items: center;
`;

const ErrorContainers = styled.div`
  color: ${(props) => props.theme.content.error};
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSize.s};
`;

const ErrorText = styled.div`
  margin-left: 5px;
`;

export { StyledTimePicker, StyledDiv, Day, ErrorContainers, ErrorText };
