import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import DeleteModal from "../Modals/Common/deleteModal";
import CreateTemplate from "../Modals/EmailTemplateModals/CreateEmailTemplateModal";
import UpdateTemplate from "../Modals/EmailTemplateModals/UpdateEmailTemplate";
import PropTypes from "prop-types";
import templateAgGridData from "../../DummyData/email/emailTemplateAgGridData";
import DeleteEntity from "../Buttons/DeleteEntity";
import DownloadCSV from "../Buttons/DownloadCSV";
import EditEntity from "../Buttons/EditEntity";
import TemplateDetails from "../Modals/MUIModals/MUIEmailTemplateDetailsModal";

const EmailTemplateGrid = ({ token }) => {
  const [editModalShow, setEditModalShow] = useState(false);
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [rowData, setRowData] = useState(
    templateAgGridData.data.messageTemplateDetails,
  );
  const [singleData, setSingleData] = useState([]);
  const gridRef = useRef();

  const colDefs = [
    {
      headerName: "Template name",
      field: "templateName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Email subject",
      field: "subject",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Priority",
      field: "messagePriority",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Type",
      field: "messageType",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Created by",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];

  colDefs.push({
    headerName: "Actions",
    cellRenderer: "renderActionButtons",
    headerClass: "cell-border-left",
  });
  const [columnDefs] = useState(colDefs);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    [],
  );
  const dimensions = { width: "100%" };

  // custom action components for the action field in ag grid
  const actionButtons = (params) => {
    const handleClick = () => {
      setSingleData(params.data);
    };

    return (
      <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
        }}
      >
        <EditEntity
          onClick={() => {
            handleClick();
            setEditModalShow(true);
          }}
        />
        <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />
      </div>
    );
  };

  const onCellClicked = (event) => {
      if (event.colDef.headerName !== "Actions" && !detailsModalShow) {
      setSingleData(event.data);
      setDetailsModalShow(true);
    }
  };

  const emailContent = () => {
    return (
            <TemplateDetails
              openDrawer={detailsModalShow}
              setOpenDrawer={setDetailsModalShow}
              data={singleData}
              setRefreshGrid={()=>{}}
            />
          );
  };

  const frameworkComponents = {
    renderEmailContent: emailContent,
    renderActionButtons: actionButtons,
  };

  //Download CSV
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-end mb-2 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <DownloadCSV onClick={onBtnExport} />
          {/* <UploadCSVModal token={token} title={"Message Templates"} /> */}
          <CreateTemplate
            token={token}
            rowData={rowData}
            setRowData={setRowData}
          />
        </div>
      </div>
      <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onCellClicked={onCellClicked}
          pagination={true}
          paginationPageSize={15}
          domLayout="autoHeight" // if too many rows should not use this, give fixed height
          animateRows={true}
          overlayNoRowsTemplate="No data to show"
          components={frameworkComponents}
        />
      </div>
      { detailsModalShow && (
            <TemplateDetails
              openDrawer={detailsModalShow}
              setOpenDrawer={setDetailsModalShow}
              data={singleData}
              setRefreshGrid={()=>{}}
            />
          )
      }
      {editModalShow && (
        <UpdateTemplate
          modalShow={editModalShow}
          setModalShow={setEditModalShow}
          data={singleData}
        />
      )}

      {deleteModalShow && (
        <DeleteModal
          modalShow={deleteModalShow}
          setModalShow={setDeleteModalShow}
          type="Message Template"
          url={`${process.env.REACT_APP_MESSAGE_TEMPLATES}/${singleData.id}`}
          entity={singleData.templateName}
          itemToDelete={singleData.id}
          demo={true}
          rowData={rowData}
          setRowData={setRowData}
          gridRef={gridRef}
        />
      )}
    </>
  );
};
EmailTemplateGrid.propTypes = {
  token: PropTypes,
};
export default EmailTemplateGrid;
