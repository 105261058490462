import { createSlice } from "@reduxjs/toolkit"

const AlertsSlice = createSlice({
    name: "alerts",
    initialState: {
        showSuccess: false,
        showFail: false,
        toggle: false,
        toggleFail: false,
        title: "",
        message: ""
    },
    reducers: {
        toggleAlert: (state) => {
            state.toggle = !state.toggle;
        },
        hideAlert: (state, action) => {
            state.showSuccess = !state.showSuccess;
            state.title = action.payload.title;
            state.message = action.payload.message;
        },
        toggleFailAlert: (state) => {
            state.toggleFail = !state.toggleFail;
        },
        hideFailAlert: (state, action) => {
            state.showFail = !state.showFail;
            state.title = action.payload.title;
            state.message = action.payload.message;
        },
    }

})

export const { hideAlert, toggleAlert, toggleFailAlert, hideFailAlert } = AlertsSlice.actions
export default AlertsSlice.reducer