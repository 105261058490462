
import { put, takeEvery, all, call } from "redux-saga/effects"
import { hideAlert, hideFailAlert } from "../Reducers/alertsReducers"
import { setMemberData, clearMemberData } from "../Reducers/memberReducer"
import { setPageTitle } from "../Reducers/pageTitleReducer"
// import axios from "axios"
// import { getStatsData } from "../Reducers/dashboardRTSReducer"
// import { setGroupData } from "../Reducers/groupReducer"
// import { setMessageTemplateData } from "../Reducers/messageTemplatesReducer"
// import { setUserData } from "../Reducers/userReducer"

function* workHideAlert({ payload }) {
    yield put(hideAlert(payload));
}
function* workHideFailAlert({ payload }) {
    yield put(hideFailAlert(payload));
}


// function* workGroupData() {
//     try {
//         const { data } = yield call(axios.get, process.env.REACT_APP_GET_GROUPS);
//         yield put(setGroupData(data));
//     }
//     catch (e) {
//         console.log(e);
//     }
// }
// function* workUserData() {
//     try {
//         const { data } = yield call(axios.get, process.env.REACT_APP_GET_USERS);
//         yield put(setUserData(data));
//     }
//     catch (e) {
//         console.log(e);
//     }
// }
// function* workMessageTemplateData() {
//     try {
//         const { data } = yield call(axios.get, process.env.REACT_APP_GET_MESSAGE_TEMPLATES);
//         yield put(setMessageTemplateData(data));
//     }
//     catch (e) {
//         console.log(e);
//     }
// }
function* workMemberData({ payload }) {
    console.log(payload)
    try {
        // const data = yield call(axios.get, process.env.REACT_APP_MEMBER_LOGIN,
        //     {
        //         headers: {
        //             "Authorization": `Bearer ${payload}`
        //         }
        //     });
        yield put(setMemberData(payload));
        // Redirect here
        window.location.assign("/");

    }
    catch (e) {
        console.log(e);
    }
}

function* workMemberLogout() {
    yield put(clearMemberData());
    // window.location.assign("/user");
}

function* workPageTitle({ payload }) {
    yield put(setPageTitle(payload));
}


function* watchAll() {
    yield all([
        takeEvery(`alerts/toggleAlert`, workHideAlert),
        takeEvery(`alerts/toggleFailAlert`, workHideFailAlert),
        takeEvery('member/getMemberData', workMemberData),
        takeEvery('member/logout', workMemberLogout),
        takeEvery('pageTitle/getPageTitle', workPageTitle)
        // takeEvery('group/getGroupData', workGroupData),
        // takeEvery('user/getUserData', workUserData),
        // takeEvery('messageTemplates/getMessageTemplateData', workMessageTemplateData),
        // takeEvery(`dashBoardRTS/getStatsFetch`, workGetStats),
    ])
}

export default watchAll