export const lightTheme = {
  name: "Light",
  button: {
    primary: {
      active: "#042F40",
      hovered: "#022330",
      disabled: "#E4E4E4",
      loading: "#042F40"
    },
    secondary: {
      active: "#F7F7F7",
      hovered: "#D4D4D4",
      disabled: "#E4E4E4",
      loading: "#F7F7F7"
    },
    tertiary: {
      hovered: "E4E4E4"
    },
    planCard: "#7fd99a"
  },
  surface: {
    primary: "#FFFFFF",
    secondary: "#F7F7F7",
    tertiary: "#E4E4E4",
    brand: "#042F40",
    disabled: "#E4E4E4",
    lightaccent: "#F1F7F3",
    lightaccent2: "#D5DFE3",
    transparent1: " #000000 10%",
    transparent2: "#000000 20%"
  },
  content: {
    primary: "#252527",
    secondary: "#717171",
    primaryinverted: "#FFFFFF",
    secondaryinverted: "#E4E4E4",
    disabled: "#D4D4D4",
    success: "#009A51",
    brand: "#042F40",
    warning: "#DF9500",
    copy: "#0C3CE5",
    error: "#DC362E",
    timeZone: "#6E6E6E"
  },
  border: {
    primary: "#252527",
    secondary: "#E4E4E4",
    radius: "37px",
    radiussecondary: "8px",
    radiustertiary: "100px",
    radiusPlan: "12px"
  },

  breakpoints: {
    mobile: {
      minwidth: "375px",
      maxwidth: "552px"
    },
    tablet: {
      minwidth: "553px",
      maxwidth: "667px"
    },
    desktop: "1054px",
    large: "1200px"
  },
  fontSize: {
    xxs: "10px",
    xs: "12px",
    s: "14px",
    m: "16px",
    l: "20px",
    xl: "24px",
    xxl: "32px"
  },
  lineHeight: {
    xxs: "16px",
    xs: "20px",
    s: "22px",
    m: "24px",
    l: "28px",
    xl: "32px",
    xxl: "40px"
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700
  },
  buttonWidth: {
    small: "30vw",
    medium: "37vw",
    large: "42vw"
  },
  progressBarWidth: {
    small: "15vw",
    medium: "20vw",
    large: "25vw"
  },
  containerWidth: {
    small: "27vw",
    medium: "32vw",
    large: "37vw"
  },

  font: "'Inter', sans-serif",

  shadow: {
    xs: "0px 1px 2px rgba(0, 0, 0, 0.07)",
    s: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    m: "0px 2px 5px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
    l:
      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
    xl:
      "0px 10px 10px -5px rgba(0, 0, 0, 0.06), 0px 20px 25px -5px rgba(0, 0, 0, 0.1)",
    "2xl":
      "0px 4px 6px -2px rgba(0, 0, 0, 0.06), 0px 25px 50px -12px rgba(0, 0, 0, 0.25)"
  },
  SelectedPlanCardGradient:
    "linear-gradient(101.2deg, #042F40 -10.25%, #678E83 174.12%)"
}

export const darkTheme = {
  ...lightTheme,
  name: "Dark"
}
