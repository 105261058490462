import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { FormControl } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import validate from "../../FormValidation/validation";
import ShowError from "../../FormValidation/ShowError";
import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';

function UpdateMessageTemplateModal(props) {
  const [templateName, setTemplateName] = useState(props.data.templateName);
  const [message, setMessage] = useState(props.data.content);
  const [messageType, setMessageType] = useState(props.data.messageType);
  const [messagePriority, setMessagePriority] = useState(props.data.messagePriority);
  const [notificationTypeList, setNotificationTypeList] = useState([]);
  // For validation
  const [templateNameError, setTemplateNameError] = useState('');
  const [messageTypeError, setMessageTypeError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    populateForm(props.data);
  }, [props.data])



  const updateTemplate = async (payload, hideModal) => {
      await axiosWithBaseUrl.put(props.url, payload, {
      headers: {
        "Authorization": `Bearer ${props.token}`
      }
    })
      .then(res => {
        if (res?.data?.status === 200) {
          hideModal()
          dispatch(toggleAlert({ title: "Success!", message: "Message Template was updated." }))
          window.location.reload();

        }
      })
      .catch(err => {
        console.error(err);
        hideModal();
        dispatch(toggleAlert({ title: "Failed!", message: "Message Template was not updated." }))

      })
  }

  const handleClick = (e, hideModal) => {
    e.preventDefault();

    // Validation form
    if (validate(message, "isEmpty")) {
      return;
    }
    if (validate(templateName, "isEmpty")) {
      return;
    }
    else {
      setTemplateNameError("");
    }
    if (validate(messageType, "isEmpty")) {
      setMessageTypeError("Message Type cannot be empty");
      return;
    }
    else {
      setMessageTypeError("");

    }
    if (notificationTypeList.length === 0) {
      return;
    }

    // Prepare the request object
    const notificationType = notificationTypeList.map(item => item.value)
    const templateData = { templateName, messageType, notificationType, messagePriority, message, id: props.data.id };

    // send request object to updateTemplate 
    updateTemplate(templateData, hideModal);
  };


  //Hydrate the form fields
  const populateForm = (data) => {
    setTemplateName(data.templateName);
    setMessage(data.message);
    setMessagePriority(data.messagePriority);
    setMessageType(data.type);
    // put notificationtypelist in a format that react select can populate
    const notificationTypeList = data.notificationType?.map(item => ({ label: item, value: item }));
    setNotificationTypeList(notificationTypeList);
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
               <Form>
               <Form.Group id="templateName">
                   <div className="mb-3">
                     <Form.Label>Template Name</Form.Label>
                     <Form.Control
                       required
                       type="text"
                       value={templateName}
                       onChange={(e) => setTemplateName(e.target.value)}
                       placeholder="Your template name."
                     />
                   {templateNameError && <ShowError msg={templateNameError} />}
                   </div>
                 </Form.Group>
                 <Form.Group>
                   <div className="mb-3">
                     <Form.Label>Notification Type</Form.Label>
                     <Form.Select
                       required
                       placeholder="Select Type"
                       value={messageType}
                       onChange={(e) => setMessageType(e.target.value)}
                     >
                       <option readOnly >Select Message Types</option>
                       <option value="OTP">OTP</option>
                       <option value="Transactional">Transactional</option>
                       <option value="Promotional">Promotional</option>
                     </Form.Select>
                     {messageTypeError && <ShowError msg={messageTypeError} />}
                   </div>
                 </Form.Group>
                 <Form.Group id="emailPriority">
                   <div className="mb-3">
                     <Form.Label>Email Priority</Form.Label>
                     <Form.Control
                       required
                       min={1}
                       max={3}
                       type="number"
                       value={messagePriority}
                       onChange={(e) => setMessagePriority(e.target.value)}
                       placeholder="Enter email priority"
                     />
       
                   </div>
                 </Form.Group>
                 <Form.Group id="email-content">
                 <div className="mb-3">
                 <Form.Label>Push Notification Content</Form.Label>
                 <FormControl
                       as="textarea"
                       placeholder="Your Content Here"
                       rows={10}
                       aria-label="With textarea"
                       value={message}
                       onChange={e => setMessage(e.target.value)}
                     />
                 </div>
                 </Form.Group>
               </Form>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit"
          onClick={(e) => handleClick(e, props.onHide)}
        >
          Update
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );

}
UpdateMessageTemplateModal.propTypes = {
  data:PropTypes.shape({
    templateName: PropTypes,
    content: PropTypes,
    messageType: PropTypes,
    messagePriority: PropTypes,
    id: PropTypes
  }),
  url: PropTypes,
  token: PropTypes,
  onHide: PropTypes
}

export default function App({ modalShow, setModalShow, url, data, token }) {
  App.propTypes = {
    modalShow: PropTypes,
    setModalShow: PropTypes,
    url: PropTypes,
    data: PropTypes,
    token: PropTypes
  }
  return (
    <>
      <UpdateMessageTemplateModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        url={url}
        data={data}
        token={token}
      />
    </>
  );
}
