import { Modal, Button, Form, FormGroup } from "react-bootstrap";
import React, { useState } from "react";
import { FormControl } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert } from "../../Redux/Reducers/alertsReducers";
import { axiosWithBaseUrl } from "../../axios/baseUrl";
import { messageTypeList } from "../../DummyData/Aggregators/Aggregators";
import PropTypes from "prop-types";
import { isValidNumber } from "libphonenumber-js";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { jwtDecode } from "jwt-decode";
import { setMemberData } from "../../Redux/Reducers/memberReducer";

function MyVerticallyCenteredModal({ ...props }) {
  const refreshToken = useSelector(
    (state) => state.persistedReducer?.member?.refreshToken
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(true);
  const [inApp, setInApp] = useState("");
  const [userId, setUserId] = useState("");
  const [notificationType, setNotificationType] = useState("SMS");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("OTP");
  const [subject, setSubject] = useState("");
  const dispatch = useDispatch();
  const axiosPrivate = CreateAxiosInstance();
  const [phoneError, setPhoneError] = useState(false);
  let token = useSelector((state) => state.persistedReducer?.member?.token);
  const username = useSelector(
    (state) => state.persistedReducer?.member?.data?.name
  );
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );
  const userEmail = useSelector(
    (state) => state.persistedReducer?.member?.data?.email
  );
  const id = useSelector((state) => state.persistedReducer?.member?.data?.id);

  const handleClose = () => {
    setEmail("");
    setPhoneNumber("");
    setMessage("");
    setInApp("");
    setUserId("");
    setSubject("");
    setNotificationType("SMS");
    setMessageType("OTP");
    setValidEmail(true);
    setPhoneError(false);
    props.onHide();
  };
  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(e.target.value);
    setValidEmail(isValidEmail);
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    const isValid = isValidNumber(e);
    if (!isValid) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
    setPhoneNumber(e);
  };

  const postMessage = async (data) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decoded.exp - currentTime < 60) {
        await refreshTokenCall(); // Call refresh token API if expiry time is less than 60 seconds
      } else {
        const isValidToken = await checkTokenValidity(); // Call API to check token validity
        if (!isValidToken) {
          await refreshTokenCall(); // Call refresh token API if token is not valid
        }
      }

      setTimeout(
        () =>
          dispatch(
            toggleAlert({
              title: "Single Send Message requested",
              message: "Message has been requested.",
            })
          ),
        1500
      );
      let apiUrl;
      if (notificationType === "SMS") {
        apiUrl = process.env.REACT_APP_SINGLE_SEND.replace(
          "notificationType",
          "sms"
        );
      }
      if (notificationType === "EMAIL") {
        apiUrl = process.env.REACT_APP_SINGLE_SEND.replace(
          "notificationType",
          "email"
        );
      }

      await axiosPrivate
        .post(
          apiUrl,
          { ...data },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Solace-Reply-Wait-Time-In-ms": "0",
            },
          }
        )
        .then((resp) => console.log(resp));
    } catch (err) {
      console.log(err);
    }
  };

  const refreshTokenCall = async () => {
    try {
      const response = await axiosWithBaseUrl.post(
        `${process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN_API}?refresh_token=${refreshToken}`
      );
      if (response) {
        const client = {
          tokenParsed: { name: username, email: userEmail },
          id: id,
          roles: [memberRole],
          token: response.data.access_token,
          refreshToken: response.data.refresh_token,
        };
        token = response.data.access_token;
        dispatch(setMemberData(client));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkTokenValidity = async () => {
    try {
      const response = await axiosWithBaseUrl.get(
        `${process.env.REACT_APP_KEYCLOAK_TOKEN_VALIDATION}?access_token=${token}`
      );
      if (response) {
        return response.data.active;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postMessage({
      phoneNumber: phoneNumber,
      email: email,
      inApp: inApp,
      userId: userId,
      notificationType: [notificationType],
      message,
      messageType,
      requestTime: new Date().toISOString(),
      token,
      ...(notificationType === "EMAIL" && { subject: subject }),
    });
    setPhoneNumber("");
    setNotificationType("SMS");
    setEmail("");
    setInApp("");
    setUserId("");
    setMessage("");
    setSubject("");
    setPhoneError(false);

    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="send-modal"
      onClose={handleClose}
      centered
    >
      <Modal.Header closeButton onClose={handleClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          Single Send
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scroll-wrapper">
          <Form>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>Notification Type</Form.Label>
                <Form.Select
                  placeholder="Notification Type"
                  aria-label="Select for Notification Type"
                  value={notificationType}
                  onChange={(e) => setNotificationType(e.target.value)}
                >
                  {messageTypeList.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </FormGroup>
            {notificationType === "SMS" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Enter Phone Number</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => handlePhoneChange(e.target.value)}
                    aria-label="phone number"
                    maxLength={15}
                  />
                  {phoneError && (
                    <small className="text-danger">
                      Please Enter Valid Phone Number
                    </small>
                  )}
                </div>
              </FormGroup>
            ) : notificationType === "EMAIL" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Enter Email address</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => {
                      handleEmailChange(e);
                    }}
                    aria-label="phone number"
                  />
                  {!validEmail && (
                    <small className="text-danger">
                      Email should be in the format of abc@domain.xyz
                    </small>
                  )}
                </div>
              </FormGroup>
            ) : notificationType === "FLASH" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Enter Device Token</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="Device token"
                    value={inApp}
                    onChange={(e) => setInApp(e.target.value)}
                    aria-label="phone number"
                  />
                </div>
              </FormGroup>
            ) : (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Enter User ID</Form.Label>
                  <FormControl
                    type="text"
                    placeholder="User ID"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    aria-label="phone number"
                  />
                </div>
              </FormGroup>
            )}

            <FormGroup>
              <div className="mb-3">
                <Form.Label>Choose Message Type</Form.Label>
                <Form.Select
                  aria-label="Select for Message Type"
                  value={messageType}
                  onChange={(e) => setMessageType(e.target.value)}
                >
                  <option disabled>Select Message Type</option>
                  <option value="OTP">OTP</option>
                  <option value="Transactional">Transactional</option>
                  <option value="Promotional">Promotional</option>
                </Form.Select>
              </div>
            </FormGroup>
            {notificationType === "EMAIL" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Enter Subject</Form.Label>
                  <FormControl
                    type="text"
                    aria-label="With textarea"
                    value={subject}
                    placeholder="Your Subject"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </FormGroup>
            ) : (
              <></>
            )}
            {notificationType === "SMS" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Type or Paste Your Message Content</Form.Label>
                  <FormControl
                    as="textarea"
                    rows={10}
                    aria-label="With textarea"
                    value={message}
                    placeholder="Your Message Content"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </FormGroup>
            ) : notificationType === "EMAIL" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Type or paste your Email content</Form.Label>
                  <FormControl
                    as="textarea"
                    rows={10}
                    aria-label="With textarea"
                    value={message}
                    placeholder="Your Message Content"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </FormGroup>
            ) : notificationType === "FLASH" ? (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>
                    Type or paste your Push Notification content
                  </Form.Label>
                  <FormControl
                    as="textarea"
                    rows={10}
                    aria-label="With textarea"
                    value={message}
                    placeholder="Your Message Content"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </FormGroup>
            ) : (
              <FormGroup>
                <div className="mb-3">
                  <Form.Label>Type or paste your LineChat content</Form.Label>
                  <FormControl
                    as="textarea"
                    rows={10}
                    aria-label="With textarea"
                    value={message}
                    placeholder="Your Message Content"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </FormGroup>
            )}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={handleClose} variant="text" className="text-btn">
          Close
        </Button>
        <Button
          type="submit"
          className="next-btn d-flex gap-3"
          onClick={handleSubmit}
          disabled={
            !(
              notificationType &&
              ((phoneNumber && !phoneError) ||
                (email && validEmail && subject) ||
                inApp ||
                userId) &&
              messageType &&
              message
            )
          }
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
MyVerticallyCenteredModal.propTypes = {
  onHide: PropTypes,
};

export default function App({ onHide }) {
  App.propTypes = {
    onHide: PropTypes,
  };

  return (
    <>
      <MyVerticallyCenteredModal show={true} onHide={onHide} />
    </>
  );
}
