import plivo_photo from "../../../assets/Aggregators/Plivo-logo.svg";
import exotel_photo from "../../../assets/Aggregators/exotel.png";
import sms_horizon from "../../../assets/Aggregators/sms_horizon.png";
import knowlarity from "../../../assets/Aggregators/knowlarityLogo.png";
import addAggregator from "../../../assets/Aggregators/addAggregator.png";

export default {
    aggregators:[
        {name:"add-Aggregator",photo:addAggregator, addButton:true, description:"Add an aggregator"},
        {name:"plivo",photo:plivo_photo, description:"Reliable Enterprise-grade Communication"},
        {name:"exotel",photo:exotel_photo, description:"6000+ businesses"},
        {name:"sms-horizon",photo:sms_horizon, description:"3000+ Valued Customers"},
        {name:"knowlarity",photo:knowlarity, description:"Seamless collaboration and support"},
    ]

}