import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import React, { useState } from "react";
import TSSpinner from "../../Spinners/TSSpinner";
import CSVReader from "react-csv-reader";
// import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import PropTypes from "prop-types";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import UploadCSV from "../../Buttons/UploadCSV";
import { uploadRecipientFileHeaders } from "../../../util/CSVFileHeaders";
import { entities } from "../../../util/Entities";

function UploadCSVModal(props) {
  let uploading = false;
  const dispatch = useDispatch();
  const [importDisabled, setImportDisabled] = useState(true);
  const [originalFile, setOriginalFile] = useState("");
  const axiosPrivate = CreateAxiosInstance();

  const token = useSelector((state) => state.persistedReducer?.member?.token);

  const handleSubmit = async (e, hideModal) => {
    e.preventDefault();
    const formData = new FormData();
    const blobOptions = { type: "text/csv" };
    const fileBlob = new Blob([originalFile], blobOptions);

    // Append the Blob to the FormData with the specified name
    formData.append("file", fileBlob, originalFile.name);
    formData.append("entity", props.title);
    hideModal();
    props.setShowAgGrid(false);
    try {
      const response = await axiosPrivate.post(
        process.env.REACT_APP_UPLOAD_CSV,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response?.status === 200) {
        dispatch(
          toggleAlert({ title: "Success!", message: "CSV file was uploaded." }),
        );
        props.setShowAgGrid(true);
      }
    } catch (err) {
      dispatch(
        toggleFailAlert({
          title: "Failed!",
          message:
            "Please check your file contents. Ensure data is entered correctly.",
        }),
      );
      props.setShowAgGrid(true);
    }
  };

  // const saveCSV = async (file, hideModal) => {
  //   try {
  //     await axiosWithBaseUrl.post(process.env.REACT_APP_UPLOAD_CSV, file, {
  //       headers: {
  //         "Authorization": `Basic ${props.token}`
  //       }
  //     })
  //       .then(resp => {
  //         if (resp.data.status === 200) {
  //           dispatch(toggleAlert({ title: "Success!", message: "CSV file was uploaded" }));
  //           window.location.reload();
  //         }
  //         else dispatch(toggleFailAlert({ title: "Failed!", message: "CSV file was not uploaded" }));
  //         setUploading(false);
  //         hideModal();
  //       })
  //   }
  //   catch (err) {
  //     dispatch(toggleFailAlert({ title: "Failed!", message: "Please check your file contents. Ensure data is entered correctly." }));
  //     hideModal();
  //     setUploading(false);
  //   }
  // }

  const validateCSVHeaders = (item) => {
    const validateFieldsByEntityType = (entity) => {
      if (entity === entities.RECIPIENT) {
        return uploadRecipientFileHeaders.every((element) =>
          item.includes(element)
        );
      }
    };

    if (validateFieldsByEntityType(props.title)) {
      return true;
    }
    dispatch(
      toggleFailAlert({
        title: "Failed!",
        message: "Ensure headers are correct.",
      }),
    );
    return false;

    // if(props.title === entities.RECIPIENT) {
    //   if(validateFieldsByEntityType(entities.RECIPIENT)){
    // } else {
    // }
    // }
    // if(props.title==="Groups") {  if(validateFieldsByEntityType(entities.RECIPIENT)){
    //   return true;
    // } else {
    //   dispatch(toggleFailAlert({ title: "Failed!", message: "Ensure headers are correct." }));
    //   return false;
    // }
    // }
    // if(props.title==="Groups") {
    //   if(item.includes("NAME") && item.includes("NUMBER OF USERS")){
    //     return true;
    //   } else {
    //     dispatch(toggleFailAlert({ title: "Failed!", message: "Ensure headers are correct." }));
    //     return false;
    //   }

    // }
    //   if(item.includes("NAME") && item.includes("NUMBER OF USERS")){
    //     return true;
    //   } else {
    //     dispatch(toggleFailAlert({ title: "Failed!", message: "Ensure headers are correct." }));
    //     return false;
    //   }
  };

  // handler for temporary csv file reader on frontend
  const handleCSVData = (data, originalFile, fileInfo) => {
    console.log("headers: \n", data[0]);
    const isValidated = validateCSVHeaders(data[0]);
    if (isValidated) {
      setOriginalFile(fileInfo);
      setImportDisabled(false);
    } else {
      setImportDisabled(true);
    }
  };

  return (
    <Modal
      {...props}
      // size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Import {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h5 className="mb-4">Delete a Template</h5> */}
        <Form>
          <Row className="align-items-center">
            <Col className="mb-3">
              <Form.Group id="users-csv">
                <Form.Label>
                  Select the CSV file you would like to upload
                </Form.Label>
                {/* <FormControl
                  type="file"
                  accept=".csv"
                  disabled={uploading}
                  // value={list}
                  onChange={e => setList(e.target.files[0])}
                /> */}
                <CSVReader
                  cssInputClass="form-control"
                  onFileLoaded={(data, fileInfo, originalFile) =>
                    handleCSVData(data, fileInfo, originalFile)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {uploading ? (
          <div className="d-flex gap-3 align-items-center">
            <TSSpinner />
            <Button disabled onClick={props.onHide}>
              Close
            </Button>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <Button
              type="submit"
              onClick={(e) => handleSubmit(e, props.onHide)}
              disabled={importDisabled}
            >
              Import
            </Button>
            <Button onClick={props.onHide}>Close</Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
UploadCSVModal.propTypes = {
  title: PropTypes,
  onHide: PropTypes,
  setShowAgGrid: PropTypes,
};

export default function App({ token, title, setRowData, setShowAgGrid }) {
  App.propTypes = {
    title: PropTypes,
    token: PropTypes,
    setRowData: PropTypes,
    setShowAgGrid: PropTypes,
  };
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <UploadCSV onClick={() => setModalShow(true)} />
      <UploadCSVModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        token={token}
        title={title}
        setRowData={setRowData}
        setShowAgGrid={setShowAgGrid}
      />
    </>
  );
}
