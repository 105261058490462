import { Modal, Button, Form } from "react-bootstrap";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert, toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import validate from "../../FormValidation/validation";
import ShowError from "../../FormValidation/ShowError";
import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormControl } from "@themesberg/react-bootstrap";


const CreateTemplateModal = (props) => {
  const [templateName, setTemplateName] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [messagePriority, setMessagePriority] = useState(1);
  const [emailSubject, setEmailSubject]=useState('')
  const [emailContent, setEmailContent]=useState('');
  const [notificationTypeList, setNotificationTypeList] = useState([]);
  // For validation
  const [templateNameError, setTemplateNameError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageTypeError, setMessageTypeError] = useState('');
  const [notificationTypeListError, setNotificationTypeListError] = useState('');
  const dispatch = useDispatch();
  const createdByName = useSelector(state => state.persistedReducer?.member?.data?.name)

const {rowData, setRowData}= props;

  const createTemplate = async (payload, hideModal) => {
    // await axiosWithBaseUrl.post(`${process.env.REACT_APP_NEW_MESSAGE_TEMPLATE}`, payload, {
    //   headers: {
    //     "Authorization": `Basic ${props.token}`
    //   }
    // })
    //   .then(res => {
    //     if (res.data.status === 200) {
    //       hideModal()
    //       dispatch(toggleAlert({ title: "Success!", message: res.data.message }));
    //       window.location.reload();
    //       // dispatch(getMessageTemplateData());
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //     dispatch(toggleFailAlert({ title: "Failed!", message: "Your message template was not created." }));
    //     hideModal();

    //   })
  }

  const handleClick = (e, hideModal) => {
    e.preventDefault();
    // if (validate(templateName, "isEmpty")) {
    //   setTemplateNameError("Template name content cannot be empty");
    //   return;
    // }
    // else {
    //   setTemplateNameError("");
    // }
    // if (validate(message, "isEmpty")) {
    //   setMessageError("Message content cannot be empty");
    //   return;
    // }
    // else {
    //   setMessageError("");
    // }
    // if (validate(messageType, "isEmpty")) {
    //   setMessageTypeError("Message Type cannot be empty");
    //   return;
    // }
    // else {
    //   setMessageTypeError("");

    // }
    // if (notificationTypeList.length === 0) {
    //   setNotificationTypeListError("Please select notification type");
    //   return;
    // }
    // else {
    //   setNotificationTypeListError("");
    // }

    const notificationType = notificationTypeList.map(item => item.value);
    const PNTemplateData = { id:"62eb97af484f252e1cd16799",templateName, title:emailSubject,messageType, message, messagePriority, createdByName};

    const newData = [...rowData];
    newData.unshift(PNTemplateData);
    setRowData(newData);
    dispatch(toggleAlert({ title: "Success!", message: "Push Notification template created successfully." }));
    hideModal();

    // createTemplate(PNTemplateData, hideModal);
  };

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group id="templateName">
            <div className="mb-3">
              <Form.Label>Template Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Your template name."
              />
            {templateNameError && <ShowError msg={templateNameError} />}
            </div>
          </Form.Group>
        <Form.Group id="templateSubject">
            <div className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                placeholder="Your template title."
              />
            {templateNameError && <ShowError msg={templateNameError} />}
            </div>
          </Form.Group>
          <Form.Group>
            <div className="mb-3">
              <Form.Label>Notification Type</Form.Label>
              <Form.Select
                required
                // defaultValue="OTP"
                placeholder="Select Type"
                onChange={(e) => setMessageType(e.target.value)}
              >
                <option readOnly >Select Message Types</option>
                <option value="OTP">OTP</option>
                <option value="Transactional">Transactional</option>
                <option value="Promotional">Promotional</option>
              </Form.Select>
              {messageTypeError && <ShowError msg={messageTypeError} />}
            </div>
          </Form.Group>
          <Form.Group id="emailPriority">
            <div className="mb-3">
              <Form.Label>Email Priority</Form.Label>
              <Form.Control
                required
                min={1}
                max={3}
                type="number"
                value={messagePriority}
                onChange={(e) => setMessagePriority(e.target.value)}
                placeholder="Enter email priority"
              />

            </div>
          </Form.Group>
          {/* <Form.Group>
            <div className="mb-3">
              <Form.Label>Notification Type</Form.Label>
              <Select
                placeholder="Select Notification Type"
                isMulti
                options={[{ label: "SMS", value: "SMS" },
                { label: "EMAIL", value: "EMAIL" },
                { label: "INAPP", value: "INAPP" }
                ]}
                // value={nof}
                onChange={e => setNotificationTypeList(e)}
              />
              {notificationTypeListError && <ShowError msg={notificationTypeListError} />}
            </div>
          </Form.Group> */}
          <Form.Group id="email-content">
          <div className="mb-3">
          <Form.Label>Push Notification Content</Form.Label>
          <FormControl
                as="textarea"
                placeholder="Your Content Here"
                rows={10}
                aria-label="With textarea"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            {/* <ReactQuill
                modules={Quill_Modules}
                formats={Quill_Formats}
                theme="snow"
                value={emailContent}
                onChange={setEmailContent}
                placeholder="Your content here"
            /> */}
          </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={(e) => handleClick(e, props.onHide)}>
          Create
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function App({ token, rowData, setRowData }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" className=" py-1 fs-6 transparent-btn" onClick={() => setModalShow(true)}>
        <div className="d-flex gap-2">
          <div
            style={{ borderRight: "1px solid grey", paddingRight: ".5em" }}
          >+</div>
          Create Template
        </div>
      </Button>
      {
        modalShow && <CreateTemplateModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          token={token}
          rowData={rowData}
          setRowData={setRowData}
        />
      }

    </>
  );
}
