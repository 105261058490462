import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getPageTitle } from "../Redux/Reducers/pageTitleReducer";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import auth from "../assets/tsnotificationSwaggerFile.json";
import { useSelector } from "react-redux";
import { Buffer } from 'buffer';

export default () => {
  const dispatch = useDispatch();
  const swaggerRef = useRef();
  const token = useSelector((state) => state.persistedReducer?.member?.token);

  useEffect(() => {
    dispatch(getPageTitle("API Keys"));
  }, []);

  const complete = function (swaggerUi) {
    console.log({swaggerUi})
    // https://stackoverflow.com/questions/2820249/base64-encoding-and-decoding-in-client-side-javascript
    let creds = Buffer.from(token, 'base64').toString('ascii').split(":"); 

    // https://stackoverflow.com/questions/45199989/how-do-i-automatically-authorize-all-endpoints-with-swagger-ui
    swaggerUi.preauthorizeBasic("API Key", creds[0], creds[1]); //preauthorizeBasic("type","username","password")
  };

  return (
    <SwaggerUI
      ref={swaggerRef}
      spec={auth}
      withCredentials={true}
      onComplete={complete}
    />
  );
};
