import React, { useState } from "react";
import { Modal, Button, Form, FormGroup, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  messageTypeList,
  aggregatorList,
} from "../../DummyData/Aggregators/Aggregators";
import Select from "react-select";
import CSVReader from "react-csv-reader";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../Redux/Reducers/alertsReducers";
import { useDispatch, useSelector } from "react-redux";
import ShowError from "../FormValidation/ShowError";
import {
  axiosWithBaseUrlFileSend,
  axiosWithBaseUrl,
} from "../../axios/baseUrl";
import { jwtDecode } from "jwt-decode";
import { setMemberData } from "../../Redux/Reducers/memberReducer";
import CustomizedSteppers from "../Stepper/CustomStepper";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const FileSendModal = (props) => {
  const { templatedata: templateData } = props;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  let token = useSelector((state) => state.persistedReducer?.member?.token);
  const [messageType, setMessageType] = useState("SMS");
  const [notificationType, setNotificationType] = useState("Transactional");
  const [destination, setDestination] = useState("");
  const [messageTemplateID, setMessageTemplateID] = useState("");
  const [fileInfo, setFileInfo] = useState("");
  const [csvData, setCsvData] = useState("");
  const [messageTemplate, setMessageTemplate] = useState("");
  const [csvError, setCsvError] = useState(false);
  const refreshToken = useSelector(
    (state) => state.persistedReducer?.member?.refreshToken
  );
  const username = useSelector(
    (state) => state.persistedReducer?.member?.data?.name
  );
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );
  const userEmail = useSelector(
    (state) => state.persistedReducer?.member?.data?.email
  );
  const id = useSelector((state) => state.persistedReducer?.member?.data?.id);
  const steps = 3;
  const [activeStep, setActiveStep] = React.useState(0);

  const validateCSVHeaders = (item) => {
    const variableRegex = /\{\{([^}]+)\}\}/g;

    // Extract variables from the message template

    const matches = messageTemplate.label.match(variableRegex);
    const variableNames = matches.map((match) => match.slice(2, -2));
    // Check if all variables are present in the CSV headers
    const allVariablesPresent = variableNames.every((variable) =>
      item.includes(variable)
    );
    const isLengthValid = item.length === variableNames.length + 1;

    if (messageType === "SMS") {
      const isOrderValid =
        item[0] === "phone" &&
        variableNames.every((variable, index) => item[index + 1] === variable);
      if (allVariablesPresent && isLengthValid && isOrderValid) {
        setCsvError("");
        return true;
      } else {
        setCsvError("Ensure headers are correct.");
        return false;
      }
    }
    if (messageType === "EMAIL") {
      const isOrderValid =
        item[0] === "email" &&
        variableNames.every((variable, index) => item[index + 1] === variable);
      if (allVariablesPresent && isLengthValid && isOrderValid) {
        setCsvError("");
        return true;
      } else {
        setCsvError("Ensure headers are correct.");
        return false;
      }
    }
  };

  const handleCSVData = (data, originalFile, fileInfo) => {
    setCsvData(data);
    setFileInfo(fileInfo);
  };
  const handleSubmit = async (e, hideModal) => {
    e.preventDefault();
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    if (decoded.exp - currentTime < 60) {
      await refreshTokenCall(); // Call refresh token API if expiry time is less than 60 seconds
    } else {
      const isValidToken = await checkTokenValidity(); // Call API to check token validity
      if (!isValidToken) {
        await refreshTokenCall(); // Call refresh token API if token is not valid
      }
    }
    const formData = new FormData();
    const blobOptions = { type: "text/csv" };
    const fileBlob = new Blob([fileInfo], blobOptions);
    let apiUrl;
    if (messageType === "SMS") {
      apiUrl = process.env.REACT_APP_FILE_SEND.replace(
        "notificationType",
        "sms"
      );
    }
    if (messageType === "EMAIL") {
      apiUrl = process.env.REACT_APP_FILE_SEND.replace(
        "notificationType",
        "email"
      );
    }

    // Append the Blob to the FormData with the specified name
    formData.append("file", fileBlob, fileInfo.name);
    try {
      const response = await axiosWithBaseUrlFileSend.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          MessageTemplateId: messageTemplateID ? messageTemplateID : "",
          Aggregator: destination,
          MessageType: notificationType,
          "Solace-Reply-Wait-Time-In-ms": "0",
        },
      });
      if (response?.status === 200) {
        dispatch(
          toggleAlert({
            title: "Success!",
            message: "Message has been requested.",
          })
        );
        hideModal();
      }
    } catch (error) {
      if (error?.response?.status) {
        dispatch(
          toggleFailAlert({
            title: "Failed!",
            message: "Unable to request message.",
          })
        );
        hideModal();
      }
    }

    setPage(1);
    setMessageTemplate("");
    setMessageTemplateID("");
    setFileInfo("");
    setDestination("");
  };
  const refreshTokenCall = async () => {
    try {
      const response = await axiosWithBaseUrl.post(
        `${process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN_API}?refresh_token=${refreshToken}`
      );
      if (response) {
        const client = {
          tokenParsed: { name: username, email: userEmail },
          id: id,
          roles: [memberRole],
          token: response.data.access_token,
          refreshToken: response.data.refresh_token,
        };
        token = response.data.access_token;
        dispatch(setMemberData(client));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkTokenValidity = async () => {
    try {
      const response = await axiosWithBaseUrl.get(
        `${process.env.REACT_APP_KEYCLOAK_TOKEN_VALIDATION}?access_token=${token}`
      );
      if (response) {
        return response.data.active;
      }
    } catch (err) {
      console.log(err);
    }
  };
  const goToNextForm = () => {
    if (page === 2) {
      const maxSizeInBytes = 60 * 1024 * 1024;
      if (fileInfo.size <= maxSizeInBytes) {
        if (messageTemplate && messageTemplateID) {
          const isValidated = validateCSVHeaders(csvData[0]);
          if (isValidated) {
            setCsvError("");
          } else {
            setCsvError("Ensure headers are correct.");
            return;
          }
        } else {
          if (messageType === "SMS") {
            if (
              csvData[0].includes("phone") &&
              csvData[0].includes("message")
            ) {
              setCsvError("");
            } else {
              setCsvError("Ensure headers are correct.");
              return;
            }
          }
          if (messageType === "EMAIL") {
            if (
              csvData[0].includes("email") &&
              csvData[0].includes("subject") &&
              csvData[0].includes("body")
            ) {
              setCsvError("");
            } else {
              setCsvError("Ensure headers are correct");
              return;
            }
          }
        }
      } else {
        setCsvError("File should be less than 60mb.");
        return;
      }
    }
    setPage((page) => page + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const goToPreviousForm = () => {
    setPage((page) => page - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (page === 3 || page === 2) {
      setMessageTemplate("");
      setMessageTemplateID("");
      setFileInfo("");
    }
  };

  const handleClose = () => {
    setNotificationType("Transactional");
    setMessageTemplateID("");
    setMessageType("SMS");
    setCsvError("");
    setPage(1);
    setActiveStep(0);
    setFileInfo("");
    props.onHide();
  };
  const validateForm = () => {
    let disabled = false;
    if (page === 1 && (messageType === "" || notificationType === ""))
      disabled = true;
    if (page === 2 && fileInfo === "") disabled = true;
    if (page === 3 && destination === "") disabled = true;
    return disabled;
  };

  const getForm = () => {
    switch (page) {
      case 1:
        return (
          <>
            <FormGroup id="messageType">
              <Form.Label>Notification Type</Form.Label>
              <Form.Select
                value={messageType}
                onChange={(e) => setMessageType(e.target.value)}
              >
                {messageTypeList.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </FormGroup>
            <FormGroup id="notificationType" className="mt-3">
              <Form.Label>Message Type</Form.Label>
              <Form.Select
                value={notificationType}
                onChange={(e) => setNotificationType(e.target.value)}
              >
                {/* <option value="otp">OTP</option> */}
                <option value="Transactional">Transactional</option>
                <option value="Promotional">Promotional</option>
              </Form.Select>
            </FormGroup>
          </>
        );
      case 2:
        return (
          <>
            <FormGroup>
              <div className="mb-3">
                <Form.Label>
                  Choose Message Template -{" "}
                  <b> Message Template Name: [Message Template Content]</b>
                </Form.Label>
                <Select
                  placeholder="Choose Message Template"
                  onChange={(e) => {
                    setMessageTemplateID(e.value);
                    setMessageTemplate(e);
                  }}
                  classNamePrefix="select"
                  options={templateData
                    .filter((item) =>
                      item.notificationType.some((type) => type === messageType)
                    )
                    .map((item) => ({
                      label: `${item?.templateName} : [${item?.message}]`,
                      value: item?.id,
                    }))}
                />
              </div>
            </FormGroup>
            <Form>
              <Row className="align-items-center">
                <Col className="mb-3">
                  <Form.Group id="users-csv">
                    <Form.Label>
                      Select the CSV file you would like to upload
                    </Form.Label>
                    <CSVReader
                      cssInputClass="form-control"
                      onFileLoaded={(data, fileInfo, originalFile) =>
                        handleCSVData(data, fileInfo, originalFile)
                      }
                    />
                  </Form.Group>
                  {csvError && <ShowError msg={csvError} />}
                </Col>
              </Row>
            </Form>
          </>
        );
      case 3:
        return (
          <FormGroup>
            <Form.Label>Choose Aggregator</Form.Label>
            <Select
              placeholder="Choose Aggregator"
              onChange={(e) => setDestination(e.value)}
              id="aggregator"
              options={(aggregatorList[messageType] || []).map((option) => {
                return {
                  label: option.label,
                  value: option.value,
                };
              })}
            />
          </FormGroup>
        );
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="send-modal"
    >
      <Modal.Header closeButton onClose={handleClose}>
        {page > 1 && (
          <IconButton
            aria-label="delete"
            onClick={goToPreviousForm}
            className="back-icon-btn"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Modal.Title id="contained-modal-title-vcenter">
          {`File Send`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomizedSteppers steps={steps} activeStep={activeStep} />
        <Form>{getForm()}</Form>
      </Modal.Body>
      <div className="d-flex justify-content-between p-3">
        <Button
          onClick={() => {
            handleClose();
          }}
          className="text-btn"
          variant="text"
        >
          Cancel
        </Button>
        <div className="d-flex gap-3">
          {page > 1 && (
            <Button
              variant="text"
              className="text-btn"
              onClick={goToPreviousForm}
            >
              Back
            </Button>
          )}
          {page < 3 && (
            <Button
              onClick={() => {
                goToNextForm();
              }}
              className="next-btn"
              disabled={validateForm()}
            >
              Next
            </Button>
          )}
          {page === 3 && (
            <Button
              type="submit"
              className="next-btn"
              onClick={(e) => {
                handleSubmit(e, props.onHide);
              }}
              disabled={!destination}
            >
              Send
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
FileSendModal.propTypes = {
  templatedata: PropTypes.any.isRequired,
  onHide: PropTypes,
};

export default function App({ templateData, groupData, onHide }) {
  App.propTypes = {
    templateData: PropTypes,
    groupData: PropTypes,
    onHide: PropTypes,
  };
  return (
    <>
      <FileSendModal
        show={true}
        onHide={onHide}
        templatedata={templateData}
        groupdata={groupData}
      />
    </>
  );
}
