import { Modal, Button, } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert, toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
// import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import PropTypes from 'prop-types';
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";

function DeleteModal(props) {
    const { url, type, entity,  demo, itemToDelete, rowData, setRowData } = props
    const dispatch = useDispatch();
    const axiosPrivate = CreateAxiosInstance();
    const token = useSelector(state => state.persistedReducer?.member?.token);
    const deleteTemplate = async (hideModal) => {

        // demo code
        if(demo){
            const newData = rowData.filter(item=>item.id!==itemToDelete)
            setRowData(newData);
            hideModal();
            dispatch(toggleAlert({ title: `Success!`, message: `${type} was deleted successfully` }));
            return;
        }

        //end of demo code
        hideModal();
        props.setShowAgGrid(false)



        await axiosPrivate.delete(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(resp => {
                console.log(resp);
                {props.memberRole === "TS_ADMIN" ? dispatch(toggleAlert({ title: `Success!`, message: `${type} deleted successfully` })) : dispatch(toggleAlert({ title: `Success!`, message: `${type}  delete request raised successfully` }))}
                // hideModal();
                props.setShowAgGrid(true)
                // window.location.reload();
            })
            .catch(err => {
                console.error(err);
                {props.memberRole === "TS_ADMIN" ? dispatch(toggleFailAlert({ title: `Failed!`, message: `${type} could not be deleted` })) : dispatch(toggleFailAlert({ title: `Failed!`, message: `Request not raised.` }))}
                // hideModal();
                props.setShowAgGrid(true)

            })
    }


    return (
        <Modal
            {...props}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                {props.memberRole==="TS_ADMIN"?<Modal.Title id="contained-modal-title-vcenter">
                    Delete {type}
                </Modal.Title> : <Modal.Title id="contained-modal-title-vcenter">
                    Delete {type} Request
                </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <p>&apos;{entity}&apos; will be deleted. Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit"
                    className="bg-danger"
                    onClick={() => deleteTemplate(props.onHide)}
                    style={{ border: "none" }}
                >
                    Yes
                </Button>
                <Button onClick={props.onHide}>No</Button>
            </Modal.Footer>
        </Modal>
    );
}
DeleteModal.propTypes={
    url: PropTypes, 
    type: PropTypes, 
    entity: PropTypes,  
    demo: PropTypes, 
    itemToDelete: PropTypes, 
    rowData: PropTypes, 
    setRowData: PropTypes,
    onHide: PropTypes,
    gridRef: PropTypes,
    memberRole: PropTypes,
    setShowAgGrid: PropTypes
 }

export default function App({ modalShow,setModalShow, url, type, title, body, entity, demo, itemToDelete, rowData, setRowData, gridRef, memberRole, setShowAgGrid}) {
    App.propTypes={
        modalShow: PropTypes,
        setModalShow: PropTypes, 
        url: PropTypes, 
        type: PropTypes, 
        title: PropTypes, 
        body: PropTypes, 
        entity: PropTypes, 
        demo: PropTypes, 
        itemToDelete: PropTypes, 
        gridRef: PropTypes,
        rowData: PropTypes, 
        setRowData: PropTypes,
        memberRole: PropTypes,
        setShowAgGrid: PropTypes
    }
    return (
        <>

            <DeleteModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                url={url}
                type={type}
                title={title}
                body={body}
                entity={entity}
                demo={demo}
                itemToDelete={itemToDelete}
                rowData={rowData}
                setRowData={setRowData}
                gridRef={gridRef}
                memberRole={memberRole}
                setShowAgGrid={setShowAgGrid}
            />
        </>
    );
}
