import React, { useState, useMemo, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import edit_icon from "../../assets/edit_icon.png";
import UpdateTemplate from "../Modals/SpamFilterModals/UpdateSpamFilterModal";
import DeleteModal from "../Modals/Common/deleteModal";
import UploadCSVModal from "../Modals/UploadCSV/UploadCSVModal";
import { Button } from "react-bootstrap";
import download from "../../assets/download.png";
import filterDemoData from "../../DummyData/filtersData/FlashMessageSpamFilterData";
import CreateSpamFilterModal from "../Modals/SpamFilterModals/CreateSpamFilterModal";
import PropTypes from 'prop-types';
import { MdOutlineDelete } from "react-icons/md";

const FlashSpamFiltersGrid = ({ token }) => {
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [rowData, setRowData] = useState(filterDemoData.data.spamFilterData);
  const [singleData, setSingleData] = useState([]);
  const gridRef = useRef();

  const colDefs = [
    {
      headerName: "DATE ADDED",
      field: "dateAdded",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "FILTER NAME",
      field: "filterName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "RULE TO MATCH",
      field: "filterRule",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    { headerName: "CONFIDENCE", cellRenderer: "renderConfidenceLevel" },
    {
      headerName: "CREATED BY",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];

  colDefs.push({
    headerName: "ACTIONS",
    cellRenderer: "renderActionButtons",
  });
  const [columnDefs] = useState(colDefs);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    []
  );
  const dimensions = { width: "100%" };

  // custom action components for the action field in ag grid
  const actionButtons = (params) => {
    const handleClick = () => {
      setSingleData(params.data);
    };
    return (
      <div className="d-flex gap-3">
        <div
          className="action-btn"
          onClick={() => {
            handleClick();
            setEditModalShow(true);
          }}
        >
          <img src={edit_icon} alt="edit-template" />
        </div>
        <div
          className="action-btn"
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        >
          <MdOutlineDelete style={{height: "20px", width: "20px"}}/>
        </div>
      </div>
    );
  };

  const confidenceFn = (params) => {
    const confidence = Number(params.data.confidence);
    if (confidence === 1) {
      return "Level 1"
    }
    if (confidence === 2) {
      return "Level 2"
    }
    if (confidence === 3) {
      return "level 3"
    }
  };

  const frameworkComponents = {
    renderActionButtons: actionButtons,
    renderConfidenceLevel: confidenceFn,
  };

  //Download CSV
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-end mb-2 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          {
            <Button
              variant="primary"
              className="py-1 fs-6 transparent-btn"
              onClick={onBtnExport}
            >
              <div className="d-flex gap-2">
                <div
                  style={{
                    borderRight: "1px solid grey",
                    paddingRight: ".5em",
                    width: "1.25em",
                  }}
                >
                  <img
                    src={download}
                    width="15"
                    style={{ filter: "hue-rotate(30deg), brightness(100%)" }}
                  />
                </div>
                Download CSV
              </div>
            </Button>
          }
          <UploadCSVModal token={token} title={"Message Templates"} />
          <CreateSpamFilterModal
            token={token}
            rowData={rowData}
            setRowData={setRowData}
          />
        </div>
      </div>
      <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={15}
          domLayout="autoHeight" // if too many rows should not use this, give fixed height
          animateRows={true}
          components={frameworkComponents}
        />
      </div>
      {editModalShow && (
        <UpdateTemplate
          modalShow={editModalShow}
          setModalShow={setEditModalShow}
          url={`${process.env.REACT_APP_MESSAGE_TEMPLATES}/${singleData.id}`}
          data={singleData}
          token={token}
        />
      )}

      {deleteModalShow && (
        <DeleteModal
          modalShow={deleteModalShow}
          setModalShow={setDeleteModalShow}
          type="Spam Filter"
          url={`${process.env.REACT_APP_MESSAGE_TEMPLATES}/${singleData.id}`}
          entity={singleData.filterName}
          itemToDelete={singleData.id}
          demo={true}
          rowData={rowData}
          setRowData={setRowData}
          gridRef={gridRef}
        />
      )}
    </>
  );
};
FlashSpamFiltersGrid.propTypes = {
  token: PropTypes
};
export default FlashSpamFiltersGrid