import React, { useEffect } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import MessageHealthStatusGrid from "../../../components/Grids/MessageHealthStatusGrid";
import { MessageHealthStatusHeader } from "../../../data/MISDashboardData"
import DataLoading from "../../../components/Spinners/DataLoading";
import Error from "../../../components/Error/Error";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";


const EmailHealthTab = (props) => {
    // const [MHSDataLoading, setMHSDataLoading] = useState(false);
    const MHSDataLoading = false;
    //states for filter by date range

    // const [dataFetchError, setDataFetchError] = useState(false);
    const dataFetchError = false;

    // useEffect(() => {
    //     setFromDate("");
    // }, [])

    useEffect(() => {
      }, [props.time]);

    if (MHSDataLoading) return <DataLoading />


    return (
        <div className="my-2 shadow-sm">
            {
                dataFetchError && <Error code={500} />
            }
            <Row className="mb-3">
                <Col xs={12} xl={12}>
                    <Card>
                        <Card.Body>
                            <MessageHealthStatusGrid
                                notificationType={"EMAIL"}
                                heading="Message Health Status"
                                tableHeading={MessageHealthStatusHeader}
                                fromDate={props.fromDate}
                                toDate={props.toDate}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
EmailHealthTab.propTypes = {
    time: PropTypes.any,
    fromDate: PropTypes.any,
    toDate: PropTypes.any
}

export default EmailHealthTab
