/* eslint-disable */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import close_modal from "../../../assets/close.svg";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import RecipientAuditLogGrid from "../../Grids/RecipientAuditLogGrid";
import { recipientConstants } from "./Constants";
import { accordionStyles } from "./MUISelectStyle";
import TimePicker from "./MUITimePickerCustom/TimePicker";

const CreateRecipientDrawer = ({
  openDrawer,
  setOpenDrawer,
  // setShowAgGrid,
  setRefreshGrid,
  entityDetails,
}) => {
  //Constants
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  // States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lineChatId, setLineChatId] = useState("");
  const [contactList, setContactList] = useState("");
  const [notificationPreferences, setNotificationPreferences] = useState({
    email: false,
    sms: false,
  });
  const [transactional, setTransactional] = useState([false, false]);
  const [accountManagement, setAccountManagement] = useState(false);
  const [securityAndFraudPrevention, setSecurityAndFraudPrevention] =
    useState(false);
  const [isPromotionalChecked, setIsPromotionalChecked] = useState();
  const [timePickerPayload, setTimePickerPayload] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("entityDetails");

  const axiosPrivate = CreateAxiosInstance();
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles,
  );
  const dispatch = useDispatch();
  const accordionClasses = accordionStyles();

  useEffect(() => {
    getContactList();
    setActiveTab("entityDetails");
  }, []);

  const getContactList = useCallback(async () => {
    try {
      setLoading(true);
      await axiosPrivate
        .post(
          process.env.REACT_APP_GROUPS,
          {
            startRow: 0,
            endRow: 100,
            sortModel: [],
            filterModel: {},
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          const matchedGroups = resp?.groupDetails
            .filter((group) => entityDetails.groupIds.includes(group.id))
            .map((group) => ({ label: group.name, value: group.id }));
          setContactList(matchedGroups);
          setOptions(
            resp?.groupDetails.map((item) => {
              return { label: item.name, value: item.id };
            }),
          );
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(
    () => console.log("From parent component payload: \n", timePickerPayload),
    [timePickerPayload],
    
  );

  useEffect(() => {
    if (!openDrawer) {
      return;
    }
    console.log('entityDetails: ',entityDetails);
    setFirstName(entityDetails.firstName);
    setLastName(entityDetails.lastName);
    setEmail(entityDetails.email);
    setPhoneNumber(entityDetails.phoneNumber);
    setLineChatId(entityDetails.lineChatId);
    setContactList(entityDetails.groupIds);

    // Initialize allowed notifications
    const allowedNotifications = entityDetails.allowedNotifications ? entityDetails.allowedNotifications : [];
    for (let item of allowedNotifications) {
      if (item === recipientConstants.SECURITY_AND_FRAUD_PREVENTION) {
        setSecurityAndFraudPrevention(true);
      } else if (item === recipientConstants.ACCOUNT_MANAGEMENT) {
        setAccountManagement(true);
      } else if (item === recipientConstants.PROMOTIONAL) {
        setIsPromotionalChecked(true);
      } else if (item === recipientConstants.OTP) {
        const paymentReminder = transactional[1];
        setTransactional([true, paymentReminder]);
      } else if (item === recipientConstants.PAYMENT_REMINDERS) {
        const otp = transactional[0];
        setTransactional([otp, true]);
      }
    }
    // Initialize capabilities
    const capabilities = entityDetails.capabilities ? entityDetails.capabilities : [];
    const capabilitiesObj = { ...notificationPreferences };
    for (let item of capabilities) {
      if (item === recipientConstants.SMS) {
        capabilitiesObj.sms = true;
      } else if (item === recipientConstants.EMAIL) {
        capabilitiesObj.email = true;
      }
    }
    setNotificationPreferences(capabilitiesObj);

    setTimePickerPayload(entityDetails?.promotionTimes);
  }, [entityDetails, openDrawer]);

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="OTPs"
        control={
          <Checkbox
            disabled
            checked={transactional && transactional[0]}
            sx={{
              marginBlockStart: "6px",
              "&.Mui-checked": {
                color: "rgba(115, 115, 219, 1)",
              },
            }}
          />
        }
      />
      <FormControlLabel
        label="Payment reminders"
        control={
          <Checkbox
            disabled
            checked={transactional && transactional[1]}
            sx={{
              "&.Mui-checked": {
                color: "rgba(115, 115, 219, 1)",
              },
            }}
          />
        }
      />
    </Box>
  );

  // Componet: Divider
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };

  // Component: Divider margin
  const addDividerMargin = () => {
    return <div className="divider-margin"></div>;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  // Component: Tab Switch
  const TabSwitch = () => {
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    return (
      <div className="tab-switch-container">
        <div
          className={`tab ${
            activeTab === "entityDetails" ? "active" : "inactive"
          }`}
          onClick={() => handleTabClick("entityDetails")}
        >
          Info
        </div>
        <div
          className={`tab ${activeTab === "auditLogs" ? "active" : "inactive"}`}
          onClick={() => handleTabClick("auditLogs")}
        >
          Audit logs
        </div>
      </div>
    );
  };

  // Handle reject or approve requests for recipient
  const handleApprovalRequest = async (requestType) => {
    const url = `${process.env.REACT_APP_ACCEPT_REJECT_REQUEST}`;
    const payload = {
      status: requestType,
      entity: "RECIPIENT",
      entityId:
        entityDetails.approvalEntityId === null
          ? entityDetails.id
          : entityDetails.approvalEntityId,
    };

    try {
      await axiosPrivate
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            dispatch(
              toggleAlert({ title: "Success!", message: res.data.message }),
            );
            toggleDrawer(false);
            setOpenDrawer(false);
            setRefreshGrid(true);
          }
        });
    } catch (error) {
      console.log(error);
      dispatch(
        toggleFailAlert({
          title: "Failed!",
          message: "Unable to process request.",
        }),
      );
    }
  };

  return (
    <div>
      {/* Main container */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        {openDrawer && (
          <Box
            sx={{ width: recipientConstants.RECIPIENT_DRAWER_WIDTH }}
            role="presentation"
            className="recipient-details-container"
          >
            <div className="recipient-details-header">
              <div className="recipient-modal-title">
                <h5> Recipient details</h5>
                <img
                  style={{ cursor: "pointer" }}
                  src={close_modal}
                  alt="Close Modal"
                  onClick={toggleDrawer(false)}
                />
              </div>
              <TabSwitch />
              {addDivider()}
            </div>
            <div
              className="recipient-details-body-content"
              style={{
                marginBlock: "14px",
                paddingBlock: 0,
                height: "20px",
                minHeight: "20px",
              }}
            >
              {activeTab === "entityDetails" && (
                <section className="">
                  <div className="recipient-item-pair-container">
                    <div className="mb-3">
                      <p className="recipient-details-label">First Name</p>
                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        <TextField
                          id="input-with-icon-textfield"
                          label=""
                          notched={false}
                          placeholder="Recipient's First Name"
                          disabled={true}
                          value={firstName}
                          InputProps={{
                            sx: {
                              padding: "1px 3px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              borderRadius: "8px",
                              height: "40px",
                              "& .MuiSvgIcon-root": {
                                height: "20px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& .MuiInputBase-input": {
                                fontWeight: 700,
                                fontFamily: "Inter",
                                lineHeight: "14px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              background: "rgba(247, 247, 250, 1)",
                            },
                          }}
                        />
                      </Box>
                    </div>

                    <Form.Group id="name">
                      <div className="mb-3">
                        <p className="recipient-details-label">Last Name</p>
                        <Box sx={{ "& > :not(style)": { m: 1 } }}>
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            placeholder="Recipient's Last Name"
                            disabled
                            value={lastName}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="recipient-item-pair-container">
                    <Form.Group id="email">
                      <div className="mb-3">
                        <p className="recipient-details-label">Email Id</p>
                        <Box sx={{ "& > :not(style)": { m: 1 } }}>
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            disabled
                            placeholder="Recipient's Email"
                            value={email}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>
                    <Form.Group id="phoneNumber">
                      <div className="mb-3">
                        <p className="recipient-details-label">Phone Number</p>
                        <Box sx={{ "& > :not(style)": { m: 1 } }}>
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            placeholder="Recipient's Phone Number"
                            disabled
                            value={phoneNumber}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="recipient-item-pair-container">
                    <Form.Group id="lineChat">
                      <div className="mb-3">
                        <p className="recipient-details-label">LineChat Id</p>
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            placeholder="Recipient's line Chat Id"
                            disabled
                            value={lineChatId}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                marginLeft: 0,
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>
                    <div className="mb-3">
                      <p className="recipient-details-label">Contact list</p>

                      <div>
                        <Select
                          isDisabled={true}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              flexGrow: 1,
                              marginTop: "16px",
                              padding: "3px 0px",
                              height: "40px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              lineHeight: "20px",
                              color: "rgba(17, 21, 24, 1)",
                              fontWeight: "500",
                              borderColor: state.isFocused
                                ? "rgba(249, 249, 249, 1)"
                                : "rgba(249, 249, 249, 1)",
                              backgroundColor: "rgba(249, 249, 249, 1)",
                            }),
                          }}
                          options={loading ? [] : options}
                          placeholder={
                            loading ? "Loading..." : "Add to a Contact List"
                          }
                          value={contactList}
                          isMulti
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* horizontal line break */}
                  {addDividerMargin()}
                  {addDivider()}
                  <div className="pb-2"></div>

                  {/* Capabilities */}
                  <Accordion classes={{ root: accordionClasses.accordionRoot }}>
                    <AccordionSummary
                      sx={{ border: "none" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="capabilities-accordion-header"
                      id="capabilities-accordion-header"
                    >
                      <p className="recipient-detail-accordion-header">
                        Capabilities
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        <div className="d-flex gap-3">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={notificationPreferences.email}
                                sx={{
                                  paddingBlock: 0,
                                  marginBlock: 0,
                                  "&.Mui-checked": {
                                    color: "rgba(115, 115, 219, 1)",
                                  },
                                }}
                              />
                            }
                            label="Email"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={notificationPreferences.sms}
                                sx={{
                                  paddingBlock: 0,
                                  marginBlock: 0,
                                  "&.Mui-checked": {
                                    color: "rgba(115, 115, 219, 1)",
                                  },
                                }}
                              />
                            }
                            label="SMS"
                          />
                        </div>
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>

                  {/* horizontal line break */}
                  <div className="pb-2"></div>
                  {addDivider()}
                  <div className="pb-2"></div>

                  {/* Configuration */}
                  <Accordion classes={{ root: accordionClasses.accordionRoot }}>
                    <AccordionSummary
                      sx={{ border: "none" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="capabilities-accordion-header"
                      id="capabilities-accordion-header"
                    >
                      <p className="recipient-detail-accordion-header">
                        Configuration
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* TRANSACTIONAL CHECKBOX */}
                      <FormGroup>
                        <div className="d-flex flex-column gap-3">
                          <div>
                            <FormControlLabel
                              label="Transactional"
                              control={
                                <Checkbox
                                  disabled
                                  sx={{
                                    paddingBlock: 0,
                                    marginBlock: 0,
                                    "&.Mui-checked": {
                                      color: "rgba(115, 115, 219, 1)",
                                    },
                                  }}
                                  checked={transactional[0] && transactional[1]}
                                  indeterminate={
                                    transactional[0] !== transactional[1]
                                  }
                                />
                              }
                            />
                            {/* CHILDREN CHECKBOXES FOR TRANSACTIONAL */}
                            {children}
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={accountManagement}
                                sx={{
                                  paddingBlock: 0,
                                  marginBlock: 0,
                                  "&.Mui-checked": {
                                    color: "rgba(115, 115, 219, 1)",
                                  },
                                }}
                              />
                            }
                            label="Account management"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={securityAndFraudPrevention}
                                sx={{
                                  paddingBlock: 0,
                                  marginBlock: 0,
                                  "&.Mui-checked": {
                                    color: "rgba(115, 115, 219, 1)",
                                  },
                                }}
                              />
                            }
                            label="Security and fraud prevention"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                sx={{
                                  paddingBlock: 0,
                                  marginBlock: 0,
                                  "&.Mui-checked": {
                                    color: "rgba(115, 115, 219, 1)",
                                  },
                                }}
                                checked={isPromotionalChecked}
                              />
                            }
                            label="Promotional"
                          />
                        </div>
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>

                  {isPromotionalChecked && (
                    <div>
                      {addDivider()}
                      {addDividerMargin()}
                      <Accordion
                        classes={{ root: accordionClasses.accordionRoot }}
                        // className="my-4"
                      >
                        <AccordionSummary
                          sx={{ border: "none" }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="capabilities-accordion-header"
                          id="capabilities-accordion-header"
                        >
                          <p className="recipient-detail-accordion-header">
                            Schedule promotional messages
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup>
                            {/* SELECTED DAYS */}
                            <TimePicker
                              timePickerFullPayload={timePickerPayload}
                              setTimePickerPayload={setTimePickerPayload}
                              operationType={"READ"}
                            />
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )}
                </section>
              )}
              {activeTab === "auditLogs" && (
                <div>
                  <RecipientAuditLogGrid
                    token={token}
                    entityId={entityDetails.approvalEntityId === null ? entityDetails.id : entityDetails.approvalEntityId}
                  />
                </div>
              )}
            </div>
            {memberRole === "TS_ADMIN" &&
              entityDetails.approvalStatus === "PENDING" && (
                <div className="recipient-details-footer">
                  <div className="recipient-modal-response-buttons">
                    <div className="recipient-details-footer">
                      <div className="recipient-modal-response-buttons">
                        <div
                          className="reject"
                          onClick={() => handleApprovalRequest("REJECTED")}
                        >
                          <p className="reject-text">Reject</p>
                        </div>
                        <div
                          className="approve"
                          onClick={() => {
                            handleApprovalRequest("ACCEPTED");
                          }}
                        >
                          <p className="approve-text">Approve</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {/* end of temp content */}
          </Box>
        )}
      </Drawer>
    </div>
  );
};

CreateRecipientDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  // setShowAgGrid: PropTypes.func,
  setRefreshGrid: PropTypes.func,
  entityDetails: PropTypes.object,
  allowedNotifications: PropTypes.array,
};

export default CreateRecipientDrawer;
