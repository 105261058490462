import React,{useState, useEffect} from "react";
import { Col, Row, Card, Form, FormControl, Button, Modal, Image } from "@themesberg/react-bootstrap";
import { FormGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getPageTitle } from "../../Redux/Reducers/pageTitleReducer";
import Select from "react-select";
import delete_icon from "../../assets/delete_red.jpeg";
import { useHistory, useParams } from "react-router-dom";
import SMSAggregatorPlivoFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorPlivoFormData";
import SMSAggregatorExotelFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorExotelFormData";
import SMSAggregatorSMSHorizonFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorSMSHorizonFormData";
import SMSAggregatorKnowlarityFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorKnowlarityFormData";
import CreateSMSAggregator from "../Forms/CreateSMSAggregator";

export default ()=>{
    const [authID, setAuthID] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [PHLOID, setPHLOID] = useState("");
    const [src, setSrc] = useState("");
    const [powerpack_uuid, setPowerpack_uuid] = useState("");
    const [url, setUrl] = useState("");
    const [method, setMethod] = useState("");
    const [messageDirection, setMessageDirection] = useState("");
    const history = useHistory();

    const params = useParams();
    const aggregatorName = params.aggregator;

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

    const dispatch = useDispatch();

    useEffect(() => {
      aggregatorName ==="add-Aggregator"?
      dispatch(getPageTitle("Add new SMS Aggregator"))
      :
       dispatch(getPageTitle(aggregatorName.charAt(0).toUpperCase() + aggregatorName.slice(1)+" Settings"));
      }, [])

    const getAggregatorFormData = ()=>{
        switch (aggregatorName){
            case "plivo": return <SMSAggregatorPlivoFormData />
            case "exotel": return <SMSAggregatorPlivoFormData />
            case "sms-Horizon": return <SMSAggregatorPlivoFormData />
            case "knowlarity": return <SMSAggregatorPlivoFormData />
            default: return <CreateSMSAggregator />
        }
    }
    

    return  <>
        <div className="py-1">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Aggregator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure you want to delete this aggregator?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                    {
                      aggregatorName!=="add-Aggregator" && 
                      <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-3">
                    <img src="https://e7.pngegg.com/pngimages/727/475/png-clipart-computer-icons-back-icon-cdr-angle.png" className="cursor-pointer" style={{width:"35px", aspectRatio:"1"}}  onClick={()=>history.push("/aggregators/sms")}/>
                    <h5>Configure your aggregator settings</h5>
                      </div>
                    <div className="d-flex gap-2 border p-2 rounded text-danger cursor-pointer" style={{fontWeight:"700"}} onClick={handleShow}>
                        <img src={delete_icon} alt="delete icon" style={{width:"15px", height:"20px"}}/>
                        Delete
                    </div>
                    </div>
                    }
                    {
                        getAggregatorFormData()
                    }
                       
                <div className="d-flex gap-2 mt-2 border-top pt-2">
                 
                  <Button type="submit">{aggregatorName==="add-Aggregator" ? "Create Aggregator" : "Save Changes"}</Button>
                  {
                    aggregatorName==="add-Aggregator" && <Button type="submit" onClick={()=>history.push("/aggregators/sms")}>Cancel</Button>
                    
                  }
                </div>
                </Card.Body>
            </Card>
        </div>
</>
}