import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import React, { useState } from "react";
import { FormControl } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import validate from "../../FormValidation/validation";
import ShowError from "../../FormValidation/ShowError";
import PropTypes from "prop-types";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import CreateEntity from "../../Buttons/CreateEntity";
import CreateTemplate from "../MUIModals/MUICreateMessageTemplateModal";

const CreateTemplateModal = (props) => {
  console.log(props.memberRole);
  const [templateName, setTemplateName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [messagePriority, setMessagePriority] = useState(1);
  const [notificationTypeList, setNotificationTypeList] = useState([]);
  // For validation
  const [templateNameError, setTemplateNameError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [messageTypeError, setMessageTypeError] = useState("");
  const [notificationTypeListError, setNotificationTypeListError] =
    useState("");
  const dispatch = useDispatch();
  const axiosPrivate = CreateAxiosInstance();

  const createTemplate = async (payload, hideModal) => {
    hideModal();
    props.setShowAgGrid(false);
    await axiosPrivate
      .post(`${process.env.REACT_APP_NEW_MESSAGE_TEMPLATE}`, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message })
          );
          props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.error(err);
        {
          props.memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Your message template was not created.",
                })
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                })
              );
        }
        props.setShowAgGrid(true);
      });
  };
  const handleNotificationTypeChange = (selectedOption) => {
    setNotificationTypeList([selectedOption]); // Wrap selectedOption in an array to make it a single element array
    console.log(selectedOption);
  };

  const handleClick = (e, hideModal) => {
    e.preventDefault();
    if (validate(templateName, "isEmpty")) {
      setTemplateNameError("Template name content cannot be empty");
      return;
    } else {
      setTemplateNameError("");
    }
    if (validate(message, "isEmpty")) {
      setMessageError("Message content cannot be empty");
      return;
    } else {
      setMessageError("");
    }
    if (validate(messageType, "isEmpty")) {
      setMessageTypeError("Message Type cannot be empty");
      return;
    } else {
      setMessageTypeError("");
    }
    if (notificationTypeList.length === 0) {
      setNotificationTypeListError("Please select notification type");
      return;
    } else {
      setNotificationTypeListError("");
    }

    const notificationType = notificationTypeList.map((item) => item.value);
    const templateData = {
      templateName,
      messageType,
      notificationType,
      messagePriority,
      message,
    };

    createTemplate(templateData, hideModal);
  };

  return (
    <Modal
      {...props}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.memberRole === "TS_ADMIN"
            ? "Create Template"
            : "Create Template Request"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group id="templateName">
            <div className="mb-3">
              <Form.Label>Template Name</Form.Label>
              <Form.Control
                required
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Enter message template name."
              />
              {templateNameError && <ShowError msg={templateNameError} />}
            </div>
          </Form.Group>
          <Form.Group id="gender">
            <div className="mb-3">
              <Form.Label>Message Type</Form.Label>
              <Form.Select
                required
                // defaultValue="OTP"
                placeholder="Select Type"
                onChange={(e) => setMessageType(e.target.value)}
              >
                <option readOnly>Select Message Types</option>
                <option value="OTP">OTP</option>
                <option value="Transactional">Transactional</option>
                <option value="Promotional">Promotional</option>
              </Form.Select>
              {messageTypeError && <ShowError msg={messageTypeError} />}
            </div>
          </Form.Group>
          <Form.Group id="messagePriority">
            <div className="mb-3">
              <Form.Label>Message Priority</Form.Label>
              <Form.Control
                required
                min={1}
                max={3}
                type="number"
                value={messagePriority}
                onChange={(e) => setMessagePriority(e.target.value)}
                placeholder="Enter message priority"
              />
            </div>
          </Form.Group>
          <Form.Group>
            <div className="mb-3">
              <Form.Label>Notification Type</Form.Label>
              <Select
                placeholder="Select Notification Type"
                // isMulti
                options={[
                  { label: "SMS", value: "SMS" },
                  { label: "EMAIL", value: "EMAIL" },
                  // { label: "INAPP", value: "INAPP" }
                ]}
                onChange={handleNotificationTypeChange}
              />
              {notificationTypeListError && (
                <ShowError msg={notificationTypeListError} />
              )}
            </div>
          </Form.Group>
          <Form.Group id="firstName">
            <div className="mb-3">
              <Form.Label>Type or paste your template content here</Form.Label>
              <FormControl
                as="textarea"
                placeholder="Your Message Content"
                rows={10}
                aria-label="With textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {messageError && <ShowError msg={messageError} />}
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={(e) => handleClick(e, props.onHide)}>
          Create
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
CreateTemplateModal.propTypes = {
  token: PropTypes,
  onHide: PropTypes,
  memberRole: PropTypes,
  setShowAgGrid: PropTypes,
};
export default function App({setRefreshGrid}) {
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  return (
    <>
      <CreateEntity
        onClick={() => setShowCreateTemplateModal(true)}
        btnText="Create template"
      />

      {showCreateTemplateModal && (
        <CreateTemplate
          openDrawer={showCreateTemplateModal}
          setOpenDrawer={setShowCreateTemplateModal}
          setRefreshGrid={setRefreshGrid}
        />
      )}
    </>
  );
}
App.propTypes = {
    setRefreshGrid: PropTypes.func
};
