import React, { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import RTSPieChart from "../../../components/Charts/RTSPieChart";
import { getPieChartSliceColors } from "../../../util/pieChartSliceColors";
import { getPieChartSliceData } from "../../../util/pieChartSliceData";
import RealTimeStats from "../../components/MISComponents/RealTimeStatsBadges";
// import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import PropTypes from "prop-types";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";

function PDFLevelsData(props) {
  const [pdfData, setPdfData] = useState({});
  const axiosPrivate = CreateAxiosInstance();
  const {
    data,
    getPieSliceValue,
    pieSlice,
    breadcrumbData,
    setBreadcrumbData,
    // pieColors,
  } = props;
  const pieChartData = {
    totalCount: pdfData?.totalMessagesCount || 0,
    data: getPieChartSliceData(
      props.filter,
      pdfData?.data,
      props.notificationType,
      true,
    ),
  };
  const pieChartColorData = getPieChartSliceColors(
    props.filter,
    pdfData?.data,
    props.notificationType,
    true,
  );

  const getPDFData = useCallback(async () => {
    await axiosPrivate
      .post(
        `${process.env.REACT_APP_PROVIDER_LEVEL_DATA}?notification-type=${props.notificationType}&&page=realtime`,
        {
          filterBy: props.filter,
          leaf: "true",
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
            From: props.fromDate.toISOString(),
            To: props.toDate.toISOString(),
          },
        },
      )
      .then((resp) => {
        if (resp?.data?.status === 200) {
          setPdfData(resp.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    props.fromDate,
    props.toDate,
    props.token,
    props.filter,
    props.notificationType,
    axiosPrivate,
  ]);

  useEffect(() => {
    getPDFData();
  }, [getPDFData]);

  return pdfData.data ? (
    <Card className="mb-3 first-page">
      <div className="d-flex justify-content-center">
        <div style={{ width: "50%" }}>
          <div style={{ height: "450px" }}>
            <RTSPieChart
              data={pieChartData}
              pieColors={pieChartColorData}
              BreadcrumbData={props.BreadCrumbData}
              pieSlice={props.filter}
            />
          </div>
        </div>
          <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
            <RealTimeStats
              data={data}
              getPieSliceValue={getPieSliceValue}
              pieSlice={pieSlice}
              pieChartData={pieChartData}
              breadcrumbData={breadcrumbData}
              setBreadcrumbData={setBreadcrumbData}
              pieColors={pieChartColorData}
            />
        </div>
      </div>
    </Card>
  ) : (
    <></>
  );
}

PDFLevelsData.propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  filter: PropTypes,
  token: PropTypes,
  data: PropTypes,
  getPieSliceValue: PropTypes,
  pieSlice: PropTypes,
  pieChartData: PropTypes,
  breadcrumbData: PropTypes,
  setBreadcrumbData: PropTypes,
  pieColors: PropTypes,
  RTSData: PropTypes,
  BreadCrumbData: PropTypes,
  notificationType: PropTypes,
};
export default PDFLevelsData;
