import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DeleteModal from "../Modals/Common/deleteModal";
import CreateTemplate from "../Modals/MessageTemplateModals/CreateTemplateModal";
import UpdateTemplate from "../Modals/MUIModals/MUIUpdateMessageTemplateModal";
import TemplateDetails from "../Modals/MUIModals/MUIMessageTemplateDetailsModal";
import {
  faCheck,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import DownloadCSV from "../../components/Buttons/DownloadCSV";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { getLastMonthDate } from "../../util/dateUtil";
import DeleteEntity from "../Buttons/DeleteEntity";
import EditEntity from "../Buttons/EditEntity";
import EntityModal from "../Modals/entityDetailModal";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import { removePlusPrefix } from "../../util/RemovedPlusPrefixFromString";

const MessageTemplateGrid = ({ token, memberRole }) => {
  const axiosPrivate = CreateAxiosInstance();
  const fromDate = getLastMonthDate();
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const gridRef = useRef();
  const [showAgGrid, setShowAgGrid] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [agGridData, setAgGridData] = useState();
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [refreshGrid, setRefreshGrid] = useState(false);

  const masterDetail = true;

  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
      columnDefs: [
        { field: "templateName", headerName: "Template name" },
        { field: "message", headerName: "Message" },
        { field: "messagePriority", headerName: "Priority" },
        { field: "messageType", headerName: "Type" },
        { field: "notificationType", headerName: "Notification types" },
        { field: "approvalOperation", headerName: "Operation" },
        { field: "approvalStatus", headerName: "Status" },
        { field: "createdByName", headerName: "Created by" },
        {
          field: "action",
          headerName: "Action",
          headerClass: "cell-border-left",

          cellRendererFramework: (params) => (
            <ActionButtonsSubGrid {...params} />
          ),
        },
      ],
      domLayout: "autoWidth",
    },
    getDetailRowData: async (params) => {
      await axiosPrivate
        .post(
          process.env.REACT_APP_MESSAGE_TEMPLATES,
          {
            startRow: 0,
            endRow: 100,
            sortModel: [],
            filterModel: {},
            approvalDocumentType: "REQUEST",
            approvalEntityId: params.data.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // const resp = response?.data?.data;
          params.successCallback(
            response.data.data.messageTemplateDetails,
            parseInt(1)
          ); //filling ag grid with content
        })
        .catch((error) => {
          console.error(error);
          // params.failCallback();
        });
      // params.successCallback(gridData,gridDataLength);
    },

    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      display: "flex",
      //   flex:2
    },
  };
  const ActionButtonsSubGrid = (params) => {
    console.log("test", params);
    if (
      (params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") ||
      params.data.approvalStatus === "APPROVED" ||
      params.data.approvalStatus === "REJECTED"
    ) {
      return (
        <div
          className=""
          style={{
            borderLeft: "1px solid rgba(233,233,233,1)",
            paddingInlineStart: "10px",
          }}
        >
          <div className="action-btn"></div>
        </div>
      );
    }
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_ADMIN") {
      return (
        <div
          className="d-flex gap-3"
          style={{
            borderLeft: "1px solid rgba(233,233,233,1)",
            paddingInlineStart: "10px",
          }}
        >
          <div
            className="action-btn"
            onClick={() => {
                showApproveRequestDetailsModal(params.data.approvalEntityId);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div
            className="action-btn"
            onClick={() => {
                showApproveRequestDetailsModal(params.data.approvalEntityId);
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      );
    }
    return null;
  };

  const dataSource = {
    async getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;

      await axiosPrivate
        .post(
          process.env.REACT_APP_MESSAGE_TEMPLATES,
          {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            approvalDocumentType: "ENTITY",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const resp = response?.data?.data;
          if (!resp?.messageTemplateDetails?.length) {
            params.successCallback([], 0);
            params.api.showNoRowsOverlay();
          } else {
            params.successCallback(
              resp.messageTemplateDetails,
              resp.totalMessageTemplatesCount
            );
            params.api.hideOverlay();
          }
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },
  };

  const colDefs = [
    {
      headerName: "Group",
      field: "templateName",
      flex: 2,
      maxWidth: 50,
      valueFormatter: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        }
      },
      cellRendererSelector: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        } else {
          return {
            component: "agGroupCellRenderer",
          };
        }
      },
      // rowGroup: true,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Template name",
      field: "templateName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Message",
      field: "message",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Priority",
      field: "messagePriority",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Type",
      field: "messageType",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Notification Types",
      field: "notificationType",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Operation",
      field: "approvalOperation",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Status",
      field: "approvalStatus",
      cellRenderer: "renderStatus",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "View audit logs",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];
  const ViewAuditLogs = (params) => {
    const handleAgGridData = async () => {
      let payload = {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {
          entityId: {
            filterType: "text",
            type: "contains",
            filter: params.data.id,
          },
        },
      };
      await axiosPrivate
        .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            from: fromDate,
            to: new Date(),
            Timezone: moment().utcOffset(),
          },
        })
        .then((response) => {
          const resp = response?.data?.data;
          setShowDetails(true);
          setAgGridData(resp.auditLogs);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <div className="d-flex gap-3">
        <div
          className="action-btn"
          onClick={() => {
            handleAgGridData();
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };

  const [columnDefs] = useState(colDefs);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    []
  );
  const dimensions = { width: "100%" };

  const onCellClicked = (event) => {
    // If action buttons are clicked then don't show the drawer.
    if (event.colDef.headerName !== "Actions" && !showDetailsDrawer) {
        if (event.data.approvalStatus==="PENDING") {
            showApproveRequestDetailsModal(event.data.id);
        } else {
            setSelectedRowData(event.data);
            setShowDetailsDrawer(true);
        }
    }
  };

  const statusTypes = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p
          style={{ fontSize: ".9rem", fontWeight: "600" }}
          className={`${getStatusType(params.data.approvalStatus)} capitalize`}
        >
          {params.data.approvalStatus?.toLowerCase()}
        </p>
      </div>
    );
  };
  const getStatusType = (type) => {
    switch (type) {
      case "APPROVED":
        return "recipient_status_approved";
      case "PENDING":
        return "recipient_status_pending";
      case "REJECTED":
        return "recipient_status_rejected";
    }
  };
  const showApproveRequestDetailsModal = async (id) => {
    await axiosPrivate
      .post(
        process.env.REACT_APP_MESSAGE_TEMPLATES,
        {
          startRow: 0,
          endRow: 100,
          sortModel: [],
          filterModel: {},
          approvalDocumentType: "REQUEST",
          approvalEntityId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSelectedRowData(response.data.data.messageTemplateDetails[0]);
        setShowDetailsDrawer(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // custom action components for the action field in ag grid
  const actionButtons = (params) => {
    const handleClick = () => {
      setSingleData(params.data);
    };
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") {
      return (
        <div className="">
          <div className="action-btn"></div>
        </div>
      );
    }
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_ADMIN") {
      return (
        <div className="d-flex gap-3">
          <div
            style={{
              paddingInlineStart: "10px",
              borderLeft: "1px solid rgba(233,233,233,1)"
            }}
            className="action-btn"
            onClick={() => {
              showApproveRequestDetailsModal(params.data.id);
            }}
          >
            <FontAwesomeIcon icon={faCheck} style={{ verticalAlign: "middle" }}/> 
          </div>
          <div
            className="action-btn"
            onClick={() => {
              showApproveRequestDetailsModal(params.data.id);
            }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ verticalAlign: "middle" }}/>
          </div>
          {params.data.approvalOperation !== "CREATE" ? (
            <DeleteEntity
              onClick={() => {
                handleClick();
                setDeleteModalShow(true);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      );
    }
    return (
      <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
        }}
      >
        <EditEntity
          onClick={() => {
            handleClick();
            setEditModalShow(true);
          }}
        />
        <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />
      </div>
    );
  };

  const frameworkComponents = {
    renderActionButtons: actionButtons,
    renderViewAuditLogs: ViewAuditLogs,
    renderStatus: statusTypes,
  };
  colDefs.push({
    headerName: "View audit logs",
    cellRenderer: "renderViewAuditLogs",
  });
  colDefs.push({
    headerName: "Actions",
    cellRenderer: "renderActionButtons",
    headerClass: "cell-border-left",
  });

  //Download CSV
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const refreshCache = useCallback(
    (searchData) => {
      const newDataSource = {
        async getRows(params) {
          const { startRow, endRow, filterModel, sortModel } = params.request;

          await axiosPrivate
            .post(
              process.env.REACT_APP_MESSAGE_TEMPLATES,
              {
                startRow: startRow,
                endRow: endRow,
                sortModel: sortModel,
                filterModel: filterModel,
                approvalDocumentType: "ENTITY",
                globalSearch: removePlusPrefix(searchData),
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              const resp = response?.data?.data;
              if (!resp?.messageTemplateDetails?.length) {
                params.successCallback([], 0);
                params.api.showNoRowsOverlay();
              } else {
                params.successCallback(
                  resp.messageTemplateDetails,
                  resp.totalMessageTemplatesCount
                );
                params.api.hideOverlay();
              }
            })
            .catch((error) => {
              console.error(error);
              params.failCallback();
            });
        },
      };
      gridRef.current.api.setServerSideDatasource(newDataSource);
    },
    [axiosPrivate, token]
  );

  useEffect(() => {
    if (refreshGrid && gridRef?.current?.api) {
      refreshGridCache("");
    }
  }, [refreshGrid]);

  const refreshGridCache = (searchData) => {
    if (!gridRef || !gridRef.current || !gridRef.current.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-2 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <GlobalSearch refreshGrid={refreshGridCache} />
          <DownloadCSV onClick={onBtnExport} />
          {/* <UploadCSVModal token={token} title={"Message Templates"} /> */}
          <CreateTemplate
            token={token}
            memberRole={memberRole}
            setRefreshGrid={setRefreshGrid}
            setShowAgGrid={setShowAgGrid}
          />
        </div>
      </div>
      {showAgGrid ? (
        <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={15}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            masterDetail={masterDetail}
            detailCellRendererParams={detailCellRendererParams}
            detailRowAutoHeight={true}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="No data to show"
            components={frameworkComponents}
            onCellClicked={onCellClicked}
          />
        </div>
      ) : (
        <></>
      )}
      {editModalShow && (
        <UpdateTemplate
          openDrawer={editModalShow}
          setOpenDrawer={setEditModalShow}
          setRefreshGrid={setRefreshGrid}
          data={singleData}
        />
      )}
      {deleteModalShow && (
        <DeleteModal
          modalShow={deleteModalShow}
          setModalShow={setDeleteModalShow}
          type="Message Template"
          url={`${process.env.REACT_APP_MESSAGE_TEMPLATES}/${singleData.id}`}
          entity={singleData.templateName}
          memberRole={memberRole}
          setShowAgGrid={setShowAgGrid}
        />
      )}
      {showDetailsDrawer && (
        <TemplateDetails
          openDrawer={showDetailsDrawer}
          setOpenDrawer={setShowDetailsDrawer}
          entityDetails={selectedRowData}
          setRefreshGrid={setRefreshGrid}
          token={token}
        />
      )}
      {showDetails && (
        <EntityModal
          modalShow={showDetails}
          setModalShow={setShowDetails}
          data={agGridData}
        />
      )}
    </>
  );
};
MessageTemplateGrid.propTypes = {
  token: PropTypes,
  memberRole: PropTypes,
};
export default MessageTemplateGrid;
