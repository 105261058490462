import React, { useEffect, useState } from "react";
import copy from "../../../../assets/reicpient-details-modal/copy.svg";
import Chip from "@mui/material/Chip";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import MultipleTimeField from "./MultpleTimeField";

{
  /* This is the UI that shows as chips: S M T W T F S */
}
const TimePicker = ({
  timePickerFullPayload,
  setTimePickerPayload,
  operationType,
}) => {
  const BLUE = "rgb(115,115,219)";
  const GREY = "rgb(239,239,247)";
  const WHITE = "rgb(255,255,255)";
  const GREY_TEXT = "rgb(126, 126, 126)";
  const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const READ = "READ";
  // Get the initial active days from main payload list
  const [activeDays, setActiveDays] = useState(
    timePickerFullPayload?.map((item) => item.day) || [],
  ); // "Sun","Mon"...
  const [daysSelected, setDaysSelected] = useState(activeDays);

  const days = [
    { Sun: "S" },
    { Mon: "M" },
    { Tue: "T" },
    { Wed: "W" },
    { Thu: "T" },
    { Fri: "F" },
    { Sat: "S" },
  ];
  // Re-render when payload's active days/selected days change
  useEffect(() => {
    const updatedDays = timePickerFullPayload?.map((item) => item.day) || []
    setActiveDays(updatedDays);
    setDaysSelected(updatedDays);
  }, [timePickerFullPayload, setActiveDays, setDaysSelected]);


  const [overlap] = useState();

  // const sortTimeSlots = (day, slots) => {
  //   const x = slots.sort((a, b) => {
  //     const dateA = new Date(`2024-03-26T${a.startTime}`);
  //     const dateB = new Date(`2024-03-26T${b.startTime}`);
  //     return dateA.getTime() - dateB.getTime();
  //   });
  //   checkOverlap(day, x);
  //   return x;
  // };

  // const checkOverlap = (day, sortedSlots) => {
  //   let hasOverlap = false;
  //   for (let i = 1; i < sortedSlots.length; i++) {
  //     const prevEndTime = new Date(
  //       `2024-03-26T${sortedSlots[i - 1].endTime}`,
  //     ).getTime();
  //     const currentStartTime = new Date(
  //       `2024-03-26T${sortedSlots[i].startTime}`,
  //     ).getTime();
  //     if (prevEndTime > currentStartTime) {
  //       hasOverlap = true;
  //       setOverlap({ day: day, index: i });
  //       break;
  //     }
  //   }
  //   if (!hasOverlap) {
  //     setOverlap({ day: "", index: -1 });
  //   }
  // };

  // handle clicks on day stack
  const handleDayClick = (day) => {
    if (operationType === READ) {
      return;
    }
    let updatedTimePickerPayload = [...timePickerFullPayload];
    const foundItem = updatedTimePickerPayload.find((item) => item.day === day);

    //Add new day to payload
    if (!foundItem) {
      const newDayObject = {
        day,
        dayIndex: DAY_NAMES.indexOf(day),
        timeSlots: [{ startTime: "06:00", endTime: "09:00" }], // TODO: validation
      };
      updatedTimePickerPayload.push(newDayObject);

      //Remove selected day from payload
    } else {
      updatedTimePickerPayload = updatedTimePickerPayload.filter(
        (item) => item.day !== day,
      );
    }

    const sortedData = updatedTimePickerPayload.slice().sort((a, b) => {
      return DAY_NAMES.indexOf(a.day) - DAY_NAMES.indexOf(b.day);
  });

    setTimePickerPayload(sortedData);
  };



  const handleCopy = () => {
    //Get the first day's time slots
    const selectedDaySlots = timePickerFullPayload.slice()[0].timeSlots;

    // Create a copy of the original payload
    const updatedPayload = timePickerFullPayload.slice();

    // Replace all the time slots with above time slots
    for (let item of updatedPayload) {
      item.timeSlots = [...selectedDaySlots];
    }

    // Update the main payload state
    setTimePickerPayload(updatedPayload);
  };

  const handleChipClick = (key) => {
    if (operationType === READ) {
      return;
    }
    const list = daysSelected;
    if (list.includes(key)) {
      const index = list.indexOf(key);
      if (index > -1) {
        // only splice array when item is found
        list.splice(index, 1); // remove one item only
      }
    } else {
      list.push(key);
    }
    //Add content instead of while list so that UI re-renders
    setDaysSelected([...list]);
  };

  const copyTextStyle = {
    color: "rgba(115, 115, 219, 1)",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    cursor: "pointer",
  };

  return (
    <div>
      <Stack direction="row" spacing={1} className="pb-4">
        {days.map((day, index) => {
          const key = Object.keys(day)[0];
          const value = Object.values(day)[0];
          const isActive = daysSelected.includes(key);

          return (
            <div key={index} onClick={() => handleDayClick(key)}>
              <Chip
                key={key}
                label={value}
                onClick={() => handleChipClick(key)}
                sx={{
                  borderRadius: "8px", // Customize the border-radius for square edges
                  backgroundColor: isActive ? BLUE : GREY,
                  "&:hover": {
                    backgroundColor: isActive ? BLUE : GREY, // Override hover effect to match the current color
                  },
                  color: isActive ? WHITE : GREY_TEXT, // Change text color based on background color
                }}
              />
            </div>
          );
        })}
      </Stack>
      <div>
        {activeDays.map((selectedDay, index) => {
          return (
            <div key={selectedDay}>
              {
             
               (
                <MultipleTimeField
                  activeDay={selectedDay}
                  selectedTimeSlots={timePickerFullPayload[index]?.timeSlots}
                  // handleRemoveSlot={handleRemovetimeSlot}
                  overlap={overlap}
                  payload={timePickerFullPayload}
                  setPayload={setTimePickerPayload}
                  dayIndex={index} //TODO: FIX INCORRECT INDEX VALUE BEING PASSED
                  operationType={operationType}
                />
              )}
              {index === 0 &&
                activeDays.length >= 2 &&
                operationType !== READ && (
                  <div
                    className="d-flex gap-3 py-3"
                    onClick={() => handleCopy(selectedDay)}
                  >
                    <img className="cursor-pointer" src={copy} alt="copy" />
                    <div style={copyTextStyle}>Copy time to all days</div>
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  timePickerFullPayload: PropTypes.array,
  setTimePickerPayload: PropTypes.func,
  operationType: PropTypes.string,
};

export default TimePicker;
