import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import close_modal from "../../../assets/close.svg";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import RecipientAuditLogGrid from "../../Grids/RecipientAuditLogGrid";
import { recipientConstants } from "./Constants";

const MessageTemplateDetailsDrawer = ({
  openDrawer,
  setOpenDrawer,
  entityDetails,
  setRefreshGrid
}) => {
  //Constants
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  useState(false);
  const [activeTab, setActiveTab] = useState("entityDetails");
  const [templateName, setTemplateName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [messagePriority, setMessagePriority] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const axiosPrivate = CreateAxiosInstance();
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );
  const dispatch = useDispatch();
  const options = [
    { value: "OTP", label: "OTP" },
    { value: "Transactional", label: "Transactional" },
    { value: "Promotional", label: "Promotional" },
  ];
  const notificationTypeList = [
    { label: "SMS", value: "SMS" },
    { label: "Email", value: "EMAIL" },
  ];

  useEffect(() => {
    setActiveTab("entityDetails");
    setRefreshGrid(false);
  }, []);

  useEffect(() => {
    console.log('entityDetails: ',entityDetails);
    const data = entityDetails;
    if (!openDrawer) {
      return;
    }
    setTemplateName(data.templateName);
    setMessage(data.message);
    setMessagePriority(data.messagePriority);
    options.forEach((option) => {
      if (option.value === data.messageType) {
        setMessageType(option);
      }
    });
    const arr = [];
    notificationTypeList.forEach((option) => {
      data.notificationType.forEach((n) => {
        if (option.value === n) {
          arr.push(option);
        }
      });
    });
    setNotificationType(arr);
  }, [entityDetails, openDrawer]);

  // Componet: Divider
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };

  // Component: Divider margin
  const addDividerMargin = () => {
    return <div className="divider-margin"></div>;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  // Component: Tab Switch
  const TabSwitch = () => {
    const handleTabClick = (tabName) => {
      console.log("entitydetails id: ", entityDetails);
      setActiveTab(tabName);
    };

    return (
      <div className="tab-switch-container">
        <div
          className={`tab ${
            activeTab === "entityDetails" ? "active" : "inactive"
          }`}
          onClick={() => handleTabClick("entityDetails")}
        >
          Info
        </div>
        <div
          className={`tab ${activeTab === "auditLogs" ? "active" : "inactive"}`}
          onClick={() => handleTabClick("auditLogs")}
        >
          Audit logs
        </div>
      </div>
    );
  };

  // Handle reject or approve requests for recipient
  const handleApprovalRequest = async (requestType) => {
    const url = `${process.env.REACT_APP_ACCEPT_REJECT_REQUEST}`;
    const payload = {
      status: requestType,
      entity: "MESSAGE_TEMPLATE",
      entityId:
        entityDetails.approvalEntityId === null
          ? entityDetails.id
          : entityDetails.approvalEntityId,
    };

    try {
      await axiosPrivate
        .post(url, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            dispatch(
              toggleAlert({ title: "Success!", message: res.data.message })
            );
            toggleDrawer(false);
            setOpenDrawer(false);
            setRefreshGrid(true);
          }
        });
    } catch (error) {
      console.log(error);
      dispatch(
        toggleFailAlert({
          title: "Failed!",
          message: "Unable to process request.",
        })
      );
    }
  };

  return (
    <div>
      {/* Main container */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        {openDrawer && (
          <Box
            sx={{ width: recipientConstants.RECIPIENT_DRAWER_WIDTH }}
            role="presentation"
            className="recipient-details-container"
          >
            <div className="recipient-details-header">
              <div className="recipient-modal-title">
                <h5> Message template details</h5>
                <img
                  style={{ cursor: "pointer" }}
                  src={close_modal}
                  alt="Close Modal"
                  onClick={toggleDrawer(false)}
                />
              </div>
              <TabSwitch />
              {addDivider()}
            </div>
            <div
              className="recipient-details-body-content"
              style={{
                marginBlock: "14px",
                paddingBlock: 0,
                height: "20px",
                minHeight: "20px",
              }}
            >
              {activeTab === "entityDetails" && (
                <section className="">
                  <div className="recipient-item-pair-container">
                    <div className="mb-3">
                      <p className="recipient-details-label">Template Name</p>
                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        <TextField
                          id="input-with-icon-textfield"
                          label=""
                          notched={false}
                          placeholder="Message Template Name"
                          required={true}
                          value={templateName}
                          disabled={true}
                          InputProps={{
                            sx: {
                              padding: "1px 3px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              borderRadius: "8px",
                              height: "40px",
                              "& .MuiSvgIcon-root": {
                                height: "20px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& .MuiInputBase-input": {
                                fontWeight: 700,
                                fontFamily: "Inter",
                                lineHeight: "14px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              background: "rgba(247, 247, 250, 1)",
                            },
                          }}
                        />
                      </Box>
                    </div>

                    <Form.Group id="name">
                      <div className="mb-3">
                        <p className="recipient-details-label">Message Type</p>
                        {
                          <div>
                            <Select
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  flexGrow: 1,
                                  marginTop: "16px",
                                  padding: "3px 0px",
                                  height: "40px",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontFamily: "Inter",
                                  lineHeight: "20px",
                                  color: "rgba(17, 21, 24, 1)",
                                  // border:"1px solid red",
                                  fontWeight: "500",
                                  borderColor: state.isFocused
                                    ? "rgba(249, 249, 249, 1)"
                                    : "rgba(249, 249, 249, 1)",
                                  backgroundColor: "rgba(249, 249, 249, 1)",
                                }),
                              }}
                              options={options}
                              placeholder="Select message type"
                              value={messageType}
                              disabled={true}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        }
                      </div>
                    </Form.Group>
                  </div>
                  <div className="recipient-item-pair-container">
                    <Form.Group id="email">
                      <div className="mb-3">
                        <p className="recipient-details-label">
                          Message Priority
                        </p>
                        <Box sx={{ "& > :not(style)": { m: 1 } }}>
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            required
                            placeholder="Message Priority"
                            value={messagePriority}
                            disabled={true}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>

                    <Form.Group id="name">
                      <div className="mb-3">
                        <p className="recipient-details-label">
                          Notification Type
                        </p>
                        {
                          <div>
                            <Select
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  flexGrow: 1,
                                  marginTop: "16px",
                                  padding: "3px 0px",
                                  height: "40px",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  fontFamily: "Inter",
                                  lineHeight: "20px",
                                  color: "rgba(17, 21, 24, 1)",
                                  // border:"1px solid red",
                                  fontWeight: "500",
                                  borderColor: state.isFocused
                                    ? "rgba(249, 249, 249, 1)"
                                    : "rgba(249, 249, 249, 1)",
                                  backgroundColor: "rgba(249, 249, 249, 1)",
                                }),
                              }}
                              options={notificationTypeList}
                              placeholder="Select message type"
                              value={notificationType}
                              isMulti
                              disabled={true}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        }
                      </div>
                    </Form.Group>
                  </div>
                  <div className="recipient-item-pair-container">
                    <Form.Group id="lineChat">
                      <div className="mb-3">
                        <p className="recipient-details-label">
                          Message Content
                        </p>
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1 },
                          }}
                        >
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            placeholder="Message Content"
                            required
                            value={message}
                            disabled={true}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                marginLeft: 0,
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>
                  </div>
                  {/* horizontal line break */}
                  {addDividerMargin()}
                  {addDivider()}
                  <div className="pb-2"></div>
                </section>
              )}
              {activeTab === "auditLogs" && (
                <div>
                  <RecipientAuditLogGrid
                    token={token}
                    entityId={entityDetails.approvalEntityId === null ? entityDetails.id : entityDetails.approvalEntityId}
                  />
                </div>
              )}
            </div>
            {memberRole === "TS_ADMIN" &&
              entityDetails.approvalStatus === "PENDING" && (
                <div className="recipient-details-footer">
                  <div className="recipient-modal-response-buttons">
                    <div className="recipient-details-footer">
                      <div className="recipient-modal-response-buttons">
                        <div
                          className="reject"
                          onClick={() => handleApprovalRequest("REJECTED")}
                        >
                          <p className="reject-text">Reject</p>
                        </div>
                        <div
                          className="approve"
                          onClick={() => {
                            handleApprovalRequest("ACCEPTED");
                          }}
                        >
                          <p className="approve-text">Approve</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {/* end of temp content */}
          </Box>
        )}
      </Drawer>
    </div>
  );
};

MessageTemplateDetailsDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  entityDetails: PropTypes.object,
  setRefreshGrid: PropTypes.func,
  allowedNotifications: PropTypes.array,
};

export default MessageTemplateDetailsDrawer;
