import React, { useState } from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { SearchRounded, Clear } from "@mui/icons-material";
import PropTypes from "prop-types";

const GlobalSearch = ({ refreshGrid }) => {
  const [globalSearchInput, setGlobalSearchInput] = useState("");


  const performGlobalSearch = (input) => {
    setGlobalSearchInput(input);
    if (input.length >= 3 || input.length === 0) {
      refreshGrid(input);
    }
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1 } }}>
      <TextField
        id="input-with-icon-textfield"
        label=""
        notched={false}
        value={globalSearchInput}
        placeholder="Search..."
        onChange={(e) => performGlobalSearch(e.target.value)}
        InputProps={{
          sx: {
            padding: "1px 3px",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            borderRadius: "8px",
            height: "40px",
            "& .MuiSvgIcon-root": {
              height: "20px",
              color: "rgba(126, 126, 126, 1)",
            },
            "& .MuiInputBase-input": {
              fontWeight: 700,
              fontFamily: "Inter",
              lineHeight: "14px",
              color: "rgba(126, 126, 126, 1)",
            },
            "& fieldset": {
              border: `${
                globalSearchInput.length ? "1px solid black" : "none"
              }`,
              // border:"1px solid red"
            },

            background: "rgba(247, 247, 250, 1)",
          },
          startAdornment: (
            <div>
              <InputAdornment position="start">
                <div style={{ paddingLeft: "15px" }}>
                  <SearchRounded />
                </div>
              </InputAdornment>
            </div>
          ),
          endAdornment: (
            <div
              style={{
                opacity: `${globalSearchInput.length === 0 ? 0 : 1}`,
              }}
            >
              <InputAdornment position="start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => performGlobalSearch("")}
                >
                  <Clear />
                </div>
              </InputAdornment>
            </div>
          ),
        }}
        // variant="standard"
      />
    </Box>
  );
};

GlobalSearch.propTypes = {
  // globalSearchInput:PropTypes.string,
  refreshGrid: PropTypes.func,
};

export default GlobalSearch;
