import React, { useEffect, useState, useCallback, useMemo } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
// import { axiosWithBaseUrl } from "../../axios/baseUrl";
import PropTypes from "prop-types";
import moment from "moment";
import PDFLevelsData from "./pdfLevelsData/PDFLevelsData";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";

function DownloadPdfPage(props) {
  const rtsDataValidate = props.realTimeStatsData;
  const {
    data,
    getPieSliceValue,
    pieSlice,
    pieChartData,
    breadcrumbData,
    setBreadcrumbData,
    pieColors,
  } = props;

  const axiosPrivate = CreateAxiosInstance();

  const fromDate = useMemo(() => {
    return new Date(props.fromDate);
  }, [props.fromDate]);

  const toDate = useMemo(() => {
    return new Date(props.toDate);
  }, [props.toDate]);

  const [RTSData, setRTSData] = useState({});

  const token = useSelector((state) => state.persistedReducer?.member?.token);

  const getRTSData = useCallback(async () => {
    await axiosPrivate
      .post(
        `${process.env.REACT_APP_PROVIDER_LEVEL_DATA}?notification-type=${props.notificationType}&&page=realtime`,
        {
          filterBy: "",
          leaf: "true",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            From: fromDate.toISOString(),
            To: toDate.toISOString(),
          },
        },
      )
      .then((resp) => {
        if (resp?.data?.status === 200) {
          setRTSData(resp.data.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [fromDate, toDate, token, props.notificationType, axiosPrivate]);

  const generatePdf = useCallback(async () => {
    const pdfName =
      `dashboard-data-${moment().format("MMM-DD-YYYY-HH:mm")}` + ".pdf";

    const captureElements = [
      { capture: document.querySelector(".first-page"), condition: true },
      {
        capture: document.querySelector(".second-page"),
        condition: rtsDataValidate.some(
          (obj) =>
            obj.k === "totalDeliveredMessagesCount" && parseInt(obj.v) > 0,
        ),
      },
      {
        capture: document.querySelector(".third-page"),
        condition: rtsDataValidate.some(
          (obj) =>
            obj.k === "totalDeliveryFailedMessagesCount" && parseInt(obj.v) > 0,
        ),
      },
      {
        capture: document.querySelector(".fourth-page"),
        condition: rtsDataValidate.some(
          (obj) => obj.k === "totalPendingMessagesCount" && parseInt(obj.v) > 0,
        ),
      },
    ];

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4",
    });

    const addPageToDoc = async (captureElement, firstpage) => {
      const { capture, condition } = captureElement;
      if (condition) {
        if (!firstpage) {
          doc.addPage();
        }
        if (firstpage) {
          const pdfHeading =
            "Downloaded At: " + moment().format("MMM-DD-YYYY-HH:mm");
          doc.text(pdfHeading, 0, 10);
        }
        await html2canvas(capture).then((canvas) => {
          const imgData = canvas.toDataURL("img/png");
          doc.setFontSize(8);
          const imgWidth = 300;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          doc.addImage(
            imgData,
            "PNG",
            0,
            30,
            imgWidth,
            imgHeight,
            "",
            "FAST",
            0.5,
          );
        });
      }
    };
    for (let i = 0; i < captureElements.length; i++) {
      await addPageToDoc(captureElements[i], i === 0);
    }
    doc.save(pdfName);
    await props.onComplete();
  }, [ props, rtsDataValidate]);

  useEffect(() => {
    getRTSData();
    const intervalId = setTimeout(generatePdf, 5000);
    return () => clearTimeout(intervalId);
  }, [getRTSData, generatePdf]);

  return RTSData.data ? (
    <div className="pdfData">
      <div style={{ marginLeft: "24px" }}>PDF is Downloading...</div>
      <div className="first-page">
        {RTSData.data ? (
          <div>
            <PDFLevelsData
             fromDate={fromDate}
             toDate={toDate}
              data={data}
              getPieSliceValue={getPieSliceValue}
              pieSlice={pieSlice}
              pieChartData={pieChartData}
              breadcrumbData={breadcrumbData}
              setBreadcrumbData={setBreadcrumbData}
              pieColors={pieColors}
              token={token}
              BreadCrumbData={["Real Time Stats"]}
              filter={""}
              RTSData={RTSData}
              notificationType={props.notificationType}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="second-page">
        {rtsDataValidate.some(
          (obj) =>
            obj.k === "totalDeliveredMessagesCount" && parseInt(obj.v) > 0,
        ) ? (
          <div>
            <PDFLevelsData
             fromDate={fromDate}
             toDate={toDate}
              data={data}
              getPieSliceValue={getPieSliceValue}
              pieSlice={pieSlice}
              pieChartData={pieChartData}
              breadcrumbData={breadcrumbData}
              setBreadcrumbData={setBreadcrumbData}
              pieColors={pieColors}
              token={token}
              BreadCrumbData={["Real Time Stats", "Messages Delivered"]}
              filter={"Messages Delivered"}
              RTSData={RTSData}
              notificationType={props.notificationType}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="third-page">
        {rtsDataValidate.some(
          (obj) =>
            obj.k === "totalDeliveryFailedMessagesCount" && parseInt(obj.v) > 0,
        ) ? (
          <div>
            <PDFLevelsData
             fromDate={fromDate}
             toDate={toDate}
              data={data}
              getPieSliceValue={getPieSliceValue}
              pieSlice={pieSlice}
              pieChartData={pieChartData}
              breadcrumbData={breadcrumbData}
              setBreadcrumbData={setBreadcrumbData}
              pieColors={pieColors}
              token={token}
              BreadCrumbData={["Real Time Stats", "Delivery Failed"]}
              filter={"Delivery Failed"}
              RTSData={RTSData}
              notificationType={props.notificationType}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="fourth-page">
        {rtsDataValidate.some(
          (obj) => obj.k === "totalPendingMessagesCount" && parseInt(obj.v) > 0,
        ) ? (
          <div>
            <PDFLevelsData
             fromDate={fromDate}
             toDate={toDate}
              data={data}
              getPieSliceValue={getPieSliceValue}
              pieSlice={pieSlice}
              pieChartData={pieChartData}
              breadcrumbData={breadcrumbData}
              setBreadcrumbData={setBreadcrumbData}
              pieColors={pieColors}
              token={token}
              BreadCrumbData={["Real Time Stats", "Messages Pending"]}
              filter={"Messages Pending"}
              RTSData={RTSData}
              notificationType={props.notificationType}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}

DownloadPdfPage.propTypes = {
  fromDate:PropTypes.string,
  toDate: PropTypes.string,
  data: PropTypes,
  getPieSliceValue: PropTypes,
  pieSlice: PropTypes,
  pieChartData: PropTypes,
  breadcrumbData: PropTypes,
  setBreadcrumbData: PropTypes,
  pieColors: PropTypes,
  realTimeStatsData: PropTypes,
  onComplete: PropTypes.func,
  notificationType: PropTypes,
};

export default DownloadPdfPage;
