import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import DownloadCSV from "../Buttons/DownloadCSV";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import DataLoading from "../Spinners/DataLoading";

export default function App({
  heading,
  tableData,
  isLoading,
  fromDate,
  toDate,
  notificationType
}) {
  // const [rowData, setRowData] = useState(tableData);
  const cacheBlockSize = 15;
  const axiosPrivate = CreateAxiosInstance();

  const gridRef = useRef();
  const columns = [
    { headerName: "Providers", field: "provider", flex: 1 },
    { headerName: "Messages sent", field: "totalMessagesCount", flex: 1 },
    {
      headerName: "Pending messages",
      field: "totalPendingMessagesCount",
      flex: 1,
    },
    { headerName: "Successful", field: "totalDeliveredMessagesCount", flex: 1 },
    { headerName: "Success rate", cellRenderer: "renderSuccessRate", flex: 1 },
    { headerName: "Failed", field: "totalFailedMessagesCount", flex: 1 },
  ];
  const [columnDefs] = useState(columns);
  // tableHeading.map((item) => {
  // // if "Reason" column then give it maximum possible width
  // const colFlex = item.toUpperCase() === "SEND MESSAGE CONTENT" ? 2 : 1;

  // return { field: item.toUpperCase(), filter: true, flex: colFlex };

  // }),

  // useEffect(() => {
  //   // Redefining row data to match column names
  //   const newRowData = (rowData || []).map((data) => {
  //     const {
  //       provider,
  //       totalMessagesCount,
  //       totalDeliveredMessagesCount,
  //       totalFailedMessagesCount,
  //       totalPendingMessagesCount,
  //     } = data;
  //     return {
  //       PROVIDERS: provider,
  //       "MESSAGES SENT": totalMessagesCount,
  //       SUCCESSFUL: totalDeliveredMessagesCount,
  //       "PENDING MESSAGES": totalPendingMessagesCount,
  //       "SUCCESS RATE":
  //         (
  //           (totalDeliveredMessagesCount / totalMessagesCount) * 100 || 0
  //         ).toFixed(2) + "%",
  //       FAILED: totalFailedMessagesCount,
  //     };
  //   });
  //   setRowData(newRowData);
  // }, [isLoading]);

  const dataSource = {
    async getRows(params) {
      // const { startRow, endRow, filterModel, sortModel } = params.request;
      await axiosPrivate
        .get(
          `${
            process.env.REACT_APP_GET_AGGREGATOR_STATS
          }?notification-type=${notificationType}`,
          {
            headers: {
              From: new Date(fromDate).toUTCString(),
              To: new Date(toDate).toUTCString(),
            },
          },
        )
        .then((response) => {
          if (response?.data?.status && response?.data?.data) {
            params.successCallback(
              response.data.data,
              response.data.data.length,
            );
          } else {
            params.successCallback([], 0);
            params.api.showNoRowsOverlay();
          }
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    [],
  );
  const dimensions = { width: "100%" };

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv({
      fileName: "Aggregator-Stats.csv",
    });
  }, []);

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
  };

  const getSuccessRate = (params) => {
    const totalDeliveredMessagesCount = params.data.totalDeliveredMessagesCount;
    const totalMessagesCount = params.data.totalMessagesCount;
    return (
      ((totalDeliveredMessagesCount / totalMessagesCount) * 100 || 0).toFixed(
        2,
      ) + "%"
    );
  };

  const frameworkComponents = {
    renderSuccessRate: getSuccessRate,
  };

  const refreshGrid = () => {
    return;
  };

  return (
    <>
      {isLoading ? (
        <DataLoading />
      ) : (
        <div id="aggregatorStatsTable">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="dashboard-heading">{heading}</h5>
            <div className="d-flex gap-3 align-items-center">
              <GlobalSearch refreshGrid={refreshGrid} />
              {tableData && (
                <div>
                  <DownloadCSV onClick={onBtnExport} />
                </div>
              )}
            </div>
          </div>
          <div className="ag-theme-alpine" style={dimensions}>
            <AgGridReact
              ref={gridRef}
              // rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={15}
              cacheBlockSize={cacheBlockSize}
              domLayout="autoHeight" // if too many rows should not use this, give fixed height
              animateRows={true}
              rowModelType="serverSide"
              serverSideInfiniteScroll={true}
              onGridReady={onGridReady}
              components={frameworkComponents}
              overlayNoRowsTemplate="No data to show"
            />
          </div>
        </div>
      )}
    </>
  );
}
App.propTypes = {
  heading: PropTypes.string,
  tableHeading: PropTypes.array,
  tableData: PropTypes.array,
  isLoading: PropTypes.bool,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  notificationType: PropTypes.string
};
