import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "../routes";
import { useSelector } from "react-redux";
import SendMessages from "./SendMessages";
import MessageTemplates from ".//MessageTemplates";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import UserEntry from "./UserEntry/UserEntry";
import Users from "./Users";
import Preloader from "../components/Preloader";
import RTS from "./dashboard/RealTimePage";
import MessageHealthStatus from "./dashboard/MessageHealthPage";
import AggregatorStats from "./dashboard/AggregatorPage";
import EmailPage from "./dashboard/email/EmailRealTimePage";
import EmailAggregatorPage from "./dashboard/email/EmailAggregatorPage";
import EmailMessageHealth from "./dashboard/email/EmailMessageHealthPage";
import EmailTemplates from "./EmailTemplates";
import SMSAggregators from "./SMSAggregators";
import SMSAggregator from "../components/Aggregators/SMSAggregator";
import EmailAggregators from "./EmailAggregators";
import EmailAggregator from "../components/Aggregators/EmailAggregator";
import FlashRealTimePage from "./dashboard/flash/FlashRealTimePage";
import AuditLogs from "./AuditLogs";
import SMSSpamFilter from "./spamFilters/SMSSpamFilter";
import EmailSpamFilter from "./spamFilters/EmailSpamFilter";
import FlashSpamFilter from "./spamFilters/FlashSpamFilter";
import SMSContactList from "./contactList/SMSContactList";
import FlashContactList from "./contactList/FlashContactList";
import EmailContactList from "./contactList/EmailContactList";
import FlashAggregators from "./aggregators/FlashAggregators";
import FlashAggregator from "../components/Aggregators/FlashAggregator";
import FlashTemplates from "./templates/FlashTemplates";
import PropTypes from "prop-types";
import ApprovalRequest from "./ApprovalRequest";

import APIKeys from "./APIKeys";
import NotFound from "../util/NotFoundRoute";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Component {...props} />
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!token) {
    window.location.href =
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_KEYCLOAK_LOGIN_API;
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: "50vh",
          transform: "translateY(-50%)",
        }}
      >
        {" "}
        Redirecting to Login page.
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <div style={{ display: "flex", height: "100%" }}>
          <Preloader show={loaded ? false : true} />
          <Sidebar />
          <main
            className="content"
            style={{
              flex: 1,
              minHeight: "100vh",
              backgroundColor: "white",
              // paddingLeft: "2.5rem",
            }}
          >
            <Navbar />
            <Component {...props} />
          </main>
        </div>
      )}
    />
  );
};

export default function App() {
  return (
    <Switch>
      <RouteWithLoader exact path={Routes.Entry.path} component={UserEntry} />
      <RouteWithSidebar exact path={Routes.Users.path} component={Users} />

      {/* CONTACT LIST */}
      <RouteWithSidebar
        exact
        path={Routes.SMSContactList.path}
        component={SMSContactList}
      />
      <RouteWithSidebar
        exact
        path={Routes.EmailContactList.path}
        component={EmailContactList}
      />
      <RouteWithSidebar
        exact
        path={Routes.FlashContactList.path}
        component={FlashContactList}
      />

      {/* NOTIFICATION TEMPLATES */}
      <RouteWithSidebar
        exact
        path={Routes.MessageTemplates.path}
        component={MessageTemplates}
      />
      <RouteWithSidebar
        exact
        path={Routes.EmailTemplates.path}
        component={EmailTemplates}
      />

      <RouteWithSidebar
        exact
        path={Routes.FlashTemplates.path}
        component={FlashTemplates}
      />

      {/* SEND NOTIFICATION */}
      <RouteWithSidebar
        exact
        path={Routes.SendMessages.path}
        component={SendMessages}
      />
      {/* DASHBOARDS */}
      <RouteWithSidebar exact path={Routes.RTS.path} component={RTS} />

      <RouteWithSidebar
        exact
        path={Routes.MHS.path}
        component={MessageHealthStatus}
      />
      <RouteWithSidebar
        exact
        path={Routes.AGS.path}
        component={AggregatorStats}
      />

      <RouteWithSidebar path={Routes.MIS.path} component={RTS} />
      <RouteWithSidebar exact path={Routes.Dashboard.path} component={RTS} />
      <RouteWithSidebar exact path={Routes.Homepage.path} component={RTS} />

      <RouteWithSidebar
        exact
        path={Routes.Flash_RTS.path}
        component={FlashRealTimePage}
      />

      {/* <RouteWithSidebar exact path={Routes.WRTS.path} component={WhatsAppMIS} /> */}

      <RouteWithSidebar
        exact
        path={Routes.EMAIL_RTS.path}
        component={EmailPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.EMAIL_MHS.path}
        component={EmailAggregatorPage}
      />
      <RouteWithSidebar
        exact
        path={Routes.EMAIL_AGS.path}
        component={EmailMessageHealth}
      />
      {/* AGGREGATORS */}
      <RouteWithSidebar
        exact
        path={Routes.SMSAggregators.path}
        component={SMSAggregators}
      />
      <RouteWithSidebar
        exact
        path={Routes.SMSAggregator.path}
        component={SMSAggregator}
      />

      <RouteWithSidebar
        exact
        path={Routes.EmailAggregators.path}
        component={EmailAggregators}
      />
      <RouteWithSidebar
        exact
        path={Routes.EmailAggregator.path}
        component={EmailAggregator}
      />

      <RouteWithSidebar
        exact
        path={Routes.FlashAggregators.path}
        component={FlashAggregators}
      />
      <RouteWithSidebar
        exact
        path={Routes.FlashAggregator.path}
        component={FlashAggregator}
      />

      {/* SPAM FILTERS */}
      <RouteWithSidebar
        exact
        path={Routes.SMSSpamFilter.path}
        component={SMSSpamFilter}
      />
      <RouteWithSidebar
        exact
        path={Routes.EmailSpamFilter.path}
        component={EmailSpamFilter}
      />
      <RouteWithSidebar
        exact
        path={Routes.FlashSpamFilter.path}
        component={FlashSpamFilter}
      />

      {/* AUDIT LOGS */}
      <RouteWithSidebar
        exact
        path={Routes.AuditLogs.path}
        component={AuditLogs}
      />
      <RouteWithSidebar
        exact
        path={Routes.ApprovalRequest.path}
        component={ApprovalRequest}
      />

      {/* API Keys */}
      <RouteWithSidebar exact path={Routes.APIKeys.path} component={APIKeys} />
      <Route path="*" exact component={NotFound} />

      {/* <Redirect to={Routes.NotFound.path} /> */}
    </Switch>
  );
}
RouteWithLoader.propTypes = {
  component: PropTypes,
};
RouteWithSidebar.propTypes = {
  component: PropTypes,
};
