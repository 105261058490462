export const transactionalSenderIDList = [
  { label: `RDCLFF`, value: `RDCLFF` },
  { label: `URNCOM`, value: `URNCOM` },
  { label: `SHALRT`, value: `SHALRT` },
  { label: `PARDIS`, value: `PARDIS` },
  { label: `EQUINX`, value: `EQUINX` },
];

export const promotionalSenderIDList = [
  { label: `110011`, value: `110011` },
  { label: `602727`, value: `602727` },
  { label: `858567`, value: `858567` },
  { label: `649121`, value: `649121` },
  { label: `650022`, value: `650022` },
];
