import React from 'react'
import ErrorMessage from './ErrorMessage'
import PropTypes from 'prop-types';

const Error = ({ code }) => {
  
  return (
    <div id="error">
      <div className="error">
        <div className="error-bg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>oops!</h1>
        <ErrorMessage code={code} />
          <a href="#">go back</a>
        {/* <div className="notfound-social">
        <a href="#"><i className="fa fa-facebook"></i></a>_
          <a href="#"><i className="fa fa-twitter"></i></a>
          <a href="#"><i className="fa fa-pinterest"></i></a>
          <a href="#"><i className="fa fa-google-plus"></i></a>
        </div> */}
      </div>
    </div>
  )
}
Error.propTypes = {
  code: PropTypes,
};

export default Error;
