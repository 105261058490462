import {
  Form,
  FormGroup,
  Button,
  FormControl,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import Select from "react-select";
import plivo from "../../../assets/Aggregators/Plivo-logo.svg";
import { useHistory } from "react-router-dom";

export default () => {
  const [authID, setAuthID] = useState("MANGI3ZWFMYMQWMWM5YW");
  const [authToken, setAuthToken] = useState(
    "Y2VmMWNhMDk2YzJiNzEyZDRlZGY1YzhlMmQ2MzRl"
  );
  const [aggregatorImage, setAggregatorImage] = useState(plivo);
  //   const [src, setSrc] = useState("+917780292711");
  const [src, setSrc] = useState("57273251");
  const [url, setUrl] = useState(
    "https://lookup.plivo.com/v1/Number/{PhoneNumber}"
  );
  const [port, setPort] = useState("8080");
  const [method, setMethod] = useState({ label: "GET", value: "get" });
  const [messageDirection, setMessageDirection] = useState({
    label: "Outbound",
    value: "outbound",
  });
  const history = useHistory();

  const [description, setDescription] = useState("");

  const showDescription = (e) => {
    console.log(e.target.id);
    switch (e.target.id) {
      case "aggregatorImage":
        return setDescription("Upload an image of your aggregator service");
    }
  };

  const clearDescription = (e) => {
    setDescription("");
  };

  //     return <div className="d-flex justify-content-between gap-4">
  //      <Form style={{flexGrow:".75"}}>
  //     <FormGroup  className="my-3" onMouseEnter={(e)=>showDescription(e)} onMouseOut={()=>clearDescription()}>
  //     <Form.Label>
  //         Select the CSV file you would like to upload
  //     </Form.Label>
  //     <FormControl
  //         type="file"
  //         id="aggregatorImage"
  //     />
  //     </FormGroup>
  //     <Row>
  //         <Col xl={6}>
  //         <FormGroup>
  //     <div className="mb-3">
  //                 <Form.Label>Authentication ID:</Form.Label>
  //                 <FormControl
  //                     aria-label="With text"
  //                     placeholder="Type or paste your Auth ID here"
  //                     required
  //                     value={authID}
  //                     onChange={e => setAuthID(e.target.value)}
  //                 />

  //     </div>
  //         </FormGroup>
  //         </Col>
  //         <Col xl={6}>
  //         <FormGroup>
  //        <div className="mb-3">
  //                     <Form.Label>Authentication Token:</Form.Label>
  //                     <FormControl
  //                         aria-label="With text"
  //                         placeholder="Type or paste your Auth Token here"
  //                         required
  //                         value={authToken}
  //                         onChange={e => setAuthToken(e.target.value)}
  //                     />

  //         </div>
  //         </FormGroup>
  //         </Col>
  //     </Row>

  //    <Row>
  //     <Col xl={6}>
  //     <FormGroup>
  //        <div className="mb-3">
  //                     <Form.Label>Plivo Source:</Form.Label>
  //                     <FormControl
  //                         aria-label="With text"
  //                         placeholder="Type or paste Plivo source here"
  //                         required
  //                         value={src}
  //                         onChange={e => setSrc(e.target.value)}
  //                     />

  //         </div>
  //         </FormGroup>
  //     </Col>
  //     <Col xl={6}>
  //     <FormGroup>
  //        <div className="mb-3">
  //                     <Form.Label>URL:</Form.Label>
  //                     <FormControl
  //                         aria-label="With text"
  //                         placeholder="Type or paste your url here"
  //                         required
  //                         value={url}
  //                         onChange={e => setPort(e.target.value)}
  //                     />

  //         </div>
  //         </FormGroup>
  //     </Col>
  //    </Row>
  //    <Row>
  //     <Col xl={6}>
  //     <FormGroup>
  //                     <div className="mb-3">
  //                         <Form.Label>HTTP Method</Form.Label>
  //                         <Select
  //                             options={[{label:"POST",value:"post"},{label:"GET",value:"get"}]}
  //                             placeholder="Select HTTP Method from list"
  //                             value={method}
  //                             onChange={e => setMethod(e)}
  //                         />
  //                     </div>
  //         </FormGroup>
  //     </Col>
  //     <Col xl={6}>
  //     <FormGroup>
  //                     <div className="mb-3">
  //                         <Form.Label>Message Direction</Form.Label>
  //                         <Select
  //                             options={[{label:"Inbound",value:"inbound"},{label:"Outbound",value:"outbound"}]}
  //                             placeholder="Select message direction from list"
  //                             value={messageDirection}
  //                             onChange={e => setMessageDirection(e)}
  //                         />
  //                     </div>
  //         </FormGroup>
  //     </Col>
  //    </Row>

  //         <div className="d-flex justify-content-between mt-2 border-top pt-2">
  //         <Button type="submit" onClick={()=>history.push("/aggregators/sms")}>Back</Button>
  //         <Button type="submit">Save Changes</Button>
  //         </div>
  //     </Form>
  //     <div className=" rounded p-2 red-border" style={{flexGrow:".25", flexWrap:"wrap", minHeight:"100%", maxWidth:"450px"}}>
  //         <p style={{color:"gray"}}>Hover on any item to see its details</p>
  //         <h6 style={{color:"gray"}}>{description}</h6>
  //     </div>
  //     </div>
  return (
    <Form>
      <FormGroup className="my-3">
        <Form.Label>Upload an image for you aggregator</Form.Label>
        <FormControl
          type="file"
          id="aggregatorImage"
          style={{ width: "fit-content" }}
        />
      </FormGroup>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Authentication ID:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste your Auth ID here"
                required
                value={authID}
                onChange={(e) => setAuthID(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Authentication Token:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste your Auth Token here"
                required
                value={authToken}
                onChange={(e) => setAuthToken(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Sender ID:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste Plivo source here"
                required
                value={src}
                onChange={(e) => setSrc(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Port:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste your port number here"
                required
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>HTTP Method</Form.Label>
              <Select
                options={[
                  { label: "POST", value: "post" },
                  { label: "GET", value: "get" },
                ]}
                placeholder="Select HTTP Method from list"
                value={method}
                onChange={(e) => setMethod(e)}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Message Direction</Form.Label>
              <Select
                options={[
                  { label: "Inbound", value: "inbound" },
                  { label: "Outbound", value: "outbound" },
                ]}
                placeholder="Select message direction from list"
                value={messageDirection}
                onChange={(e) => setMessageDirection(e)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>URL:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste your Auth ID here"
                required
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
