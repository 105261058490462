import {
  Button,
  Card,
  Modal
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPageTitle } from "../../Redux/Reducers/pageTitleReducer";
import delete_icon from "../../assets/delete.png";
// import SMSAggregatorPlivoFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorPlivoFormData";
// import SMSAggregatorExotelFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorExotelFormData";
// import SMSAggregatorSMSHorizonFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorSMSHorizonFormData";
// import SMSAggregatorKnowlarityFormData from "../../DummyData/AggregatorData/SMSAggregatorData/SMSAggregatorKnowlarityFormData";
import { useHistory } from "react-router-dom";
import FlashAggregatorFormData from "../../DummyData/AggregatorData/FlashAggregatorData/FlashAggregatorFormData";
import CreateFlashAggregaror from "../Forms/CreateFlashAggregaror";

const FlashAggregator = () => {
  const history = useHistory();

  const params = useParams();
  const aggregatorName = params.aggregator;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  useEffect(() => {
    aggregatorName === "add-Aggregator"
      ? dispatch(getPageTitle("Add new Flash Aggregator"))
      : dispatch(
          getPageTitle(
            aggregatorName.charAt(0).toUpperCase() +
              aggregatorName.slice(1) +
              " Settings"
          )
        );
  }, [aggregatorName, dispatch]);

  const getAggregatorFormData = () => {
    switch (aggregatorName) {
      case "Messente":
        return <FlashAggregatorFormData />;
      case "Fast2SMS":
        return <FlashAggregatorFormData />;
      case "ToucanText":
        return <FlashAggregatorFormData />;
      default:
        return <CreateFlashAggregaror />;
    }
  };

  return (
    <>
      <div className="py-1">
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Aggregator</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this aggregator?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Yes
            </Button>
            <Button variant="primary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            {aggregatorName !== "add-Aggregator" && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-3">
                  <img
                    src="https://e7.pngegg.com/pngimages/727/475/png-clipart-computer-icons-back-icon-cdr-angle.png"
                    className="cursor-pointer"
                    style={{ width: "35px", aspectRatio: "1" }}
                    onClick={() => history.push("/aggregators/flash")}
                  />
                  <h5>Configure your aggregator settings</h5>
                </div>
                <div
                  className="d-flex gap-2 border p-2 rounded text-danger cursor-pointer"
                  style={{ fontWeight: "700" }}
                  onClick={handleShow}
                >
                  <img
                    src={delete_icon}
                    alt="delete icon"
                    style={{ width: "15px", height: "20px" }}
                  />
                  Delete
                </div>
              </div>
            )}
            {getAggregatorFormData()}
            <div className="d-flex gap-2 mt-2 border-top pt-2">
              <Button type="submit">
                {aggregatorName === "add-Aggregator"
                  ? "Create Aggregator"
                  : "Save Changes"}
              </Button>
              {aggregatorName === "add-Aggregator" && (
                <Button
                  type="submit"
                  onClick={() => history.push("/aggregators/flash")}
                >
                  Cancel
                </Button>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default FlashAggregator;