const getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours('00');
    yesterday.setMinutes('00');
    yesterday.setSeconds('00');
    return yesterday;
}

const getLastMonthDate = () => {
    const lastMonth = new Date();
    lastMonth.setDate(lastMonth.getDate() - 30);
    lastMonth.setHours('00');
    lastMonth.setMinutes('00');
    lastMonth.setSeconds('00');
    return lastMonth;
}

const getDaysDate = (day) => {
    const daysDate = new Date();
    daysDate.setDate(daysDate.getDate() - day);
    daysDate.setHours('00');
    daysDate.setMinutes('00');
    daysDate.setSeconds('00');
    return daysDate;
}

const getHoursDate = (hours) => {
    let currentDateObj = new Date();
    let numberOfMlSeconds = currentDateObj.getTime();
    let addMlSeconds = hours * 60 * 60 * 1000;
    let newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
    return newDateObj;
}

const getCurrentDate = () => {
    return new Date();
}

const convertAgGridTimeToLocalTime = (filterModel, parentFromDate, parentToDate) => {
    try {
        for (let i = 0; i < Object.entries(filterModel).length; i++) {

            //IF NO CONDITIONS(AND/OR)
            if (Object.values(filterModel)[i].dateFrom) {
                let childFromDate = new Date(Object.values(filterModel)[i].dateFrom);
                // console.log({ parentFromDate }, parentFromDate.getTime());
                // console.log({ childFromDate }, childFromDate.getTime());

                if (childFromDate.getTime() < parentFromDate.getTime()) {
                    alert("Date out of scope, try again");
                    return;
                }

                childFromDate.setHours("00");
                childFromDate.setMinutes("00");
                childFromDate.setSeconds("00");
                Object.values(filterModel)[i].dateFrom = childFromDate.toISOString();
            }

            if (Object.values(filterModel)[i].dateTo) {
                let childToDate = new Date(Object.values(filterModel)[i].dateTo);
                if (childToDate.getTime() > parentToDate.getTime()) {
                    alert("Date out of scope, try again");
                    return;
                }
                childToDate.setHours("11");
                childToDate.setMinutes("59");
                childToDate.setSeconds("59");
                Object.values(filterModel)[i].dateTo = childToDate.toISOString();
            }

            continue;

            // //CONDITION 1 FROM:
            // if (Object.values(filterModel)[i].condition1.dateFrom) {
            //     let fromDate = new Date(Object.values(filterModel)[i].condition1.dateFrom);
            //     let toDate = new Date(Object.values(filterModel)[i].condition1.dateFrom);
            //     fromDate.setHours("00");
            //     fromDate.setMinutes("00");
            //     fromDate.setSeconds("00");
            //     Object.values(filterModel)[i].condition1.dateFrom = fromDate.toISOString();
            // }
            // //CONDITION 1 TO:
            // if (Object.values(filterModel)[i].condition1.dateTo) {
            //     let toDate = new Date(Object.values(filterModel)[i].condition1.dateTo);
            //     toDate.setHours("23");
            //     toDate.setMinutes("59");
            //     toDate.setSeconds("59");
            //     Object.values(filterModel)[i].condition1.dateTo = toDate.toISOString();
            // }

            // //CONDITION 2 FROM:
            // if (Object.values(filterModel)[i].condition2.dateFrom) {

            //     let date = new Date(Object.values(filterModel)[i].condition2.dateFrom);
            //     date.setHours("23");
            //     date.setMinutes("59");
            //     date.setSeconds("59");
            //     Object.values(filterModel)[i].condition2.dateFrom = date.toISOString();
            // }
            // //CONDITION 2 TO:
            // if (Object.values(filterModel)[i].condition2.dateTo) {
            //     let toDate = new Date(Object.values(filterModel)[i].condition2.dateTo);
            //     toDate.setHours("23");
            //     toDate.setMinutes("59");
            //     toDate.setSeconds("59");
            //     Object.values(filterModel)[i].condition2.dateTo = toDate.toISOString();
            // }


            // if (Object.keys[i].dateTo) {
            //     Object.keys[i].dateTo = new Date(Object.keys[i].dateTo)
            // }
        }
    } catch (error) {
        console.log(error)
    }
}


export {
    getYesterdayDate,
    getCurrentDate,
    getHoursDate,
    getDaysDate,
    getLastMonthDate,
    convertAgGridTimeToLocalTime
};