import { faEllipsisV, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../Redux/Reducers/alertsReducers";
import DeleteEntity from "../../components/Buttons/DeleteEntity";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { entities } from "../../util/Entities";
import { removePlusPrefix } from "../../util/RemovedPlusPrefixFromString";
import { getLastMonthDate } from "../../util/dateUtil";
import DownloadCSV from "../Buttons/DownloadCSV";
import EditEntity from "../Buttons/EditEntity";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
import DeleteModal from "../Modals/Common/deleteModal";
import MUIRecipientDetailsModal from "../Modals/MUIModals/MUIRecipientDetailsModal";
import MUIUpdateRecipientDrawer from "../Modals/MUIModals/MUIUpdateRecipientModal";
import UploadCSVModal from "../Modals/UploadCSV/UploadCSVModal";
import CreateUserModal from "../Modals/UsersModal/CreateUserModal";
import EntityModal from "../Modals/entityDetailModal";

const UsersGrid = ({ tableData, token, memberRole, userEmail }) => {
  const dispatch = useDispatch();
  const axiosPrivate = CreateAxiosInstance();
  const fromDate = getLastMonthDate();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [singleData, setSingleData] = useState([]);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const paginationPageSize = 10;
  const [showDetails, setShowDetails] = useState(false);
  const [agGridData, setAgGridData] = useState();
  const [showAgGrid, setShowAgGrid] = useState(true);
  const [showRecipientDetailsDrawer, setShowRecipientDetailsDrawer] =
    useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [showUpdatRecipientDrawer, setShowUpdatRecipientDrawer] =
    useState(false);

  const [refreshGrid, setRefreshGrid] = useState(false);

  const colDefs = [
    {
      headerName: "GROUP",
      field: "approvalRequestsCount",
      flex: 2,
      maxWidth: 50,
      valueFormatter: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        }
      },
      cellRendererSelector: (params) => {
        if (params.data.approvalRequestsCount <= 0) {
          return "";
        } else {
          return {
            component: "agGroupCellRenderer",
          };
        }
      },
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "First name",
      field: "firstName",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Last name",
      field: "lastName",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Status",
      cellRenderer: "renderStatus",
      flex: 2,
      style: { borderLeft: "1px solid rgba(233,233,233,1)" },
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Email",
      field: "email",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Phone number",
      field: "phoneNumber",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Capabilities",
      field: "capabilities",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Allowed notifications",
      field: "allowedNotifications",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "CREATED BY",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];

  const detailCellRendererParams = {
    // provide the Grid Options to use on the Detail Grid
    detailGridOptions: {
        columnDefs: [
            { field: 'firstName', headerName: "FIRST NAME", flex: 1 },
            { field: 'lastName', headerName: "LAST NAME", flex: 1 },
            { field: 'email', headerName: "EMAIL", flex: 1},
            // { field: "lineChatUserId", headerName: "LINE CHAT USER ID"},
            { field: "phoneNumber", headerName: "PHONE NUMBER", flex: 1},
            { field: "approvalOperation", headerName: "OPERATION", flex: 1},
            {
              headerName: "Status",
              cellRendererFramework: (params) => (
                <StatusTypes {...params} />
              ),
              flex: 2,
              filter: "agTextColumnFilter",
              filterParams: { suppressAndOrCondition: true },
            },
            {
              field: "action",
              headerName: "ACTION", flex: 1,
              cellRendererFramework: (params) => (
                <ActionButtonsSubGrid {...params} />
              ),
          }
        ],
        domLayout: 'autoWidth',
    },
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      display: "flex",
      flex:1
    },
    getDetailRowData: async params => {
      await axiosPrivate
        .post(
          process.env.REACT_APP_USERS,
          {
            startRow: 0,
            endRow: 100,
            sortModel: [],
            filterModel: {},
            "approvalDocumentType": "REQUEST",
            "approvalEntityId": params.data.id

          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // const resp = response?.data?.data;
            params.successCallback(response.data.data.userDetails, parseInt(1)); //filling ag grid with content
        })
        .catch((error) => {
          console.error(error);
          // params.failCallback();
        });
      // params.successCallback(gridData,gridDataLength);
  }

};
const ActionButtonsSubGrid = (params) => {
  if((params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") || params.data.approvalStatus === "APPROVED" || params.data.approvalStatus === "REJECTED"){
    return (
      <div className="">
        <div className="action-btn" >
  
        </div>

      </div>
    )
  }
  if(params.data.approvalStatus === "PENDING" && memberRole === "TS_ADMIN"){
    return (
      <div className="d-flex gap-3">
        <div className="action-btn" onClick={()=>{
          // handleApproveRequest()
          // setEditModalShow(true)
          // setHandleRequest("APPROVED")
          getUpdateData(params.data)
        }}>
          <FontAwesomeIcon icon={faCheck}/>
        </div>
        <div className="action-btn" onClick={()=>{
          // handleApproveRequest()
          // setEditModalShow(true);
          // setHandleRequest("REJECTION")
          getUpdateData(params.data)
        }}>
          <FontAwesomeIcon icon={faTimes}/>
        </div>
      </div>
    )
  }
};

  // Setup the column for status by adding colors
  const StatusTypes = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p
          style={{ fontSize: ".9rem", fontWeight: "600" }}
          className={`${getStatusType(params.data.approvalStatus)} capitalize`}
        >
          {params.data.approvalStatus?.toLowerCase()}
        </p>
      </div>
    );
  };
  const getStatusType = (type) => {
    switch (type) {
      case "APPROVED":
        return "recipient_status_approved";
      case "PENDING":
        return "recipient_status_pending";
      case "REJECTED":
        return "recipient_status_rejected";
    }
  };

  const ViewAuditLogs = (params) => {
    const handleAgGridData = async () => {
      let payload = {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {
          entityId: {
            filterType: "text",
            type: "contains",
            filter: params.data.id,
          },
        },
      };
      await axiosPrivate
        .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            from: fromDate,
            to: new Date(),
            Timezone: moment().utcOffset(),
          },
        })
        .then((response) => {
          const resp = response?.data?.data;
          setShowDetails(true);
          setAgGridData(resp.auditLogs);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <div className="d-flex gap-3">
        <div
          className="action-btn"
          onClick={() => {
            handleAgGridData();
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };

 

  const dataSource = {
    async getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
      await axiosPrivate
        .post(
          process.env.REACT_APP_USERS,
          {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            approvalDocumentType: "ENTITY",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          params.successCallback(resp.userDetails, resp.userDetails.length); //filling ag grid with content
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },
  };
  colDefs.push({
    headerName: "Actions",
    cellRenderer: "renderActionButtons",
    headerClass: "cell-border-left",
  });
  const columnDefs = colDefs;

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const dimensions = { width: "100%" };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    [],
  );
  const getUpdateData = async (data) => {
    console.log("dckn")
    await axiosPrivate
      .post(
        process.env.REACT_APP_USERS,
        {
          startRow: 0,
          endRow: 100,
          sortModel: [],
          filterModel: {},
          "approvalDocumentType": "REQUEST",
          "approvalEntityId": data.approvalEntityId !== null ? data.approvalEntityId : data.id

        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response,"resp")
        setSelectedRowData(response.data.data.userDetails[0]);
        setShowRecipientDetailsDrawer(true)

      })
      .catch((error) => {
        console.error(error);
        // params.failCallback();
      });
    // params.successCallback(gridData,gridDataLength);
}

  // custom action components for the action field in ag grid
  const ActionButtons = (params) => {
    const handleClick = () => {
      setSingleData(params.data);
      setSelectedRowData(params.data);
    };
    if (params.data.approvalStatus === "PENDING" && memberRole === "TS_USER") {
      return <div></div>
    }
    if(params.data.approvalStatus === "PENDING") {
      return (
        <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
         
        }}
      >
       <div className="action-btn">
       <FontAwesomeIcon icon={faCheck} style={{height: "14px", width: "14px"}}
          onClick={()=>{
            getUpdateData(params.data)}}
        />
       </div>
        
       <div className="action-btn">
       <FontAwesomeIcon icon={faTimes} style={{height: "14px", width: "14px"}}
          onClick={()=>{getUpdateData(params.data)}}
        />
       </div>
        { params.data.approvalOperation !== "CREATE" && <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />}
      </div>
      )
    }

    return (
      <div
        className="d-flex gap-3"
        style={{
          borderLeft: "1px solid rgba(233,233,233,1)",
          paddingInlineStart: "10px",
        }}
      >
        <EditEntity
          onClick={() => {
            handleClick();
            setShowUpdatRecipientDrawer(true);
          }}
        />

        <DeleteEntity
          onClick={() => {
            handleClick();
            setDeleteModalShow(true);
          }}
        />
      </div>
    );
  };

  const frameworkComponents = {
    renderActionButtons: ActionButtons,
    renderViewAuditLogs: ViewAuditLogs,
    renderStatus: StatusTypes,
  };

  // --------------------------------------------- Custom csv download ------------------------------------------------------------------------------------------

  const onBtnExport = useCallback(async () => {
    // gridRef.current.api.exportDataAsCsv();
    await axiosPrivate
      .post(
        `${process.env.REACT_APP_DOWNLOAD_CSV}?email=${userEmail}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        console.log({ response });
        dispatch(
          toggleAlert({
            title: "Success!",
            message: "CSV download request raised successfully",
          }),
        );
      })
      .catch((error) => {
        console.error({ error });
        dispatch(
          toggleFailAlert({
            title: "Failed!",
            message: error.response.data.message,
          }),
        );
      });
  }, [axiosPrivate, dispatch, token, userEmail]);

  const onCellClicked = (event) => {
    if(event.data.approvalStatus === "PENDING" && event.data.approvalOperation === "UPDATE" && !(event.colDef.headerName === "Actions") ){
      getUpdateData(event.data)
      return;
    }
    // If action buttons are clicked then don't show the drawer.
    if (event.colDef.headerName !== "Actions" && !showRecipientDetailsDrawer) {
      setSelectedRowData(event.data);
      setShowRecipientDetailsDrawer(true);
    }
  };

  const refreshCache = useCallback(
    (searchdata) => {
      const newDataSource = {
        async getRows(params) {
          const { startRow, endRow, filterModel, sortModel } = params.request;
          await axiosPrivate
            .post(
              process.env.REACT_APP_USERS,
              {
                startRow: startRow,
                endRow: endRow,
                sortModel: sortModel,
                filterModel: filterModel,
                approvalDocumentType: "ENTITY",
                globalSearch: removePlusPrefix(searchdata),
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then((response) => {
              const resp = response?.data?.data;
              if(!resp){
                params.successCallback([], 0);
              params.api.showNoRowsOverlay();

              }
              else{
                params.successCallback(resp.userDetails, resp.userDetails.length); //filling ag grid with content
              }
            })
            .catch((error) => {
              console.error(error);
              params.failCallback();
            });
        },
      };
      gridRef.current?.api?.setServerSideDatasource(newDataSource);
    },
    [axiosPrivate, token],
  );

  useEffect(() => {
    if (gridRef?.current?.api) {
      refreshCache("");
    }
  }, [refreshGrid, refreshCache]);

  // update ag grid data for global search operation
  const refreshGridAfterSearch = (searchData) => {
    if (!gridRef.current?.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  return (
    <>
      <div>
        {showRecipientDetailsDrawer && (
          <MUIRecipientDetailsModal
            openDrawer={showRecipientDetailsDrawer}
            setOpenDrawer={setShowRecipientDetailsDrawer}
            entityDetails={selectedRowData}
            token={token}
            setRefreshGrid={setRefreshGrid}
          />
        )}
      </div>

      <div className="d-flex justify-content-end mb-2 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <GlobalSearch refreshGrid={refreshGridAfterSearch} />
          {tableData && (
            <>
              <DownloadCSV onClick={onBtnExport} />
            </>
          )}
          {memberRole === "TS_ADMIN" && (
            <UploadCSVModal
              token={token}
              title={entities.RECIPIENT}
              setRowData={setRowData}
              setShowAgGrid={setShowAgGrid}
            />
          )}
          <CreateUserModal
            token={token}
            setShowAgGrid={setShowAgGrid}
            memberRole={memberRole}
            setRefreshGrid={setRefreshGrid}
          />
        </div>
      </div>
      {showAgGrid ? (
        <div className="user-ag-grid">
          <div
            id="recipients-details-grid"
            className="ag-theme-alpine pt-3 shadow-4"
            style={dimensions}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={paginationPageSize}
              domLayout="autoHeight" // if too many rows should not use this, give fixed height
              animateRows={true}
              rowModelType="serverSide"
              serverSideInfiniteScroll={true}
              masterDetail={true}
              detailCellRendererParams={detailCellRendererParams}
              detailRowAutoHeight={true}
              onGridReady={onGridReady}
              overlayNoRowsTemplate="No data to show"
              components={frameworkComponents}
              onCellClicked={onCellClicked}
              // pointer hover class: _recipient_details.scss line:35
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {showUpdatRecipientDrawer && (
        <MUIUpdateRecipientDrawer
          openDrawer={showUpdatRecipientDrawer}
          setOpenDrawer={setShowUpdatRecipientDrawer}
          setShowAgGrid={setShowAgGrid}
          setRefreshGrid={setRefreshGrid}
          entityDetails={selectedRowData}
          allowedNotifications={selectedRowData.allowedNotifications}
        />
      )}
      {deleteModalShow && (
        <DeleteModal
          modalShow={deleteModalShow}
          setModalShow={setDeleteModalShow}
          type="Recipient"
          url={`${process.env.REACT_APP_USERS}/${singleData.id}`}
          entity={singleData.firstName + " " + singleData.lastName}
          memberRole={memberRole}
          setShowAgGrid={setShowAgGrid}
        />
      )}
      {showDetails && (
        <EntityModal
          modalShow={showDetails}
          setModalShow={setShowDetails}
          data={agGridData}
        />
      )}
    </>
  );
};
UsersGrid.propTypes = {
  tableData: PropTypes.array,
  token: PropTypes.string,
  memberRole: PropTypes.string,
  userEmail: PropTypes.string,
};
export default UsersGrid;
