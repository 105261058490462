import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
// import { Button } from "@themesberg/react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
// import download from "../../assets/download.png";
import "ag-grid-enterprise";
import moment from "moment";
import { convertAgGridTimeToLocalTime } from "../../util/dateUtil";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import PropTypes from "prop-types";
import GlobalSearch from "../GlobalSearch/GlobalSearch";
const AuditLogsGrid = ({ heading, fromDate, toDate }) => {
  // User token to pass for api requests
  console.log(heading, "heading");
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState(null);
  let totalRecords = useRef(0);
  const paginationPageSize = 15;
  const cacheBlockSize = 15;
  const axiosPrivate = CreateAxiosInstance();

  //START OF AG GRID CONFIGURATIONS
  /*
      Three types of column filter: agTextColumnFilter, agNumberColumnFilter & agDateColumnFilter
       */
  const colDefs = [
    {
      headerName: "Created on",
      field: "timestamp",
      flex: 1,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["lessThan", "greaterThan", "inRange"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Entity",
      field: "entity",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Entity name",
      field: "entityName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Entity id",
      field: "entityId",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Action by roles",
      field: "actionByRole",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Service name",
      field: "serviceName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Message",
      field: "message",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Operation",
      cellRenderer: "renderActionTypes",
      field: "operation",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },

    {
      headerName: "Action by id",
      field: "actionById",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Action by name",
      field: "actionByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "State change message",
      field: "stateChangeMessage",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];
  const actionTypes = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center mt-2">
        <p
          style={{ fontSize: ".9rem", fontWeight: "600" }}
          className={getActionTypeClass(params.data.operation)}
        >
          {params.data.operation}
        </p>
      </div>
    );
  };
  const getActionTypeClass = (type) => {
    console.log({ type });
    switch (type) {
      case "READ":
        return "text-gray";
      case "CREATE":
        return "text-success";
      case "UPDATE":
        return "text-warning";
      case "DELETE":
        return "text-danger";
    }
  };
  const frameworkComponents = {
    renderActionTypes: actionTypes,
  };

  const [columnDefs] = useState(colDefs);
  const dataSource = useMemo(() => {
    return {
      async getRows(params) {
        const { startRow, endRow, filterModel, sortModel } = params.request;
        if (Object.keys(filterModel).length > 0) {
          convertAgGridTimeToLocalTime(filterModel, fromDate, toDate);
        }
        let payload = {
          startRow: startRow,
          endRow: endRow,
          sortModel: sortModel,
          filterModel: filterModel,
        };

        await axiosPrivate
          .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
            headers: {
              Authorization: `Bearer ${token}`,
              from: fromDate,
              to: toDate,
              Timezone: moment().utcOffset(),
            },
          })
          .then((response) => {
            const resp = response?.data?.data;
            if (!resp?.auditLogs?.length) {
              params.successCallback([], 0);
              params.api.showNoRowsOverlay();
            } else {
              params.successCallback(resp.auditLogs, parseInt(resp.totalLogs));
            }

            //For CSV download button setting the totalRows:
            totalRecords.current = resp.totalLogs;
          })
          .catch((error) => {
            console.error(error);
            params.failCallback();
          });
      },
    };
  }, [fromDate, toDate, token, axiosPrivate]);

  //change grid data according to filters used
  useEffect(() => {
    rowData && rowData.setServerSideDatasource(dataSource);
    // eslint-disable-next-line
  }, [fromDate, toDate, dataSource]);

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,

      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    [],
  );

  // const onBtnExport = useCallback(async () => {
  //   gridRef.current.api.exportDataAsCsv(); //After download is complete, re-populate the ag grid data with current parameters
  // }, []);

  const refreshCache = useCallback(
    (searchdata) => {
      const newDataSource = {
        async getRows(params) {
          const { startRow, endRow, filterModel, sortModel } = params.request;
          if (Object.keys(filterModel).length > 0) {
            convertAgGridTimeToLocalTime(filterModel, fromDate, toDate);
          }
          let payload = {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            globalSearch: searchdata,
          };

          await axiosPrivate
            .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
              headers: {
                Authorization: `Bearer ${token}`,
                from: fromDate,
                to: toDate,
                Timezone: moment().utcOffset(),
              },
            })
            .then((response) => {
              const resp = response?.data?.data;
              if (!resp?.auditLogs?.length) {
                params.successCallback([], 0);
                params.api.showNoRowsOverlay();
              } else {
                params.successCallback(
                  resp.auditLogs,
                  parseInt(resp.totalLogs),
                );
              }

              //For CSV download button setting the totalRows:
              totalRecords.current = resp.totalLogs;
            })
            .catch((error) => {
              console.error(error);
              params.failCallback();
            });
        },
      };

      gridRef.current.api.setServerSideDatasource(newDataSource);
    },
    [axiosPrivate, token, fromDate, toDate],
  );

  const dimensions = { width: "100%" };

  const refreshGrid = (searchData) => {
    if (!gridRef.current.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
          <GlobalSearch refreshGrid={refreshGrid} />
        </div>
        <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={paginationPageSize}
            cacheBlockSize={cacheBlockSize}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="No data to show"
            components={frameworkComponents}
          />
        </div>
      </div>
    </>
  );
};
AuditLogsGrid.propTypes = {
  heading: PropTypes,
  fromDate: PropTypes,
  toDate: PropTypes,
};
export default AuditLogsGrid;
