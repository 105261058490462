import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getPageTitle } from "../../Redux/Reducers/pageTitleReducer";
import reset_icon from "../../assets/Dashboard/reset.svg";
import DownloadCSV from "../../components/Buttons/DownloadCSV";
import RTSPieChart from "../../components/Charts/RTSPieChart";
import CustomDivider from "../../components/CustomDivider/CustomDivider";
import Error from "../../components/Error/Error";
import MessageHealthStatusGrid from "../../components/Grids/MessageHealthStatusGrid";
import MISFilterModal from "../../components/Modals/MISFilterModal";
import { MessageHealthStatusHeader } from "../../data/MISDashboardData";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import AggregatorTab from "../../pages/dashboard/AggregatorPage";
import MessageHealthTab from "../../pages/dashboard/MessageHealthPage";
import { reactSelectStyles } from "../../util/ReactSelectStyle";
import {
  getCurrentDate,
  getDaysDate,
  getHoursDate,
  getLastMonthDate,
} from "../../util/dateUtil";
import { getPieChartSliceColors } from "../../util/pieChartSliceColors";
import { getPieChartSliceData } from "../../util/pieChartSliceData";
import RealTimeStats from "../components/MISComponents/RealTimeStatsBadges";
import DownloadPdfPage from "./DownloadPdfPage";

const RTS = () => {
  const [fromDate, setFromDate] = useState(getLastMonthDate());
  const [toDate, setToDate] = useState(new Date());
  const [time, setTime] = useState(null);
  const [currentTab, setCurrentTab] = useState("realTime");
  const axiosPrivate = CreateAxiosInstance();
  //states for filter by date range
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const dispatch = useDispatch();
  const MHSRef = useRef();
  // states to store filtered data
  const [RTSData, setRTSData] = useState({});
  const [pieSlice, setPieSliceValue] = useState("");
  const [errorCode, setErrorCode] = useState("");

  // boolean states
  const [RTSDataLoading, setRTSDataLoading] = useState(true);
  const [dataFetchError, setDataFetchError] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState(["Real Time Stats"]);
  const [pdfDisplay, setPdfDisplay] = useState(false);
  const [deliveryFailedData, setDeliveryFailedData] = useState([]);
  const deliveryFailed = useRef(null);
  //api for getting data for Real Time Stats
  const getRTSData = useCallback(async () => {
    let payload;
    if (deliveryFailed.current) {
      payload = {
        filterBy: pieSlice,
        leaf: "true",
        failureReason: "true",
      };
    } else {
      payload = {
        filterBy: pieSlice,
        leaf: "true",
      };
    }
    setRTSDataLoading(true);
    try {
      const response = await axiosPrivate.post(
        `${
          process.env.REACT_APP_PROVIDER_LEVEL_DATA
        }?notification-type=${"SMS"}&&page=realtime`,
        payload,
        {
          headers: {
            From: fromDate.toISOString(),
            To: toDate.toISOString(),
          },
        },
      );
      if (response?.data?.status) {
        setRTSData(response?.data?.data);
        setRTSDataLoading(false);
        if (pieSlice === "Delivery Failed") {
          setDeliveryFailedData(response.data.data.data);
        }
      }
    } catch (error) {
      console.error(error);
      setErrorCode(error?.response?.status);
      setRTSDataLoading(false);
      setDataFetchError(true);
    }
  }, [fromDate, toDate, pieSlice, axiosPrivate]);

  useEffect(() => {
    // Handle logic after deliveryFailedData has been updated
    let foundData = false;
    if (deliveryFailedData.length > 0) {
      deliveryFailedData.forEach((item) => {
        if (item.k === pieSlice) {
          foundData = true;
        }
      });
    }
    deliveryFailed.current = foundData;

    // If pieSlice does not match, set deliveryFailed to false
    if (!foundData) {
      deliveryFailed.current = false;
    }
  }, [deliveryFailedData, pieSlice]);

  const getPieSliceValue = (value) => {
    if (value === "Plivo Sent") {
      setPieSliceValue("PLIVO_SENT");
    } else if (value === "Validated Messages") {
      setPieSliceValue("VALIDATED");
    } else if (value === "Dispatched Messages") {
      setPieSliceValue("DISPATCHED");
    } else if (value === "Plivo Queued") {
      setPieSliceValue("PLIVO_QUEUED");
    } else if (value === "Built Messages") {
      setPieSliceValue("BUILT");
    } else if (value === "Plivo Requested") {
      setPieSliceValue("PLIVO_REQUESTED");
    } else {
      setPieSliceValue(value);
    }
  };

  useEffect(() => {
    getRTSData();
  }, [getRTSData]);

  //For Resetting Data
  const resetData = () => {
    setFromDate(getLastMonthDate());
    setToDate(getCurrentDate());
    setTime("");
    setPieSliceValue("Real Time Stats");
    setBreadcrumbData(["Real Time Stats"]);
  };

  const downloadPdf = () => {
    setPdfDisplay(true);
  };
  //For api request with filter
  const handleSubmit = async (e, onHide) => {
    e.preventDefault();
    onHide();

    setPieSliceValue("");
    setTime(null);
  };

  const getTimeData = (time) => {
    switch (time) {
      case "hour":
        setFromDate(getHoursDate(1));
        setToDate(getCurrentDate());
        break;
      case "6hours":
        setFromDate(getHoursDate(6));
        setToDate(getCurrentDate());
        break;
      case "day":
        setFromDate(getDaysDate(1));
        setToDate(getCurrentDate());
        break;
      case "week":
        setFromDate(getDaysDate(7));
        setToDate(getCurrentDate());
        break;
      case "month":
        setFromDate(getDaysDate(30));
        setToDate(getCurrentDate());
        break;
      case "last2months":
        setFromDate(getDaysDate(60));
        setToDate(getCurrentDate());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    time;
  }, [time]);

  //Dispatch for page title
  useEffect(() => {
    dispatch(getPageTitle("SMS MIS"));
  }, [dispatch]);

  const pieChartColorData = getPieChartSliceColors(
    pieSlice,
    RTSData?.data,
    "SMS",
  );
  const pieChartData = {
    totalCount: RTSData?.totalMessagesCount || 0,
    data: getPieChartSliceData(pieSlice, RTSData?.data, "SMS"),
  };

  return dataFetchError ? (
    <div className="mb-3 shadow-sm">
      {dataFetchError && <Error code={errorCode} />}
    </div>
  ) : (
    <div className="mb-3 shadow-sm">
      {pdfDisplay ? (
        <>
          <DownloadPdfPage
            fromDate={fromDate}
            toDate={toDate}
            data={RTSData}
            getPieSliceValue={getPieSliceValue}
            pieSlice={pieSlice}
            pieChartData={pieChartData}
            breadcrumbData={breadcrumbData}
            setBreadcrumbData={setBreadcrumbData}
            pieColors={pieChartColorData}
            notificationType={"EMAIL"}
            realTimeStatsData={RTSData.data}
            onComplete={() => setPdfDisplay(!pdfDisplay)}
          />
        </>
      ) : (
        <></>
      )}
      <div className="my-3 dashboard-tabs-container">
        <div>
          {/* <Container> */}
          <Col xs={30} className="dashboard-tabs">
            <div>
              <div className="d-flex gap-4">
                <h4
                  onClick={() => setCurrentTab("realTime")}
                  className={` entry-tab ${
                    currentTab === "realTime"
                      ? "user-entry__active-tab "
                      : "user-entry__inactive-tab"
                  }`}
                >
                  Real time
                </h4>

                <h4
                  className={` entry-tab ${
                    currentTab === "aggregator"
                      ? "user-entry__active-tab "
                      : "user-entry__inactive-tab"
                  }`}
                  onClick={() => setCurrentTab("aggregator")}
                >
                  Aggregator
                </h4>
                <h4
                  className={`entry-tab ${
                    currentTab === "health"
                      ? "user-entry__active-tab "
                      : "user-entry__inactive-tab"
                  }`}
                  onClick={() => setCurrentTab("health")}
                >
                  SMS health
                </h4>
              </div>
            </div>
          </Col>
          {/* </Container> */}
        </div>
        {/* FILTER BY TIME */}
        <div className="d-flex gap-3 align-items-center">
          <Select
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={reactSelectStyles}
            placeholder="Filter By Time"
            value={time}
            onChange={(e) => {
              setTime({ label: e.label, value: e.value });
              getTimeData(e.value);
            }}
            options={[
              { label: "Last Hour", value: "hour" },
              { label: "Last 6 hours", value: "6hours" },
              { label: "Last Day", value: "day" },
              { label: "Last Week", value: "week" },
              { label: "Last Month", value: "month" },
              { label: "Last 2 Months", value: "last2months" },
            ]}
          />

          {/* FILTER BY DATE RANGE */}
          <MISFilterModal
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleSubmit={handleSubmit}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
          />
          <div
            onClick={() => resetData()}
            className="cursor-pointer"
            style={{ width: "50px", display: "flex", justifyContent: "center" }}
          >
            <img
              style={{ flexGrow: 1 }}
              src={reset_icon}
              alt="reset-button"
              // width={70}
              className="reset-btn"
            />
          </div>
        </div>
      </div>

      {/* END OF FILTERS */}

      {currentTab === "realTime" && (
        <div>
          <Card className="mb-3">
            {RTSData !== null ? (
              <div
                className="d-flex justify-content-between align-items-center mt-4"
                style={{ marginInline: "1.4rem" }}
              >
                <div
                  className="dashboard-heading"
                >
                  Real time stats
                  {/* <AgChartBreadcrumb
                    data={breadcrumbData}
                    setData={setBreadcrumbData}
                    getPieSliceValue={getPieSliceValue}
                    pieSlice={pieSlice}
                  /> */}
                </div>
                <div
                  data-html2canvas-ignore="true"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <DownloadCSV onClick={downloadPdf} btnText="Download PDF" />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-start gap-5" style={{marginInline:"1.4rem"}}>
              <div style={{ width: "45%" }}>
                {RTSDataLoading ? (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "450px",
                      width: "100%",
                    }}
                  >
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div style={{ height: "450px"}}>
                    <RTSPieChart
                      data={pieChartData}
                      MHSRef={MHSRef}
                      pieColors={pieChartColorData}
                      RTSDataLoading={RTSDataLoading}
                      getPieSliceValue={getPieSliceValue}
                      BreadcrumbData={breadcrumbData}
                      setBreadcrumbData={setBreadcrumbData}
                      pieSlice={pieSlice}
                    />
                  </div>
                )}
              </div>
              <div
                style={{ width: "40%", display: "flex", alignItems: "center" }}
              >
                <RealTimeStats
                  // isLoading={RTSDataLoading}
                  data={RTSData}
                  getPieSliceValue={getPieSliceValue}
                  pieSlice={pieSlice}
                  pieChartData={pieChartData}
                  breadcrumbData={breadcrumbData}
                  setBreadcrumbData={setBreadcrumbData}
                  pieColors={pieChartColorData}
                />
              </div>
            </div>
          </Card>

          <div style={{ marginInline: "1.4rem" }}>
            <CustomDivider />
          </div>

          {/* MESSAGE HEATLH STATS */}
          <Row className="mb-3" ref={MHSRef}>
            <Col xs={12} xl={12}>
              <Card>
                <Card.Body>
                  <MessageHealthStatusGrid
                    notificationType={"SMS"}
                    heading="Message Health Status"
                    tableHeading={MessageHealthStatusHeader}
                    fromDate={fromDate}
                    toDate={toDate}
                    pieSlice={pieSlice}
                    MHSRef={MHSRef}
                    deliveryFailed={deliveryFailed}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {currentTab === "aggregator" && (
        <AggregatorTab
          fromDate={fromDate}
          toDate={toDate}
          from={from}
          to={to}
          time={time}
        />
      )}
      {currentTab === "health" && (
        <MessageHealthTab
          fromDate={fromDate}
          toDate={toDate}
          from={from}
          to={to}
          time={time}
        />
      )}
    </div>
  );
};

export default RTS;
