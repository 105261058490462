import { Form, FormGroup, Button, FormControl, Row, Col } from "@themesberg/react-bootstrap";
import React, {useState} from "react";
import Select from "react-select";

export default ()=> {
    const [userName, setUsername] = useState("john@protonmail.com");
    const [password, setPassword] = useState("ijsiqsn17127@W2NFN2!!@@N#");
    const [maxSize, setMaxSize] = useState({label:"16",value:"16"});
    const [powerpack_uuid, setPowerpack_uuid] = useState("");
    const [url, setUrl] = useState("");
    const [method, setMethod] = useState("");
    const [messageDirection, setMessageDirection] = useState("");

    return  <Form >
     <FormGroup id="aggregatorImage" className="my-3" style={{width:"fit-content"}}>
    <Form.Label>
    Upload an image for you aggregator
    </Form.Label>
    <FormControl
        type="file"
    />
    </FormGroup>
   <Row>
    <Col xl={6}>
    <FormGroup>
    <div className="mb-3">
                <Form.Label>User name:</Form.Label>
                <FormControl
                    aria-label="With text"
                    placeholder="Type or paste your username here"
                    required
                    value={userName}
                    onChange={e => setUsername(e.target.value)}
                />

    </div>
        </FormGroup> 
  
    </Col>
    <Col xl={6}>
    <FormGroup>
       <div className="mb-3">
                    <Form.Label>Password:</Form.Label>
                    <FormControl
                        aria-label="With text"
                        placeholder="Type or paste your password here"
                        // type="password"
                        // as="text"
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />

        </div>
        </FormGroup>
    </Col>
   </Row>
   <Row>
    <Col xl={61}>

    </Col>
   </Row>

        <FormGroup>
                    <div className="mb-3">
                        <Form.Label>Email Maximum Size (KB):</Form.Label>
                        <Select
                            options={[
                                {label:"16",value:"16"},
                               {label:"32",value:"32"},
                               {label:"128",value:"128"},
                               {label:"512",value:"512"},
                               {label:"1024",value:"1024"},
                        ]}
                            placeholder="Select message direction from list"
                            value={maxSize}
                            onChange={e => setMaxSize(e)}
                        />
                    </div>
        </FormGroup>
        
        <div className="d-flex justify-content-end">

        {/* <Button style={{marginInlineStart:"auto"}} type="submit">Save Changes</Button> */}
        </div>
    </Form>
}