import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import validate from "../../FormValidation/validation";
import ShowError from "../../FormValidation/ShowError";
import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import UpdateTemplate from '../MUIModals/MUIUpdateEmailTemplateModal';

function UpdateMessageTemplateModal(props) {
  const [templateName, setTemplateName] = useState(props.data.templateName);
  const [message, setMessage] = useState(props.data.emailContent);
  const [messageType, setMessageType] = useState(props.data.messageType);
  const [messagePriority, setMessagePriority] = useState(props.data.messagePriority);
  const [emailSubject, setEmailSubject]=useState(props.data.subject)
  const [emailContent, setEmailContent]=useState(props.data.emailContent);
  const [notificationTypeList, setNotificationTypeList] = useState(props.data.messageType);
  // For validation
  const [templateNameError, setTemplateNameError] = useState('');
  const [messageTypeError, setMessageTypeError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    populateForm(props.data);
  }, [props.data])

  const Quill_Modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike', 'size'],        // toggled buttons
        ['blockquote', 'code-block', 'link'],
        ['image', 'video'],

        [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],                                      // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ]
};
const Quill_Formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "code-block",
    "color",
    "background",
    'script',
    'align',
    'indent',
    'direction',
]

  const updateTemplate = async (payload, hideModal) => {
      await axiosWithBaseUrl.put(props.url, payload, {
      headers: {
        "Authorization": `Bearer ${props.token}`
      }
    })
      .then(res => {
        if (res?.data?.status === 200) {
          hideModal();
          dispatch(toggleAlert({ title: "Success!", message: "Message Template was updated." }));
          window.location.reload();

        }
      })
      .catch(err => {
        console.error(err);
        hideModal();
        dispatch(toggleAlert({ title: "Failed!", message: "Message Template was not updated." }));

      })
  }

  const handleClick = (e, hideModal) => {
    e.preventDefault();

    // Validation form
    if (validate(message, "isEmpty")) {
      return;
    }
    if (validate(templateName, "isEmpty")) {
      return;
    }
    else {
      setTemplateNameError("");
    }
    if (validate(messageType, "isEmpty")) {
      setMessageTypeError("Message Type cannot be empty");
      return;
    }
    else {
      setMessageTypeError("");

    }
    if (notificationTypeList.length === 0) {
      return;
    }

    // Prepare the request object
    const notificationType = notificationTypeList.map(item => item.value)
    const templateData = { templateName, messageType, notificationType, messagePriority, message, id: props.data.id };

    // send request object to updateTemplate 
    updateTemplate(templateData, hideModal);
  };


  //Hydrate the form fields
  const populateForm = (data) => {
    setTemplateName(data.templateName);
    setMessage(data.message);
    setMessagePriority(data.messagePriority);
    setMessageType(data.type);
    // put notificationtypelist in a format that react select can populate
    const notificationTypeList = data.notificationType?.map(item => ({ label: item, value: item }));
    setNotificationTypeList(notificationTypeList);
  }

  return (
    <Modal
      {...props}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
           <Form>
           <Form.Group id="templateName">
               <div className="mb-3">
                 <Form.Label>Template Name</Form.Label>
                 <Form.Control
                   required
                   type="text"
                   value={templateName}
                   onChange={(e) => setTemplateName(e.target.value)}
                   placeholder="Enter email template name."
                 />
               {templateNameError && <ShowError msg={templateNameError} />}
               </div>
             </Form.Group>
           <Form.Group id="templateSubject">
               <div className="mb-3">
                 <Form.Label>Subject</Form.Label>
                 <Form.Control
                   required
                   type="text"
                   value={emailSubject}
                   onChange={(e) => setEmailSubject(e.target.value)}
                   placeholder="Enter email subject."
                 />
               {templateNameError && <ShowError msg={templateNameError} />}
               </div>
             </Form.Group>
             <Form.Group>
               <div className="mb-3">
                 <Form.Label>Email Type</Form.Label>
                 <Form.Select
                   required
                   // defaultValue="OTP"\
                   value={notificationTypeList}
                   placeholder="Select Type"
                   onChange={(e) => setMessageType(e.target.value)}
                 >
                   <option readOnly >Select Message Types</option>
                   <option value="OTP">OTP</option>
                   <option value="Transactional">Transactional</option>
                   <option value="Promotional">Promotional</option>
                 </Form.Select>
                 {messageTypeError && <ShowError msg={messageTypeError} />}
               </div>
             </Form.Group>
             <Form.Group id="emailPriority">
               <div className="mb-3">
                 <Form.Label>Email Priority</Form.Label>
                 <Form.Control
                   required
                   min={1}
                   max={3}
                   type="number"
                   value={messagePriority}
                   onChange={(e) => setMessagePriority(e.target.value)}
                   placeholder="Enter email priority"
                 />
   
               </div>
             </Form.Group>
             <Form.Group id="email-content">
             <div className="mb-3">
             <Form.Label>Email Content</Form.Label>
                <ReactQuill
                    modules={Quill_Modules}
                    formats={Quill_Formats}
                    theme="snow"
                    value={emailContent}
                    onChange={setEmailContent}
                    placeholder="Type your email content here"
                />
             </div>
             </Form.Group>
           </Form>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit"
          onClick={(e) => handleClick(e, props.onHide)}
        >
          Update
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );

}
UpdateMessageTemplateModal.propTypes = {
  data: PropTypes.shape({
      templateName: PropTypes,
      emailContent: PropTypes,
      messageType: PropTypes,
      messagePriority: PropTypes,
      subject: PropTypes,
      id: PropTypes
  }),
  url: PropTypes,
  token: PropTypes,
  onHide: PropTypes
};

export default function App({ modalShow, setModalShow, data }) {
  App.propTypes = {
    modalShow: PropTypes,
    setModalShow: PropTypes,
    data: PropTypes,
  }
  return (
    <>
      <UpdateTemplate
        openDrawer={modalShow}
        setRefreshGrid={()=>{}}
        setOpenDrawer={setModalShow}
        data={data}
      />
    </>
  );
}
