import {
  Form,
  FormGroup,
  Button,
  FormControl,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import Select from "react-select";

export default () => {
  const [aggregatorImage, setAggregatorImage] = useState("");
  const [aggregatorName, setAggregatorName] = useState("");
  const [aggregatorAPIKey, setAggregatorAPIKey] = useState("");

  return (
    <Form>
      <FormGroup className="my-3">
        <Form.Label>Upload an image for you aggregator</Form.Label>
        <FormControl
          type="file"
          id="aggregatorImage"
          style={{ width: "fit-content" }}
          onChange={(e) => setAggregatorImage(e.target.value)}
        />
      </FormGroup>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Aggregator name:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste your Auth ID here"
                required
                value={aggregatorName}
                onChange={(e) => setAggregatorName(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <FormGroup>
            <div className="mb-3">
              <Form.Label>Your API key:</Form.Label>
              <FormControl
                aria-label="With text"
                placeholder="Type or paste your API key here"
                required
                value={aggregatorAPIKey}
                onChange={(e) => setAggregatorAPIKey(e.target.value)}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
