import React from "react";
import Divider from "@mui/material/Divider";


const CustomDivider = ()=>{
  const DIVIDER_COLOR = "rgba(233,233,233,1)";

        return (
          <div className="divider-margin-accordions">
            <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />
          </div>
        );
      
}

export default CustomDivider;