import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import close_modal from "../../../assets/close.svg";
import Divider from "@mui/material/Divider";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import Select from "react-select";
import SMSSpamAuditLogGrid from "../../Grids/SMSSpamAuditLogGrid";
import { spamConfidenceLevelList } from "../../../DummyData/Aggregators/Aggregators";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import { toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import PropTypes from "prop-types";

function MUISMSSpamDetailModal({
  openDrawer,
  setOpenDrawer,
  setRefreshGrid,
  entityDetails,
  token,
}) {
  const axiosPrivate = CreateAxiosInstance()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("entityDetails");
  const [filterName, setFilterName] = useState("");
  const [filterRule, setFilterRule] = useState("");
  const [spamLevel, setSpamLevel] = useState("");
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };
  const TabSwitch = () => {
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };

    return (
      <div className="tab-switch-container">
        <div
          className={`tab ${
            activeTab === "entityDetails" ? "active" : "inactive"
          }`}
          onClick={() => handleTabClick("entityDetails")}
        >
          Info
        </div>
        <div
          className={`tab ${activeTab === "auditLogs" ? "active" : "inactive"}`}
          onClick={() => handleTabClick("auditLogs")}
        >
          Audit logs
        </div>
      </div>
    );
  };
  const handleApprovalRequest = async (requestType) => {
    // e.preventDefault();
    // hideModal();
    // props.setShowAgGrid(false);
    const payload = {
      status: requestType,
      entity: "SPAM_FILTER",
      entityId:
        entityDetails.approvalEntityId === null
          ? entityDetails.id
          : entityDetails.approvalEntityId,
    };
    await axiosPrivate
      .post(process.env.REACT_APP_ACCEPT_REJECT_REQUEST, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message })
          );
          toggleDrawer(false);
          setOpenDrawer(false);
          setRefreshGrid(true);
          // props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          toggleFailAlert({
            title: "Failed!",
            message: "Unable to process request.",
          })
        );
        toggleDrawer(false);
        setOpenDrawer(false);
        setRefreshGrid(true);
        // props.setShowAgGrid(true);
      });
  };
  useEffect(() => {
    if (!openDrawer) {
      return;
    }
    setFilterName(entityDetails.name);
    setFilterRule(entityDetails.keyword);
    setSpamLevel(
      spamConfidenceLevelList.find(
        (item) => item.value === entityDetails?.confidence.toString()
      )
    );
    setRefreshGrid(false);
  }, [entityDetails, openDrawer]);
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        {openDrawer && (
          <Box
            sx={{ width: 650 }}
            role="presentation"
            className="recipient-details-container"
          >
            <div className="recipient-details-header">
              <div className="recipient-modal-title">
                <h5> Spam Filter details</h5>
                <img
                  style={{ cursor: "pointer" }}
                  src={close_modal}
                  alt="Close Modal"
                  onClick={toggleDrawer(false)}
                />
              </div>
              <TabSwitch />
              {addDivider()}
            </div>
            <div
              className="recipient-details-body-content"
              style={{
                marginBlock: "14px",
                paddingBlock: 0,
                height: "20px",
                minHeight: "20px",
              }}
            >
              {activeTab === "entityDetails" && (
                <section className="">
                  <div className="recipient-item-pair-container">
                    <div className="mb-3">
                      <p className="recipient-details-label">Filter Name</p>
                      <Box sx={{ "& > :not(style)": { m: 1 } }}>
                        <TextField
                          id="input-with-icon-textfield"
                          label=""
                          notched={false}
                          placeholder="Add Filter Name"
                          // required={true}
                          disabled
                          value={filterName}
                          // onChange={(e) => handleFilterName(e)}
                          InputProps={{
                            sx: {
                              padding: "1px 3px",
                              fontSize: "0.875rem",
                              lineHeight: "1.25rem",
                              borderRadius: "8px",
                              height: "40px",
                              "& .MuiSvgIcon-root": {
                                height: "20px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& .MuiInputBase-input": {
                                fontWeight: 700,
                                fontFamily: "Inter",
                                lineHeight: "14px",
                                color: "rgba(126, 126, 126, 1)",
                              },
                              "& fieldset": {
                                border: "none",
                              },
                              background: "rgba(247, 247, 250, 1)",
                            },
                          }}
                        />
                      </Box>
                    </div>

                    <Form.Group id="name">
                      <div className="mb-3">
                        <p className="recipient-details-label">Keyword</p>
                        <Box sx={{ "& > :not(style)": { m: 1 } }}>
                          <TextField
                            id="input-with-icon-textfield"
                            label=""
                            notched={false}
                            placeholder="Add Keyword Here"
                            required
                            disabled
                            value={filterRule}
                            // onChange={(e) => handleFilterRule(e)}
                            InputProps={{
                              sx: {
                                padding: "1px 3px",
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                                borderRadius: "8px",
                                height: "40px",
                                "& .MuiInputBase-input": {
                                  fontWeight: 700,
                                  fontFamily: "Inter",
                                  lineHeight: "14px",
                                  color: "rgba(126, 126, 126, 1)",
                                },
                                "& fieldset": {
                                  border: "none",
                                },
                                background: "rgba(247, 247, 250, 1)",
                              },
                            }}
                          />
                        </Box>
                      </div>
                    </Form.Group>
                  </div>
                  <div className="recipient-item-pair-container">
                    <div className="mb-3">
                      <p className="recipient-details-label">
                        Choose Spam Confidence Level
                      </p>
                      <div>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              flexGrow: 1,
                              marginTop: "16px",
                              padding: "3px 0px",
                              height: "40px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              lineHeight: "20px",
                              color: "rgba(17, 21, 24, 1)",
                              // border:"1px solid red",
                              fontWeight: "500",
                              borderColor: state.isFocused
                                ? "rgba(249, 249, 249, 1)"
                                : "rgba(249, 249, 249, 1)",
                              backgroundColor: "rgba(249, 249, 249, 1)",
                            }),
                          }}
                          options={spamConfidenceLevelList.map((option) => {
                            return {
                              label: option.label,
                              value: option.value,
                            };
                          })}
                          isDisabled={true}
                          placeholder="Choose Spam Confidence Level"
                          value={spamLevel}
                          // onChange={(e) => handleSpamLevel(e)}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              )}
              {activeTab === "auditLogs" && (
                <div>
                  <SMSSpamAuditLogGrid
                    token={token}
                    entityId={entityDetails.approvalEntityId === null ? entityDetails.id : entityDetails.approvalEntityId}
                  />
                </div>
              )}
            </div>
            {memberRole === "TS_ADMIN" &&
              entityDetails.approvalStatus === "PENDING" && (
                <div className="recipient-details-footer">
                  <div className="recipient-modal-response-buttons">
                    <div className="recipient-details-footer">
                      <div className="recipient-modal-response-buttons">
                        <div
                          className="reject"
                          onClick={() => handleApprovalRequest("REJECTED")}
                        >
                          <p className="reject-text">Reject</p>
                        </div>
                        <div
                          className="approve"
                          onClick={() => {
                            handleApprovalRequest("ACCEPTED");
                          }}
                        >
                          <p className="approve-text">Approve</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </Box>
        )}
      </Drawer>
    </div>
  );
}
MUISMSSpamDetailModal.propTypes = {
  entityDetails: PropTypes.string,
  openDrawer: PropTypes.func,
  setOpenDrawer: PropTypes.func,
  token: PropTypes.string,
  setRefreshGrid: PropTypes.func
}

export default MUISMSSpamDetailModal;
