import React, { useEffect } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import APISuccess from "../components/Toasts/APISuccess";
import APIFail from "../components/Toasts/APIFail";
import { getPageTitle } from "../Redux/Reducers/pageTitleReducer";
import EmailTemplateGrid from "../components/Grids/EmailTemplateGrid";

export default () => {
  const alertSuccess = useSelector(state => state.persistedReducer.alerts.showSuccess);
  const token = useSelector(state => state.persistedReducer?.member?.token);

  const alertFail = useSelector(state => state.persistedReducer.alerts.showFail);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageTitle("Email Templates"));
  }, [])

  return (
    // <Card border="light" className="bg-white shadow-sm my-4 animate-fade-in">
    <Card border="light" className="bg-white shadow-sm my-2">
      <Card.Body>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Row>
              <Col xs={12} xl={12}>
                <Row>
                  <Col xs={12} className="px-0">
                    {alertSuccess && <APISuccess toggle={true} />}
                    {alertFail && <APIFail toggle={true} />}
                    <EmailTemplateGrid
                      token={token}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
