import { Modal, Button, Form } from "react-bootstrap";
import React, { useState } from "react";
import { FormControl } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import ShowError from "../../FormValidation/ShowError";
import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import "react-quill/dist/quill.snow.css";
import PropTypes from 'prop-types';

function UpdateMessageTemplateModal(props) {
  const [filterName, setFilterName] = useState(props.data.filterName);
  const [filterRule, setFilterRule] = useState(props.data.filterRule);
  // For validation
  const templateNameError = "";
  const messageError = "";
  const dispatch = useDispatch();
  const createdByName = useSelector(
    (state) => state.persistedReducer?.member?.data?.name
  );
  const [filterSensitivity, setFilterSensitivity] = useState(
    props.data.confidence
  );

  const updateTemplate = async (payload, hideModal) => {
    await axiosWithBaseUrl
      .put(props.url, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          hideModal();
          dispatch(
            toggleAlert({
              title: "Success!",
              message: "Message Template was updated.",
            })
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
        hideModal();
        dispatch(
          toggleAlert({
            title: "Failed!",
            message: "Message Template was not updated.",
          })
        );
      });
  };

  const handleClick = (e, hideModal) => {
    e.preventDefault();

    // Prepare the request object
    const templateData = {
      id: "1212121",
      filterName,
      filterRule,
      confidence: filterSensitivity,
      createdByName,
    };

    // send request object to updateTemplate
    updateTemplate(templateData, hideModal);
  };

  const getFilterSensitivityDescription = () => {
    switch (Number(filterSensitivity)) {
      case 1:
        return (
          <div>
            <p style={{ fontWeight: 800, margin: 0 }} className="text-danger">
              Low
            </p>
            <ul style={{ margin: "0" }}>
              <li>Fewer checks, fastest performance</li>
              <li> Recommended for promotional messages</li>
            </ul>
          </div>
        );
      case 2:
        return (
          <div>
            <p style={{ fontWeight: 800, margin: 0 }}>Medium</p>
            <ul style={{ margin: "0" }}>
              <li>More number of checks, decent performance</li>
              <li> Recommended for OTP and Transactional messages</li>
            </ul>
          </div>
        );
      case 3:
        return (
          <div>
            <p style={{ fontWeight: 800, margin: 0 }} className="text-success">
              High
            </p>
            <ul style={{ margin: "0" }}>
              <li>Most number of checks, slowest performance</li>
              <li> Recommended for OTP and Transactional messages</li>
            </ul>
          </div>
        );
    }
  };

  return (
    <Modal
      {...props}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Template
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <Form>
            <Form.Group id="templateName">
              <div className="mb-3">
                <Form.Label>Filter Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  placeholder="Enter filter name."
                />
                {templateNameError && <ShowError msg={templateNameError} />}
              </div>
            </Form.Group>

            <Form.Group id="firstName">
              <div className="mb-3">
                <Form.Label>Filter Rule</Form.Label>
                <FormControl
                  as="textarea"
                  placeholder="Add your filter rule here"
                  rows={10}
                  aria-label="With textarea"
                  value={filterRule}
                  onChange={(e) => setFilterRule(e.target.value)}
                />
                {messageError && <ShowError msg={messageError} />}
              </div>
            </Form.Group>
            <Form.Group id="filterSensitivity">
              <div className="mb-3">
                <Form.Label>Spam Confidence Level</Form.Label>
                <Form.Range
                  min={1}
                  max={3}
                  value={filterSensitivity}
                  onChange={(e) => {
                    setFilterSensitivity(e.target.value);
                  }}
                />
                {getFilterSensitivityDescription()}
                {messageError && <ShowError msg={messageError} />}
              </div>
            </Form.Group>
          </Form>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={(e) => handleClick(e, props.onHide)}>
          Update
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
UpdateMessageTemplateModal.propTypes = {
  data: PropTypes.shape({
    filterName: PropTypes,
    filterRule: PropTypes,
    confidence: PropTypes
  }),
  url: PropTypes,
  token: PropTypes,
  onHide: PropTypes
}

export default function App({ modalShow, setModalShow, url, data, token }) {
  App.propTypes = {
    modalShow: PropTypes,
    setModalShow: PropTypes,
    url: PropTypes,
    data: PropTypes,
    token: PropTypes
  }
  return (
    <>
      <UpdateMessageTemplateModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        url={url}
        data={data}
        token={token}
      />
    </>
  );
}
