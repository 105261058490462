import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import close_modal from "../../../assets/close.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import { accordionStyles } from "./MUISelectStyle";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import TimePicker from "./MUITimePickerCustom/TimePicker";
import { Form } from "react-bootstrap";
import { isValidNumber } from "libphonenumber-js";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import { useSelector, useDispatch } from "react-redux";
import validate from "../../FormValidation/validation";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import ShowError from "../../FormValidation/ShowError";
import { recipientConstants } from "./Constants";


const CreateRecipientDrawer = ({
  openDrawer,
  setOpenDrawer,
  setRefreshGrid,
}) => {
  //Constants
  const SMS = "sms",
    EMAIL = "email";
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  // States
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lineChatId, setLineChatId] = useState("");
  const [contactList, setContactList] = useState("");
  const [notificationPreferences, setNotificationPreferences] = useState({
    sms: false,
    email: false,
  });
  const [transactional, setTransactional] = useState([false, false]);
  const [accountManagement, setAccountManagement] = useState(false);
  const [securityAndFraudPrevention, setSecurityAndFraudPrevention] =
    useState(false);
  const [isPromotionalChecked, setIsPromotionalChecked] = useState(false);
  const [timePickerPayload, setTimePickerPayload] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => console.log("From parent component payload: \n", timePickerPayload),
    [timePickerPayload],
  );
  const axiosPrivate = CreateAxiosInstance();
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles,
  );
  const dispatch = useDispatch();
  const accordionClasses = accordionStyles();
  const getContactList = useCallback(async () => {
    try {
      setLoading(true);
      await axiosPrivate
        .post(
          process.env.REACT_APP_GROUPS,
          {
            startRow: 0,
            endRow: 100,
            sortModel: [],
            filterModel: {},
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          setOptions(
            resp?.groupDetails.map((item) => {
              return { label: item.name, value: item.id };
            }),
          );
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [token, axiosPrivate]);

  useEffect(() => {
    getContactList();
    setRefreshGrid(false);
  }, [getContactList]);

  const handlenotificationPreference = (event, notificationType) => {
    let sms = notificationPreferences.sms;
    let email = notificationPreferences.email;

    if (notificationType === SMS) {
      sms = event.target.checked;
    } else {
      email = event.target.checked;
    }
    setNotificationPreferences({ sms, email });
  };

  const handleTransactionalCheckbox = (event) => {
    setTransactional([event.target.checked, event.target.checked]);
  };

  const handleOtpCheckbox = (event) => {
    setTransactional([event.target.checked, transactional[1]]);
  };

  const handlePaymentReminderCheckbox = (event) => {
    setTransactional([transactional[0], event.target.checked]);
  };

  const createRecipientPayload = () => {
    let groupIds;
    if (contactList) {
      groupIds = contactList.map((item) => item.value);
    }
    return {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      groupIds: groupIds,
      email: email,
      lineChatUserId: lineChatId,
      messageTypes: {
        transactional: {
          enabled: transactional[0] || transactional[1],
          subtypes: {
            otp: transactional[0],
            paymentReminders: transactional[1],
          },
        },
        accountManagement: {
          enabled: accountManagement,
        },
        securityAndFraudPrevention: {
          enabled: securityAndFraudPrevention,
        },
        promotional: {
          enabled: isPromotionalChecked,
        },
      },
      notificationPreferences: {
        sms: notificationPreferences.sms,
        email: notificationPreferences.email,
      },
      promotionTimes: timePickerPayload,
    };
  };

  const handleCreateRecipient = async () => {
    /* Validate */
    if (
      validate(phoneNumber, "isEmpty") &&
      validate(email, "isEmpty") &&
      validate(lineChatId, "isEmpty")
    ) {
      setError("Please enter either Email, Phone Number or Line Chat Id ");
      return;
    } else {
      setError("");
      if (!validate(email, "isEmpty")) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setError("Please Enter valid Email");
          return;
        }
      }

      if (!validate(phoneNumber, "isEmpty")) {
        const isValid = isValidNumber(phoneNumber);
        if (!isValid) {
          setError("Please Enter Valid Phone Number");
          return;
        }
      }

      if (!validate(lineChatId, "isEmpty")) {
        const userIdRegex = /^U[0-9a-f]{32}$/;
        if (!userIdRegex.test(lineChatId)) {
          setError("Please Enter Valid Line Chat User Id");
          return;
        }
      }
    }

    const payload = createRecipientPayload();

    await axiosPrivate
      .post(`${process.env.REACT_APP_NEW_USER}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          setOpenDrawer(false);
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message }),
          );
          toggleDrawer(false);
          setRefreshGrid(true);
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
        console.error(err);
        {
          memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "User was not created.",
                }),
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                }),
              );
        }
      });
  };

  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label="OTPs"
        control={
          <Checkbox
            checked={transactional[0]}
            onChange={handleOtpCheckbox}
            sx={{
              marginBlockStart: "6px",
              "&.Mui-checked": {
                color: "rgba(115, 115, 219, 1)",
              },
            }}
          />
        }
      />
      <FormControlLabel
        label="Payment reminders"
        control={
          <Checkbox
            checked={transactional[1]}
            onChange={handlePaymentReminderCheckbox}
            sx={{
              "&.Mui-checked": {
                color: "rgba(115, 115, 219, 1)",
              },
            }}
          />
        }
      />
    </Box>
  );

  const handleFirstNameChange = (e) => {
    const input = e.target.value;
    console.log({ error });
    const allowedCharacters = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    handleInputChange(
      input,
      allowedCharacters.test.bind(allowedCharacters),
      setFirstName,
      "Please enter only alphabets for the name.",
    );
  };

  const handleInputChange = (input, validator, setter, errorMessage) => {
    const isValid = validator(input);
    if (!isValid) {
      setError(errorMessage);
    } else {
      setError("");
    }
    setter(input);
  };

  // Componet: Divider
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };

  // Component: Divider margin
  const addDividerMargin = () => {
    return <div className="divider-margin"></div>;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleLastNameChange = (e) => {
    const input = e.target.value;
    const allowedCharacters = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    handleInputChange(
      input,
      allowedCharacters.test.bind(allowedCharacters),
      setLastName,
      "Please enter only alphabets for the name.",
    );
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    handleInputChange(
      input,
      isValidNumber,
      setPhoneNumber,
      "Please Enter Valid Phone Number",
    );
  };
  const handleEmailChange = (e) => {
    const input = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    handleInputChange(
      input,
      emailRegex.test.bind(emailRegex),
      setEmail,
      "Please Enter Valid Email",
    );
  };
  const handleLineChatId = (e) => {
    const input = e.target.value;
    const userIdRegex = /^U[0-9a-f]{32}$/;
    handleInputChange(
      input,
      userIdRegex.test.bind(userIdRegex),
      setLineChatId,
      "Please Enter Valid Line Chat User Id",
    );
  };

  return (
    <div>
      {/* Main container */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{ width: recipientConstants.RECIPIENT_DRAWER_WIDTH }}
          role="presentation"
          className="recipient-details-container"
        >
          {/* tenmp content */}

          <div
            className="recipient-details-header"
            style={{ minHeight: "64px" }}
          >
            <div
              className="recipient-modal-title"
              style={{ marginBlock: "14px", paddingBlock: 0 }}
            >
              <h5>Create recipient</h5>
              <img
                style={{ cursor: "pointer" }}
                src={close_modal}
                alt="Close Modal"
                onClick={toggleDrawer(false)}
              />
            </div>
            {/* <TabSwitch /> */}
            {addDivider()}
          </div>
          <div
            className="recipient-details-body-content"
            style={{
              marginBlock: "14px",
              paddingBlock: 0,
              height: "20px",
              minHeight: "20px",
            }}
          >
            <section className="">
              <div className="recipient-item-pair-container">
                <div className="mb-3">
                  <p className="recipient-details-label">First Name</p>
                  <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <TextField
                      id="input-with-icon-textfield"
                      label=""
                      notched={false}
                      placeholder="Recipient's First Name"
                      required={true}
                      value={firstName}
                      onChange={(e) => handleFirstNameChange(e)}
                      InputProps={{
                        sx: {
                          padding: "1px 3px",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          borderRadius: "8px",
                          height: "40px",
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& .MuiInputBase-input": {
                            fontWeight: 700,
                            fontFamily: "Inter",
                            lineHeight: "14px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                          background: "rgba(247, 247, 250, 1)",
                        },
                      }}
                    />
                  </Box>
                </div>

                <Form.Group id="name">
                  <div className="mb-3">
                    <p className="recipient-details-label">Last Name</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Recipient's Last Name"
                        required
                        value={lastName}
                        onChange={(e) => handleLastNameChange(e)}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>
              </div>
              <div className="recipient-item-pair-container">
                <Form.Group id="email">
                  <div className="mb-3">
                    <p className="recipient-details-label">Email Id</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        required
                        placeholder="Recipient's Email"
                        value={email}
                        onChange={(e) => handleEmailChange(e)}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>
                <Form.Group id="phoneNumber">
                  <div className="mb-3">
                    <p className="recipient-details-label">Phone Number</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Recipient's Phone Number"
                        required
                        value={phoneNumber}
                        onChange={(e) => handlePhoneChange(e)}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>
              </div>
              <div className="recipient-item-pair-container">
                <Form.Group id="lineChat">
                  <div className="mb-3">
                    <p className="recipient-details-label">LineChat Id</p>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Recipient's line Chat Id"
                        required
                        value={lineChatId}
                        onChange={(e) => handleLineChatId(e)}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            marginLeft: 0,
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>
                <div className="mb-3">
                  <p className="recipient-details-label">Contact list</p>
                  {loading ? (
                    <div></div>
                  ) : (
                    <div>
                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            flexGrow: 1,
                            marginTop: "16px",
                            padding: "3px 0px",
                            height: "40px",
                            borderRadius: "8px",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            lineHeight: "20px",
                            color: "rgba(17, 21, 24, 1)",
                            // border:"1px solid red",
                            fontWeight: "500",
                            borderColor: state.isFocused
                              ? "rgba(249, 249, 249, 1)"
                              : "rgba(249, 249, 249, 1)",
                            backgroundColor: "rgba(249, 249, 249, 1)",
                          }),
                        }}
                        options={options}
                        placeholder="Add to a Contact List"
                        value={contactList}
                        isMulti
                        onChange={(e) => setContactList(e)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {error && <ShowError msg={error} />}

              {/* horizontal line break */}
              {addDividerMargin()}
              {addDivider()}
              <div className="pb-2"></div>

              {/* Capabilities */}
              <Accordion classes={{ root: accordionClasses.accordionRoot }}>
                <AccordionSummary
                  sx={{ border: "none" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="capabilities-accordion-header"
                  id="capabilities-accordion-header"
                >
                  <p className="recipient-detail-accordion-header">
                    Capabilities
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    <div className="d-flex gap-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={notificationPreferences.email}
                            onChange={(e) =>
                              handlenotificationPreference(e, EMAIL)
                            }
                            sx={{
                              paddingBlock: 0,
                              marginBlock: 0,
                              "&.Mui-checked": {
                                color: "rgba(115, 115, 219, 1)",
                              },
                            }}
                          />
                        }
                        label="Email"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={notificationPreferences.sms}
                            onChange={(e) =>
                              handlenotificationPreference(e, SMS)
                            }
                            sx={{
                              paddingBlock: 0,
                              marginBlock: 0,
                              "&.Mui-checked": {
                                color: "rgba(115, 115, 219, 1)",
                              },
                            }}
                          />
                        }
                        label="SMS"
                      />
                    </div>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              {/* horizontal line break */}
              <div className="pb-2"></div>
              {addDivider()}
              <div className="pb-2"></div>

              {/* Configuration */}
              <Accordion classes={{ root: accordionClasses.accordionRoot }}>
                <AccordionSummary
                  sx={{ border: "none" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="capabilities-accordion-header"
                  id="capabilities-accordion-header"
                >
                  <p className="recipient-detail-accordion-header">
                    Configuration
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  {/* TRANSACTIONAL CHECKBOX */}
                  <FormGroup>
                    <div className="d-flex flex-column gap-3">
                      <div>
                        <FormControlLabel
                          label="Transactional"
                          control={
                            <Checkbox
                              sx={{
                                paddingBlock: 0,
                                marginBlock: 0,
                                "&.Mui-checked": {
                                  color: "rgba(115, 115, 219, 1)",
                                },
                              }}
                              checked={transactional[0] && transactional[1]}
                              indeterminate={
                                transactional[0] !== transactional[1]
                              }
                              onChange={handleTransactionalCheckbox}
                            />
                          }
                        />
                        {/* CHILDREN CHECKBOXES FOR TRANSACTIONAL */}
                        {children}
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={accountManagement}
                            onChange={(e) =>
                              setAccountManagement(e.target.checked)
                            }
                            sx={{
                              paddingBlock: 0,
                              marginBlock: 0,
                              "&.Mui-checked": {
                                color: "rgba(115, 115, 219, 1)",
                              },
                            }}
                          />
                        }
                        label="Account management"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={securityAndFraudPrevention}
                            onChange={(e) =>
                              setSecurityAndFraudPrevention(e.target.checked)
                            }
                            sx={{
                              paddingBlock: 0,
                              marginBlock: 0,
                              "&.Mui-checked": {
                                color: "rgba(115, 115, 219, 1)",
                              },
                            }}
                          />
                        }
                        label="Security and fraud prevention"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              paddingBlock: 0,
                              marginBlock: 0,
                              "&.Mui-checked": {
                                color: "rgba(115, 115, 219, 1)",
                              },
                            }}
                            checked={isPromotionalChecked}
                            onChange={(e) =>
                              setIsPromotionalChecked(e.target.checked)
                            }
                          />
                        }
                        label="Promotional"
                      />
                    </div>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>

              {isPromotionalChecked && (
                <div>
                  {addDivider()}
                  {addDividerMargin()}
                  <Accordion
                    classes={{ root: accordionClasses.accordionRoot }}
                    // className="my-4"
                  >
                    <AccordionSummary
                      sx={{ border: "none" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="capabilities-accordion-header"
                      id="capabilities-accordion-header"
                    >
                      <p className="recipient-detail-accordion-header">
                        Schedule promotional messages
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {/* SELECTED DAYS */}
                        <TimePicker
                          timePickerFullPayload={timePickerPayload}
                          setTimePickerPayload={setTimePickerPayload}
                          operationType={"CREATE"}
                        />
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </section>
          </div>
          <div className="recipient-details-footer">
            <div className="recipient-modal-response-buttons">
              <div
                className="approve"
                onClick={() => {
                  handleCreateRecipient();
                }}
              >
                <p className="approve-text">Create</p>
              </div>
            </div>
          </div>

          {/* end of temp content */}
        </Box>
      </Drawer>
    </div>
  );
};

CreateRecipientDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  // setShowAgGrid: PropTypes.func,
  setRefreshGrid: PropTypes.func,
};

export default CreateRecipientDrawer;
