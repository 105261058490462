import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Button } from "@themesberg/react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import download from "../../assets/download.png";
import "ag-grid-enterprise";
import moment from "moment";
import { flashAgGridData } from "../../DummyData/flash/flashAgGridData";
import { hasTimedOut } from "../../util/messagePendingStatusValidation";
import PropTypes from 'prop-types';
import { MESSAGE_STATUSES } from "../../util/messageStatus";

const FlashMessageHealthGrid = ({
  heading,
  fromDate,
  toDate,
  pieSlice,
  setPendingMessageStatusUpdateCount,
}) => {
  const gridRef = useRef(null);
  const rowData = flashAgGridData?.data?.messageDetails;
  const paginationPageSize = 15;
  const cacheBlockSize = 15;

  const updatePendingMessageStatus = useCallback(() => {
    let count = 0;
    flashAgGridData.data.messageDetails =
      flashAgGridData.data.messageDetails.map((message) => {
        if (MESSAGE_STATUSES.slice(0, 3).includes(message.messageStatus)) {
          if (hasTimedOut(message)) {
            message.messageStatus = "DELIVERY_FAILED";
            message.smsFailureReason = "Timed out";
            count++;
          }
        }

        return message;
      });
    setPendingMessageStatusUpdateCount(count);
  }, [setPendingMessageStatusUpdateCount]);

  useEffect(() => {
    updatePendingMessageStatus();
  }, [updatePendingMessageStatus]);

  //START OF AG GRID CONFIGURATIONS
  /*
      Three types of column filter: agTextColumnFilter, agNumberColumnFilter & agDateColumnFilter
       */
  const colDefs = [
    {
      headerName: "CREATED BY",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "REQUESTED TIME",
      field: "createdOn",
      flex: 1,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["lessThan", "greaterThan", "inRange"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "DELIVERED TIME",
      field: "messageDeliveredTime",
      flex: 1,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["lessThan", "greaterThan", "inRange"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "SEND TO",
      field: "email",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "EMAIL PROVIDER",
      field: "smsProvider",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "STATUS",
      field: "messageStatus",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "FAILURE REASON",
      field: "smsFailureReason",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];
  const [columnDefs] = useState(colDefs);

  useEffect(() => {

  }, [fromDate, toDate]);

  useEffect(() => {

  }, [pieSlice]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,

      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    []
  );

  const onBtnExport = useCallback(async () => {
    gridRef.current.api.exportDataAsCsv({
      onlySelectedAllPages: false,
      fileName: `email_${moment().format("ddd-DD-MM-yyyy-HH-mm-ss")}`,
    });
  }, []);

  const dimensions = { width: "100%" };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>{heading}</h5>
          {
            <Button
              variant="primary"
              className="py-1 fs-6 transparent-btn"
              onClick={onBtnExport}
            >
              <div className="d-flex gap-2">
                <div
                  style={{
                    borderRight: "1px solid grey",
                    paddingRight: ".5em",
                    width: "1.25em",
                  }}
                >
                  <img
                    src={download}
                    width="15"
                    style={{ filter: "hue-rotate(30deg), brightness(100%)" }}
                  />
                </div>
                Download CSV
              </div>
            </Button>
          }
        </div>
        <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={paginationPageSize}
            cacheBlockSize={cacheBlockSize}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
          />
        </div>
      </div>
    </>
  );
};
FlashMessageHealthGrid.propTypes = {
  heading: PropTypes,
  fromDate: PropTypes,
  toDate: PropTypes,
  pieSlice: PropTypes,
  pendingMessageStatusUpdateCount: PropTypes,
  setPendingMessageStatusUpdateCount: PropTypes,
}
export default FlashMessageHealthGrid;
