import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo, useRef, useState } from "react";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { getLastMonthDate } from "../../util/dateUtil";
import moment from "moment";
import PropTypes from "prop-types";

function SMSSpamAuditLogGrid({ token, entityId }) {
  const axiosPrivate = CreateAxiosInstance();
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const paginationPageSize = 10;
  const masterDetail = true;
  const fromDate = getLastMonthDate();
  const colDefs = [
    {
      headerName: "",
      field: "previousState", //previous or current state since both will be identical
      flex: 0.5,
      valueFormatter: () => {
        //Remove any content for this column
        return "";
      },
      cellRendererSelector: (params) => {
        // Add the cell renderer for showing preferences details
        if (
          //if both previousstate and currentState are not null and not empty
          params.data.previousState &&
          params.data.previousState.length &&
          params.data.currentState &&
          params.data.currentState.length
        ) {
          //Check if preferences object is present
          const prevStateJsonString = params.data.previousState.find(
            (item) => item.preferences !== undefined
          );
          const currentStateJsonString = params.data.currentState.find(
            (item) => item.preferences !== undefined
          );
          // If present then show the ">" expand option
          if (prevStateJsonString && currentStateJsonString) {
            return {
              component: "agGroupCellRenderer",
            };
          }
        }
        // Show nothing
        return "";
      },
    },
    {
      headerName: "Time",
      field: "timestamp",
      flex: 2.5,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "User name",
      cellRenderer: "renderFullName",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    // {
    //   headerName: "Status",
    //   cellRenderer: "renderStatus",
    //   flex: 2,
    //   style: { borderLeft: "1px solid rgba(233,233,233,1)" },
    //   filter: "agTextColumnFilter",
    //   filterParams: { suppressAndOrCondition: true },
    // },
    {
      headerName: "Operation",
      field: "operation",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
      valueFormatter: (params) => {
        // Split the string by space and capitalize each word
        return params.value
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      },
    },
  ];
  const getFullName = (params) => {
    // return <div>{`${params.data.firstName} ${params.data.lastName} `}</div>;
    return <div>{`${params.data.actionByName}`}</div>;
  };
  const statusTypes = (params) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <p
          style={{ fontSize: ".9rem", fontWeight: "600" }}
          className={`${getStatusType(params.data.approvalStatus)} capitalize`}
        >
          {params.data.approvalStatus?.toLowerCase()}
        </p>
      </div>
    );
  };
  const getStatusType = (type) => {
    switch (type) {
      case "APPROVED":
        return "recipient_status_approved";
      case "PENDING":
        return "recipient_status_pending";
      case "REJECTED":
        return "recipient_status_rejected";
    }
  };
  const dataSource = {
    async getRows(params) {
      let payload = {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {
          entityId: {
            filterType: "text",
            type: "contains",
            filter: entityId,
          },
        },
      };
      await axiosPrivate
        .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            from: fromDate,
            to: new Date(),
            Timezone: moment().utcOffset(),
          },
        })
        .then((response) => {
          const resp = response?.data?.data;
          params.successCallback(resp?.auditLogs, resp?.auditLogs?.length || 1); //filling ag grid with content
        })
        .catch((error) => {
          console.error(error);
        });
    },
  };
  const columnDefs = colDefs;
  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const dimensions = { width: "100%", height: "100%" };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    []
  );

  const frameworkComponents = {
    renderStatus: statusTypes,
    renderFullName: getFullName,
  };

  return (
    <>
      <div className="recipient-audit-log-grid">
        <div
          id="recipient-audit-logs-grid"
          className="ag-theme-alpine pt-1"
          style={dimensions}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={paginationPageSize}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            masterDetail={masterDetail}
            detailRowAutoHeight={true}
            // autoGroupColumnDef={autoGroupColumnDef}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="No data to show"
            components={frameworkComponents}
            // pointer hover class: _recipient_details.scss line:35
          />
        </div>
      </div>
    </>
  );
}
SMSSpamAuditLogGrid.propTypes = {
  token: PropTypes.string,
  entityId: PropTypes.string
}

export default SMSSpamAuditLogGrid;
