import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { setMemberData } from "./Redux/Reducers/memberReducer";
import { useDispatch } from "react-redux";
import { axiosWithBaseUrl } from "./axios/baseUrl";

const LoginSuccessComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userInfo, setUserinfo] = useState("");
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const currentHash = window.location.hash;

    const hashParams = new URLSearchParams(currentHash.substring(1));

    const accessTokenStart =
      currentHash.indexOf("access_token=") + "access_token=".length;
    const accessTokenEnd =
      currentHash.indexOf("&", accessTokenStart) !== -1
        ? currentHash.indexOf("&", accessTokenStart)
        : currentHash.length;

    const accessToken = currentHash.substring(accessTokenStart, accessTokenEnd);
    const refreshToken = hashParams.get("refresh_token");
    const getUserInfo = async () => {
      try {
        const response = await axiosWithBaseUrl.get(
          `${process.env.REACT_APP_KEYCLOAK_USER_INFO_API}?access_token=${accessToken}`
        );
        if (response) {
          if (isMounted) {
            setUserinfo(response.data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (accessToken) {
      getUserInfo();
    }
    if (userInfo) {
      const client = {
        tokenParsed: { name: userInfo.name, email: userInfo.email },
        id: userInfo.sub,
        roles: userInfo.realm_access.roles,
        token: accessToken,
        refreshToken: refreshToken,
      };
      dispatch(setMemberData(client));
      setRedirect(true);
    }
    return () => {
      isMounted = false;
    };
  }, [history, dispatch, userInfo]);
  if (redirect) {
    return <Redirect exact to="/" />;
  }

  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "50vh",
        transform: "translateY(-50%)",
      }}
    >
      You&apos;re logged in. Redirecting to Homepage.
    </div>
  );
};

export default LoginSuccessComponent;
