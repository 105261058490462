const MessageHealthStatusHeader = [
  "Created by",
  "Requested Time",
  "Delivered Time",
  "Send To",
  "Email Content",
  "Email Provider",
  "Status",
  "Failure Reason",
];

const AggregatorStatsHeader = [
  "Providers",
  "Messages Sent",
  "Pending Messages",
  "Successful",
  "SUCCESS RATE",
  "Failed",
];

const EmailHealthStatusHeader = [
  "Created by",
  "Requested Time",
  "Delivered Time",
  "Send To",
  "Send Message Content",
  "Message Provider",
  "Status",
  "Failure Reason",
];

const FlashHealthStatusHeader = [
  "Created by",
  "Requested Time",
  "Delivered Time",
  "Send To",
  "Send Message Content",
  "Message Provider",
  "Status",
  "Failure Reason",
];

export {
  MessageHealthStatusHeader,
  AggregatorStatsHeader,
  EmailHealthStatusHeader,
  FlashHealthStatusHeader,
};
