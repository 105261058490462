import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux"
import store from "./Redux/Store"
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
// styles
import "./scss/style.scss";
import "react-datetime/css/react-datetime.css";
import ScrollToTop from "./components/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";

let persistor = persistStore(store)
const queryClient = new QueryClient(({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: 1
      // staleTime: 1000
    }
  }
}));


ReactDOM.render(
  <HashRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>

          <ScrollToTop />
          <App />

        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </HashRouter>,
  document.getElementById("root")
);
