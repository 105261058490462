const uploadRecipientFileHeaders = [
  "First Name",
  "Last Name",
  "Email",
  "Phone Number",
  "Capabilities",
  "Allowed Notifications",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];


export {uploadRecipientFileHeaders};
