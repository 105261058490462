export const aggregatorList = {
  SMS: [{ label: `Plivo`, value: `plivo` }],
  EMAIL: [{ label: `MailJet`, value: `mailjet` }],
  FLASH: [{ label: `FCM`, value: `fcm` }],
  LINECHAT: [{ label: `Linechat`, value: `linechat` }],
};

export const messageTypeList = [
  { label: "SMS", value: "SMS" },
  { label: "E-mail", value: "EMAIL" },
  // { label: "InApp", value: "FLASH" },
  // { label: "LineChat", value: "LINECHAT" },
];
export const spamConfidenceLevelList = [
  { label: "Low", value: "1" },
  { label: "Medium", value: "2" },
  { label: "High", value: "3" },
];
