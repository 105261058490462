import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import close_modal from "../../../assets/close.svg";
import Select from "react-select";
import { TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import ShowError from "../../FormValidation/ShowError";
import { recipientConstants } from "./Constants";
import validate from "../../FormValidation/validation";

const UpdateMessageTemplateDrawer = ({
  openDrawer,
  setOpenDrawer,
  data,
  setRefreshGrid,
}) => {
  //Constants
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  // States
  const [error, setError] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [messagePriority, setMessagePriority] = useState("");
  const [notificationType, setNotificationType] = useState("");

  useState(false);
  const options = [
    { value: "OTP", label: "OTP" },
    { value: "Transactional", label: "Transactional" },
    { value: "Promotional", label: "Promotional" },
  ];
  const notificationTypeList = [
    { label: "SMS", value: "SMS" },
    { label: "Email", value: "EMAIL" },
  ];

  const axiosPrivate = CreateAxiosInstance();
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles
  );
  const dispatch = useDispatch();

  const createPayload = () => {
    const notificationTypes = notificationType.map((item) => item.value);
    return {
      templateName,
      messageType: messageType.value,
      notificationType: notificationTypes,
      messagePriority,
      message,
    };
  };

  useEffect(() => {
    setRefreshGrid(false);
    populateForm(data);
  }, [data]);

  //Hydrate the form fields
  const populateForm = (data) => {
    setTemplateName(data.templateName);
    setMessage(data.message);
    setMessagePriority(data.messagePriority);
    options.forEach((option) => {
      if (option.value === data.messageType) {
        setMessageType(option);
      }
    });
    const arr = [];
    notificationTypeList.forEach((option) => {
      data.notificationType.forEach((n) => {
        if (option.value === n) {
          arr.push(option);
        }
      });
    });
    setNotificationType(arr);
  };

  const handleUpdateRequest = async () => {
    /* Validate */
    if (validate(templateName, "isEmpty")) {
      setError("Please enter template name");
      return;
    }
    if (
      validate(messageType, "isEmpty") ||
      !messageType ||
      Object.keys(messageType).length === 0
    ) {
      setError("Please select message type");
      return;
    }
    if (
      validate(notificationType, "isEmpty") ||
      !notificationType ||
      notificationType.length === 0
    ) {
      setError("Please select notification type");
      return;
    }
    if (validate(messagePriority, "isEmpty")) {
      setError("Please enter message priority");
      return;
    }
    if (validate(message, "isEmpty")) {
      setError("Please enter message content");
      return;
    }
    if (!/^[^\d]*$/.test(templateName)) {
      setError("Please enter a valid name");
      return;
    }

    const payload = createPayload();

    await axiosPrivate
    .put(`${process.env.REACT_APP_MESSAGE_TEMPLATES}/${data.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          setOpenDrawer(false);
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message })
          );
          toggleDrawer(false);
          setRefreshGrid(true);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        console.error(err);
        {
          memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Template was not created.",
                })
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                })
              );
        }
      });
  };

  const onTemplateNameChange = (e) => {
    let value = e.target.value;
    setTemplateName(value);
  };
  const onMessageTypeChange = (e) => {
    setMessageType(e);
  };
  const onMessagePriorityChange = (e) => {
    const min = 1;
    const max = 3;
    let value = parseInt(e.target.value, 10);
    if (value > max) value = max;
    if (value < min) value = min;
    setMessagePriority(value);
  };
  const onNotificationTypeChange = (e) => {
    setNotificationType(e);
  };
  const onMessageChange = (e) => {
    let value = e.target.value;
    setMessage(value);
  };

  // Componet: Divider
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };

  // Component: Divider margin
  const addDividerMargin = () => {
    return <div className="divider-margin"></div>;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  return (
    <div>
      {/* Main container */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{ width: recipientConstants.RECIPIENT_DRAWER_WIDTH }}
          role="presentation"
          className="recipient-details-container"
        >
          {/* tenmp content */}

          <div
            className="recipient-details-header"
            style={{ minHeight: "64px" }}
          >
            <div
              className="recipient-modal-title"
              style={{ marginBlock: "14px", paddingBlock: 0 }}
            >
              <h5>Update message template</h5>
              <img
                style={{ cursor: "pointer" }}
                src={close_modal}
                alt="Close Modal"
                onClick={toggleDrawer(false)}
              />
            </div>
            {/* <TabSwitch /> */}
            {addDivider()}
          </div>
          <div
            className="recipient-details-body-content"
            style={{
              marginBlock: "14px",
              paddingBlock: 0,
              height: "20px",
              minHeight: "20px",
            }}
          >
            <section className="">
              <div className="recipient-item-pair-container">
                <div className="mb-3">
                  <p className="recipient-details-label">Template Name</p>
                  <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <TextField
                      id="input-with-icon-textfield"
                      label=""
                      notched={false}
                      placeholder="Message Template Name"
                      required={true}
                      value={templateName}
                      onChange={onTemplateNameChange}
                      InputProps={{
                        sx: {
                          padding: "1px 3px",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          borderRadius: "8px",
                          height: "40px",
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& .MuiInputBase-input": {
                            fontWeight: 700,
                            fontFamily: "Inter",
                            lineHeight: "14px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                          background: "rgba(247, 247, 250, 1)",
                        },
                      }}
                    />
                  </Box>
                </div>

                <Form.Group id="name">
                  <div className="mb-3">
                    <p className="recipient-details-label">Message Type</p>
                    {
                      <div>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              flexGrow: 1,
                              marginTop: "16px",
                              padding: "3px 0px",
                              height: "40px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              lineHeight: "20px",
                              color: "rgba(17, 21, 24, 1)",
                              // border:"1px solid red",
                              fontWeight: "500",
                              borderColor: state.isFocused
                                ? "rgba(249, 249, 249, 1)"
                                : "rgba(249, 249, 249, 1)",
                              backgroundColor: "rgba(249, 249, 249, 1)",
                            }),
                          }}
                          options={options}
                          placeholder="Select message type"
                          value={messageType}
                          onChange={onMessageTypeChange}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    }
                  </div>
                </Form.Group>
              </div>
              <div className="recipient-item-pair-container">
                <Form.Group id="email">
                  <div className="mb-3">
                    <p className="recipient-details-label">Message Priority</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        type="number"
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        required
                        placeholder="Message Priority"
                        value={messagePriority}
                        onChange={onMessagePriorityChange}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>

                <Form.Group id="name">
                  <div className="mb-3">
                    <p className="recipient-details-label">Notification Type</p>
                    {
                      <div>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              flexGrow: 1,
                              marginTop: "16px",
                              padding: "3px 0px",
                              height: "40px",
                              borderRadius: "8px",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              lineHeight: "20px",
                              color: "rgba(17, 21, 24, 1)",
                              // border:"1px solid red",
                              fontWeight: "500",
                              borderColor: state.isFocused
                                ? "rgba(249, 249, 249, 1)"
                                : "rgba(249, 249, 249, 1)",
                              backgroundColor: "rgba(249, 249, 249, 1)",
                            }),
                          }}
                          options={notificationTypeList}
                          placeholder="Select message type"
                          value={notificationType}
                          isMulti
                          onChange={onNotificationTypeChange}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    }
                  </div>
                </Form.Group>
              </div>
              <div className="recipient-item-pair-container">
                <Form.Group id="lineChat">
                  <div className="mb-3">
                    <p className="recipient-details-label">Message Content</p>
                    <Box
                      sx={{
                        "& > :not(style)": { m: 1 },
                      }}
                    >
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Message Content"
                        required
                        value={message}
                        onChange={onMessageChange}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            marginLeft: 0,
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </Form.Group>
              </div>
              {error && <ShowError msg={error} />}

              {/* horizontal line break */}
              {addDividerMargin()}
              {addDivider()}
              <div className="pb-2"></div>
            </section>
          </div>
          <div className="recipient-details-footer">
            <div className="recipient-modal-response-buttons">
              <div
                className="approve"
                onClick={() => {
                  handleUpdateRequest();
                }}
              >
                <p className="approve-text">Update</p>
              </div>
            </div>
          </div>

          {/* end of temp content */}
        </Box>
      </Drawer>
    </div>
  );
};

UpdateMessageTemplateDrawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  setRefreshGrid: PropTypes.func,
  data: PropTypes.shape({
    templateName: PropTypes,
    message: PropTypes,
    messageType: PropTypes,
    messagePriority: PropTypes,
    notificationType: PropTypes,
    type: PropTypes,
    id: PropTypes,
    operation: PropTypes,
    requestedState: PropTypes,
    approvalStatus: PropTypes,
    approvalOperation: PropTypes,
    approvalEntityId: PropTypes,
  }),
};

export default UpdateMessageTemplateDrawer;
