import React from 'react'
import subMenuChat from "../../../assets/subMenuChat.svg"
import subMenuDraft from "../../../assets/subMenuDraft.svg"
import { Routes } from "../../../routes";
import {
  Nav,
} from "@themesberg/react-bootstrap";
import {  Dropdown } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

function SubMenu(props) {
  const eventKey = props.eventKey
  const history = useHistory()
  const handleSMSClick = () => {
    if(eventKey === "mis-dashboard/") {
      history.push(Routes.RTS.path)

    } if (eventKey === "contact-list/") {
      history.push(Routes.SMSContactList.path);
    } if (eventKey === "templates/"){
      history.push(Routes.MessageTemplates.path)

    } if(eventKey === "spam-filters/") {
      history.push(Routes.SMSSpamFilter.path)
    }
  }
  const handleEmailClick = () => {
    if(eventKey === "mis-dashboard/") {
      history.push(Routes.EMAIL_RTS.path)

    } if (eventKey === "contact-list/") {
      history.push(Routes. EmailContactList.path);
    } if (eventKey === "templates/"){
      history.push(Routes.EmailTemplates.path)

    } if(eventKey === "spam-filters/") {
      history.push(Routes.EmailSpamFilter.path)
    }
  }
  
 
  return (
    <Nav.Item>
      <Nav.Link >
    <Dropdown show={props.isOpen} onClose={()=>{props.onClose}}>
      <Dropdown.Menu className="user-dropdown-subMenu dropdown-menu-right mt-2" style={{paddingLeft:"8px", paddingRight: "8px",left:0}}>
                    <Dropdown.Item
                      className="fw-bold text-textPrimary dropdown-menu-icon"
                    >
                      <div style={{display:"flex", flexDirection:"row",gap:"12px",alignItems:"center"}} onClick={handleSMSClick}>
                        <div>
                          <img src={subMenuChat} style={{height:"24px",width:"24px"}} />
                        </div>
                        <div>
                          SMS
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="fw-bold text-textPrimary dropdown-menu-icon"
                    >
                      <div style={{display:"flex", flexDirection:"row",gap:"12px",alignItems:"center"}} onClick={handleEmailClick}>
                        <div>
                          <img src={subMenuDraft} style={{height:"24px",width:"24px",fill:"red"}}/>
                        </div>
                        <div style={{marginTop:"2px"}}>
                          Email
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </Nav.Link>
                </Nav.Item>

  )
}
SubMenu.propTypes = {
  eventKey: PropTypes.string,
  isOpen: PropTypes.func,
  onClose: PropTypes.func
}
export default SubMenu