import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ErrorContainers,
  ErrorText,
  StyledDiv,
  StyledTimePicker,
} from "./timeField.styles";
import minus from "../../../../assets/reicpient-details-modal/minus.svg";
import info from "../../../../assets/reicpient-details-modal/Info.svg";
import { lightTheme } from "./Themes";
import dayjs from "dayjs";
import { FlexColumn } from "./MultipleTimeField.styles";

const TimeField = ({
  // day = "",
  // handleTimeSlots,
  startTime,
  // endTime,
  // index,
  overlap,
  payload,
  setPayload,
  dayIndex,
  timefieldIndex,
  operationType,
}) => {
  const READ = "READ";
  const START = "start";
  const END = "end";
  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState(
    // dayjs("00:00", "HH:mm"),
    dayjs(payload[dayIndex]?.timeSlots[timefieldIndex]?.startTime) ||
      dayjs("00:00", "HH:mm"),
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    // dayjs("01:00", "HH:mm"),
    dayjs(payload[dayIndex]?.timeSlots[timefieldIndex]?.startTime) ||
      dayjs("01:00", "HH:mm"),
  );

  useEffect(() => {
    let startTime = "00:00";
    let endTime = "01:00";

    console.log({payload})
    console.log({dayIndex})
    console.log({timefieldIndex})

    if (payload.length) {
      startTime = payload[dayIndex].timeSlots[timefieldIndex].startTime;
      endTime = payload[dayIndex].timeSlots[timefieldIndex].endTime;
    }

    // const day = payload[dayIndex];

    // if(day){
    //   const timeSlots = day.timeSlots;
    //   if(timeSlots && timeSlots.length){
    //       const startTime = timeSlots[timefieldIndex]?.startTime;
    //       if(startTime){
    //         setSelectedStartTime(startTime)
    //       }
    //       const endTime = timeSlots[timefieldIndex]?.endTime;
    //       if(endTime){
    //         setSelectedEndTime(endTime)
    //       }
    //   }
    // }

    setSelectedStartTime(dayjs(startTime, "HH:mm"));
    setSelectedEndTime(dayjs(endTime, "HH:mm"));
  }, [payload, dayIndex, timefieldIndex]);

  // const handleStartTimeChange = (newValue) => {
  //   setSelectedStartTime(newValue);
  // };

  const getTime = (time) => {
    return time.format("HH:mm");
  };

  // const handleEndTimeChange = (newValue) => {
  //   setSelectedEndTime(newValue);
  //   handleTimeSlots(
  //     index,
  //     day,
  //     selectedStartTime.format("HH:mm"),
  //     newValue.format("HH:mm"),
  //   );
  // };

  // useEffect(() => {
  //   setSelectedStartTime(startTime);
  // }, [startTime, overlap]);

  // useEffect(() => {
  //   setSelectedEndTime(endTime);
  // }, [endTime, overlap]);

  const timeFieldStyles = {
    popper: {
      sx: {
        "& .MuiMenuItem-root": {
          "&.Mui-selected": {
            backgroundColor: lightTheme.surface.lightaccent2,
            color: lightTheme.content.primary,
          },
          color: lightTheme.content.primary,
        },
        "& .MuiButtonBase-root": {
          color: "black",
        },
        "& .MuiPaper-root": {
          borderRadius: "8px",
        },
      },
    },
  };

  const handleTimerClose = (event, timerType) => {
    if (operationType === READ) {
      return;
    }
    if (!event.target.matches(".MuiMenuItem-root")) {
      if (timerType === START) {
        setShowStartTimePicker(!showStartTimePicker);
      } else {
        setShowEndTimePicker(!showEndTimePicker);
      }
    }
  };

  const handleCloseTimePicker = (timerType) => {
    if (timerType === START) {
      setShowStartTimePicker(false);
    } else {
      setShowEndTimePicker(false);
    }
  };
  const handleStartTimeAcceptValue = (time) => {
    const updatedPayload = [...payload];
    updatedPayload[dayIndex].timeSlots[timefieldIndex].startTime =
      getTime(time);
    setPayload(updatedPayload);
  };

  const handleEndTimeAcceptValue = (time) => {
    const updatedPayload = [...payload];
    updatedPayload[dayIndex].timeSlots[timefieldIndex].endTime = getTime(time);
    setPayload(updatedPayload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FlexColumn>
        <StyledDiv overlap={overlap}>
          <div onClick={(event) => handleTimerClose(event, START)}>
            <StyledTimePicker
              disabled={operationType === READ}
              open={showStartTimePicker}
              slotProps={timeFieldStyles}
              defaultValue={startTime}
              value={selectedStartTime}
              onClose={() => handleCloseTimePicker(START)}
              onAccept={handleStartTimeAcceptValue}
            />
          </div>
          <img src={minus} alt="minus" />
          <div onClick={(event) => handleTimerClose(event, END)}>
            <StyledTimePicker
              disabled={operationType === READ}
              open={showEndTimePicker}
              defaultValue={selectedEndTime}
              value={selectedEndTime}
              slotProps={timeFieldStyles}
              onClose={() => handleCloseTimePicker(END)}
              onAccept={handleEndTimeAcceptValue}
            />
          </div>
        </StyledDiv>
        {overlap && (
          <ErrorContainers>
            <img src={info} alt="info" />{" "}
            <ErrorText>Working hours cannot overlap</ErrorText>
          </ErrorContainers>
        )}
      </FlexColumn>
    </LocalizationProvider>
  );
};
TimeField.propTypes = {
  day: PropTypes.string,
  handleTimeSlots: PropTypes.func,
  startTime: PropTypes.object,
  endTime: PropTypes.object,
  index: PropTypes.number,
  overlap: PropTypes.bool,
  payload: PropTypes.array,
  setPayload: PropTypes.func,
  dayIndex: PropTypes.number,
  timefieldIndex: PropTypes.number,
  operationType: PropTypes.string,
};
export default TimeField;
