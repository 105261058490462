const recipientConstants = {
  SMS: "SMS",
  EMAIL: "Email",
  ACCOUNT_MANAGEMENT: "Account Management",
  OTP: "OTP",
  PROMOTIONAL: "Promotional",
  PAYMENT_REMINDERS: "Payment Reminders",
  SECURITY_AND_FRAUD_PREVENTION: "Security And Fraud Prevention",
  TRANSACTIONAL: "Transactional",
  RECIPIENT_DRAWER_WIDTH:650
};

export { recipientConstants };
