import React, { useState, useEffect } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import MessageHealthStatusGrid from "../../components/Grids/MessageHealthStatusGrid";
import { MessageHealthStatusHeader } from "../../data/MISDashboardData"
import Error from "../../components/Error/Error";
import { Card } from "react-bootstrap";
import PropTypes from 'prop-types';



const MessageHealthStatus = (props) => {
    //states for filter by date range


    const [dataFetchError] = useState(false);

    useEffect(() => {
      }, [props.time]);
    

    //For api request with filter

    return (
        <div className="my-2 shadow-sm">
            {
                dataFetchError && <Error code={500} />
            }

            <Row className="mb-3">
                <Col xs={12} xl={12}>
                    <Card>
                        <Card.Body>
                            <MessageHealthStatusGrid
                                notificationType={"SMS"}
                                heading="Notification Health Status"
                                tableHeading={MessageHealthStatusHeader}
                                fromDate={props.fromDate}
                                toDate={props.toDate}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
MessageHealthStatus.propTypes = {
    time: PropTypes,
    fromDate: PropTypes,
    toDate: PropTypes
}
export default MessageHealthStatus
