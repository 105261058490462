import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import close_modal from "../../../assets/close.svg";
import { TextField } from "@mui/material";
import validate from "../../FormValidation/validation"
import ShowError from "../../FormValidation/ShowError";
import { useDispatch, useSelector } from "react-redux";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import { toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import PropTypes from "prop-types";

function MUIUpdateContactListDrawer({
  openDrawer,
  setOpenDrawer,
  entityDetails,
  refreshCache
}) {
  const axiosPrivate = CreateAxiosInstance()
  const dispatch = useDispatch()
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  const memberRole = useSelector(
    (state) => state.persistedReducer?.member?.data?.roles,
  );
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  useEffect(()=>{
    if (!openDrawer) {
      return;
    }
    setName(entityDetails.name)
  },[entityDetails, openDrawer])
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };

  const updateGroup = async () => {
    if (validate(name, "isEmpty")) {
      setNameError("Group Name cannot be empty");
      return;
    } else {
      setNameError("");
    }
    const payload = { name };
    await axiosPrivate
      .put(`${process.env.REACT_APP_GROUPS}/${entityDetails.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          {
            setOpenDrawer(false);
            memberRole === "TS_ADMIN"
              ? dispatch(
                  toggleAlert({
                    title: "Success!",
                    message: "Group was updated successfully.",
                  })
                )
              : dispatch(
                  toggleAlert({
                    title: "Success!",
                    message: "Update Group request raised successfully.",
                  })
                );
          }toggleDrawer(false);
          // setRefreshGrid(true);
          refreshCache("")
        }
      })
      .catch((err) => {
        console.error(err);
        setOpenDrawer(false)
        {
          memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Group could not be updated.",
                })
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                })
              );
        }
        // props.setShowAgGrid(true);
      });
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  };
  return (
    <div>
      {" "}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          console.log("hbh");
        }}
        BackdropProps={{ invisible: true }}
      >
        {openDrawer && (
          <Box
            sx={{ width: 650 }}
            role="presentation"
            className="recipient-details-container"
          >
            <div
              className="recipient-details-header"
              style={{ minHeight: "64px" }}
            >
              <div
                className="recipient-modal-title"
                style={{ marginBlock: "14px", paddingBlock: 0 }}
              >
                <h5>Update Group</h5>
                <img
                  style={{ cursor: "pointer" }}
                  src={close_modal}
                  alt="Close Modal"
                  onClick={toggleDrawer(false)}
                />
              </div>
              {/* <TabSwitch /> */}
              {addDivider()}
            </div>
            <div
              className="recipient-details-body-content"
              style={{
                marginBlock: "14px",
                paddingBlock: 0,
                height: "20px",
                minHeight: "20px",
              }}
            >
              <section className="">
                <div className="recipient-item-pair-container">
                  <div className="mb-3">
                    <p className="recipient-details-label">Name</p>
                    <Box sx={{ "& > :not(style)": { m: 1 } }}>
                      <TextField
                        id="input-with-icon-textfield"
                        label=""
                        notched={false}
                        placeholder="Add Group Name Here"
                        // required={true}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        InputProps={{
                          sx: {
                            padding: "1px 3px",
                            fontSize: "0.875rem",
                            lineHeight: "1.25rem",
                            borderRadius: "8px",
                            height: "40px",
                            "& .MuiSvgIcon-root": {
                              height: "20px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& .MuiInputBase-input": {
                              fontWeight: 700,
                              fontFamily: "Inter",
                              lineHeight: "14px",
                              color: "rgba(126, 126, 126, 1)",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                            background: "rgba(247, 247, 250, 1)",
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </section>
              {nameError && <ShowError msg={nameError} />}
            </div>
            <div className="recipient-details-footer">
              <div className="recipient-modal-response-buttons">
                <div
                  className="approve"
                  onClick={() => {
                    updateGroup();
                  }}
                >
                  <p className="approve-text">Update</p>
                </div>
              </div>
            </div>
          </Box>
        )}
      </Drawer>
    </div>
  );
}
MUIUpdateContactListDrawer.propTypes = {
  openDrawer: PropTypes.string,
  setOpenDrawer: PropTypes.func,
  setRefreshGrid: PropTypes.func,
  entityDetails: PropTypes.string,
  refreshCache: PropTypes.func
  
}

export default MUIUpdateContactListDrawer;
