import React from "react";
import { Navbar, Container } from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";

const Menubar = () => {

  const pageTitle = useSelector(
    (state) => state.persistedReducer?.pageTitle?.title,
  );

  const pageTitleStyle = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#111518",
    marginBlockStart:"16px"
  };

  return (
    <Navbar
      variant="dark"
      expanded
      className={`ps-0 pe-2 pb-0`}
      sticky="top"
    >
      <Container fluid style={{ paddingInline: "1.4rem" }}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 style={pageTitleStyle}>{pageTitle}</h5>
        </div>
      </Container>
    </Navbar>
  );
};
export default Menubar;
