import React, { useEffect } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import APISuccess from "../../components/Toasts/APISuccess";
import GroupGrid from "../../components/Grids/GroupGrid";
import { useDispatch, useSelector } from "react-redux";
import APIFail from "../../components/Toasts/APIFail";
import { getPageTitle } from "../../Redux/Reducers/pageTitleReducer";

export default function App() {
  const alertSuccess = useSelector(
    (state) => state.persistedReducer.alerts.showSuccess
  );
  const alertFail = useSelector(
    (state) => state.persistedReducer.alerts.showFail
  );
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const memberRole = useSelector(state => state.persistedReducer?.member?.data?.roles)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageTitle("Contact List"));
  }, [dispatch]);

  // Get all users
  // const { data: group, error, isLoading } = useQuery("group",
  //     () =>
  //         axiosWithBaseUrl(process.env.REACT_APP_GET_GROUPS, {
  //             headers: {
  //                 "Authorization": `Basic ${token}`
  //             }
  //         })
  //             .then(resp => resp.data.data),
  //     { retry: 1 }
  // )

  // if (error) return <Error code={error.response.status} />

  // if (isLoading) return <DataLoading />

  return (
    <Card border="light" className="bg-white shadow-sm my-2 ">
      <Card.Body>
        <Row>
          {/* toast for api success */}
          {alertSuccess && <APISuccess toggle={true} />}
          {/* toast for api fail */}
          {alertFail && <APIFail toggle={true} />}
          <Col xs={12} xl={12} className="mb-4 ">
            <Row>
              <Col xs={12} xl={12}>
                <Row>
                  <Col xs={12} className="px-0">
                    <GroupGrid
                      token={token}
                      tableHeading={["name", "users", "created by"]}
                      memberRole={memberRole}
                      // tableData={group}
                      fieldToFlex="GROUP"
                      // refetchData={refetchData}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
