import React, { useState, useEffect, useRef } from "react";
import RealTimeStats from "../../components/MISComponents/RealTimeStatsBadges";
import { useDispatch } from "react-redux";
import { getPageTitle } from "../../../Redux/Reducers/pageTitleReducer";
import MISFilterModal from "../../../components/Modals/MISFilterModal";
import DataLoading from "../../../components/Spinners/DataLoading";
import Error from "../../../components/Error/Error";
import { Card, Col, Row } from "react-bootstrap";
// import moment from "moment";
import EmailMessageHealthStatusGrid from "../../../components/Grids/EmailMessageHealthStatusGrid";
import {
  EmailHealthStatusHeader,
  FlashHealthStatusHeader,
} from "../../../data/MISDashboardData";
import RTSPieChart from "../../../components/Charts/RTSPieChart";
import mtz from "moment-timezone";
import Select from "react-select";
import {
  getCurrentDate,
  getDaysDate,
  getHoursDate,
  getLastMonthDate,
} from "../../../util/dateUtil";
import { getPieChartSliceData } from "../../../util/pieChartSliceData";
import { getPieChartSliceColors } from "../../../util/pieChartSliceColors";
import { flashRTSData } from "../../../DummyData/flash/flashRTSData";
import FlashMessageHealthGrid from "../../../components/Grids/FlashMessageHealthGrid";

const RTS = () => {
  //date/time filter states
  const [fromDate, setFromDate] = useState(getLastMonthDate());
  const [toDate, setToDate] = useState(new Date());
  const [time, setTime] = useState(null);

  //states for filter by date range
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const dispatch = useDispatch();
  const MHSRef = useRef();

  // states to store filtered data
  const [RTSData, setRTSData] = useState({});
  const [pieSlice, setPieSliceValue] = useState("");

  // boolean states
  const [showFilterTitle, setShowFilterTitle] = useState(false);
  const [showTimeFilterTitle, setShowTimeFilterTitle] = useState(false);
  const [RTSDataLoading, setRTSDataLoading] = useState(true);
  const [dataFetchError, setDataFetchError] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState(["Real Time Stats"]);
  const [pendingMessageStatusUpdateCount, setPendingMessageStatusUpdateCount] =
    useState(0);
  let pieChartData;

  //api for getting data for Real Time Stats
  const getRTSData = async () => {
    setRTSDataLoading(true);
    setRTSData(flashRTSData.data);
    setRTSDataLoading(false);
    return;
  };

  const getPieSliceValue = (value) => {
    setPieSliceValue(value);
  };

  useEffect(() => {
    pieChartData = {
      totalCount: RTSData?.totalMessagesCount || 0,
      data: getPieChartSliceData(
        pieSlice,
        RTSData?.data,
        "flash",
        pendingMessageStatusUpdateCount
      ),
    };
  }, [pendingMessageStatusUpdateCount]);

  // console.log({pendingMessageStatusUpdateCount})

  useEffect(() => {
    getRTSData();
  }, [fromDate, toDate, pieSlice, pendingMessageStatusUpdateCount]);

  //For Resetting Data
  const resetData = () => {
    setFromDate(getLastMonthDate());
    setToDate(getCurrentDate());
    setTime("");
    setPieSliceValue("Real Time Stats");
    setShowTimeFilterTitle(false);
    setShowFilterTitle(false);
    setBreadcrumbData(["Real Time Stats"]);
  };

  //For api request with filter
  const handleSubmit = async (e, onHide) => {
    e.preventDefault();
    onHide();

    setShowTimeFilterTitle(false);
    setShowFilterTitle(true);
    setPieSliceValue("");
    setTime(null);
  };

  const getTimeData = (time) => {
    switch (time) {
      case "hour":
        setFromDate(getHoursDate(1));
        setToDate(getCurrentDate());
        break;
      case "6hours":
        setFromDate(getHoursDate(6));
        setToDate(getCurrentDate());
        break;
      case "day":
        setFromDate(getDaysDate(1));
        setToDate(getCurrentDate());
        break;
      case "week":
        setFromDate(getDaysDate(7));
        setToDate(getCurrentDate());
        break;
      case "month":
        setFromDate(getDaysDate(30));
        setToDate(getCurrentDate());
        break;
      case "last2months":
        setFromDate(getDaysDate(60));
        setToDate(getCurrentDate());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setShowFilterTitle(false);
    time && setShowTimeFilterTitle(true);
  }, [time]);

  //Dispatch for page title
  useEffect(() => {
    dispatch(getPageTitle("Flash Real Time Stats"));
  }, []);

  if (RTSDataLoading) return <DataLoading />;

  const pieChartColorData = getPieChartSliceColors(pieSlice, RTSData?.data);
  pieChartData = {
    totalCount: RTSData?.totalMessagesCount || 0,
    data: getPieChartSliceData(
      pieSlice,
      RTSData?.data,
      "flash",
      pendingMessageStatusUpdateCount
    ),
  };

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#ffffff",
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: "#ffffff",
      fontWeight: 600,
    }),
  };

  return (
    <div className="mb-3 shadow-sm">
      {dataFetchError && <Error code={500} />}
      {/* START OF FILTERS */}
      <div className="my-3 d-flex justify-content-between">
        <div>
          {showFilterTitle ? (
            <div
              style={{ paddingInline: "1.2em", paddingTop: "0.4em" }}
              className="d-flex gap-4 justify-content-center"
            >
              <h5>Showing data</h5>
              <p>
                <b>From:</b> {new Date(fromDate).toDateString()}{" "}
                {new Date(fromDate).toTimeString().slice(0, 9)}
              </p>
              <p>
                <b>To:</b> {new Date(toDate).toDateString()}{" "}
                {new Date(toDate).toTimeString().slice(0, 9)} (Timezone:{" "}
                {mtz.tz.guess()})
              </p>
            </div>
          ) : (
            !showTimeFilterTitle && (
              <div
                style={{ paddingInline: "1.2em", paddingTop: "0.4em" }}
                className="d-flex gap-4 justify-content-center"
              >
                <h5>Showing data since 1 month ago</h5>
                <p>
                  <b>From:</b> {new Date(fromDate).toDateString()}{" "}
                  {new Date(fromDate).toTimeString().slice(0, 9)}
                </p>
                <p>
                  <b>To:</b> {new Date(toDate).toDateString()}{" "}
                  {new Date(toDate).toTimeString().slice(0, 9)} (Timezone:{" "}
                  {mtz.tz.guess()})
                </p>
              </div>
            )
          )}
          {showTimeFilterTitle && (
            <div
              style={{ paddingInline: "1.2em", paddingTop: "0.4em" }}
              className="d-flex gap-4 justify-content-center"
            >
              <h5>Showing data for past {time?.label}</h5>
              <p>
                <b>From:</b> {new Date(fromDate).toDateString()}{" "}
                {new Date(fromDate).toTimeString().slice(0, 9)}
              </p>
              <p>
                <b>To:</b> {new Date(toDate).toDateString()}{" "}
                {new Date(toDate).toTimeString().slice(0, 9)} (Timezone:{" "}
                {mtz.tz.guess()})
              </p>
            </div>
          )}
        </div>
        {/* FILTER BY TIME */}
        <div className="d-flex gap-3 align-items-center">
          <Select
            className="flex-grow-1 time-select-style"
            styles={colourStyles}
            classNamePrefix="time_select"
            placeholder="Filter By Time"
            value={time}
            onChange={(e) => {
              setTime({ label: e.label, value: e.value });
              getTimeData(e.value);
            }}
            options={[
              { label: "Last Hour", value: "hour" },
              { label: "Last 6 hours", value: "6hours" },
              { label: "Last Day", value: "day" },
              { label: "Last Week", value: "week" },
              { label: "Last Month", value: "month" },
              { label: "Last 2 Months", value: "last2months" },
            ]}
          />

          {/* FILTER BY DATE RANGE */}
          <MISFilterModal
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            handleSubmit={handleSubmit}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
          />
          <div onClick={() => resetData()} className="cursor-pointer">
            <img
              src="https://e7.pngegg.com/pngimages/923/202/png-clipart-computer-icons-arrow-reset-button-text-monochrome-thumbnail.png"
              alt="reset-button"
              width={30}
              className="reset-btn"
            />
          </div>
        </div>
      </div>
      {/* END OF FILTERS */}

      {/* REAL TIME STATS */}
      {/* REAL TIME STATS CHART */}
      <Card className="mb-3">
        <Row>
          <Col xs={12} xl={8}>
            <Card.Body>
              <RTSPieChart
                data={pieChartData}
                MHSRef={MHSRef}
                pieColors={pieChartColorData}
                RTSDataLoading={RTSDataLoading}
                getPieSliceValue={getPieSliceValue}
                BreadcrumbData={breadcrumbData}
                setBreadcrumbData={setBreadcrumbData}
                pieSlice={pieSlice}
                entity="flash messages"
              />
            </Card.Body>
          </Col>
          <Col xs={12} xl={4}>
            <Card.Body>
              <RealTimeStats
                data={RTSData}
                MHSRef={MHSRef}
                fromDate={fromDate}
                toDate={toDate}
                entity="Flash Messages"
                pendingMessageStatusUpdateCount={
                  pendingMessageStatusUpdateCount
                }
              />
            </Card.Body>
          </Col>
        </Row>
      </Card>

      {/* MESSAGE HEATLH STATS */}
      <Row className="mb-3" ref={MHSRef}>
        <Col xs={12} xl={12}>
          <Card>
            <Card.Body>
              <FlashMessageHealthGrid
                heading="Flash Messages Health Status"
                tableHeading={FlashHealthStatusHeader}
                fromDate={fromDate}
                toDate={toDate}
                pieSlice={pieSlice}
                MHSRef={MHSRef}
                pendingMessageStatusUpdateCount={
                  pendingMessageStatusUpdateCount
                }
                setPendingMessageStatusUpdateCount={
                  setPendingMessageStatusUpdateCount
                }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RTS;
