import { createSlice } from "@reduxjs/toolkit"

const PageTitleSlice = createSlice({
    name: "pageTitle",
    initialState: {
        title: "",
        isLoading: false
    },
    reducers: {
        getPageTitle: (state) => {
            state.isLoading = true;
        },
        setPageTitle: (state, action) => {
            state.title = action.payload;
            state.isLoading = false;
        }
    }

})

export const { getPageTitle, setPageTitle } = PageTitleSlice.actions
export default PageTitleSlice.reducer