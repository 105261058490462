import { axiosWithBaseUrl } from "../axios/baseUrl";

export const logoutAPI = async (token, refreshToken, dispatchLogout) => {
  await axiosWithBaseUrl
    .get(
      `${process.env.REACT_APP_KEYCLOAK_LOGOUT_API}?access_token=${token}&refresh_token=${refreshToken}`
    )
    .then((res) => {
      if (res?.status === 204) {
        dispatchLogout();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
