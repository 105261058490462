import proton from "../../../assets/Aggregators/email-Aggregators/proton.svg";
import hubspot from "../../../assets/Aggregators/email-Aggregators/hubspot.svg";
import aol from "../../../assets/Aggregators/email-Aggregators/sms_horizon.png";
import titan from "../../../assets/Aggregators/email-Aggregators/titan-logo-white.png";
import addAggregator from "../../../assets/Aggregators/addAggregator.png";

export default {
    aggregators:[
        {name:"add-Aggregator",photo:addAggregator, addButton:true, description:"Add an aggregator"},
        {name:"proton",photo:proton, description:"Reliable Enterprise-grade Communication"},
        {name:"hubspot",photo:hubspot, description:"Seamless collaboration and support"},
        {name:"aol",photo:aol, description:"6000+ businesses"},
        // {name:"titan",photo:titan, description:"3000+ Valued Customers"},
    ]

}