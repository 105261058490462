import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import React, { useEffect, useState, useCallback } from "react";
import { FormControl } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import {
  toggleAlert,
  toggleFailAlert,
} from "../../../Redux/Reducers/alertsReducers";
import validate from "../../FormValidation/validation";
import ShowError from "../../FormValidation/ShowError";
import Loader from "react-spinners/ClipLoader";
// import { axiosWithBaseUrl } from "../../../axios/baseUrl";
import PropTypes from "prop-types";
import { isValidNumber } from "libphonenumber-js";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import CreateEntity from "../../Buttons/CreateEntity";
import CreateRecipientDrawer from "../MUIModals/MUICreateRecipientModal";

function CreateUserModal(props) {
  const axiosPrivate = CreateAxiosInstance();
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [linechatId, setLineChatId] = useState("");
  const dispatch = useDispatch();
  // For validation
  const [error, setError] = useState("");
  const [group, setGroup] = useState([]);
  const [options, setOptions] = useState([]);
  const [groupLoading, setGroupLoading] = useState(true);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);

  const getAllGroups = useCallback(async () => {
    await axiosPrivate
      .post(
        process.env.REACT_APP_GROUPS,
        {
          startRow: 0,
          endRow: 100,
          sortModel: [],
          filterModel: {},
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        },
      )
      .then((response) => {
        const resp = response?.data?.data;
        setOptions(
          resp?.groupDetails.map((item) => {
            return { label: item.name, value: item.id };
          }),
        );
        setGroupLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.token, axiosPrivate]);

  useEffect(() => {
    getAllGroups();
  }, [getAllGroups]);

  const createUser = async (payload, hideModal) => {
    hideModal();
    props.setShowAgGrid(false);
    await axiosPrivate
      .post(`${process.env.REACT_APP_NEW_USER}`, payload, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message }),
          );
          props.setShowAgGrid(true);
        }
      })
      .catch((err) => {
        console.error(err);
        {
          props.memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "User was not created.",
                }),
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                }),
              );
        }
        props.setShowAgGrid(true);
      });
  };
  const handleFirstNameChange = (e) => {
    const input = e.target.value;
    const allowedCharacters = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    handleInputChange(
      input,
      allowedCharacters.test.bind(allowedCharacters),
      setFirstName,
      "Please enter only alphabets for the name.",
    );
  };

  const handleInputChange = (input, validator, setter, errorMessage) => {
    const isValid = validator(input);
    if (!isValid) {
      setError(errorMessage);
    } else {
      setError("");
    }
    setter(input);
  };
  useEffect(() => {
    if (firstName.length > 1 && lastname.length > 1) {
      setIsButtonEnabled(false);
    } else {
      setIsButtonEnabled(true);
    }
  }, [firstName, lastname]);

  const handleLastNameChange = (e) => {
    const input = e.target.value;
    const allowedCharacters = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/;
    handleInputChange(
      input,
      allowedCharacters.test.bind(allowedCharacters),
      setLastname,
      "Please enter only alphabets for the name.",
    );
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    handleInputChange(
      input,
      isValidNumber,
      setPhoneNumber,
      "Please Enter Valid Phone Number",
    );
  };
  const handleEmailChange = (e) => {
    const input = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    handleInputChange(
      input,
      emailRegex.test.bind(emailRegex),
      setEmail,
      "Please Enter Valid Email",
    );
  };
  const handleLineChatId = (e) => {
    const input = e.target.value;
    const userIdRegex = /^U[0-9a-f]{32}$/;
    handleInputChange(
      input,
      userIdRegex.test.bind(userIdRegex),
      setLineChatId,
      "Please Enter Valid Line Chat User Id",
    );
  };
  const handleClick = (e, hideModal) => {
    e.preventDefault();

    /* Validate */
    if (
      validate(phoneNumber, "isEmpty") &&
      validate(email, "isEmpty") &&
      validate(linechatId, "isEmpty")
    ) {
      setError("Please enter either Email, Phone Number or Line Chat Id ");
      return;
    } else {
      setError("");
      if (!validate(email, "isEmpty")) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          setError("Please Enter valid Email");
          return;
        }
      }

      if (!validate(phoneNumber, "isEmpty")) {
        const isValid = isValidNumber(phoneNumber);
        if (!isValid) {
          setError("Please Enter Valid Phone Number");
          return;
        }
      }

      if (!validate(linechatId, "isEmpty")) {
        const userIdRegex = /^U[0-9a-f]{32}$/;
        if (!userIdRegex.test(linechatId)) {
          setError("Please Enter Valid Line Chat User Id");
          return;
        }
      }
    }
    const groupIds = group.map((item) => item.value);
    const templateData = {
      firstName: firstName,
      lastName: lastname,
      phoneNumber: phoneNumber,
      groupIds: groupIds ? groupIds : null,
      email: email,
      lineChatUserId: linechatId,
    };
    createUser(templateData, hideModal);
  };

  return (
    <Modal
      {...props}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.memberRole === "TS_USER"
            ? "Create Recipient Request"
            : "Create Recipient"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {groupLoading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <Form>
            <Form.Group id="name">
              <div className="mb-3">
                <Form.Label>First Name</Form.Label>
                <FormControl
                  aria-label="With text"
                  placeholder="Recipient's First Name"
                  required
                  value={firstName}
                  onChange={(e) => {
                    handleFirstNameChange(e);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group id="name">
              <div className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <FormControl
                  aria-label="With text"
                  placeholder="Recipient's Last Name"
                  required
                  value={lastname}
                  onChange={(e) => handleLastNameChange(e)}
                />
              </div>
            </Form.Group>
            <Form.Group id="phoneNumber">
              <div className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Recipient's Phone Number"
                  aria-label="With text"
                  required
                  value={phoneNumber}
                  onChange={(e) => handlePhoneChange(e)}
                />
              </div>
            </Form.Group>
            <Form.Group id="email">
              <div className="mb-3">
                <Form.Label>Email Id</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Recipient's Email"
                  aria-label="With text"
                  required
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>
            </Form.Group>
            <Form.Group id="lineChat">
              <div className="mb-3">
                <Form.Label>LineChat Id</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Recipient's line Chat Id"
                  aria-label="With text"
                  required
                  value={linechatId}
                  onChange={(e) => handleLineChatId(e)}
                />
              </div>
            </Form.Group>
            {groupLoading ? (
              <p>loading groups..</p>
            ) : (
              <Form.Group>
                <div className="mb-3">
                  <Form.Label>Contact List</Form.Label>
                  <Select
                    options={options}
                    placeholder="Add Recipient to a Contact List"
                    value={group}
                    isMulti
                    onChange={(e) => setGroup(e)}
                  />
                </div>
              </Form.Group>
            )}
            {error && <ShowError msg={error} />}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          onClick={(e) => handleClick(e, props.onHide)}
          disabled={isButtonEnabled}
        >
          Create
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

CreateUserModal.propTypes = {
  token: PropTypes.string,
  onHide: PropTypes.func,
  memberRole: PropTypes.string,
  setShowAgGrid: PropTypes.func,
};

const App = ({ token, memberRole, setShowAgGrid, setRefreshGrid }) => {
  const [modalShow, setModalShow] = useState(false);
  const [showCreateRecipientDrawer, setShowCreateRecipientDrawer] =
    useState(false);

  return (
    <>
     <CreateEntity
        btnText={
          memberRole === "TS_USER"
            ? "Create Recipient Request"
            : "Create Recipient"
        }
        // onClick={() => setModalShow(true)}
        onClick={() => setShowCreateRecipientDrawer(true)}
      />

        {
          showCreateRecipientDrawer &&
          <CreateRecipientDrawer
          openDrawer={showCreateRecipientDrawer}
          setOpenDrawer={setShowCreateRecipientDrawer}
          setShowAgGrid={setShowAgGrid}
          setRefreshGrid={setRefreshGrid}
        />}
      
     

      {modalShow && (
        <CreateUserModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          token={token}
          memberRole={memberRole}
          setShowAgGrid={setShowAgGrid}
        />
      )}
    </>
  );
};

App.propTypes = {
  token: PropTypes.string,
  memberRole: PropTypes.string,
  setShowAgGrid: PropTypes.func,
  setRefreshGrid: PropTypes.func,
};

export default App;
