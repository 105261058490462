import React, { useEffect } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import APISuccess from "../../components/Toasts/APISuccess";
import MessageTemplateGrid from "../../components/Grids/MessageTemplateGrid";
import APIFail from "../../components/Toasts/APIFail";
import { getPageTitle } from "../../Redux/Reducers/pageTitleReducer";
import {
  AggregatorItem,
  ChoosePhotoWidget,
  CounterWidget,
  ProfileCardWidget,
} from "../../components/Widgets";
import FlashAggregatorData from "../../DummyData/AggregatorData/FlashAggregatorData/FlashAggregatorData";
import { Link } from "react-router-dom";

export default () => {
  const alertSuccess = useSelector(
    (state) => state.persistedReducer.alerts.showSuccess
  );
  const token = useSelector((state) => state.persistedReducer?.member?.token);

  const alertFail = useSelector(
    (state) => state.persistedReducer.alerts.showFail
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageTitle("Flash Message Aggregators"));
  }, []);

  return (
    <div className="">
      {
        <div className="py-1">
          <Row>
            {FlashAggregatorData.aggregators.map((aggregator, index) => {
              return (
                <Col key={index} xs={12} sm={12} xl={3}>
                  <div>
                    <Link to={`/aggregators/flash/${aggregator.name}`}>
                      <AggregatorItem
                        // title={error ? <p className="text-gray">Failed to get data</p> : `${totalMessagesCount} ${totalMessagesCount >= 1000 ? "k" : ""}`}
                        // title={<p className="text-gray">Plivo</p>}
                        photo={aggregator.photo}
                        addButton={aggregator.addButton}
                        description={aggregator.description}
                      />
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      }
    </div>
  );
};
