import React, { useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { getTotal } from "../../util/totalCounter";
import { isLeaf } from "../../util/pieChartLeafSliceValidator";
import PropTypes from "prop-types";
import checkObjIsEmpty from "../../util/checkObjIsEmpty";

const RTSPieChart = ({
  data,
  MHSRef,
  BreadcrumbData,
  setBreadcrumbData,
  pieColors,
  getPieSliceValue,
  entity = "Messages",
}) => {
  let [pieData] = useState(data.data);
  const [subtitle] = useState(
    "Number of " + entity + ": " + getTotal(data?.data),
  );

  if (!checkObjIsEmpty(pieData)) {
    pieData = pieData.map((item) => ({
      ...item,
      v: Number(item.v),
    }));
  }

  const handleSlice = (type) => {
    if (BreadcrumbData.length === 0) {
      BreadcrumbData.push("");
    }
    if (!BreadcrumbData.includes(type.datum.k)) {
      const newData = [...BreadcrumbData, type.datum.k];
      setBreadcrumbData(newData);
    }

    getPieSliceValue(type.datum.k);
    if (isLeaf(type.datum.k)) MHSRef.current && MHSRef.current.scrollIntoView();
  };

  const pieChartOptions = {
      dataSource: {
      getData: () =>
        new Promise(() => {
          // Never resolve so the loading spinner remains
        }),
    },
    autoSize: true,
    title: {
      enabled: false,
      fontSize: 18,
    },
    subtitle: {
      text: subtitle,
      enabled: false,
    },
    series: [
      {
          dataSource: {
      getData: () =>
        new Promise(() => {
          // Never resolve so the loading spinner remains
        }),
    },
        data: pieData,
        type: "pie",
        legendItemKey: "k",
        calloutLabelKey: "k",
        calloutLabel: {
          formatter: ({ datum, calloutLabelKey, angleKey }) => {
            return `${datum[calloutLabelKey]}: ${datum[angleKey]}`;
          },
        },
        tooltip: { enabled: false },
        angleKey: "v",
        fills: pieColors,
        strokes: pieColors,
      },
    ],
    padding: {
      top: 60,
      bottom: 60,
    },
    legend: {
      position: "left",
    },
    listeners: {
      seriesNodeClick: (type) => {
        handleSlice(type);
      },
    },
    theme: {
      overrides: {
        pie: {
          series: {
            cursor: "pointer",
            highlightStyle: {
              item: {
                fill: undefined,
                stroke: "black",
                strokeWidth: 2,
              },
              series: {
                dimOpacity: 1,
                strokeWidth: 2,
              },
            },
          },
        },
      },
    },
  };

  const [options] = useState(pieChartOptions);

  return (
    <div className="mb-4" style={{ height: "450px" }}>
      <AgChartsReact options={options} />
    </div>
  );
};
RTSPieChart.propTypes = {
  data: PropTypes,
  MHSRef: PropTypes,
  BreadcrumbData: PropTypes,
  setBreadcrumbData: PropTypes,
  pieColors: PropTypes,
  getPieSliceValue: PropTypes,
  pieSlice: PropTypes,
  entity: PropTypes,
};

export default RTSPieChart;
