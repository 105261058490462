import { configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import { combineReducers } from "redux"
import persistReducer from "redux-persist/es/persistReducer"
import createSagaMiddleware from "@redux-saga/core"
import alerts from "./Reducers/alertsReducers"
import rootSaga from "./Saga/sagas"
import memberReducer from "./Reducers/memberReducer"
import pageTitleReducer from "./Reducers/pageTitleReducer"
// import dashBoardRTSSlice from "./Reducers/dashboardRTSReducer"
// import GroupReducer from "./Reducers/groupReducer"
// import userReducer from "./Reducers/userReducer"
// import messageTemplatesReducer from "./Reducers/messageTemplatesReducer"

const saga = createSagaMiddleware()

const reducers = combineReducers({
    // dashBoardRTS: dashBoardRTSSlice,
    // group: GroupReducer,
    // user: userReducer,
    // messageTemplates: messageTemplatesReducer,
    alerts,
    member: memberReducer,
    pageTitle: pageTitleReducer
})

const persistConfig = {
    key: "root", storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: {
        persistedReducer
    }
    ,
    middleware: [saga]
})

saga.run(rootSaga)

export default store