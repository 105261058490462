export const data = {
    "endpoint": "http://localhost:9001/bank/send/api-triggered-request",
    "http_method": "POST",
    "json_content": `'{
        "phoneNumber": "Enter mobile number here",
        "notificationType": ["SMS", "EMAIL", "INAPP"],
        "message": "Your OTP for transaction id for XYZ123 is 123456",
        "token": "Enter-token-value-here",
        "messageType": "Transactional",
        "requestTime": "2022-06-23T14:00:00Z"
    }'`,
    "curl_command": `curl --location --request POST 'http://localhost:9000/bank/sms/web/quicksend/microgateway' \
    --header 'Solace-Reply-Wait-Time-In-ms: 1' \
    --header 'Content-Type: application/json' \
    --header 'Cookie: TSID=b7aadccab6a6ec70' \
    --data-raw '{
        "phoneNumber": "Enter mobile number here",
        "notificationType": ["SMS", "EMAIL", "INAPP"],
        "message": "Your OTP for transaction id for XYZ123 is 123456",
        "token": "Enter-token-value-here",
        "messageType": "Transactional",
        "requestTime": "2022-06-23T14:00:00Z"
    }   '`,
    "expected_response": "{\n'status':{\n\t'code':'200',\n\t'message':'Successful'\n\t}\n}"
}