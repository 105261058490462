import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { toggleAlert } from "../../../Redux/Reducers/alertsReducers";
import { toggleFailAlert } from "../../../Redux/Reducers/alertsReducers";
import close_modal from "../../../assets/close.svg";
import { TextField } from "@mui/material";
import { CreateAxiosInstance } from "../../../hooks/useAxiosPrivate";
import validate from "../../FormValidation/validation";
import ShowError from "../../FormValidation/ShowError";
import PropTypes from 'prop-types';

function MUICreateContactList({
  openDrawer,
  setOpenDrawer,
  memberRole,
  token,
  // setShowAgGrid,
  refreshCache
}) {
  const DIVIDER_COLOR = "rgba(233,233,233,1)";
  const axiosPrivate = CreateAxiosInstance();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const dispatch = useDispatch();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };
  const addDivider = () => {
    return <Divider sx={{ borderBottomWidth: 2, background: DIVIDER_COLOR }} />;
  };
  const handleClick = () => {
    //Validate form
    if (validate(name, "isEmpty")) {
      setNameError("Group Name cannot be empty");
      return;
    } else {
      setNameError("");
    }

    const newGroup = { name };

    createGroup(newGroup);
  };
  const createGroup = async (payload) => {
    // hideModal();
    // props.setShowAgGrid(false);
    await axiosPrivate
      .post(`${process.env.REACT_APP_NEW_GROUP}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("yes")
        if (res?.data?.status === 200) {
          setOpenDrawer(false);
          dispatch(
            toggleAlert({ title: "Success!", message: res.data.message })
          );
          // props.setShowAgGrid(true)
          toggleDrawer(false);
          refreshCache("")
          // setRefreshGrid(true);
        }
      })
      .catch((err) => {
        console.log("no")
        console.error(err);
        setOpenDrawer(false);
        {
          memberRole === "TS_ADMIN"
            ? dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Group could not be created.",
                })
              )
            : dispatch(
                toggleFailAlert({
                  title: "Failed!",
                  message: "Request not raised.",
                })
              );
        }
        // props.setShowAgGrid(true)
      });
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          console.log("cnj");
        }}
        BackdropProps={{ invisible: true }}
      >
        <Box
          sx={{ width: 650 }}
          role="presentation"
          className="recipient-details-container"
        >
          <div
            className="recipient-details-header"
            style={{ minHeight: "64px" }}
          >
            <div
              className="recipient-modal-title"
              style={{ marginBlock: "14px", paddingBlock: 0 }}
            >
              <h5>Create Group</h5>
              <img
                style={{ cursor: "pointer" }}
                src={close_modal}
                alt="Close Modal"
                onClick={toggleDrawer(false)}
              />
            </div>
            {addDivider()}
          </div>
          <div
            className="recipient-details-body-content"
            style={{
              marginBlock: "14px",
              paddingBlock: 0,
              height: "20px",
              minHeight: "20px",
            }}
          >
            <section className="">
              <div className="recipient-item-pair-container">
                <div className="mb-3">
                  <p className="recipient-details-label">Name</p>
                  <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <TextField
                      id="input-with-icon-textfield"
                      label=""
                      notched={false}
                      placeholder="Add Group Name Here"
                      required={true}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      InputProps={{
                        sx: {
                          padding: "1px 3px",
                          fontSize: "0.875rem",
                          lineHeight: "1.25rem",
                          borderRadius: "8px",
                          height: "40px",
                          "& .MuiSvgIcon-root": {
                            height: "20px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& .MuiInputBase-input": {
                            fontWeight: 700,
                            fontFamily: "Inter",
                            lineHeight: "14px",
                            color: "rgba(126, 126, 126, 1)",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                          background: "rgba(247, 247, 250, 1)",
                        },
                      }}
                    />
                  </Box>
                </div>
              </div>
            </section>
            {nameError && <ShowError msg={nameError} />}
          </div>
          <div className="recipient-details-footer">
            <div className="recipient-modal-response-buttons">
              <div
                className="approve"
                onClick={() => {
                  handleClick();
                }}
              >
                <p className="approve-text">Add</p>
              </div>
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
}
MUICreateContactList.propTypes = {
  token: PropTypes.string,
  memberRole: PropTypes.string,
  setRefreshGrid: PropTypes.func,
  openDrawer: PropTypes.string,
  setOpenDrawer: PropTypes.func,
  refreshCache: PropTypes.func
}

export default MUICreateContactList;
