import PropTypes from "prop-types";
import React from "react";
import right_arrow from "../../../assets/reicpient-details-modal/arrow_right_alt.svg";
import { recipientConstants } from "../../Modals/MUIModals/Constants";

const DetailCellRenderer = ({ prevStateValue, currentStateValue }) => {
  if (!prevStateValue || !currentStateValue) {
    return <div>No data to show</div>;
  }
  const beforeJsonObject = JSON.parse(prevStateValue.preferences);
  const afterJsonObject = JSON.parse(currentStateValue.preferences);

  const configurationMapper = (item) => {
    if (item === "transactional") {
      return "Transactional";
    }
    if (item === "accountManagement") {
      return recipientConstants.ACCOUNT_MANAGEMENT;
    }
    if (item === "securityAndFraudPrevention") {
      return recipientConstants.SECURITY_AND_FRAUD_PREVENTION;
    }
    if (item === "promotional") {
      return recipientConstants.PROMOTIONAL;
    }
  };

  const militaryTo12Hour = (time) => {
    // Convert military time to 24-hour format (HH:MM)
    const militaryTime = time.toString().padStart(4, "0");
    const hours = militaryTime.slice(0, 2);
    const minutes = militaryTime.slice(2, 4);

    // Create a Date object to handle time formatting
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format time in 12-hour format (hh:mm AM/PM)
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };

  const getSlots = (timeSlot) => {
    return (
      <div>
        {`${militaryTo12Hour(timeSlot.startTime)} to ${militaryTo12Hour(
          timeSlot.endTime,
        )}`}
      </div>
    );
  };

  const getDaySchedule = (dayItem) => {
    const day = dayItem.day;
    const timeSlots = dayItem.timeSlots.map((item) => getSlots(item));

    const formattedList = timeSlots.reduce((acc, currentItem, index) => {
      if (index === timeSlots.length - 1) {
        return [...acc, currentItem];
      } else {
        return [...acc, currentItem, ",  "];
      }
    }, []);

    return (
      <div className="d-flex flex-wrap">
        {day} (
        {formattedList.map((item) => (
          <div key={item}>{item}</div>
        ))}
        )
      </div>
    );
  };

  const getConfiguration = () => {
    /**
     * 1. bring the nested key values to same level
     * e.g. {level1:{level2:value}} -> {level1:level2,level2:value }
     * Do the same for previous and current objects in audit logs
     */
    const prevList = [];
    const prevMessageTypes = beforeJsonObject.messageTypes;
    for (const messageType in prevMessageTypes) {
      const item = prevMessageTypes[messageType];
      const newItem = {
        key: messageType,
        ...item,
      };
      prevList.push(newItem);
    }
    const currentList = [];
    const currentMessageTypes = beforeJsonObject.messageTypes;
    for (const messageType in currentMessageTypes) {
      const item = currentMessageTypes[messageType];
      const newItem = {
        key: messageType,
        ...item,
      };
      currentList.push(newItem);
    }
    /**
     * 2. create the final jsx to be returned, using item for previousState item and currentList[index] to get currentState item
     *
     */
    const finalList = prevList.map((item, index) => {
      return (
        <div key={item} className="d-flex gap-2 align-items-center">
          <div className="previous flex-grow-1">
            {
              <div className="audit-log-text" key={item.key}>
                {/* {configurationMapper(item.key)} ({item.enabled ? "On" : "Off"}) */}
                {configurationMapper(item.key) ===
                recipientConstants.TRANSACTIONAL
                  ? `${configurationMapper(item.key)} (${
                      item.subtypes.otp ? "OTPs" : " "
                    } ${item.subtypes.paymentReminders ? ", " : ""}${
                      item.subtypes.paymentReminders ? "Payment reminders" : ""
                    })`
                  : `${configurationMapper(item.key)} (${
                      item.enabled ? "On" : "Off"
                    })`}
              </div>
            }
          </div>
          <div className="flex-grow-1">
            <img src={right_arrow} alt="right arrow" />
          </div>
          <div className="after flex-grow-1">
            {
              <div className="audit-log-text" key={currentList[index].key}>
                {configurationMapper(currentList[index].key) ===
                recipientConstants.TRANSACTIONAL
                  ? `${configurationMapper(currentList[index].key)} (${
                      currentList[index].subtypes.otp ? "OTPs" : " "
                    } ${
                      currentList[index].subtypes.paymentReminders ? ", " : ""
                    }${
                      currentList[index].subtypes.paymentReminders
                        ? "Payment reminders"
                        : ""
                    })`
                  : `${configurationMapper(currentList[index].key)} (${
                      currentList[index].enabled ? "On" : "Off"
                    })`}
              </div>
            }
          </div>
        </div>
      );
    });
    return finalList;
  };

  const getCapabilities = (item) => {
    getConfiguration(beforeJsonObject.messageTypes);
    return (
      <div>
        {item.email && "Email"}
        {item.sms && ","} {item.sms && "SMS"}
      </div>
    );
  };

  return (
    <>
      <div className="audit-log-container">
        {/* Promotional Schedule */}
        <section className="promotional_schedule p-3">
          <p className="recipient-details-label">Promotional schedule</p>
          <div className="d-flex gap-2 align-items-center">
            <div className="previous flex-grow-1">
              {beforeJsonObject.promotionTimes.map((item) => (
                <div className="audit-log-text" key={item}>
                  {getDaySchedule(item)}
                </div>
              ))}
            </div>
            <div className="flex-grow-1">
              <img src={right_arrow} alt="right arrow" />
            </div>
            <div className="after flex-grow-2">
              {afterJsonObject.promotionTimes.map((item) => (
                <div className="audit-log-text" key={item}>
                  {getDaySchedule(item)}
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* Configuration */}
        <section className="configuration p-3">
          <p className="recipient-details-label">Configuration</p>
          {getConfiguration()}
        </section>
        {/* Capabilites */}
        <section className="capabilities p-3">
          <p className="recipient-details-label">Capabilities</p>
          <div className="d-flex gap-2 align-items-center">
            <div className="previous">
              <div className="audit-log-text">
                {getCapabilities(beforeJsonObject.notificationPreferences)}
              </div>
            </div>
            <div className="">
              <img src={right_arrow} alt="right arrow" />
            </div>
            <div className="after">
              <div className="audit-log-text">
                {getCapabilities(afterJsonObject.notificationPreferences)}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
DetailCellRenderer.propTypes = {
  prevStateValue: PropTypes.string,
  currentStateValue: PropTypes.string,
};

export default DetailCellRenderer;
