export  const hasTimedOut = (message)=>{

    //Date validation
    // const messageDay = Number(message.updatedOn.split(" ")[1].slice(0,2));
    // const messageMonth = Number(message.updatedOn.split(" ")[1].slice(3,5));
    // const messageYear =  Number(message.updatedOn.split(" ")[1].slice(6,10));
    // const currentYear =  new Date().getFullYear();
    // const currentMonth = new Date().getMonth();
    // const currentDay = new Date().getDate();


    // if(messageDay!==currentDay || messageMonth!==currentMonth || messageYear!==currentYear){
    //   return true;
    // }

    //Time Validation
    const messageHour = 3600* Number(message.updatedOn.split(" ")[1].slice(0,2));
    const messageMinute = 60 * Number(message.updatedOn.split(" ")[1].slice(3,5));
    const messageSecond = Number(message.updatedOn.split(" ")[1].slice(6,8));
    const messageTimeSum = messageHour+messageSecond+messageMinute;
    const currentHour = 3600* new Date().getHours();
    const currentMinute = 60 * new Date().getMinutes();
    const currentSecond = new Date().getSeconds();
    const currentTimeSum = currentHour + currentMinute + currentSecond;

    if(Math.abs(messageTimeSum - currentTimeSum) > 30){
      return true;
    }

    return false;
  }
