import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: "transparent",
      border: '1px solid transparent',
      fontSize: 14,
      padding: 0,
      height:"1px",
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "Inter"
      ].join(',')
    },
  }));


  const accordionStyles = makeStyles(() => ({
    accordionRoot: {
      border: 'none',
      boxShadow: 'none',
      '&:before': {
        display: 'none', // Remove the border before pseudo-element
      },
    },
  }));

  const dashboardAccordionStyles = makeStyles(() => ({
    accordionRoot: {
      border: 'none',
      boxShadow: 'none',
      '& .MuiAccordionSummary-expandIconWrapper':{
        background:"rgba(249, 249, 249, 1)",
        padding:"5px 7px",
        borderRadius:"4px"
      },
      '&:before': {
        display: 'none', // Remove the border before pseudo-element
      },
    },
  }));


  export {BootstrapInput, accordionStyles, dashboardAccordionStyles};