import HomePage from "./pages/HomePage";
import React  from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollToTop from "./components/ScrollToTop";
import LoginSuccessComponent from "./LoginSuccessComponent";


let persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: 1,
      // staleTime: 1000
    },
  },
});
function App() {
  return  (
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ScrollToTop />
            <Switch>
              <Route exact path="/login-success" component={LoginSuccessComponent} />
              <Route path="/" component={HomePage} />
            </Switch>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </HashRouter>
  ) 
}

export default App;
