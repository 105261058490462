import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import GlobalSearch from "../../components/GlobalSearch/GlobalSearch";
import { CreateAxiosInstance } from "../../hooks/useAxiosPrivate";
import { removePlusPrefix } from "../../util/RemovedPlusPrefixFromString";
import { convertAgGridTimeToLocalTime } from "../../util/dateUtil";
import DownloadCSV from "../Buttons/DownloadCSV";
import EntityModal from "../Modals/entityDetailModal";

const MessageHealthStatusGrid = ({
  notificationType,
  heading,
  fromDate,
  toDate,
  pieSlice,
  deliveryFailed,
}) => {
  // User token to pass for api requests
  const axiosPrivate = CreateAxiosInstance();
  const token = useSelector((state) => state.persistedReducer?.member?.token);
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [agGridData, setAgGridData] = useState();
  let totalRecords = useRef(0);
  const paginationPageSize = 15;
  const cacheBlockSize = 15;
  // const [showAgGrid, setShowAgGrid] = useState(true);
  // const [globalSearchInput, setGlobalSearchInput] = useState("");

  //START OF AG GRID CONFIGURATIONS
  /*
    Three types of column filter: agTextColumnFilter, agNumberColumnFilter & agDateColumnFilter
     */
  const ViewAuditLogs = (params) => {
    const handleAgGridData = async () => {
      let payload = {
        startRow: 0,
        endRow: 100,
        sortModel: [],
        filterModel: {
          entityId: {
            filterType: "text",
            type: "contains",
            filter: params.data.entityId,
          },
        },
      };
      await axiosPrivate
        .post(`${process.env.REACT_APP_AUDIT_LOG}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            from: fromDate,
            to: toDate,
            Timezone: moment().utcOffset(),
          },
        })
        .then((response) => {
          const resp = response?.data?.data;
          setShowDetails(true);
          setAgGridData(resp.auditLogs);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    return (
      <div className="d-flex gap-3">
        <div
          className="action-btn"
          onClick={() => {
            handleAgGridData();
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
    );
  };
  const colDefs = [
    {
      headerName: "Created by",
      field: "createdByName",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Requested time",
      field: "createdOn",
      flex: 1,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["lessThan", "greaterThan", "inRange"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Delivered time",
      field: "messageDeliveredTime",
      flex: 1,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["lessThan", "greaterThan", "inRange"],
        suppressAndOrCondition: true,
      },
    },
    {
      headerName: "Send message content",
      field: "messageContent",
      flex: 2,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Message provider",
      field: "messageProvider",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Status",
      field: "messageStatus",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
    {
      headerName: "Failure Reason",
      field: "messageFailureReason",
      flex: 1,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true },
    },
  ];
  const ActionButtons = (params) => {
    const sendTo =
      notificationType === "EMAIL" && params?.data?.email
        ? params.data.email
        : params.data.phoneNumber;
    return (
      <div className="d-flex gap-3">
        <div className="action-btn">{sendTo}</div>
      </div>
    );
  };

  const ActionButtonsSubject = (params) => {
    const subject =
      notificationType === "EMAIL" && params?.data?.messageSubject
        ? params.data.messageSubject
        : "";
    return (
      <div className="d-flex gap-3">
        <div className="action-btn">{subject}</div>
      </div>
    );
  };
  colDefs.splice(4, 0, {
    headerName: "Send to",
    field: notificationType === "EMAIL" ? "email" : "phoneNumber",
    cellRenderer: "renderActionButtons",
  });
  if (notificationType === "EMAIL") {
    colDefs.splice(3, 0, {
      headerName: "subject",
      cellRenderer: "renderActionButtonsSubject",
    });
  }

  const frameworkComponents = {
    renderActionButtons: ActionButtons,
    renderActionButtonsSubject: ActionButtonsSubject,
    renderViewAuditLogs: ViewAuditLogs,
  };
  const [columnDefs] = useState(colDefs);
  const dataSource = {
    async getRows(params) {
      const { startRow, endRow, filterModel, sortModel } = params.request;
      if (Object.keys(filterModel).length > 0) {
        convertAgGridTimeToLocalTime(filterModel, fromDate, toDate);
      }
      let payload;
      if (deliveryFailed && deliveryFailed.current) {
        payload = {
          startRow: startRow,
          endRow: endRow,
          sortModel: sortModel,
          filterModel: filterModel,
          smsFailureReason: pieSlice,
        };
      } else {
        payload = {
          startRow: startRow,
          endRow: endRow,
          sortModel: sortModel,
          filterModel: filterModel,
          pieSliceValue: pieSlice,
        };
      }

      await axiosPrivate
        .post(
          `${process.env.REACT_APP_GET_MESSAGE_HEATLH_STATUS}?notification-type=${notificationType}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              from: fromDate,
              to: toDate,
              Timezone: moment().utcOffset(),
            },
          },
        )
        .then((response) => {
          const resp = response?.data?.data;
          if (!resp?.messageDetails?.length) {
            params.successCallback([], 0);
            params.api.showNoRowsOverlay();
          } else {
            params.successCallback(
              resp.messageDetails,
              resp.totalMessagesCount,
            );
            params.api.hideOverlay();
          }

          //For CSV download button setting the totalRows:
          totalRecords.current = resp.totalMessagesCount;
        })
        .catch((error) => {
          console.error(error);
          params.failCallback();
        });
    },
  };

  //change grid data according to filters used
  useEffect(() => {
    rowData && rowData.setServerSideDatasource(dataSource);
    // eslint-disable-next-line
  }, [fromDate, toDate, dataSource]);

  //change grid data according to pie chart slice click
  useEffect(() => {
    pieSlice && rowData && rowData.setServerSideDatasource(dataSource);
    // eslint-disable-next-line
  }, [pieSlice, dataSource]);

  const onGridReady = (params) => {
    // register datasource with the grid
    params.api.setServerSideDatasource(dataSource);
    if (rowData === null) setRowData(params.api);
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,

      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    }),
    [],
  );
  colDefs.push({
    headerName: "View audit logs",
    cellRenderer: "renderViewAuditLogs",
  });

  const onBtnExport = useCallback(async () => {
    gridRef.current.api.exportDataAsCsv(); //After download is complete, re-populate the ag grid data with current parameters
  }, []);

  const dimensions = { width: "100%" };

  const refreshCache = useCallback((searchdata) => {
    const newDataSource = {
      async getRows(params) {
        const { startRow, endRow, filterModel, sortModel } = params.request;
        if (Object.keys(filterModel).length > 0) {
          convertAgGridTimeToLocalTime(filterModel, fromDate, toDate);
        }
        let payload;
        if (deliveryFailed && deliveryFailed.current) {
          payload = {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            smsFailureReason: pieSlice,
            globalSearch: removePlusPrefix(searchdata),
          };
        } else {
          payload = {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel,
            pieSliceValue: pieSlice,
            globalSearch: removePlusPrefix(searchdata),
          };
        }

        await axiosPrivate
          .post(
            `${process.env.REACT_APP_GET_MESSAGE_HEATLH_STATUS}?notification-type=${notificationType}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                from: fromDate,
                to: toDate,
                Timezone: moment().utcOffset(),
              },
            },
          )
          .then((response) => {
            const resp = response?.data?.data;
            if (!resp?.messageDetails?.length) {
              params.successCallback([], 0);
              params.api.showNoRowsOverlay();
            } else {
              params.successCallback(
                resp.messageDetails,
                resp.totalMessagesCount,
              );
              params.api.hideOverlay();
            }

            //For CSV download button setting the totalRows:
            totalRecords.current = resp.totalMessagesCount;
          })
          .catch((error) => {
            console.error(error);
            params.failCallback();
          });
      },
    };
    gridRef.current.api.setServerSideDatasource(newDataSource);
  }, [axiosPrivate, deliveryFailed, fromDate, toDate, token, notificationType, pieSlice]);

  const refreshGrid = (searchData) => {
    if (!gridRef.current.api) {
      return;
    }
    if (searchData.length >= 3 || searchData.length === 0) {
      refreshCache(searchData);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="dashboard-heading">{heading}</h5>
          <div className="d-flex gap-3 align-items-center">
            <GlobalSearch refreshGrid={refreshGrid} />
            <div>
              <DownloadCSV onClick={onBtnExport} />
            </div>
          </div>
        </div>

        <div id="msgHealthTable" className="ag-theme-alpine" style={dimensions}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={paginationPageSize}
            cacheBlockSize={cacheBlockSize}
            domLayout="autoHeight" // if too many rows should not use this, give fixed height
            animateRows={true}
            rowModelType="serverSide"
            serverSideInfiniteScroll={true}
            onGridReady={onGridReady}
            overlayNoRowsTemplate="No data to show"
            components={frameworkComponents}
          />
        </div>
      </div>
      {showDetails && (
        <EntityModal
          modalShow={showDetails}
          setModalShow={setShowDetails}
          data={agGridData}
        />
      )}
    </>
  );
};
MessageHealthStatusGrid.propTypes = {
  heading: PropTypes,
  fromDate: PropTypes,
  toDate: PropTypes,
  pieSlice: PropTypes,
  notificationType: PropTypes,
  deliveryFailed: PropTypes,
};
export default MessageHealthStatusGrid;
