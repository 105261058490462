import React, { useEffect, useState } from 'react'
import { Image } from '@themesberg/react-bootstrap';
import ThingSphereBlack from "../../assets/img/technologies/thingsphere-black.png";

const DataLoading = () => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setShow(false), 1500);
        return () => clearTimeout(timer);
    }, []);


    return (
        // <div className="w-100 text-center mt-4"
        // >
        //     <ClipLoader color="gray" css={css} loading={true} size={50} />
        // </div>
        <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
            <Image className="loader-element animate__animated animate__jackInTheBox" src={ThingSphereBlack} height={50} />
        </div>

    )
}

export default DataLoading