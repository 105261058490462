import React, { useEffect } from "react";
import { Col, Row, Card } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import APISuccess from "../components/Toasts/APISuccess";
import APIFail from "../components/Toasts/APIFail";
import UsersGrid from "../components/Grids/UsersGrid";
import { getPageTitle } from "../Redux/Reducers/pageTitleReducer";

export default function App() {
    const alertSuccess = useSelector(state => state.persistedReducer.alerts.showSuccess);
    const alertFail = useSelector(state => state.persistedReducer.alerts.showFail);
    const token = useSelector(state => state.persistedReducer?.member?.token);
    const memberRole = useSelector(state => state.persistedReducer?.member?.data?.roles)
    const userEmail = useSelector(state => state.persistedReducer?.member?.data?.email);



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPageTitle("Recipients"));
    }, [dispatch])

    return (
        <Card border="light" className="bg-white shadow-sm my-2" style={{border:"1px solid blue",height:"calc(100vh - 80px)"}}>
            <Card.Body>
                <Row>
                    <Col xs={12} xl={12} className="mb-4">
                        <Row>
                            <Col xs={12} xl={12}>
                                <Row>
                                    <Col xs={12} className="px-0">
                                        {alertSuccess && <APISuccess toggle={true} />}
                                        {alertFail && <APIFail toggle={true} />}
                                        <UsersGrid
                                            token={token}
                                            tableData={[]}
                                            memberRole={memberRole}
                                            fieldToFlex="GROUP"
                                            userEmail={userEmail}
                                        />

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}
