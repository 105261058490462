import messente from "../../../assets/Aggregators/flashAggregators/messente.svg";
import toucantext from "../../../assets/Aggregators/flashAggregators/ToucanText.svg";
import Fast2SMS from "../../../assets/Aggregators/flashAggregators/Fast2SMS.png";
import addAggregator from "../../../assets/Aggregators/addAggregator.png";

export default {
  aggregators: [
    {
      name: "add-Aggregator",
      photo: addAggregator,
      addButton: true,
      description: "Add an aggregator",
    },
    {
      name: "Messente",
      photo: messente,
      description: "Reliable Enterprise-grade Communication",
    },
    {
      name: "Fast2SMS",
      photo: Fast2SMS,
      description: "Seamless collaboration and support",
    },
    { name: "ToucanText", photo: toucantext, description: "6000+ businesses" },
  ],
};
